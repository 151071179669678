import { get } from 'lodash';
import { createContext, useContext } from 'react';
import { AUTO_SAVABLE_STEP_TYPES } from '../../model/blockTypes';

const StepContext = createContext(null);

export const useDefinedCtx = () => {
  return useContext(StepContext) || {};
};

export const useStepType = () => {
  return useDefinedCtx().type;
};

export const useIsAutoSavable = (passedInType = null) => {
  const stepType = useStepType();
  return AUTO_SAVABLE_STEP_TYPES.includes(passedInType || stepType);
};

export const useIsInsideDashboard = () => {
  return useDefinedCtx().isInsideDashboard;
};

export const useStepIsDirty = () => {
  return useDefinedCtx().stepIsDirty;
};

export const traverseToPathValue = (path = [], valueToTraverse) => {
  if (path.length > 1) {
    const firstPieceValue = get(valueToTraverse, [path[0], 'value']);
    return traverseToPathValue(path.slice(1), firstPieceValue);
  } else if (path.length === 1) {
    return get(valueToTraverse, path[0]);
  }
};

const recursivelyFindValueForId = (idToFind, path = [], valueToTraverse) => {
  if (path.length > 1) {
    const valuesFound = [];
    const firstPieceValue = get(valueToTraverse, [path[0], 'value']);
    const childMatchingTargetId = get(firstPieceValue, idToFind);
    if (childMatchingTargetId) {
      valuesFound.push(childMatchingTargetId);
    }
    return [
      ...recursivelyFindValueForId(idToFind, path.slice(1), firstPieceValue),
      ...valuesFound,
    ];
  } else if (path.length === 1) {
    if (get(valueToTraverse, path[0])) {
      return [get(valueToTraverse, path[0])];
    }
  }

  return [];
};

export const useStepInputValue = (inputId, ideallyForPath) => {
  const values = useDefinedCtx().stepSentenceInputValues;
  if (ideallyForPath !== undefined) {
    const fullPathToTraverse = `${ideallyForPath}.${inputId}`.split('.');
    const valuesForIdealPath = recursivelyFindValueForId(
      inputId,
      fullPathToTraverse,
      values
    );
    if (valuesForIdealPath.length > 0) return valuesForIdealPath[0];
  }

  const topLevelPath = [inputId];
  const topLevelValue = traverseToPathValue(topLevelPath, values);
  return topLevelValue;
};

export const useAllStepInputValues = () => {
  return useDefinedCtx().stepSentenceInputValues;
};

export const useStepOutputs = () => {
  return useDefinedCtx().outputs;
};

export default StepContext; // TODO: if useful, provide context at root of each step
