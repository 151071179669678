import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Tooltip, Button, Badge } from 'antd';
import { useBillingInfo, useFeatureFlagInfo } from '../model/BillingContext';
import { Text } from './typography';

const FLAG_TYPES = {
  invisible: 'invisible',
  visible_not_usable: 'visible_not_usable',
};

const DisabledWrapper = styled.div`
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
`;

const TooltipHeader = styled(Text)`
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayscale.faintGray2};
  padding: 8px 16px;
`;

const RibbonContainerWrapper = styled.div`
  > .ant-ribbon-wrapper {
    margin-left: ${(p) => p.ribbonBadgeLeftMargin};
  }
`;

const TooltipContent = ({ to_unblock }) => {
  const history = useHistory();
  const { available_plans = {} } = useBillingInfo();
  if (!Object.keys(available_plans).length) return <Text>Loading...</Text>;

  let upgradePlans = 'right plan';

  if (to_unblock) {
    upgradePlans = to_unblock
      .reduce(
        (accum, planId) =>
          available_plans[planId]
            ? [...accum, available_plans[planId].name]
            : accum,
        []
      )
      .join(' or ');
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <TooltipHeader>Upgrade Required</TooltipHeader>
      <div style={{ padding: '4px 16px 16px' }}>
        <Text style={{ padding: '8px 0' }}>
          Please upgrade to the {upgradePlans} to access this feature.
        </Text>
        <Button
          onClick={() => history.push('/settings/billing/change-plans')}
          style={{ borderRadius: 4 }}
          size="large"
        >
          <Text style={{ fontSize: 16 }}>See Plans</Text>
        </Button>
      </div>
    </div>
  );
};

export default ({
  featureFlagId = '',
  tooltipPlacement = undefined,
  badgePlacement = 'start',
  children,
  neverEnforceVisiblity = false,
  ribbonBadgeLeftMargin,
}) => {
  const { color, typography } = useTheme();
  const { flag, to_unblock } = useFeatureFlagInfo(featureFlagId.toLowerCase());

  if (!flag) return <>{children}</>;
  if (flag === FLAG_TYPES.invisible && !neverEnforceVisiblity) return null;

  return (
    <Tooltip
      overlayClassName={'feature-flag-gate-tooltip-overlay'}
      // antd bug: this doesn't work, we use Stone Age global CSS instead (sigh...)
      // overlayStyle={{ backgroundColor: 'white', padding: 0 }}
      title={TooltipContent({ to_unblock })}
      placement={tooltipPlacement}
    >
      <RibbonContainerWrapper ribbonBadgeLeftMargin={ribbonBadgeLeftMargin}>
        <Badge.Ribbon text="Premium" color="purple" placement={badgePlacement}>
          <DisabledWrapper>{children}</DisabledWrapper>
        </Badge.Ribbon>
      </RibbonContainerWrapper>
    </Tooltip>
  );
};
