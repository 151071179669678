import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  GalleryPageTitle,
  SectionTitle,
} from '../standard-components/typography';
import {
  CardContainer,
  GalleryContentHeader,
  GalleryPageContainer,
} from '../standard-components/containers';
import INTEGRATION_TYPES from '../integration-components/integrationTypes';
import FeatureFlagGate from '../standard-components/FeatureFlagGate';

const PREVIEW_CARD_WIDTH = '228px';
const PREVIEW_CARD_HEIGHT = '165px';
const IntegrationCardContainer = styled(CardContainer)`
  margin-right: 40px;
  margin-bottom: 40px;
  padding: 20px 25px;
  min-width: ${PREVIEW_CARD_WIDTH};
  max-width: ${PREVIEW_CARD_WIDTH};
  min-height: ${PREVIEW_CARD_HEIGHT};
  max-height: ${PREVIEW_CARD_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const IntegrationTypeOptionsContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
`;

const IntegrationLogoImage = styled.img`
  width: 80px;
  height: 80px;
`;

const IntegrationTypeCard = ({ name, integration_type, image }) => {
  const history = useHistory();
  return (
    <FeatureFlagGate
      featureFlagId={`integrations.${integration_type}`}
      tooltipPlacement={'left'}
      neverEnforceVisiblity
    >
      <IntegrationCardContainer
        onClick={() => history.push(`/integrations/add/${integration_type}`)}
      >
        <IntegrationLogoImage src={image} />
        <SectionTitle bold grayscale darker>
          {name}
        </SectionTitle>
      </IntegrationCardContainer>
    </FeatureFlagGate>
  );
};

const IntegrationTypeOptions = () => (
  <IntegrationTypeOptionsContainer>
    {Object.values(INTEGRATION_TYPES).map((integrationType) => (
      <IntegrationTypeCard
        key={integrationType.integration_type}
        {...integrationType}
      />
    ))}
  </IntegrationTypeOptionsContainer>
);

const ButtonContainer = styled.div`
  width: 150px;
`;
const BackButton = styled(Button)`
  color: ${({ theme: { color } }) => color.grayscale.darkish};
`;

const BackButtonAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export default () => {
  const history = useHistory();

  useEffect(() => {
    window.analytics.page('Add Integration Page');
  }, []);

  return (
    <GalleryPageContainer>
      <GalleryContentHeader>
        <BackButtonAndTitleContainer>
          <ButtonContainer>
            <BackButton onClick={() => history.push('/integrations')}>
              Back
            </BackButton>
          </ButtonContainer>
          <GalleryPageTitle>Add Integrations</GalleryPageTitle>
          <ButtonContainer />
        </BackButtonAndTitleContainer>
      </GalleryContentHeader>
      <IntegrationTypeOptions />
    </GalleryPageContainer>
  );
};
