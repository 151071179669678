import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, message, Modal, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { PrimaryShadowButton } from '../standard-components/CustomColorButton';
import RequiresSubscriptionModal from '../auth-action-components/RequiresSubscriptionModal';
import { get } from 'lodash';

const Container = styled.div``;

const Body = styled.div`
  min-height: 100px;
  padding: 40px;
`;

const Footer = styled.div`
  padding: 15px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButton = styled(Button)`
  margin-left: 10px;
`;

const NewAppButton = styled(PrimaryShadowButton)`
  height: 40px;
  border-radius: 3px;
`;

export const CreateNewApp = () => {
  const team_id = useTeamId();
  const inputRef = React.useRef(null);
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [creationInProgress, setCreationInProgress] = useState(false);
  const [appNameDraft, setAppNameDraft] = useState('');
  const [createAppErrorCode, setCreateAppErrorCode] = useState('');
  const cleanUpAndCloseModal = () => {
    setAppNameDraft('');
    setShowCreateAppModal(false);
  };

  const [
    requiresSubscriptionModalVisible,
    setRequiresSubscriptionModalVisible,
  ] = useState(false);

  const history = useHistory();

  const submitAppForCreation = async () => {
    setCreationInProgress(true);
    const [result, error] = await makeAPICall({
      endpoint: `/app/`,
      method: 'POST',
      body: { name: appNameDraft, team_id },
    });

    setCreationInProgress(false);
    if (error) {
      if (error.code === 'TooManyApps') {
        cleanUpAndCloseModal();
        setCreateAppErrorCode(error.code);
        setRequiresSubscriptionModalVisible(true);
      } else {
        message.error(
          'Sorry something went wrong. Please try again or contact hello@intersectlabs.io'
        );
      }
    } else {
      const newCreatedAppId = get(result, 'id');
      window.analytics.track('Created App');
      history.push(`/apps/${newCreatedAppId}`);
    }
  };

  useEffect(() => {
    if (showCreateAppModal && inputRef.current) {
      inputRef.current.focus({
        cursor: 'start',
      });
    }
  }, [showCreateAppModal, inputRef]);

  return (
    <div>
      <NewAppButton
        size="large"
        icon={<PlusOutlined />}
        onClick={() => setShowCreateAppModal(true)}
      >
        New App
      </NewAppButton>

      <Modal
        title="Create New App"
        visible={showCreateAppModal}
        onCancel={cleanUpAndCloseModal}
        footer={null}
        okText="Create"
        width={700}
        bodyStyle={{ padding: 0 }}
      >
        <Spin spinning={creationInProgress === true} tip="Creating App...">
          <Container>
            <Body>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                size="middle"
              >
                <Form.Item
                  label="App Name:"
                  // hasFeedback={nameError !== undefined}
                  // validateStatus={nameError ? 'error' : ''}
                  // help={nameError || ''}
                >
                  <Input
                    type="text"
                    ref={inputRef}
                    value={appNameDraft}
                    placeholder="Enter App Name..."
                    onChange={(e) => {
                      setAppNameDraft(e.target.value);
                    }}
                    onPressEnter={submitAppForCreation}
                  />
                </Form.Item>
              </Form>
            </Body>
            <Footer>
              <ActionButton onClick={cleanUpAndCloseModal}>Cancel</ActionButton>
              <ActionButton
                disabled={!appNameDraft}
                onClick={submitAppForCreation}
                type="primary"
              >
                Create
              </ActionButton>
            </Footer>
          </Container>
        </Spin>
      </Modal>
      <RequiresSubscriptionModal
        visible={requiresSubscriptionModalVisible}
        onSelectComplete={() => {
          setRequiresSubscriptionModalVisible(false);
        }}
        message={createAppErrorCode}
      />
    </div>
  );
};
