import { get } from 'lodash';
import { useContext } from 'react';
import { BLOCK_GALLERY_URL_PREFIX } from '../pages/BlockGallery';
import { makeAPICall, useAPI } from '../api/useAPI';
import { openNotificationWithIcon } from '../standard-components/notificationHelper';
import { DataAppContext, useAppInfo } from './DataAppContext';

export const APP_STATUS = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  RUN: 'RUN',
};

const ACCESS_LEVELS = ['OWNER', 'EDITOR', 'USER', 'VIEWER', 'NO_ACCESS'];

export const useMetadata = () => {
  const { metadata } = useAppInfo();
  return metadata || {};
};

export const getViewingAppAsPublic = () => {
  const { pathname } = window.location;
  return (
    pathname.startsWith('/public') ||
    pathname.startsWith(BLOCK_GALLERY_URL_PREFIX) ||
    pathname.startsWith('/welcome')
  );
};

export const useAppName = () => {
  const { name } = useMetadata();
  return name;
};

export const useAppId = () => {
  const { id } = useMetadata();
  return id;
};

export const useAppStatus = () => {
  const { status } = useMetadata();
  return status;
};

export const useCanEditApp = () => {
  const { access } = useMetadata();

  const indexOfAccessLevel = ACCESS_LEVELS.indexOf(access);

  // If access level doesn't exist or access level is not OWNER or EDITOR, then can't edit
  if (indexOfAccessLevel === -1 || indexOfAccessLevel > 1) {
    return false;
  }

  return true;
};

export const useLastPublishedAt = () => {
  const { last_published_at } = useMetadata();
  return last_published_at;
};

export const useRunAt = () => {
  const { run_at } = useMetadata();
  return run_at;
};

export const getVersionId = (appInfo) => {
  const versionId = get(appInfo, 'metadata.version_id');
  return versionId;
};

export const getAppStatus = (appInfo) => {
  const status = get(appInfo, 'metadata.status');
  return status;
};

export const getInstanceId = (appInfo) => {
  const instanceId = get(appInfo, 'metadata.instance_id');
  const status = get(appInfo, 'metadata.status');

  if (instanceId === null && status === 'DRAFT') {
    return 'setup';
  }
  return instanceId;
};

export const useInstanceName = () => {
  const { instance_name } = useMetadata();
  return instance_name;
};

export const useVersionId = () => {
  const appInfo = useAppInfo();
  return getVersionId(appInfo);
};

export const useInstanceId = () => {
  const appInfo = useAppInfo();
  return getInstanceId(appInfo);
};

export const useAppVisibility = () => {
  const { visibility } = useMetadata();
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return {
    appVisibility: visibility,
    updateAppVisibility: (newVisibility) =>
      updateDataAppContextValue((draftState) => {
        draftState.data.metadata.visibility = newVisibility;
      }),
  };
};

export const useUpdateNotebookName = () => {
  const appId = useAppId();
  const appName = useAppName();

  const { updateDataAppContextValue } = useContext(DataAppContext);
  return async (newName) => {
    const oldName = appName;

    updateDataAppContextValue((draftState) => {
      draftState.data.metadata.name = newName;
    });

    const [result, error] = await makeAPICall({
      endpoint: `/app/rename?app_id=${appId}`,
      method: 'POST',
      body: {
        new_name: newName,
      },
    });

    if (!error) {
      return true;
    } else {
      updateDataAppContextValue((draftState) => {
        draftState.data.metadata.name = oldName;
      });
      openNotificationWithIcon(
        'error',
        "Couldn't change title",
        'Something went wrong! Please try again or contact support hello@intersectlabs.io'
      );
      return false;
    }
  };
};

/*** Runs */

export const useRunBy = () => {
  const { run_by } = useMetadata();
  return run_by;
};

export const useCurrentInstanceRunStatus = () => {
  const { run_status } = useMetadata();
  return run_status;
};

export const usePublicAppStatus = () => {
  const instanceId = useInstanceId();
  const appId = useAppId();
  return useAPI({
    endpoint: `/app/public?instance_id=${instanceId}&app_id=${appId}`,
  });
};
