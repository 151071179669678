/* eslint-disable import/no-anonymous-default-export */
import { EditFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import {
  useIsDataAppLoading,
  useIsPublishedOrRunVersionOpen,
} from '../model/DataAppContext';
import {
  useAppName,
  useLastPublishedAt,
  useAppId,
  useUpdateNotebookName,
} from '../model/DataAppMetadataContextHelpers';
import { Steps } from './Steps';
import EditableTitle from '../standard-components/EditableTitle';

const DraftEditorPage = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  background-color: ${(p) => p.theme.color.tertiary.dull};
  padding-top: 40px;
`;

const AppTitleBar = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.color.grayscale.white};
  font-size: ${(p) => p.theme.typography.fontSize.large};
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  margin: 60px 0px 60px 75px;
  background: ${(p) => p.theme.color.secondary.darkest};
  padding: 30px 30px 30px 50px;
  border-radius: 20px;
`;

const NotebookLabel = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  color: ${(p) => p.theme.color.grayscale.white};
  font-size: ${(p) => p.theme.typography.fontSize.ittybitty};
  padding: 5px 10px;
  background: ${(p) => p.theme.color.secondary.light};
  border-radius: 6px;
  align-self: center;
`;

const InfoText = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  color: ${(p) => p.theme.color.secondary.light};
  font-size: ${(p) => p.theme.typography.fontSize.small};
  border-radius: 15px;
  margin-top: 8px;
`;

export default () => {
  const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();
  const dataAppIsLoading = useIsDataAppLoading();
  const appName = useAppName();
  const lastPublishedAt = useLastPublishedAt();
  const updateNotebookName = useUpdateNotebookName();

  if (dataAppIsLoading || isPublishedOrRunVersionOpen) return <></>;

  return (
    <DraftEditorPage>
      <Row justify="center" align="middle">
        <Col xs={24} sm={22} md={22} lg={20} xl={18} xxl={16}>
          <AppTitleBar>
            <Row justify="space-between">
              <span>
                {/* {appName} */}
                <EditableTitle
                  title={appName}
                  titleStyle={{ color: 'white' }}
                  isEditable={true}
                  saveTitleChange={updateNotebookName}
                />
              </span>
              <NotebookLabel>
                <EditFilled /> EDITING APP
              </NotebookLabel>
            </Row>
            {lastPublishedAt && (
              <InfoText>
                Last published as App on
                {' ' + format(new Date(`${lastPublishedAt}Z`), 'PPp')}
              </InfoText>
            )}
          </AppTitleBar>
          <Steps />
        </Col>
      </Row>
    </DraftEditorPage>
  );
};
