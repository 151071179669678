import { get, update, upperFirst } from 'lodash';
import { useTeamId } from '../../../../model/Team';
import { PublishedValueText, withCoreInputBehavior } from './CoreInputHOC';
import FetchedListDropdownInput from './helpers/FetchedListDropdownInput';
import React, { useContext, useState } from 'react';
import { Select, Button, Divider, Modal } from 'antd';
import IntegrationForm from '../../../../integration-components/IntegrationForm';
import styled from 'styled-components';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';
import { PlusOutlined } from '@ant-design/icons';
import FeatureFlagGate from '../../../../standard-components/FeatureFlagGate';

const AddIntegrationButtonContainerStyled = styled.div`
  display: flex;
  justify-content: center;
`;

const AddIntegrationButtonStyled = styled(Button)`
  flex: none;
  /* padding: 8px; */
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
`;

const AddIntegrationDividerStyled = styled(Divider)`
  margin: 4px 0;
`;

const NoIntegrationsAddNewButton = styled(Button)`
  min-width: 200px;
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const PREVIEW_CARD_WIDTH = '531px';
const IntegrationModal = styled(Modal)`
  min-width: calc(${PREVIEW_CARD_WIDTH} + 10%);
`;

const IntegrationInput = ({
  integrationType,
  schema = {},
  disabled,
  shouldShowPublishedVersion,
}) => {
  const [
    isAppIntegrationModalVisible,
    setIsAppIntegrationModalVisible,
  ] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState();

  const showAppIntegrationModal = () => {
    setIsAppIntegrationModalVisible(true);
  };

  const closeModal = () => {
    setIsAppIntegrationModalVisible(false);
  };

  const onIntegrationAddComplete = (success) => {
    if (success) {
      setShouldRefetch(Date.now());
    }
    closeModal();
  };

  const teamId = useTeamId();
  let { subtype } = schema;
  if (integrationType) {
    subtype = integrationType;
  }
  const subtypeCaps = subtype.toUpperCase();

  if (shouldShowPublishedVersion) {
    return (
      <PublishedValueText capitalize>{subtype} Integration</PublishedValueText>
    );
  }
  const actionTitle = `Add New ${upperFirst(
    subtype.toLowerCase()
  )} Integration`;
  return (
    <FeatureFlagGate
      featureFlagId={`integrations.${subtype}`}
      tooltipPlacement={'left'}
      ribbonBadgeLeftMargin="6px"
      neverEnforceVisiblity
    >
      <FetchedListDropdownInput
        endpoint={`/integration/list?integration_type=${subtype.toUpperCase()}&team_id=${teamId}`}
        shouldRefetch={shouldRefetch}
        stepInputId={schema.id}
        disabled={disabled}
        placeholder={schema.placeholder_value || 'Select integration...'}
        errorNotification="Sorry, something went wrong while trying to load the integration."
        ifNoResultsComponent={
          <>
            <NoIntegrationsAddNewButton
              type="primary"
              onClick={showAppIntegrationModal}
            >
              {actionTitle}
            </NoIntegrationsAddNewButton>
          </>
        }
        afterResultsAddOn={
          <>
            <AddIntegrationDividerStyled />
            <AddIntegrationButtonContainerStyled>
              <AddIntegrationButtonStyled
                type="primary"
                icon={<PlusOutlined />}
                onClick={showAppIntegrationModal}
              >
                Add New Integration
              </AddIntegrationButtonStyled>
            </AddIntegrationButtonContainerStyled>
          </>
        }
      />
      <IntegrationModal
        title={actionTitle}
        visible={isAppIntegrationModalVisible}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        <IntegrationForm
          integrationType={subtypeCaps}
          onIntegrationAddComplete={onIntegrationAddComplete}
        />
      </IntegrationModal>
    </FeatureFlagGate>
  );
};

export default withCoreInputBehavior(IntegrationInput);
