import React from 'react';
import { useTheme } from 'styled-components';
import { withAuthHOC } from '../model/User';
import ChatWidget from '@papercups-io/chat-widget';

export default withAuthHOC(({ userInfo }) => {
  const { color } = useTheme();
  let customer = {};
  if (userInfo) {
    customer = {
      name: userInfo.name,
      email: userInfo.email,
      external_id: userInfo.id,
    };
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const hidePapercups = params.get('hidePapercups');

  if (process.env.NODE_ENV === 'production' && !hidePapercups) {
    return (
      <ChatWidget
        title="Welcome to Intersect Labs"
        subtitle="Ask us anything in the chat window below 😊"
        primaryColor={color.primary.darkest}
        greeting=""
        newMessagePlaceholder="Start typing..."
        accountId="a6df734b-bc45-4a83-974c-e5e0a756fe14"
        baseUrl="https://app.papercups.io"
        customer={customer}
      />
    );
  } else {
    return <> </>;
  }
});
