import { Button } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { CardContainer } from '../standard-components/containers';
import LoadingSplash from '../standard-components/LoadingSplash';
import { SectionTitle } from '../standard-components/typography';

const Container = styled(CardContainer)`
  min-width: 530px;
  margin: auto;
  width: 50vw;
  height: 200px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;

export default ({ integrationId }) => {
  const teamId = useTeamId();
  const [integrationData, setIntegrationData] = useState();
  const [loading, setLoading] = useState(false);

  const onLoginClick = () => {
    setLoading(true);
    window.FB.login(
      async function (response) {
        console.log('fb', response);
        const accessToken = get(response, 'authResponse.accessToken');
        if (response.status === 'connected' && accessToken) {
          setLoading(true);
          const result = await makeAPICall({
            endpoint: `/oauth2/facebook/login?team_id=${teamId}&facebook_token=${accessToken}`,
          });
          setIntegrationData(result);
          setLoading(false);
        }
      },
      { scope: 'ads_read' }
    );
  };

  useEffect(() => {
    const fetchIntegrationData = async () => {
      const [result, error] = await makeAPICall({
        endpoint: `/integration/?integration_id=${integrationId}`,
      });

      setIntegrationData(result);
      setLoading(false);
    };

    if (integrationId) {
      setLoading(true);
      fetchIntegrationData();
    }
  }, [integrationId]);

  if (integrationData) {
    return (
      <Container>
        <SectionTitle>
          {integrationData.name || 'Your Facebook account'} is connected.
        </SectionTitle>
      </Container>
    );
  }

  return (
    <Container>
      <Helmet>
        <script>
          {`window.fbAsyncInit = function() {
            FB.init({
              appId      : '271235801150645',
              cookie     : true,
              xfbml      : true,
              version    : 'v12.0'
            });
          };
        
          (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
          `}
        </script>
      </Helmet>
      <SectionTitle>
        Let Intersect Labs get data from Facebook's Marketing API you add to
        your apps:
      </SectionTitle>
      <Button
        onClick={onLoginClick}
        size="large"
        type="primary"
        loading={loading}
      >
        Authorize Intersect Labs
      </Button>
    </Container>
  );
};
