import styled from 'styled-components';
import {
  ELLIPSES_ON_OVERFLOW,
  getColorFromProps,
  getFontWeightFromProps,
} from '../theming/helpers';

export const PageTitle = styled.div`
  font-family: ${({ theme: { typography } }) => typography.fontFamily.main};
  font-size: ${({ theme: { typography } }) => typography.fontSize.xl};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
  color: ${getColorFromProps};
`;

export const GalleryPageTitle = styled(PageTitle)`
  padding: 12px 24px;
  background-color: ${({ theme: { color } }) => color.grayscale.faintGray1};
  color: transparent;
  font-size: 28px;
  line-height: 32px;
  font-weight: 900;
  text-shadow: -1px 1px 3px hsla(0, 0%, 100%, 0.7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SectionTitle = styled.div`
  font-family: ${({ theme: { typography } }) => typography.fontFamily.main};
  font-size: ${({ theme: { typography } }) => typography.fontSize.medium};
  line-height: 21px;
  font-weight: ${(props) => getFontWeightFromProps(props, 400)};
  color: ${getColorFromProps};
`;

export const SubSectionTitle = styled.div`
  font-family: ${({ theme: { typography } }) => typography.fontFamily.main};
  font-size: ${({ theme: { typography } }) => typography.fontSize.small};
  line-height: ${({ theme: { typography } }) =>
    `${(typography.fontSize.small * 3) / 2}px`};
  font-weight: ${getFontWeightFromProps};
  color: ${getColorFromProps};
  ${ELLIPSES_ON_OVERFLOW}
`;

export const InnerShadowTitle = styled.div`
  background-color: ${({ theme: { color } }) => color.grayscale.faintGray1};
  color: transparent;
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  line-height: 21px;
  font-weight: 900;
  text-shadow: -1px 1px 3px hsla(0, 0%, 100%, 0.7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Text = styled.div`
  font-family: ${({ theme: { typography } }) => typography.fontFamily.main};
  font-size: ${({ theme: { typography } }) => typography.fontSize.small};
  font-weight: ${getFontWeightFromProps};
  line-height: 20px;
  color: ${getColorFromProps};
`;

export const SubText = styled.div`
  font-family: ${({ theme: { typography } }) => typography.fontFamily.main};
  font-size: ${({ theme: { typography } }) => typography.fontSize.mediumSmall};
  font-weight: ${getFontWeightFromProps};
  color: ${({ theme: { color } }) => color.grayscale.faintGray1};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TagLabel = styled.div`
  background-color: #fbf5ff;
  color: #947aaa;
  font-size: 12px;
  line-height: 21px;
  font-weight: 700;
  padding: 1px 5px;
  border-radius: 5px;
  margin-right: 8px;
`;
