import React from 'react';
import styled, { useTheme } from 'styled-components';
import blockTypes from '../model/blockTypes';

const Palette = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 75px 30px;
  overflow: auto;
  height: 100vh;
  flex: 1;

  > :last-child {
    padding: 50px;
  }
`;

const Title = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: ${(p) => p.theme.typography.fontSize.xl};
  margin-top: 70px;
`;

const Subtitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.black};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  margin-top: 40px;
  margin-bottom: 15px;
`;

const ColorSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const ColorBlockRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColorBlock = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 3px;
  background: ${(p) => p.color};
  margin-bottom: 10px;
  border: 1px solid #eee;
`;

const TextDisplayGuide = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 21px;
  background: #f7f7f7;
  margin-bottom: 5px;
  border-radius: 3px;
  padding: 5px 10px;
`;

export default () => {
  const theme = useTheme();

  const colorCategories = Object.keys(theme.color);
  const colors = colorCategories.map((color) => {
    const categorySpecificColors = theme.color[color];
    const categorySpecificColorNames = Object.keys(categorySpecificColors);
    return (
      <ColorSection>
        <Subtitle>{color}</Subtitle>
        <ColorBlockRow>
          {categorySpecificColorNames.map((catColor) => (
            <ColorSection>
              <ColorBlock color={categorySpecificColors[catColor]} />
              <div>{catColor}</div>
              <div>{categorySpecificColors[catColor]}</div>
            </ColorSection>
          ))}
        </ColorBlockRow>
      </ColorSection>
    );
  });

  const blockTypeColors = Object.values(blockTypes).map(
    ({ display, color, deprecated }) => {
      if (deprecated) return <></>;

      return (
        <ColorSection>
          <ColorBlock color={color} />
          <div>{display}</div>
          <div>{color}</div>
        </ColorSection>
      );
    }
  );

  const fontSize = theme.typography.fontSize;
  const fontSizeNames = Object.keys(fontSize);
  const fontSizeGuide = fontSizeNames.map((fontSizeName) => {
    const style = { fontSize: fontSize[fontSizeName] };
    return (
      <TextDisplayGuide>
        <span style={style}>{fontSizeName}</span>
        <span>{style.fontSize}</span>
      </TextDisplayGuide>
    );
  });

  const fontWeight = theme.typography.fontWeight;
  const fontWeightNames = Object.keys(fontWeight);
  const fontWeightGuide = fontWeightNames.map((fontWeightName) => {
    const style = { fontWeight: fontWeight[fontWeightName] };
    return (
      <TextDisplayGuide>
        <span style={style}>{fontWeightName}</span>
        <span>{style.fontWeight}</span>
      </TextDisplayGuide>
    );
  });

  return (
    <Palette>
      <Title>Colors</Title>
      {colors}
      <ColorSection>
        <Subtitle>Block Type Colors</Subtitle>
        <ColorBlockRow>{blockTypeColors}</ColorBlockRow>
      </ColorSection>
      <Title>Typography</Title>
      <Subtitle>Font Size</Subtitle>
      {fontSizeGuide}
      <Subtitle>Font Weight</Subtitle>
      {fontWeightGuide}
      <div />
    </Palette>
  );
};
