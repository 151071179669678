import React, { Suspense, useContext } from 'react';
import { SentenceInput } from '../SentenceRenderers/SentenceRendererCore';
import styled from 'styled-components';
import StepContext, {
  useIsInsideDashboard,
  useStepOutputs,
} from '../../StepContext';
import { useVariableValue } from '../../../../model/DataAppVariableContextHelpers';
import { METADATA_VARIABLE_POSTFIX } from '../SentenceRenderers/DataSetInput';
import { Spin } from 'antd';

const DataVizComponentContainer = styled.div`
  border-radius: 6px;
  overflow: hidden;
  height: inherit;
  background: #fff;
`;

const LoadingWrapper = styled.div`
  display: flex;
  min-width: 400px;
  min-height: 250px;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.color.tertiary.dull};
`;

const SUBTYPE_TO_RENDERER_MAPPING = {
  SCATTER: 'ScatterPlot',
  LINE: 'LineChart',
  BAR: 'BarChart',
  HORIZONTAL_BAR: 'HorizontalBarChart',
  STACKED_BAR: 'StackedBarChart',
  PIE: 'PieChart',
  SPIDERPLOT: 'SpiderPlot',
  HEATMAP: 'HeatMap',
};

const getDatavizOutput = (outputs) =>
  outputs.filter(
    (variableId) => !variableId.includes(METADATA_VARIABLE_POSTFIX)
  )[0];

const importComponent = (comp) => {
  return React.lazy(() => import(`./DataVizComponents/${comp}`));
};

const findSubtype = (variableInfo, subtype) => {
  const variableTypes = variableInfo.types || variableInfo.outputs_types || [];
  if (variableTypes.includes('STACKED_BAR')) {
    return 'STACKED_BAR';
  }
  if (variableTypes.includes('HEATMAP')) {
    return 'HEATMAP';
  }
  if (variableTypes.includes('SPIDERPLOT')) {
    return 'SPIDERPLOT';
  }

  return subtype;
};

const DataVizRenderer = ({ subtype, variableInfo, isInsideDashboard }) => {
  const _subtype = findSubtype(variableInfo, subtype);
  const Component = importComponent(SUBTYPE_TO_RENDERER_MAPPING[_subtype]);

  return (
    <DataVizComponentContainer isInsideDashboard={isInsideDashboard}>
      <Suspense
        fallback={
          <LoadingWrapper>
            <Spin tip="Loading visualization..." />
          </LoadingWrapper>
        }
      >
        <Component variableInfo={variableInfo} />
      </Suspense>
    </DataVizComponentContainer>
  );
};

const DataVizRendererMemoized = React.memo(
  DataVizRenderer,
  (props, nextProps) => {
    const subtypeIsTheSame = props.subtype === nextProps.subtype;
    const variableInfoIsTheSame = props.variableInfo === nextProps.variableInfo;
    const isInsideDashboardIsTheSame =
      props.isInsideDashboard === nextProps.isInsideDashboard;
    return (
      subtypeIsTheSame && variableInfoIsTheSame && isInsideDashboardIsTheSame
    );
  }
);

export default () => {
  const { subtype } = useContext(StepContext);
  const isInsideDashboard = useIsInsideDashboard();

  const outputs = useStepOutputs();
  const [variableInfo] = useVariableValue(getDatavizOutput(outputs));

  return (
    <DataVizRendererMemoized
      subtype={subtype}
      variableInfo={variableInfo}
      isInsideDashboard={isInsideDashboard}
    />
  );
};
