/* eslint-disable import/no-anonymous-default-export */
import { get } from 'lodash';
import React, { useContext } from 'react';
import StepContext from '../../StepContext';
import { SentenceInput } from '../SentenceRenderers/SentenceRendererCore';
import DataFileUploadStepRenderer from './DataFileUploadStepRenderer';
import MongodbRenderer from './MongodbRenderer';
import SQLRenderer from './SQLRenderer';

const SUBTYPE_TO_RENDERER_MAPPING = {
  UPLOAD: DataFileUploadStepRenderer,
  QUERY: SQLRenderer,
  QUERY_MONGO: MongodbRenderer,
};

export default () => {
  const { subtype, type } = useContext(StepContext);
  const Renderer = SUBTYPE_TO_RENDERER_MAPPING[subtype];

  let renderedInput = <SentenceInput />;
  if (Renderer) renderedInput = <Renderer schema={{ type, subtype }} />;
  return <>{renderedInput}</>;
};
