import { RightOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { useSteps } from '../../../../model/DataAppStepsContextHelpers';
import { useVariables } from '../../../../model/DataAppVariableContextHelpers';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';
import StepContext from '../../StepContext';
import { PublishedValueText, withCoreInputBehavior } from './CoreInputHOC';
import blockTypes from '../../../../model/blockTypes';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import { useRef } from 'react';

export const METADATA_VARIABLE_POSTFIX = '__metadata';

const InputWrapper = styled.div`
  display: inline-block;
  margin: ${SENTENCE_INPUT_MARGIN};
  height: 32px;
`;

const SelectedDataset = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  border: 1px solid ${(p) => p.theme.color.grayscale.faintGray2};
  background-color: ${(p) => p.theme.color.grayscale.white};
  border-radius: 2px;
  transition: all 0.2s;
  width: 250px;
  cursor: pointer;

  svg {
    color: #bfbfbf;
  }

  :hover {
    border-color: #40a9ff;
    color: #40a9ff;

    svg {
      color: #40a9ff;
    }
  }
`;

const IconContainer = styled.span`
  margin-left: 10px;
`;

const CurrentSelectionView = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-height: 440px;
  overflow: auto;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OptionTitle = styled.div`
  color: ${(p) => p.theme.color.grayscale.faintGray1};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  max-width: 600px;
  margin: 5px 10px 0px;
`;

const OptionVariable = styled.div`
  /* font-weight: ${(p) => p.selected && p.theme.typography.fontWeight.bold}; */
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  color: ${(p) => p.theme.color.primary.blackout};
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: all 0.3s;
  border-radius: 4px;
  background-color: ${(p) => (p.selected ? '#e6f7ff' : '#fff')};
  border: 1px solid;
  border-color: ${(p) => (p.selected ? p.theme.color.primary.bright : '#fff')};
  cursor: pointer;

  :hover {
    background-color: ${(p) =>
      !p.selected && p.theme.color.grayscale.extraLightGray};
    border-color: ${(p) => p.theme.color.grayscale.faintGray3};
  }

  :active {
    background-color: #e6f7ff;
  }
`;

export const isCommentOutput = (variableId) =>
  variableId && variableId.startsWith('note_');

export const DataSetInputUnattachedToSchema = ({
  onVariableSelect,
  currentValue,
  includeDataviz = false,
  includeComment = false,
  shouldShowPublishedVersion = false,
  constrainToVariableType,
  schemaType = 'dataset',
}) => {
  const { currentStepIndex } = useContext(StepContext);
  const steps = useSteps();
  const variables = useVariables();
  const selectedOptionRef = useRef(null);

  const dataVariables = Object.values(variables).reduce(
    (accum, variableMetadata) => {
      const { types, variable } = variableMetadata;
      let outputIsSelectable;

      if (schemaType === 'any_variable') {
        if (constrainToVariableType) {
          outputIsSelectable = types.includes(constrainToVariableType);
        } else {
          outputIsSelectable = true;
        }
      } else {
        outputIsSelectable =
          types.includes('DATA') ||
          (includeDataviz && types.includes(blockTypes.DATAVIZ.id)) ||
          (includeComment && isCommentOutput(variable));
      }
      return outputIsSelectable
        ? { ...accum, [variable]: variableMetadata }
        : accum;
    },
    {}
  );

  const orderedVariableOptions = [];
  const optionsRendered = [];
  steps.slice(0, currentStepIndex).forEach(({ name, outputs }, index) => {
    outputs.forEach((variableId) => {
      if (dataVariables[variableId] !== undefined) {
        orderedVariableOptions.push(variableId);
        const selected = currentValue === variableId;
        const optionContainerRefProps = {};
        if (selected) {
          optionContainerRefProps.ref = selectedOptionRef;
        }
        optionsRendered.push(
          <OptionContainer key={index + name} {...optionContainerRefProps}>
            <OptionTitle>
              {index + 1}. {name}
            </OptionTitle>
            <OptionVariable
              selected={selected}
              onClick={() => onVariableSelect(variableId)}
            >
              {dataVariables[variableId].name}
            </OptionVariable>
          </OptionContainer>
        );
      }
    });
  });
  const options = (
    <OptionsListContainer>{optionsRendered}</OptionsListContainer>
  );

  const scrollSelectedOptionIntoView = () => {
    selectedOptionRef.current && selectedOptionRef.current.scrollIntoView();
  };

  useEffect(() => {
    const count = orderedVariableOptions.length;
    if (!currentValue && count > 0) {
      onVariableSelect(orderedVariableOptions[count - 1]);
    }
  }, [currentValue]);

  if (shouldShowPublishedVersion) {
    return (
      <PublishedValueText>
        {get(variables, [currentValue, 'name'], '____________')}
      </PublishedValueText>
    );
  }

  return (
    <InputWrapper>
      <Popover
        placement="rightBottom"
        content={options}
        onVisibleChange={scrollSelectedOptionIntoView}
      >
        <SelectedDataset>
          <CurrentSelectionView>
            {get(dataVariables, [currentValue, 'name'])}
          </CurrentSelectionView>
          <IconContainer>
            <RightOutlined />
          </IconContainer>
        </SelectedDataset>
      </Popover>
    </InputWrapper>
  );
};

const DataSetInputSentenceSchemaWrapper = ({
  schema,
  reportInputValue,
  currentValue,
  shouldShowPublishedVersion,
}) => {
  const onVariableSelect = (selectedValue) => {
    reportInputValue(schema.id, {
      type: SCHEMA_INPUT_TYPE.VARIABLE,
      value: selectedValue,
    });
  };

  return (
    <DataSetInputUnattachedToSchema
      onVariableSelect={onVariableSelect}
      currentValue={currentValue}
      shouldShowPublishedVersion={shouldShowPublishedVersion}
      constrainToVariableType={schema.constrainToVariableType}
      schemaType={schema.type}
    />
  );
};

export default withCoreInputBehavior(DataSetInputSentenceSchemaWrapper);
