import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { LightBlueButton } from '../../standard-components/CustomColorButton';

const NormalShadow = '0px 1px 4px rgba(0, 0, 0, 0.1);';
const BlockCard = styled.div`
  background: ${(p) =>
    p.selected
      ? p.theme.color.primary.blackout
      : p.theme.color.grayscale.white};
  box-shadow: ${(p) => (p.selected ? 'none' : NormalShadow)};
  cursor: ${(p) => p.wholeCardSelectable && 'pointer'};
  padding: 20px;
  border-radius: 6px;
  border: ${(p) => p.bordered && `1px solid ${p.theme.color.primary.blackout}`};
  margin: 0px 20px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  transition: 0.2s box-shadow;
  color: ${(p) =>
    p.selected
      ? p.theme.color.grayscale.white
      : p.theme.color.primary.blackout};

  :hover {
    box-shadow: ${(p) =>
      p.wholeCardSelectable &&
      !p.selected &&
      '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 6px rgba(0, 0, 0, 0.08)'};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    flex-grow: 1;
  }
`;

const IconContainer = styled.div`
  margin-right: 20px;
  margin-top: 3px;

  > svg,
  img {
    width: 70px;
    height: 70px;
    border-radius: 6px;
  }
`;

const BlockDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.small};
  font-weight: ${(p) => p.boldTitle && p.theme.typography.fontWeight.bold};
`;

const Subtitle = styled(Typography.Paragraph)`
  color: ${(p) => p.theme.color.primary.gentleGrayishText};
  font-size: ${(p) => p.theme.typography.fontSize.small};
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  margin-top: 2px;
  margin-bottom: 5px !important;
`;

const RemoveButton = styled.div`
  color: ${(p) => p.theme.color.primary.darkDull};
  position: absolute;
  top: -8px;
  left: -10px;
  font-size: 11px;
  color: #496b9d;
  border: 1px solid ${(p) => p.theme.color.grayscale.faintGray3};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #fff;
`;

const AddButton = styled(LightBlueButton)``;

const ActionButtonRow = styled(Row)`
  > * {
    margin-top: 15px;
  }

  > :last-child {
    margin-left: 10px;
    flex-grow: 1;
  }

  > :first-child {
    margin-left: 0px;
    flex-grow: 2;
  }
`;

export default ({
  icon,
  title,
  subtitle,
  selected,
  onRemoveClick,
  onAdd,
  onSelect,
  moreInfoButton,
  bordered,
  boldTitle = true,
  style,
  limitSubtitleToTwoLines = false,
}) => {
  return (
    <BlockCard
      onClick={onSelect}
      selected={selected}
      style={style}
      wholeCardSelectable={!onAdd}
      bordered={bordered}
    >
      {onRemoveClick && (
        <RemoveButton size="small" shape="circle" onClick={onRemoveClick}>
          <CloseOutlined />
        </RemoveButton>
      )}
      <Row>
        {icon && <IconContainer>{icon}</IconContainer>}
        <BlockDetailsContainer>
          <Title boldTitle={boldTitle}>{title}</Title>
          {subtitle && (
            <Subtitle
              ellipsis={{
                rows: 2,
                expandable: true,
                // symbol: '...',
              }}
              title={subtitle}
            >
              {subtitle}
            </Subtitle>
          )}
        </BlockDetailsContainer>
      </Row>
      <ActionButtonRow>
        {onAdd && (
          <AddButton icon={<PlusOutlined />} onClick={onAdd}>
            Add
          </AddButton>
        )}
        {moreInfoButton}
      </ActionButtonRow>
    </BlockCard>
  );
};
