import React, { createContext, useContext, useEffect } from 'react';
import { useTeamId } from './Team';
import { makeAPICall } from '../api/useAPI';
import { useImmer } from 'use-immer';
import { manuallyAddedBlocks } from './blockTypes';
import { get, intersection, last, union } from 'lodash';
import {
  allCategoryPaths,
  allCategoryRootToLeafPaths,
} from './blockCategories';
import {
  BLOCK_GALLERY_URL_DOMAIN,
  BLOCK_GALLERY_URL_PREFIX,
} from '../pages/BlockGallery';

export const BlocksContext = createContext({});

export const featuredBlocks = {
  //input blocks
  '53b0c203-4ae8-4192-b161-20c5739f9f9b': 1, // Upload file
  '61525fdb-62bc-45e2-91cd-3d24052a2304': 1, // Google Sheets

  // transform blocks
  'd2ad5118-ab8a-42aa-9d9f-1028e8f721a3': 2, // Filter Data
  '1cef8c3f-5e94-495f-896d-edd3bd370ec5': 3, // Do Math
  '693e9f8e-ab8e-42e3-bc48-f5ea87396bcc': 4, // Aggregate Data
  'eb2af2c9-74f8-4f15-8596-0ddfb1f8a4ab': 5, // Pivot Table
};

const getSearchStringMatcher = (searchFilter) => {
  if (searchFilter && searchFilter.length > 0) {
    const lowerCaseSearchFilter = searchFilter.toLowerCase();
    return ({ title, description }) =>
      (title + description).toLowerCase().includes(lowerCaseSearchFilter);
  }

  return () => true;
};

export const shouldShowBlockChecker = (searchFilter, tagsToMatch = []) => {
  const searchStringMatcher = getSearchStringMatcher(searchFilter);
  const tagMatcher =
    tagsToMatch.length > 0
      ? (tags) => intersection(tags, tagsToMatch).length > 0
      : () => true;
  return (block) => {
    if (!block.deprecated) {
      return searchStringMatcher(block.schema) && tagMatcher(block.tags);
    }

    return false;
  };
};

export const useBlockGalleryURL = (blockOperation) => {
  const { blockGalleryAppIdByOperation = {} } = useContext(BlocksContext);
  const blockAppId = blockGalleryAppIdByOperation[blockOperation];
  if (!blockAppId) return;

  return `${BLOCK_GALLERY_URL_DOMAIN}${BLOCK_GALLERY_URL_PREFIX}${blockAppId}`;
};

export const BlocksContextProvider = ({ children }) => {
  const [blocksContextValue, updateBlocksContextValue] = useImmer({
    blocksAreLoading: false,
    blocks: [],
    pathsToBlocks: {},
    blockGalleryApps: [],
    blockGalleryAppIdByOperation: {},
  });
  const teamId = useTeamId();

  useEffect(() => {
    const fetchBlocks = async () => {
      updateBlocksContextValue((draftState) => {
        draftState.blocksAreLoading = true;
      });
      const [allBlocksResult, error] = await makeAPICall({
        endpoint: `/app/blocks?team_id=${teamId}`,
      });

      const [galleryBlocks, galleryError] = await makeAPICall({
        endpoint: `/block-gallery/`,
        method: 'GET',
      });

      if (!galleryError || galleryBlocks) {
        updateBlocksContextValue((draftState) => {
          draftState.blockGalleryApps = [galleryBlocks];
        });
      }

      if (!error) {
        const listOfAllAPIProvidedBlocks = get(allBlocksResult, 'blocks', []);
        const blockGalleryAppIdByOperation = {};
        const galleryBlocksById = {};
        if (galleryBlocks) {
          galleryBlocks.forEach(
            (block) => (galleryBlocksById[block.block_id] = block)
          );
          listOfAllAPIProvidedBlocks.forEach((block) => {
            if (galleryBlocksById[block.id]) {
              blockGalleryAppIdByOperation[block.operation] = block.id;
            }
          });
        }

        updateBlocksContextValue((draftState) => {
          draftState.blockGalleryAppIdByOperation = blockGalleryAppIdByOperation;
          draftState.blocks = [
            ...manuallyAddedBlocks,
            ...listOfAllAPIProvidedBlocks,
          ];
        });
      }

      updateBlocksContextValue((draftState) => {
        draftState.blocksAreLoading = false;
      });
    };

    if (teamId) {
      fetchBlocks();
    }
  }, [teamId]);

  useEffect(() => {
    if (blocksContextValue.blocks.length === 0) {
      return;
    }

    updateBlocksContextValue((draftState) => {
      const _pathsToBlocks = {};
      const _pathsToFeaturedBlocks = {};
      allCategoryPaths.forEach((path) => {
        const matchingLeafPaths = allCategoryRootToLeafPaths.filter((_path) =>
          _path.includes(last(path))
        );

        let matchingBlocks = [];
        matchingLeafPaths.forEach((matchingPath) => {
          const tag = last(matchingPath);
          matchingBlocks = union(
            matchingBlocks,
            draftState.blocks.filter(shouldShowBlockChecker(undefined, [tag]))
          );
        });

        if (matchingBlocks.length > 0) {
          _pathsToBlocks[path] = matchingBlocks;

          const matchingFeaturedBlocks = matchingBlocks.reduce(
            (_featuredBlocks, block) => {
              if (featuredBlocks[block.id] !== undefined) {
                return [block.id, ..._featuredBlocks];
              }

              return _featuredBlocks;
            },
            []
          );
          if (matchingFeaturedBlocks.length > 0) {
            _pathsToFeaturedBlocks[path] = matchingFeaturedBlocks;
          }
        }
      });
      draftState.pathsToBlocks = _pathsToBlocks;
      draftState.pathsToFeaturedBlocks = _pathsToFeaturedBlocks;
    });
  }, [blocksContextValue.blocks]);

  return (
    <BlocksContext.Provider value={blocksContextValue}>
      {children}
    </BlocksContext.Provider>
  );
};
