import React from 'react';
import CodeEditor from '../../../../standard-components/CodeEditor';
import { withCoreInputBehavior } from './CoreInputHOC';

const CodeRenderer = ({ schema, reportInputValue, currentValue, disabled }) => {
  const onNewValue = (value) => {
    reportInputValue(schema.id, {
      type: 'static',
      value,
    });
  };

  return (
    <CodeEditor
      editorContent={currentValue}
      onEditorContentChange={onNewValue}
      disabled={disabled}
      language={schema.subtype}
      placeholder={schema.placeholder_value}
    />
  );
};

export default withCoreInputBehavior(CodeRenderer);
