import { Button, Result, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import PricingView from '../checkout-components/PricingViewController';
import { PRICING_FLOWS } from '../checkout-components/SubscriptionUtils';
import { logout } from '../model/User';

const SubscribeNowButton = styled(Button)``;

export default ({
  visible = false,
  onSelectComplete = () => {},
  message = ' ',
}) => {
  const [isPricingViewVisible, setIsPricingViewVisible] = useState(false);
  const titles = {
    TooManyApps: 'Too Many Apps',
    NoSubscription: 'Subscribe To Continue Intersecting',
  };
  const subTitles = {
    TooManyApps:
      'Sorry, you have reached your limit of apps for this account. To create more apps upgrade your subscription',
    NoSubscription:
      "You don't have an active subscription. If you need to talk to someone on our team please email us at hello@intersectlabs.io",
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onSelectComplete}
        header={null}
        footer={null}
      >
        <Result
          status="403"
          title={titles[message]}
          subTitle={subTitles[message]}
          extra={
            <Space direction="vertical" size="large">
              <SubscribeNowButton
                size="large"
                type="primary"
                shape="round"
                onClick={() => setIsPricingViewVisible(true)}
              >
                Subscribe Now
              </SubscribeNowButton>
              <Button size="large" shape="round" onClick={logout}>
                Logout
              </Button>
            </Space>
          }
        />
      </Modal>
      <PricingView
        pricingFlow={PRICING_FLOWS.changePlans}
        showModal={isPricingViewVisible}
        onClose={() => setIsPricingViewVisible(false)}
      />
    </>
  );
};
