import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { withCoreInputBehavior } from './CoreInputHOC';
import { isValidNumericInput } from '../../../utils';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';

const StyledNumberInput = styled(Input)`
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const NumberInput = ({
  schema,
  reportInputValue,
  getCurrentValue,
  disabled,
  size,
}) => {
  const { value } = getCurrentValue(schema.id) || {};
  const onNewValue = ({ target: { value } }) => {
    if (isValidNumericInput(value)) {
      reportInputValue(schema.id, {
        type: 'static',
        value,
      });
    }
  };
  return (
    <StyledNumberInput
      style={{ width: 65 }}
      disabled={disabled}
      onChange={onNewValue}
      placeholder={schema.placeholder_value}
      value={isNaN(value) ? schema.default_value : value}
      size={size}
    />
  );
};

export default withCoreInputBehavior(NumberInput);
