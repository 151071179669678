import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import StepContext from '../StepContext';
import styled from 'styled-components';
import CodeEditor from '../../../standard-components/CodeEditor';

const SandboxContainer = styled.div`
  height: 100%;
  padding: 48px;
`;

const StepContainer = styled.div`
  text-align: left;
`;

const SchemaInputContainer = styled.div`
  text-align: left;
  font-family: monospace;
  padding-top: 24px;
`;

const Error = styled.div`
  background-color: red;
`;

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const Step = ({ error, Renderer }) => {
  if (error) return <Error>{error}</Error>;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Renderer />
    </ErrorBoundary>
  );
};

export default ({ Renderer }) => {
  const { properties, ...parentContext } = useContext(StepContext);

  const [schema, setSchema] = useState(JSON.stringify(properties.schema));
  const [draftSchema, setDraftSchema] = useState(
    JSON.stringify(properties.schema, null, 2)
  );
  let jsonSchema;
  let error;
  try {
    jsonSchema = JSON.parse(schema);
  } catch {
    error = 'invalid json';
  }

  const contextWithOverwrittenSchema = {
    ...parentContext,
    properties: {
      ...properties,
      schema: jsonSchema,
    },
  };

  const SubmitButton = () => (
    <Button
      type={schema !== draftSchema && 'primary'}
      disabled={schema === draftSchema}
      onClick={() => setSchema(draftSchema)}
    >
      Render the schema below
    </Button>
  );

  return (
    <SandboxContainer>
      <StepContainer>
        <StepContext.Provider value={contextWithOverwrittenSchema}>
          <Step error={error} Renderer={Renderer} />
        </StepContext.Provider>
      </StepContainer>
      <SchemaInputContainer>
        <SubmitButton />
        <CodeEditor
          placeholder={'Enter JSON schema'}
          language="javascript"
          editorContent={draftSchema}
          onEditorContentChange={(changedContent) =>
            setDraftSchema(changedContent)
          }
        />
      </SchemaInputContainer>
    </SandboxContainer>
  );
};
