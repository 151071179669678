import {
  UserAddOutlined,
  UserDeleteOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Empty, List, Input, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { useState, useContext } from 'react';
import styled from 'styled-components';
import {
  BillingContext,
  useBillingInfoIsLoading,
  useTeammates,
} from '../model/BillingContext';
import SignupLinkGenerator from './SignupLinkGenerator';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';

const SectionTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionTitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const RemoveTeammateButtonStyled = styled(Button)`
  margin-left: 1em;
`;

const SectionContainer = styled.div`
  border-radius: 16px;
  background-color: white;
`;

const RemoveTeammateModal = () => {
  const [searchFilter, setSearchFilter] = useState('');
  const billingInfoIsLoading = useBillingInfoIsLoading();
  const teammates = useTeammates();

  const filteredTeamates = teammates.filter(
    ({ name }) =>
      !searchFilter ||
      name.toLowerCase().includes(searchFilter.toLowerCase().trim())
  );
  const teamId = useTeamId();
  const { updateBillingContextValue } = useContext(BillingContext);

  const DeleteTeammate = async ({ name, email, teamId }) => {
    updateBillingContextValue((draftState) => {
      draftState.isLoading = true;
    });

    const [res, fetchError] = await makeAPICall({
      method: 'POST',
      endpoint: `/billing/remove-teammate?email_address=${email}&&team_id=${teamId}`,
    });
    if (res) {
      message.success(`Successfuly Removed ${name}`);
    } else {
      message.error('Trouble Removing Teammate, Please try again');
    }

    makeAPICall({
      endpoint: `/billing/?team_id=${teamId}`,
    }).then(([fetchedBillingInfo, fetchError]) =>
      updateBillingContextValue((draftState) => {
        draftState.billingInfo = fetchedBillingInfo;
        draftState.isLoading = false;
      })
    );
  };

  return (
    <SectionContainer>
      <Input.Search
        placeholder={`Search for a teammate...`}
        onChange={(e) => setSearchFilter(e.target.value)}
        style={{ width: '100%' }}
        allowClear
        value={searchFilter}
      />
      <List
        size="large"
        loading={billingInfoIsLoading}
        locale={{ emptyText: <Empty description="No Team Members found." /> }}
        dataSource={filteredTeamates}
      >
        {filteredTeamates.map(({ name, email, id }) => (
          <TeamListItem
            key={id}
            id={id}
            name={name}
            email={email}
            teamId={teamId}
            deleteTeammate={DeleteTeammate}
          />
        ))}
      </List>
    </SectionContainer>
  );
};

const TeamListItem = ({ name, email, teamId, deleteTeammate }) => {
  return (
    <List.Item>
      <List.Item.Meta title={name} description={email} />
      <Button onClick={() => deleteTeammate({ name, email, teamId })}>
        <DeleteOutlined />
      </Button>
    </List.Item>
  );
};

export default () => {
  const teammates = useTeammates();
  const billingInfoIsLoading = useBillingInfoIsLoading();
  const [addTeammateModalIsVisible, setAddTeammateModalIsVisible] = useState(
    false
  );
  const [
    removeTeammateModalIsVisible,
    setRemoveTeammateModalIsVisible,
  ] = useState(false);

  return (
    <>
      <SectionTitleBar>
        <SectionTitle>Team Members</SectionTitle>
        <div>
          <Button
            onClick={() => setAddTeammateModalIsVisible(true)}
            type="primary"
            icon={<UserAddOutlined />}
          >
            Add Teammate
          </Button>
          <RemoveTeammateButtonStyled
            onClick={() => setRemoveTeammateModalIsVisible(true)}
            type="secondary"
            icon={<UserDeleteOutlined />}
          >
            Remove Teammate
          </RemoveTeammateButtonStyled>
        </div>
      </SectionTitleBar>
      <List
        size="large"
        bordered
        loading={billingInfoIsLoading}
        locale={{ emptyText: <Empty description="No Team Members found." /> }}
        dataSource={teammates}
        pagination={{ defaultPageSize: 15, hideOnSinglePage: true }}
        renderItem={({ name, email }) => {
          return (
            <List.Item>
              <List.Item.Meta title={name} description={email} />
            </List.Item>
          );
        }}
      />
      <Modal
        visible={addTeammateModalIsVisible}
        onCancel={() => setAddTeammateModalIsVisible(false)}
        footer={null}
        title="Add A Teammate"
        destroyOnClose
      >
        {<SignupLinkGenerator />}
      </Modal>
      <Modal
        visible={removeTeammateModalIsVisible}
        onCancel={() => setRemoveTeammateModalIsVisible(false)}
        footer={null}
        title="Remove A Teammate"
        destroyOnClose
        width={700}
        bodyStyle={{ padding: 20 }}
      >
        {<RemoveTeammateModal />}
      </Modal>
    </>
  );
};
