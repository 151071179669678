import React, { useEffect } from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import IntersectLogo from '../images/Logo.svg';
import { DataAppContextProvider } from '../model/DataAppContext';
import { StudioPageContextProvider } from '../model/StudioPageContext';
import RunInstancePage from '../studio-page-components/published-components/RunInstancePage';
import { makeAPICall } from '../api/useAPI';
import { Link, useHistory, useParams } from 'react-router-dom';
import LoadingSplash from '../standard-components/LoadingSplash';
import { idToIcon, allCategoryRootToLeafPaths } from '../model/blockCategories';
import { get, last, reverse, startCase } from 'lodash';
import { Helmet } from 'react-helmet';
import fallbackIcon from '../images/fallback-icon.svg';
import BlockGalleryHome from '../block-gallery-components/BlockGalleryHome';
import { useImmer } from 'use-immer';
import { iconNameToFileName } from '../images/blockIcons';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import { LightBlueButton } from '../standard-components/CustomColorButton';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import EmbeddableDataApp from '../studio-page-components/published-components/EmbeddableDataApp';

export const BLOCK_GALLERY_URL_DOMAIN = 'https://showcase.intersectlabs.io';
export const BLOCK_GALLERY_URL_PREFIX = '/gallery/blocks/';

const NavBarContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding: 5px 0px;
  background-color: ${(p) => p.theme.color.grayscale.white};

  @media (max-width: 1224px) {
    position: absolute;
    right: 0px;
    left: 0px;
    z-index: 2000;
    height: 65px;
  }
`;

const LogoPageNameContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1224px) {
  }
`;

const Logo = styled.img`
  margin: 10px 20px 10px 25px;
  max-width: 150px;

  @media (max-width: 1224px) {
    max-width: 120px;
    margin: 5px 10px;
  }
`;

const PageTitle = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.large};
  font-weight: ${(p) => p.theme.typography.fontWeight.black};
  margin-left: 20px;
  margin-top: auto;
  background-color: ${(p) => p.theme.color.grayscale.faintGray1};
  color: transparent;
  text-shadow: -1px 1px 3px hsla(0, 0%, 100%, 0.7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1224px) {
    font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
    margin-left: 10px;
    margin-top: 12px;
    width: 100px;
  }
`;

const NavButtonContainer = styled.div`
  width: 60px;
`;

const StyledDivider = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${(p) => p.theme.color.grayscale.faintGray4};

  @media (max-width: 1224px) {
    height: 30px;
  }
`;

const NavBar = ({ onSidebarMenuButtonClick, sidebarIsVisible }) => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  return (
    <NavBarContainer>
      {isTabletOrMobile && (
        <NavButtonContainer>
          <LightBlueButton
            onClick={onSidebarMenuButtonClick}
            icon={sidebarIsVisible ? <CloseOutlined /> : <MenuOutlined />}
          ></LightBlueButton>
        </NavButtonContainer>
      )}
      <LogoPageNameContainer
        onClick={() => history.push(BLOCK_GALLERY_URL_PREFIX)}
      >
        <Logo src={IntersectLogo} title="Intersect Labs" />
        <StyledDivider />
        <PageTitle>Block Gallery</PageTitle>
      </LogoPageNameContainer>
      {isTabletOrMobile && <NavButtonContainer />}
    </NavBarContainer>
  );
};

const SideBarContainer = styled.div`
  text-align: left;
  width: 100%;
  max-width: 410px;
  height: 100vh;
  padding-top: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: 1224px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${(p) => (p.isTabletOrMobile === false ? 1 : 1000)};
    background: ${(p) => p.theme.color.grayscale.white};
  }
`;

const InfoContainer = styled.div`
  padding: 20px 10px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const BlockNamesContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  line-height: 36px;
  text-transform: capitalize;

  > :last-child::after {
    content: ' ';
    height: 40px;
    display: block;
  }
`;

const BlockCategoryName = styled.h4`
  font-size: 15px;
  font-weight: ${(p) => p.theme.typography.fontWeight.black};
  color: ${(p) => p.theme.color.primary.gentleGrayishText};
  margin: 10px 0px 10px -15px;
`;

const Item = styled(Link)`
  font-size: 15px;
  line-height: 20px;
  font-weight: ${(p) =>
    p.active
      ? p.theme.typography.fontWeight.bold
      : p.theme.typography.fontWeight.normal};
  color: ${(p) =>
    p.active ? p.theme.color.primary.darker : p.theme.color.grayscale.dark};
  cursor: pointer;
  transition: color 0.3s ease;
  margin-bottom: 12px;

  &:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.color.primary.darker};
  }
`;

const BlockName = ({ title, block_id, active }) => {
  return (
    <Item active={active} to={`${BLOCK_GALLERY_URL_PREFIX}${block_id}`}>
      {title}
    </Item>
  );
};

const BlockMenuSidebar = ({ activeBlock, categories, categoryNames }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  return (
    <SideBarContainer isTabletOrMobile={isTabletOrMobile}>
      {!isTabletOrMobile && <NavBar />}
      <InfoContainer>
        <BlockNamesContainer>
          {categoryNames.map((category) => {
            return (
              <>
                <BlockCategoryName>{category}</BlockCategoryName>
                {categories[category].map((block) => {
                  return (
                    <BlockName
                      active={activeBlock === block.block_id}
                      key={block.block_id}
                      {...block}
                    />
                  );
                })}
              </>
            );
          })}
        </BlockNamesContainer>
      </InfoContainer>
    </SideBarContainer>
  );
};

const BlockShowcaseApp = ({ block, noShadow }) => {
  const blockGalleryId = get(block, 'gallery_app.id');
  const instanceId = get(block, 'gallery_app.instance_id');

  return (
    <EmbeddableDataApp
      appId={blockGalleryId}
      instanceId={instanceId}
      noShadow={noShadow}
    />
  );
};

const DescriptionContainer = styled.div`
  height: auto;
  transition: transform 150ms ease-out 0s;
  display: flex;
  margin-bottom: 3em;
  margin-left: 4em;

  @media (max-width: 1224px) {
    margin-left: 10px;
    padding: 0px 20px;
  }
`;

const BlockImageContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BlockInfoContainer = styled.div`
  padding-top: 2em;
  margin-left: 2em;
`;

const Path = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;

  color: #858585;
`;

const BlockTitle = styled.h1`
  display: flex;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px;
  text-transform: capitalize;

  color: #000000;
`;

const BlockImage = styled.img`
  border-radius: 6px;
  overflow: hidden;
  height: 100px;
  width: 100px;
`;

const Description = ({ block }) => {
  const selectedBlockTitle = block.title;
  const blockTags = block.gallery_app.tags || [];

  const paths = allCategoryRootToLeafPaths.filter(
    (path) => blockTags[0] === last(path)
  );
  const DisplayedPath = paths.length > 0 && paths[0].join('  /  ');

  return (
    <DescriptionContainer>
      <BlockImageContainer>
        <BlockImage
          src={idToIcon[block.block_id] || fallbackIcon}
          title={selectedBlockTitle}
        />
      </BlockImageContainer>
      <BlockInfoContainer>
        <Path> {DisplayedPath} </Path>
        <BlockTitle>{selectedBlockTitle}</BlockTitle>
      </BlockInfoContainer>
    </DescriptionContainer>
  );
};

const AppContainer = styled.div`
  height: 100vh;
  overflow: auto;
  padding: 4vw;
  text-align: left;
  z-index: 100;
  background-color: ${(p) => p.theme.color.grayscale.white};

  @media (max-width: 1224px) {
    padding: 70px 0px;
  }
`;

const BlockGalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BLOCK_ICON_S3_PREFIX =
  'https://intersect-block-icons.s3.amazonaws.com/';

export default () => {
  const [blocksData, updateBlocksData] = useImmer({});
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [sidebarIsVisible, setSidebarIsVisible] = useState(!isTabletOrMobile);
  const { blockId } = useParams();

  useEffect(() => {
    const fetchBlocks = async () => {
      const [blocks, error] = await makeAPICall({
        endpoint: `/block-gallery/`,
        method: 'GET',
      });

      if (error || !blocks) {
        return;
      }

      let categories = {};
      blocks.forEach((block) => {
        const firstTag = get(block, 'gallery_app.tags[0]');
        const currentCategorySet = categories[firstTag] || [];
        categories[firstTag] = [...currentCategorySet, block];
      });
      let categoryNames = reverse(Object.keys(categories));

      updateBlocksData((draftState) => {
        draftState.blocks = blocks;
        draftState.categories = categories;
        draftState.categoryNames = categoryNames;
      });
    };

    fetchBlocks();
  }, []);

  if (!blocksData.blocks) {
    return <LoadingSplash />;
  }

  if (!blockId) return <BlockGalleryHome data={blocksData} />;

  const activeBlock = blocksData.blocks.find(
    ({ block_id }) => block_id === blockId
  );

  const metaTitle = startCase(get(activeBlock, 'title', 'Block Gallery'));
  const metaDescription = get(activeBlock, 'gallery_app.meta_desc');
  var isPrerender =
    navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;

  return (
    <StyleSheetManager disableCSSOMInjection={isPrerender}>
      <BlockGalleryContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@intersectlabshq" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta
            name="twitter:image"
            content={`${BLOCK_ICON_S3_PREFIX}${
              iconNameToFileName(activeBlock.block_icon) || 'fallback-icon'
            }.png`}
          />
        </Helmet>
        {isTabletOrMobile && (
          <NavBar
            onSidebarMenuButtonClick={() => setSidebarIsVisible((v) => !v)}
            sidebarIsVisible={sidebarIsVisible}
          />
        )}
        {sidebarIsVisible !== false && (
          <BlockMenuSidebar
            activeBlock={blockId}
            blocks={blocksData.blocks}
            categories={blocksData.categories}
            categoryNames={blocksData.categoryNames}
          />
        )}
        <AppContainer>
          <Description block={activeBlock} />
          <BlockShowcaseApp block={activeBlock} noShadow={isTabletOrMobile} />
        </AppContainer>
      </BlockGalleryContainer>
    </StyleSheetManager>
  );
};
