/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Select } from 'antd';
import { get, isArray, isString, isUndefined } from 'lodash';
import styled from 'styled-components';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';
import { useState } from 'react';
import { useEffect } from 'react';
import { makeAPICall } from '../../../../api/useAPI';
import { useValuesForIds } from '../../../../model/SchemaRendererContext';
import { PublishedValueText } from './CoreInputHOC';
import { useClearPreviousSchemaInputOnColumnTypeChange } from '../../../../model/ColumnInputContext';
import useSchemaOptions from './helpers/useSchemaOptions';

const Option = Select.Option;

const StyledSelect = styled(Select)`
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const getLabelsForValues = (options, values) => {
  if (!isArray(options) || options.length === 0) return values;

  if (isString(options[0])) return values;

  const valuesToLabelMap = {};
  options.forEach((option) => {
    const label = Object.keys(option)[0];
    const value = option[label];
    valuesToLabelMap[value] = label;
  });

  const labels = values.map((value) => valuesToLabelMap[value]);
  return labels;
};

export default ({
  id,
  options = [],
  renderedOptions,
  allowMultiple = false,
  mode,
  reportInputValue,
  placeholder,
  defaultValue,
  value,
  disabled = false,
  showSearch = true,
  shouldShowPublishedVersion,
  size,
}) => {
  if (shouldShowPublishedVersion) {
    if (isUndefined(value)) {
      return <></>;
    } else if (isArray(value)) {
      const labels = getLabelsForValues(options, value);
      return <PublishedValueText>{labels.join(', ')}</PublishedValueText>;
    } else {
      const labels = getLabelsForValues(options, [value]);
      return <PublishedValueText>{`${labels[0]}`}</PublishedValueText>;
    }
  }

  const [finalOptions, isLoadingOptions] = useSchemaOptions(options);

  const onSelect = (value) => reportInputValue(id, { type: 'static', value });
  useClearPreviousSchemaInputOnColumnTypeChange(reportInputValue, id);

  return (
    <StyledSelect
      style={{ minWidth: 200 }}
      onChange={onSelect}
      mode={mode || (allowMultiple ? 'multiple' : 'single')}
      placeholder={placeholder}
      size={size}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled || isLoadingOptions}
      loading={isLoadingOptions}
      dropdownMatchSelectWidth={350}
      // Optional string filter search:
      showSearch={showSearch}
      optionFilterProp={showSearch && 'children'}
      filterOption={
        showSearch &&
        ((input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      }
    >
      {renderedOptions}
      {finalOptions &&
        finalOptions.map((opt, index) => {
          if (isString(opt)) {
            return (
              <Option key={opt} value={opt}>
                {opt}
              </Option>
            );
          } else {
            const labels = Object.keys(opt);
            if (labels.length > 0) {
              return (
                <Option
                  key={`${labels[0]} ${opt[labels[0]]} ${index}`}
                  value={opt[labels[0]]}
                >
                  {labels[0]}
                </Option>
              );
            }
          }

          return (
            <Option key={'invalid' + index} value="invalid" disabled>
              Invalid Option
            </Option>
          );
        })}
    </StyledSelect>
  );
};
