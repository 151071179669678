import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InboxOutlined } from '@ant-design/icons';
import { get, last } from 'lodash';
import { Spin, Upload } from 'antd';
import StepContext, { useStepInputValue } from '../../StepContext';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import { makeAPICall } from '../../../../api/useAPI';
import { format } from 'date-fns';
import { withCoreInputBehavior } from '../SentenceRenderers/CoreInputHOC';
import { useIsPublishedOrRunVersionOpen } from '../../../../model/DataAppContext';
import InputConfigPanel from '../../InputConfigPanel';
const Dragger = Upload.Dragger;

const Container = styled.div`
  padding: 20px;
  background-color: ${(p) => p.theme.color.grayscale.white};
  border: 1px solid ${(p) => p.theme.color.secondary.lighter};
  border-radius: 3px;
`;

const CurrentDataSetView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(p) => (p.extraBottomMargin ? '20px' : '0px')};
  justify-content: center;
  align-items: center;
`;

const MetadataViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilenameView = styled.div`
  color: ${(p) => p.theme.color.grayscale.dark};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
`;

const DetailView = styled.div`
  color: ${(p) => p.theme.color.grayscale.darkish};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
`;

const MetadataView = ({ metadata }) => {
  if (!metadata) return <></>;
  return (
    <MetadataViewWrapper>
      <FilenameView>{metadata.filename}</FilenameView>
      <DetailView>{metadata.uploaded_email}</DetailView>
      <DetailView>
        {metadata.created_time &&
          format(new Date(metadata.created_time), 'PPp')}
      </DetailView>
    </MetadataViewWrapper>
  );
};

const DataFileUploadStepRenderer = (props) => {
  const { disabled, shouldShowPublishedVersion } = props;
  const filename = get(useStepInputValue('_filename'), 'value');
  const data_id = get(useStepInputValue('data_id'), 'value');
  const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();
  const [selectedFile, setSelectedFile] = useState();
  const [fileParsingError, setFileParsingError] = useState();
  const {
    setStepStateIsSaveable,
    updateSentenceInputValues,
    viewingAsPublic,
    interactive = {},
  } = useContext(StepContext);
  const [loading, setLoading] = useState(false);

  const [dataMetadata, setDataMetatdata] = useState({
    filename: data_id && filename,
  });

  useEffect(() => {
    const fetchMetadata = async () => {
      setLoading(true);
      const [metadata, error] = await makeAPICall({
        endpoint: `/data/metadata?data_id=${data_id}`,
      });
      if (metadata) setDataMetatdata(metadata);
      setLoading(false);
    };

    if (data_id && !viewingAsPublic) {
      fetchMetadata();
    }
  }, [data_id]);

  const handleFileSelection = (selection, base64File) => {
    setSelectedFile(selection);
    updateSentenceInputValues({
      _file: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: base64File,
      },
      _filename: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: get(selection, 'name'),
      },
    });
  };

  useEffect(() => {
    setStepStateIsSaveable(selectedFile !== undefined);
  }, [selectedFile]);

  const fileList = selectedFile ? [selectedFile] : [];
  const draggerProps = {
    accept: '.csv,.json',
    name: 'file',
    action: '/data/upload',
    multiple: false,
    fileList,
    beforeUpload: () => false,
    onChange: (info) => {
      const toBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => handleFileSelection(latestFile, reader.result);
        reader.onerror = (error) => setFileParsingError(error);
      };

      const latestFile = last(info.fileList);
      if (latestFile !== undefined) {
        toBase64(latestFile.originFileObj);
      } else {
        handleFileSelection(undefined, undefined);
      }
    },
  };

  const isInteractive = interactive.data_id;

  return (
    <Spin spinning={loading}>
      <Container>
        <CurrentDataSetView
          extraBottomMargin={
            (!shouldShowPublishedVersion || isInteractive) && data_id
          }
        >
          <MetadataView metadata={dataMetadata} />
        </CurrentDataSetView>
        {(!shouldShowPublishedVersion || isInteractive) && (
          <Dragger {...draggerProps} disabled={disabled}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Drag & Drop a CSV file</p>
            <p className="ant-upload-hint">Or click to upload</p>
          </Dragger>
        )}
        {!isPublishedOrRunVersionOpen && (
          <InputConfigPanel pathToInput="data_id" doNotHideBehindIcon={true} />
        )}
      </Container>
    </Spin>
  );
};

export default withCoreInputBehavior(DataFileUploadStepRenderer);
