import styled, { useTheme } from 'styled-components';
import React, { useContext } from 'react';
import {
  CheckOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  useErrorDetailForStep,
  useStepStatus,
} from '../../../model/DataAppStepsContextHelpers';
import StepContext from '../StepContext';

export const STEP_STATUS = {
  SUCCESS: 'SUCCESS',
  RUNNING: 'RUNNING',
  FAILURE: 'FAILURE',
};

const StepStatusIndicator = styled.div`
  border: 1px solid ${(p) => p.theme.color.grayscale.faintGray4};
  border-radius: 35px;
  background-color: ${(p) => p.theme.color.grayscale.white};
  padding: 2px 20px 2px;
  cursor: default;

  > .anticon {
    margin-right: 10px;
  }
`;

export const useStatusIndicatorElements = (
  status,
  successText,
  errorDetail,
  inProgressText = 'Running Step'
) => {
  const { color } = useTheme();
  const darkColor = color.secondary.dark;
  const baseColor = '#52c41a';
  const style = { fontSize: 14, color: baseColor };
  const redStyle = { ...style, color: color.info.error };
  const darkStyle = { ...style, color: darkColor };
  let statusIcon = <></>;
  let fullIndicator = <></>;

  if (status === STEP_STATUS.SUCCESS) {
    statusIcon = <CheckOutlined style={style} />;
    fullIndicator = (
      <StepStatusIndicator key="success">
        {statusIcon}
        {successText}
      </StepStatusIndicator>
    );
  } else if (status === STEP_STATUS.RUNNING) {
    statusIcon = <LoadingOutlined spin={true} style={darkStyle} />;
    fullIndicator = (
      <StepStatusIndicator key="running">
        {statusIcon}
        {inProgressText}
      </StepStatusIndicator>
    );
  } else if (status === STEP_STATUS.FAILURE) {
    statusIcon = <ExclamationCircleFilled style={redStyle} />;
    fullIndicator = (
      <StepStatusIndicator key="failure">
        {statusIcon}
        Step Failed
        {errorDetail && `: ${errorDetail}`}
      </StepStatusIndicator>
    );
  }

  return [statusIcon, fullIndicator];
};

export default ({ iconOnly = false, successText, inProgressText }) => {
  const { id } = useContext(StepContext);
  const status = useStepStatus(id);
  const errorDetail = useErrorDetailForStep({ suppress: iconOnly });
  const [statusIcon, fullIndicator] = useStatusIndicatorElements(
    status,
    successText,
    errorDetail,
    inProgressText
  );

  if (iconOnly) return statusIcon;

  return fullIndicator;
};
