import React, { useContext } from 'react';
import { withCoreInputBehavior } from './CoreInputHOC';
import StepContext, { useStepInputValue } from '../../StepContext';
import { useAPI } from '../../../../api/useAPI';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import { Select } from 'antd';
import styled from 'styled-components';
import { useTeamId } from '../../../../model/Team';
import { get } from 'lodash';

const IntegrationSelect = styled(Select)`
  min-width: 120px;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const FetchedIntegrationListDropdownInput = ({
  endpoint,
  stepInputId = 'model_metadata',
  disabled,
}) => {
  const selectedListItem =
    (useStepInputValue(stepInputId) || {}).value || undefined;
  const { updateSentenceInputValues } = useContext(StepContext);
  const teamId = useTeamId();
  const [fetchedList = [], { isLoading }] = useAPI({
    endpoint: `${endpoint}?team_id=${teamId}`,
  });

  const onChange = (selectedModelName) => {
    // TODO: this serializing is jank, idealy there's a more elegant way...
    const serializedListItem = JSON.stringify(
      fetchedList.find((listItem) => listItem.model_name === selectedModelName)
    );

    updateSentenceInputValues({
      [stepInputId]: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: serializedListItem,
      },
    });
  };

  const placeholder =
    !isLoading && fetchedList.length === 0
      ? 'None exist yet!'
      : 'Select an item';

  const value = isLoading
    ? undefined
    : selectedListItem && JSON.parse(selectedListItem).model_name;
  return (
    <IntegrationSelect
      value={value}
      loading={isLoading}
      placeholder={isLoading ? 'Loading...' : placeholder}
      onChange={onChange}
      disabled={disabled}
    >
      {fetchedList.map((listItem) => (
        <Select.Option key={listItem} value={listItem.model_name}>
          {listItem.model_name}
        </Select.Option>
      ))}
    </IntegrationSelect>
  );
};

const MLModelInput = ({ schema, reportInputValue, currentValue, disabled }) => {
  return (
    <FetchedIntegrationListDropdownInput
      endpoint={'/app/mlmodels/'}
      stepInputId={'model_metadata'}
    />
  );
};

export default withCoreInputBehavior(MLModelInput);
