import React from 'react';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';

const FeaturesPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  border-top: ${(p) =>
    p.borderTop ? '1px solid rgba(0, 0, 0, 0.07)' : 'none'};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
`;

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px 0px;
  text-transform: capitalize;
`;

export default ({ pros = [], cons, borderTop = false }) => {
  return (
    <FeaturesPanel borderTop={borderTop}>
      {pros.map((pro) => (
        <Feature key={pro}>{pro}</Feature>
      ))}
    </FeaturesPanel>
  );
};
