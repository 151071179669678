import { cloneDeep, findIndex, get, set } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { makeAPICall } from '../api/useAPI';
import { DataAppContext, useAppInfo, useStepStatuses } from './DataAppContext';
import { useAppId, useInstanceId } from './DataAppMetadataContextHelpers';
import StepContext from '../studio-page-components/Step/StepContext';
import { STEP_STATUS } from '../studio-page-components/Step/StepBody/StepStatusIndicator';

export const useSteps = () => {
  const { workflow } = useAppInfo();
  return get(workflow, 'steps', []);
};

export const useStepStatus = (stepId) => {
  const stepStatuses = useStepStatuses();
  return get(stepStatuses, [stepId, 'status']);
};

export const useAppHasSuccessfulStep = () => {
  const steps = useSteps();
  const stepsValues = Object.values([...steps]);
  const numberOfStepsInDataApp = stepsValues.length;
  const stepRunResults = stepsValues.map((s) => s.status);
  const isThereAPublishedStep = stepRunResults.includes('SUCCESS');

  return numberOfStepsInDataApp > 0 && isThereAPublishedStep;
};

const formatErrorDetail = (e) => {
  try {
    return JSON.parse(e).message;
  } catch {
    return e;
  }
};

export const useDraftStepExists = () => {
  const steps = useSteps();
  const stepIndex = findIndex(steps, (s) => s.isDraft);
  if (stepIndex === -1) return false;

  return true;
};

export const useErrorDetailForStep = ({ suppress = false }) => {
  const { id } = useContext(StepContext);
  const status = useStepStatus(id);
  const instanceId = useInstanceId();
  const appId = useAppId();

  const [errorDetail, setErrorDetail] = useState(null);

  useEffect(() => {
    if (!suppress && status === STEP_STATUS.FAILURE) {
      makeAPICall({
        endpoint: `/app/error?app_id=${appId}&instance_id=${instanceId}&node_id=${id}`,
      }).then(([result, apiError]) => {
        const { detail = null } = result || {};
        setErrorDetail(formatErrorDetail(detail));
      });
    }
  }, [status]);

  return errorDetail;
};

export const useAddDraftStep = () => {
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return (block, afterStepId) => {
    updateDataAppContextValue((draftState) => {
      const steps = get(draftState, 'data.workflow.steps', []);

      const stepToAdd = {
        ...block,
        id: undefined, // ensure block id doesn't get set to id
        isDraft: true,
        afterStepId,
        blockId: block.id,
        appBlockId: block.appId,
        name: block.schema.title,
        outputs: [],
        properties: {
          schema: block.schema,
        },
      };

      if (afterStepId === null) {
        steps.unshift(stepToAdd);
      } else {
        const stepIndex = findIndex(steps, (s) => s.id === afterStepId);
        if (stepIndex === -1) return false;

        steps.splice(stepIndex + 1, 0, stepToAdd);
      }

      set(draftState, 'data.workflow.steps', steps);
    });
  };
};

export const useRemoveDraftStep = () => {
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return (removeDraftStepAtIndex) => {
    updateDataAppContextValue((draftState) => {
      const steps = get(draftState, 'data.workflow.steps');
      steps.splice(removeDraftStepAtIndex, 1);
    });
  };
};

export const useSaveNewStep = () => {
  const appId = useAppId();
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return async (
    blockId,
    stepInputValues,
    afterStepId = null,
    appBlockId,
    newStepName,
    stepVisibility,
    isUserProvided,
    interactive,
    blockType
  ) => {
    const step_schema_filled = {
      inputs: stepInputValues,
    };
    if (newStepName) step_schema_filled.name = newStepName;
    if (appBlockId) step_schema_filled.app_id = appBlockId;
    else if (blockId) step_schema_filled.block_id = blockId;

    const body = {
      step_schema_filled,
      after_step_id: afterStepId,
    };

    if (stepVisibility) body.visible = stepVisibility;
    if (isUserProvided) body.user_provided = isUserProvided;
    if (interactive) body.interactive = interactive;

    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/step/add?app_id=${appId}`,
      method: 'POST',
      body,
    });

    if (!error) {
      window.analytics.track('Added Step', {
        app_id: appId,
        block_id: blockId,
        block_type: blockType,
      });
      updateDataAppContextValue((draftState) => {
        draftState.data = appInfo;
      });
      return true;
    } else {
      //TODO handle error
      return false;
    }
  };
};

export const usePublicSaveEditedStep = () => {
  const appId = useAppId();
  const instanceId = useInstanceId();
  const { updateDataAppContextValue } = useContext(DataAppContext);
  const { workflow } = useAppInfo();
  return async (stepId, stepInputValues) => {
    const updatedWorkflow = cloneDeep(workflow);
    const stepIndex = findIndex(updatedWorkflow.steps, (s) => s.id === stepId);
    set(updatedWorkflow, `steps[${stepIndex}].inputs`, stepInputValues);

    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/run-public-app?app_id=${appId}`,
      method: 'POST',
      body: {
        step_id: stepId,
        instance_id: instanceId,
        workflow: updatedWorkflow,
      },
    });

    if (!error) {
      updateDataAppContextValue((draftState) => {
        draftState.data = appInfo;
        draftState.variableValues = appInfo.variable_values;
      });
      return true;
    } else {
      //TODO handle error
      return false;
    }
  };
};

export const useSaveEditedStep = () => {
  const appId = useAppId();
  const instanceId = useInstanceId();
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return async (body = {}, fetchUpdatedAppInfo = true) => {
    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/step/edit?app_id=${appId}&instance_id=${instanceId}`,
      method: 'POST',
      body: { ...body, get_app_info: fetchUpdatedAppInfo },
    });

    if (!error) {
      if (fetchUpdatedAppInfo) {
        updateDataAppContextValue((draftState) => {
          draftState.data = appInfo;
        });
      }
      return true;
    } else {
      //TODO handle error
      return false;
    }
  };
};

export const useDeleteStep = () => {
  const appId = useAppId();
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return async (stepId) => {
    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/step/delete?app_id=${appId}&step_id=${stepId}`,
      method: 'POST',
    });

    if (!error) {
      updateDataAppContextValue((draftState) => {
        draftState.data = appInfo;
      });
      return true;
    } else {
      //TODO handle error
      return false;
    }
  };
};

export const useStepForVariableId = (variableId) => {
  const steps = useSteps();
  let step = steps.find((step) => step.outputs.includes(variableId));
  return step || {};
};

export const useUpdateStepProperty = (property) => {
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return (stepId, newVisibility) =>
    updateDataAppContextValue((draftState) => {
      const steps = get(draftState, 'data.workflow.steps', []);
      const stepIndex = findIndex(steps, (s) => s.id === stepId);
      if (stepIndex === -1) return false;
      set(
        draftState,
        `data.workflow.steps[${stepIndex}].${property}`,
        newVisibility
      );
    });
};

export const useSetVisibilityForAllSteps = () => {
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return (newVisibility) =>
    updateDataAppContextValue((draftState) => {
      const steps = get(draftState, 'data.workflow.steps', []);
      const updatedSteps = steps.map((step) => ({
        ...step,
        visible: newVisibility,
      }));
      set(draftState, `data.workflow.steps`, updatedSteps);
    });
};
