import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import IntersectLogoWhite from '../../images/Logo_white.svg';
import {
  getViewingAppAsPublic,
  useMetadata,
} from '../../model/DataAppMetadataContextHelpers';
import HomeButton from './HomeButton';

const Header = styled.div`
  color: ${(p) =>
    p.isDraftDashboard
      ? p.theme.color.grayscale.darkish
      : p.theme.color.grayscale.white};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 50px;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
`;

const ButtonWrapper = styled.div`
  width: 100px;
`;

const Logo = styled.img`
  cursor: pointer;
  max-width: 135px;
`;

const AppName = styled.div`
  color: ${(p) => p.isDraftDashboard && p.theme.color.grayscale.dark};
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: ${(p) => p.theme.typography.fontSize.xl};
  line-height: 1.1;
  max-width: 80%;
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
`;

const Subtitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
`;

export default ({ isDraftDashboard }) => {
  const { name, created_by } = useMetadata();
  const history = useHistory();
  const viewingAppAsPublic = getViewingAppAsPublic();
  const theme = useTheme();

  return (
    <Header isDraftDashboard={isDraftDashboard}>
      <Row>
        <ButtonWrapper>
          {!viewingAppAsPublic && isDraftDashboard && (
            <HomeButton inDraftDashboard={isDraftDashboard} />
          )}
        </ButtonWrapper>
        <Logo
          onClick={() => !viewingAppAsPublic && history.push('/apps/')}
          src={
            isDraftDashboard
              ? theme.logos.fullWordmark
              : theme.logos.fullWordmarkForDarkBG
          }
          title="Intersect Labs"
        />
        <ButtonWrapper />
      </Row>
      <AppName isDraftDashboard={isDraftDashboard}>{name}</AppName>
      <Subtitle>by {created_by}</Subtitle>
    </Header>
  );
};
