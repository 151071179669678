import { get, set } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { makeAPICall } from '../api/useAPI';
import { DataAppContext, useAppInfo } from './DataAppContext';
import { useAppId, useInstanceId } from './DataAppMetadataContextHelpers';
import {
  useStepForVariableId,
  useSteps,
  useStepStatus,
} from './DataAppStepsContextHelpers';
import { exportCSVToBrowserDownloads } from '../studio-page-components/Step/StepOutput/utilsCSV';
import { isDataviz } from '../studio-page-components/Step/StepOutput/StepOutput';
import { useStepType } from '../studio-page-components/Step/StepContext';
import blockTypes from './blockTypes';

export const useVariables = () => {
  const { variables } = useAppInfo();
  return variables || {};
};

export const useVariable = (variableId) => {
  const { variables } = useAppInfo();
  return get(variables, variableId);
};

export const useAllVariableValues = () => {
  const { variableValues } = useContext(DataAppContext);
  return variableValues || {};
};

export const useSetVariableValue = () => {
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return (variableId, value) => {
    updateDataAppContextValue((draftState) => {
      set(draftState, ['variableValues', variableId], value);
    });
  };
};

export const useVariableReadyState = (variableId) => {
  const { variableStatus } = useContext(DataAppContext);
  return get(variableStatus, variableId);
};

export const useIsDataviz = (variableId) => {
  const [variableInfo, { isLoading }] = useVariableValue(variableId);
  return !isLoading && isDataviz(variableInfo);
};

export const useVariableValue = (variableId) => {
  const setVariableValue = useSetVariableValue();
  const variableValues = useAllVariableValues();
  const appId = useAppId();
  const instanceId = useInstanceId();
  const step = useStepForVariableId(variableId);
  const stepStatus = useStepStatus(step.id);
  const [isLoading, setIsLoading] = useState(false);
  let value, error;
  if (variableValues[variableId] !== undefined) {
    value = variableValues[variableId];
  }

  useEffect(() => {
    const fetchValue = async () => {
      setIsLoading(true);
      const [value, error] = await makeAPICall({
        endpoint: `/app/variable?app_id=${appId}&instance_id=${instanceId}&variable_name=${variableId}`,
      });
      setVariableValue(variableId, value);
      setIsLoading(false);
    };

    if (value === undefined && stepStatus === 'SUCCESS') {
      fetchValue();
    }
  }, [value, stepStatus]);

  return [value, { isLoading, stepStatus }];
};

export const useVariableName = (variableId) => {
  const variables = useVariablesForTheseOutputVariableIds([variableId]);
  const { name } =
    variables.find(({ variable }) => variable === variableId) || {};
  return name || variableId;
};

export const useFullDatasetCSVString = ({
  variableId,
  download = false,
  downloadAfterFetching = false,
}) => {
  const [fullDatasetVariable, setFullDatasetVariable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const appId = useAppId();
  const instanceId = useInstanceId();
  const variableName = useVariableName(variableId);

  useEffect(() => {
    const fetchValue = async () => {
      setIsLoading(true);
      const [csv, error] = await makeAPICall({
        endpoint: `/app/variable/download?app_id=${appId}&instance_id=${instanceId}&variable_name=${variableId}`,
      });

      setFullDatasetVariable(csv);
      setIsLoading(false);
      downloadAfterFetching && exportCSVToBrowserDownloads(csv, variableName);
    };

    download && fetchValue();
  }, [variableId, download]);

  return [fullDatasetVariable, { isLoading }];
};

const STEPS_WITH_HIDDEN_OUTPUTS = [
  blockTypes.COMMENT.id,
  blockTypes.COMMENT_V2.id,
];
export const useVariablesForTheseOutputVariableIds = (outputs) => {
  const stepType = useStepType();
  const variables = useVariables();
  if (STEPS_WITH_HIDDEN_OUTPUTS.includes(stepType)) return [];

  const outputsWithProcessedVariables = outputs.filter(
    (variableId) => variables[variableId] !== undefined
  );
  return outputsWithProcessedVariables.map(
    (variableId) => variables[variableId]
  );
};

export const useVariablesGroupedByStep = () => {
  const steps = useSteps();
  const variables = useVariables();
  const stepsWithVariables = steps.map((step) => {
    if (!step.outputs) {
      return step;
    }
    const outputsWithProcessedVariables = step.outputs.filter(
      (variableId) => variables[variableId] !== undefined
    );
    const variablesForStep = outputsWithProcessedVariables.map(
      (variableId) => variables[variableId]
    );
    return { ...step, variables: variablesForStep };
  });
  return stepsWithVariables;
};

export const fetchLatestStepVariableMetadata = async (
  appId,
  instanceId,
  stepId,
  updateDataAppContextValue
) => {
  const [metadatas, error] = await makeAPICall({
    endpoint: `/app/step/outputs/metadata?app_id=${appId}&instance_id=${instanceId}&step_id=${stepId}`,
  });

  if (!metadatas || metadatas.length === 0) return;
  updateDataAppContextValue((draftState) => {
    const variables = get(draftState, 'data.variables');
    metadatas.forEach((metadata) => (variables[metadata.variable] = metadata));
  });
};

export const useUpdateVariableName = () => {
  const appId = useAppId();
  const { updateDataAppContextValue } = useContext(DataAppContext);
  return async (variableId, newName) => {
    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/variable/update?app_id=${appId}`,
      method: 'POST',
      body: { internal_variable_name: variableId, user_variable_name: newName },
    });

    if (!error) {
      updateDataAppContextValue((draftState) => {
        set(draftState, `data.variables.${variableId}.name`, newName);
      });
      return true;
    } else {
      //TODO handle error
      return false;
    }
  };
};
