import React, { useContext } from 'react';
import styled from 'styled-components';
import StepContext, { useIsAutoSavable } from '../StepContext';
import { Button, Space } from 'antd';
import { PurpleButton } from '../../../standard-components/CustomColorButton';
import StatusIndicator from './StepStatusIndicator';

const EmptyButtonRow = styled.div`
  height: 30px;
`;

export const StepActionButtons = ({
  noPadding = false,
  addLeftMargin = false,
  successText = 'Done Running',
  inProgressText = 'Running Step',
}) => {
  const {
    id,
    stepIsDirty,
    stepActionsInProgress,
    resetStepToSavedState,
    onAddButtonClicked,
    cancelAdd,
    onSaveButtonClicked,
    stepStateIsSaveable,
  } = useContext(StepContext);
  const isAutoSavable = useIsAutoSavable();

  let btns = [];
  if (!id && !isAutoSavable) {
    btns.push(
      <PurpleButton
        loading={stepActionsInProgress.add}
        onClick={onAddButtonClicked}
        disabled={!stepStateIsSaveable}
        key="add"
        type="primary"
      >
        Add Step
      </PurpleButton>
    );
    btns.push(
      <Button
        disabled={stepActionsInProgress.add}
        onClick={cancelAdd}
        key="cancel-add"
      >
        Cancel
      </Button>
    );
  } else if (!stepIsDirty) {
    const statusIndicator = (
      <StatusIndicator
        key="status-indicator"
        successText={isAutoSavable ? 'Saved' : successText}
        inProgressText={isAutoSavable ? 'Saving...' : inProgressText}
      />
    );
    btns.push(statusIndicator);
  } else if (stepIsDirty && !isAutoSavable) {
    btns.push(
      <PurpleButton
        key="save"
        loading={stepActionsInProgress.save}
        disabled={!stepStateIsSaveable}
        onClick={onSaveButtonClicked}
        type="primary"
      >
        Save
      </PurpleButton>
    );
    btns.push(
      <Button
        key="cancel"
        disabled={stepActionsInProgress.save}
        onClick={resetStepToSavedState}
      >
        Cancel
      </Button>
    );
  }

  let buttonsRowStyle = {};
  if (!noPadding) {
    buttonsRowStyle = {
      paddingTop: 15,
      paddingBottom: 20,
      paddingLeft: addLeftMargin ? 15 : 0,
    };
  }

  if (btns.length === 0) return <EmptyButtonRow />;

  return (
    <Space size="middle" style={buttonsRowStyle}>
      {btns}
    </Space>
  );
};
