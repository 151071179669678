const blockTypes = {
  INPUT: {
    id: 'INPUT',
    display: 'Import data',
    color: '#00A179',
  },
  TRANSFORM: {
    id: 'TRANSFORM',
    display: 'Transform data',
    color: '#613EC1',
  },
  DATAVIZ: {
    id: 'DATAVIZ',
    display: 'Data Visualization',
    color: '#CF7C00',
  },
  DASHBOARD: {
    id: 'DASHBOARD',
    display: 'Dashboards',
    color: '#CF7C00',
    hasNoPadding: true,
  },
  OUTPUT: {
    id: 'OUTPUT',
    display: 'Export data',
    color: '#2378C6',
  },
  COMMENT: {
    id: 'COMMENT',
    display: 'Notes',
    color: '#000',
    deprecated: true,
  },
  COMMENT_V2: {
    id: 'COMMENT_V2',
    categoryId: 'Note',
    display: 'Notes',
    color: '#000',
  },
  EMAIL: {
    id: 'EMAIL',
    display: 'Email',
    color: '#2378C6',
  },
  ML_TRAIN: {
    id: 'ML_TRAIN',
    display: 'Train Machine Learning Model',
    color: '#BC594B',
  },
  ML_PREDICT: {
    id: 'ML_PREDICT',
    display: 'Use Machine Learning Model',
    color: '#BC594B',
  },
  // CUSTOM: {
  //   // TEMPORARY: so Ankit can easily add new step types for demos etc
  //   id: 'CUSTOM',
  //   display: 'Custom',
  // },
};

export const AUTO_SAVABLE_STEP_TYPES = [
  blockTypes.COMMENT_V2.id,
  blockTypes.COMMENT.id,
  blockTypes.DASHBOARD.id,
];

export const blockSubTypes = {
  AIRTABLE: 'airtable',
  MYSQL: 'mysql',
  MONGODB: 'mongodb',
  REDSHIFT: 'redshift',
  GOOGLE_SHEETS: 'google_sheets',
  SNOWFLAKE: 'snowflake',
  SENDGRID: 'sendgrid',
};

const COMMENT_BLOCK = {
  id: 'b562ec20-410c-4426-915a-d15dca999c74',
  schema: {
    type: 'object',
    title: 'Comment',
    description: 'Write a rich-text comment',
    children: [
      {
        id: 'comment_content',
        type: 'string',
        required: true,
        placeholder_value: 'Type away...',
      },
    ],
  },
  type: blockTypes.COMMENT.id,
};

const COMMENT_BLOCK_V2 = {
  id: '693c2427-64cf-4319-8637-c2e1ef4f33dd',
  schema: {
    type: 'object',
    title: 'Note',
    description:
      'Write a note with headings, code blocks, quotes, and other formatted text',
    children: [
      {
        id: 'note',
        type: 'string',
        required: true,
        placeholder_value: 'Type away...',
      },
    ],
  },
  type: blockTypes.COMMENT_V2.id,
};

const ML_TRAIN_APP = {
  appId: 'b69e46d6-1be5-49c1-9f78-6c3b33008160',
  schema: {
    type: 'object',
    title: 'Train Machine Learning Model',
    description: '',
    children: [
      'In data set',
      { id: 'input_df', type: 'dataset', required: true },
      'train a machine learning model to predict',
      {
        id: 'column_to_predict',
        type: 'column',
        placeholder_value: 'select one or more columns',
      },
      'and name that model',
      {
        id: 'name',
        type: 'string',
        placeholder_value: 'My Model',
      },
    ],
  },
  type: blockTypes.ML_TRAIN.id,
  tags: ['ML'],
};

const ML_PREDICT_BLOCK = {
  id: 'd323a1bb-fc1b-48ea-80d0-8e9619d13a33',
  schema: {
    type: 'object',
    title: 'Use Machine Learning Model',
    description: '',
    children: [
      'In data set',
      { id: 'input_df', type: 'dataset', required: true },
      'make predictions using the the pre-trained model',
      {
        id: 'model_metadata',
        type: 'ml_model',
      },
    ],
  },
  type: blockTypes.ML_PREDICT.id,
};

export const manuallyAddedBlocks = [];

export const singleStepTypeBlockInfo = {
  [blockTypes.COMMENT.id]: COMMENT_BLOCK,
  [blockTypes.COMMENT_V2.categoryId]: COMMENT_BLOCK_V2,
  [blockTypes.ML_TRAIN.id]: ML_TRAIN_APP,
  [blockTypes.ML_PREDICT.id]: ML_PREDICT_BLOCK,
};

export default blockTypes;
