/* eslint-disable import/no-anonymous-default-export */
import {
  EditFilled,
  HomeOutlined,
  QuestionCircleOutlined,
  RocketFilled,
  SaveOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { Popover, Space, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled, { useTheme } from 'styled-components';
import {
  useGoToEditableApp,
  useIsDataAppLoading,
  useIsPublishedVersionOpen,
  useIsRunVersionOpen,
  usePublishApp,
} from '../../model/DataAppContext';
import {
  getViewingAppAsPublic,
  useAppId,
  useCanEditApp,
  useLastPublishedAt,
} from '../../model/DataAppMetadataContextHelpers';
import {
  TopAttachedWhiteSecondaryButton,
  TopAttachedWhitePrimaryButton,
  TopLeftAttachedPrimaryButton,
  TopLeftAttachedWhiteSecondaryButton,
  TopAttachedPrimaryButton,
} from '../../standard-components/CustomColorButton';
import IntersectLogoWhite from '../../images/Logo_white.svg';
import { RunStatusDisplay } from '../../standard-components/RunStatusDisplay';
import AppSharingModal from '../app-sharing/AppSharingModal';
import ShareAppRunModal from './ShareAppRunModal';
import { ResourcesMenu } from '../../standard-components/Sidebar';
import {
  useAppHasSuccessfulStep,
  useSteps,
} from '../../model/DataAppStepsContextHelpers';
import { mapValues } from 'lodash';

const NavBar = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1009;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  width: 135px;
`;

const ButtonHolder = styled(Space).attrs({ align: 'top' })`
  flex-basis: 33%;
`;

const CenterButtonHolder = styled(ButtonHolder)`
  justify-content: center;
`;

const RightButtonHolder = styled(ButtonHolder)`
  padding-right: 25px;
  justify-content: flex-end;
`;

const ResourcesIcon = styled(QuestionCircleOutlined)`
  color: ${(p) => p.theme.color.grayscale.faintGray1};
  font-size: 16px;
`;

export default ({ isEmbedded = false }) => {
  const { logos } = useTheme();
  const publishApp = usePublishApp();
  const lastPublishedAt = useLastPublishedAt();
  const history = useHistory();
  const appId = useAppId();
  const viewingAppAsPublic = getViewingAppAsPublic();
  const dataAppIsLoading = useIsDataAppLoading();
  const goToEditableApp = useGoToEditableApp();
  const isPublishedVersionOpen = useIsPublishedVersionOpen();
  const isRunVersionOpen = useIsRunVersionOpen();
  const canEditApp = useCanEditApp();
  const appHasSuccessfulStep = useAppHasSuccessfulStep();

  const [appShareModalOpen, setAppShareModalOpen] = useState(false);
  const toggleAppSharingModal = () => setAppShareModalOpen((open) => !open);
  const [isAppRunShareModalOpen, setIsAppRunShareModalOpen] = useState(false);

  let publishButton = (
    <TopAttachedWhiteSecondaryButton
      onClick={publishApp}
      icon={<SaveOutlined />}
      id="publish-button"
      className={appHasSuccessfulStep && 'enabled-published-button'}
      disabled={!appHasSuccessfulStep}
      style={!appHasSuccessfulStep && { borderWidth: 1 }}
    >
      {!lastPublishedAt ? 'Publish As App' : 'Publish Changes'}
    </TopAttachedWhiteSecondaryButton>
  );

  if (!appHasSuccessfulStep) {
    publishButton = (
      <Tooltip title="Your data app must have at least one step before publishing">
        {publishButton}
      </Tooltip>
    );
  }

  if (dataAppIsLoading || isEmbedded || (!dataAppIsLoading && !appId)) {
    return <></>;
  }

  const navigateToAppDashboard = () => {
    history.push(`/apps/${appId}/dashboard`);
  };
  const goHome = () => {
    if (!viewingAppAsPublic) {
      history.push('/apps/');
    }
  };

  const shareAppButton = (AttachedButton) => (
    <>
      <AttachedButton
        icon={<SelectOutlined />}
        selected={appShareModalOpen}
        onClick={toggleAppSharingModal}
        id="share-app-button"
      >
        Share App
      </AttachedButton>
      {appShareModalOpen && (
        <AppSharingModal toggleAppSharingModal={toggleAppSharingModal} />
      )}
    </>
  );

  if (isPublishedVersionOpen) {
    return (
      <NavBar>
        <TopLeftAttachedPrimaryButton onClick={goHome} id="home-button">
          <HomeOutlined />
        </TopLeftAttachedPrimaryButton>
        <RightButtonHolder size="large">
          {!viewingAppAsPublic && (
            <>
              <TopAttachedWhitePrimaryButton
                icon={<RocketFilled />}
                selected
                id="app-mission-control-button"
              >
                App Mission Control
              </TopAttachedWhitePrimaryButton>
              {canEditApp && (
                <TopAttachedWhitePrimaryButton
                  icon={<EditFilled />}
                  onClick={goToEditableApp}
                  id="mission-control-edit-notebook-button"
                >
                  Edit App
                </TopAttachedWhitePrimaryButton>
              )}
              {shareAppButton(TopAttachedPrimaryButton)}
            </>
          )}
        </RightButtonHolder>
      </NavBar>
    );
  }

  if (isRunVersionOpen) {
    return (
      <>
        <NavBar>
          <ButtonHolder>
            <TopLeftAttachedPrimaryButton onClick={goHome} id="home-button">
              <Logo
                onClick={goHome}
                src={logos.fullWordmarkForDarkBG}
                title="Intersect Labs"
              />
            </TopLeftAttachedPrimaryButton>
          </ButtonHolder>
          <CenterButtonHolder>
            <RunStatusDisplay />
          </CenterButtonHolder>
          <RightButtonHolder size="large">
            <TopAttachedWhiteSecondaryButton
              icon={<RocketFilled />}
              onClick={navigateToAppDashboard}
              id="app-mission-control-button"
            >
              App Mission Control
            </TopAttachedWhiteSecondaryButton>
            {canEditApp && (
              <TopAttachedWhitePrimaryButton
                icon={<EditFilled />}
                onClick={goToEditableApp}
                id="edit-notebook-button"
              >
                Edit App
              </TopAttachedWhitePrimaryButton>
            )}
            <TopAttachedPrimaryButton
              icon={<SelectOutlined />}
              onClick={() => setIsAppRunShareModalOpen(true)}
              id="share-run-results-button"
            >
              Share Run Results
            </TopAttachedPrimaryButton>
          </RightButtonHolder>
          <ShareAppRunModal
            isShareAppRunModalVisible={isAppRunShareModalOpen}
            closeShareAppRunModal={() => setIsAppRunShareModalOpen(false)}
          />
        </NavBar>
      </>
    );
  }

  return (
    <NavBar>
      <ButtonHolder>
        <TopLeftAttachedWhiteSecondaryButton onClick={goHome} id="home-button">
          <HomeOutlined />
        </TopLeftAttachedWhiteSecondaryButton>
        <Popover content={ResourcesMenu}>
          <TopAttachedWhiteSecondaryButton hoverheight="45px">
            <ResourcesIcon />
          </TopAttachedWhiteSecondaryButton>
        </Popover>
      </ButtonHolder>
      <RightButtonHolder size="large">
        {publishButton}
        {lastPublishedAt && (
          <TopAttachedWhiteSecondaryButton
            icon={<RocketFilled />}
            onClick={navigateToAppDashboard}
            id="app-mission-control-button"
          >
            App Mission Control
          </TopAttachedWhiteSecondaryButton>
        )}
        <TopAttachedWhiteSecondaryButton icon={<EditFilled />} selected>
          Edit App
        </TopAttachedWhiteSecondaryButton>
        {shareAppButton(TopAttachedWhiteSecondaryButton)}
      </RightButtonHolder>
    </NavBar>
  );
};
