import { Text } from '../standard-components/typography';
import React from 'react';
import styled from 'styled-components';
import { Input, Select } from 'antd';
import CodeEditor from '../standard-components/CodeEditor';

const FormInputRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 16px;
  margin-right: 24px;
`;

const StyledInput = styled(Input)`
  width: 256px;
`;
const FormInputName = styled(Text)`
  min-width: 130px;
  max-width: 138px;
  margin-right: 5px;
`;

const StyledPassword = styled(Input.Password)`
  width: 256px;
`;

const CodeEditorContainer = styled.div`
  min-width: 100%;
  max-height: 250px;
  overflow: auto;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
`;

const FormInput = ({
  placeholder,
  value,
  onChange,
  isProtected = false,
  dropdownOptions = false,
  needsCodeEditor = false,
}) => {
  if (needsCodeEditor) {
    return (
      <CodeEditorContainer>
        <CodeEditor
          placeholder={placeholder}
          editorContent={value}
          style={{ border: 'none' }}
          onEditorContentChange={onChange}
          language="json"
        />
      </CodeEditorContainer>
    );
  }

  if (isProtected)
    return (
      <StyledPassword
        placeholder={placeholder}
        value={value}
        onChange={({ target }) => onChange(target.value)}
      />
    );

  if (dropdownOptions) {
    return (
      <Select
        value={value}
        style={{ minWidth: 250 }}
        onChange={(value) => onChange(value)}
      >
        {dropdownOptions.map(([id, displayText]) => (
          <Select.Option key={id} value={id}>
            {displayText}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <StyledInput
      placeholder={placeholder}
      value={value}
      onChange={({ target }) => onChange(target.value)}
    />
  );
};

export default ({ inputTitle, ...rest }) => {
  let style = {};
  if (rest.needsCodeEditor) style.flexWrap = 'wrap';
  return (
    <FormInputRowContainer style={style}>
      <FormInputName>{inputTitle}</FormInputName>
      <FormInput {...rest} />
    </FormInputRowContainer>
  );
};
