import { FileTextOutlined } from '@ant-design/icons';
import { Empty, List } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

const InvoiceLink = styled.a`
  border-bottom: 1px solid ${(p) => p.theme.color.grayscale.faintGray3};
  transition: all 0.3s;

  :hover {
    border-bottom-color: ${(p) => p.theme.color.primary.bright};
  }
`;

const InvoiceNumber = styled.div`
  color: ${(p) => p.theme.color.grayscale.faintGray3};
`;

export default ({ invoices }) => {
  return (
    <List
      size="large"
      bordered
      dataSource={invoices}
      pagination={{ defaultPageSize: 10 }}
      locale={{
        emptyText: (
          <Empty description="Looks like you don't have any invoices yet." />
        ),
      }}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<FileTextOutlined />}
            title={
              <InvoiceLink
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {format(new Date(parseInt(item.timestamp) * 1000), 'PPP')}
              </InvoiceLink>
            }
          />
          <InvoiceNumber>Invoice {item.number}</InvoiceNumber>
        </List.Item>
      )}
    />
  );
};
