import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { GalleryPageContainer } from '../standard-components/containers';
import BillingView from '../settings-components/BillingView';
import TeamView from '../settings-components/TeamView';
import { useParams } from 'react-router-dom';
import GeneralAppSettings from '../settings-components/GeneralAppSettings';

const { TabPane } = Tabs;

const StyledTabPage = styled(TabPane)`
  background: ${(p) => p.theme.color.grayscale.white};
  margin-top: -16px;
  padding: 25px 40px;
  border: 0.5px solid ${(p) => p.theme.color.grayscale.faintGray4};
`;

export default () => {
  let { tabId = 'team', subTabId = null } = useParams();
  return (
    <GalleryPageContainer>
      <Tabs defaultActiveKey={tabId} size="large">
        <StyledTabPage tab="Team" key="team">
          <TeamView />
        </StyledTabPage>
        <StyledTabPage tab="Billing" key="billing">
          <BillingView subTabId={subTabId} />
        </StyledTabPage>
        <StyledTabPage tab="App Settings" key="app-settings">
          <GeneralAppSettings />
        </StyledTabPage>
      </Tabs>
    </GalleryPageContainer>
  );
};
