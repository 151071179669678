import styled from 'styled-components';
import React from 'react';
import StepStatusIndicator from '../Step/StepBody/StepStatusIndicator';

const PublishedStepHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 25px;
  box-shadow: 0px 0px 6px 0px rgba(23, 24, 24, 0.03),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  z-index: 50;
  position: relative;
  border-radius: 30px;
  background-color: ${(p) => p.theme.color.grayscale.white};
`;

const PublishedStepTitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  color: ${(p) => p.theme.color.grayscale.dark};
  margin-right: 10px;
`;

export default ({ title }) => {
  let statusIcon = <StepStatusIndicator iconOnly={true} />;

  return (
    <PublishedStepHeader>
      <PublishedStepTitle>{title}</PublishedStepTitle>
      {statusIcon}
    </PublishedStepHeader>
  );
};
