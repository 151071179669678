import React, { useContext } from 'react';
import CodeEditor from '../../../../standard-components/CodeEditor';
import StepContext, { useStepInputValue } from '../../StepContext';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import { withCoreInputBehavior } from '../SentenceRenderers/CoreInputHOC';
import IntegrationInput from '../SentenceRenderers/IntegrationInput';

const DB_TYPES = {
  POSTGRES: 'POSTGRES',
};

const SQLRenderer = ({ dbType = DB_TYPES.POSTGRES, disabled }) => {
  const { updateSentenceInputValues } = useContext(StepContext);
  const sqlContent = (useStepInputValue('query') || {}).value || '';
  const onEditorContentChange = (updatedSqlContent) => {
    updateSentenceInputValues({
      query: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: updatedSqlContent,
      },
    });
  };
  return (
    <div>
      <div>Select integration: </div>
      <IntegrationInput
        schema={{ id: 'integration_id' }}
        disabled={disabled}
        integrationType={dbType}
      />
      <CodeEditor
        editorContent={sqlContent}
        onEditorContentChange={onEditorContentChange}
        disabled={disabled}
      />
    </div>
  );
};

export default withCoreInputBehavior(SQLRenderer);
