import add_characters_to_each_element_in_a_column from '../images/block-icons/add-characters-to-each-element-in-a-column.svg';
import add_time_to_date from '../images/block-icons/add-time-to-date.svg';
import aggregate_data from '../images/block-icons/aggregate-data.svg';
import airtable from '../images/block-icons/integration-block-icons/airtable.svg';
import bar_chart from '../images/block-icons/bar-chart.svg';
import boolean_is_cell_populated from '../images/block-icons/boolean-is-cell-populated.svg';
import break_up_comma_separated_list from '../images/block-icons/break-up-comma-separated-list.svg';
import closeio from '../images/block-icons/integration-block-icons/closeio.svg';
import compare_datetime_column_to_date from '../images/block-icons/compare-datetime-column-to-date.svg';
import compare_to_number from '../images/block-icons/compare-to-number.svg';
import convert_column_percentage_dist from '../images/block-icons/convert-column-percentage-dist.svg';
import convert_text_case from '../images/block-icons/convert-text-case.svg';
import copy_column from '../images/block-icons/copy-column.svg';
import create_dummy_columns from '../images/block-icons/create-dummy-columns.svg';
import create_dummy_columns_if_missing from '../images/block-icons/create-dummy-columns-if-missing.svg';
import dashboard from '../images/block-icons/dashboard.svg';
import delete_columns from '../images/block-icons/delete-columns.svg';
import difference_datetime_columns from '../images/block-icons/difference-datetime-columns.svg';
import do_math from '../images/block-icons/do-math.svg';
import domo from '../images/block-icons/integration-block-icons/domo.svg';
import dynamodb from '../images/block-icons/integration-block-icons/dynamodb.png';
import email from '../images/block-icons/integration-block-icons/email.svg';
import enrich_by_zipcode from '../images/block-icons/enrich-by-zipcode.svg';
import extract_date_time from '../images/block-icons/extract-date-time.svg';
import facebook_ads from '../images/block-icons/integration-block-icons/facebook-ads.svg';
import fill_missing_values from '../images/block-icons/fill-missing-values.svg';
import filter_data from '../images/block-icons/filter-data.svg';
import filter_for_true from '../images/block-icons/filter-for-true.svg';
import find_and_replace from '../images/block-icons/find-and-replace.svg';
import find_missing_rows_in_two_tables from '../images/block-icons/find-missing-rows-in-two-tables.svg';
import find_words_from_a_list_and_add_them_to_new_column from '../images/block-icons/find-words-from-a-list-and-add-them-to-new-column.svg';
import google_big_query from '../images/block-icons/integration-block-icons/google-big-query.svg';
import google_sheets from '../images/block-icons/integration-block-icons/google-sheets.svg';
import harvest from '../images/harvest-logo.png';
import heatmap from '../images/block-icons/heatmap.svg';
import horizontal_bar_chart from '../images/block-icons/horizontal-bar-chart.svg';
import hubspot from '../images/hubspot.svg';
import join_tables_horizontally from '../images/block-icons/join-tables-horizontally.svg';
import join_tables_vertically from '../images/block-icons/join-tables-vertically.svg';
import line_chart from '../images/block-icons/line-chart.svg';
import logical_and from '../images/block-icons/logical-and.svg';
import merge_multiple_columns_with_separator from '../images/block-icons/merge-multiple-columns-with-separator.svg';
import mongodb from '../images/block-icons/integration-block-icons/mongodb.svg';
import notion from '../images/block-icons/integration-block-icons/notion.png';
import note from '../images/block-icons/note.svg';
import pie_chart from '../images/block-icons/pie-chart.svg';
import pivot_table from '../images/block-icons/pivot-table.svg';
import Postgres from '../images/block-icons/integration-block-icons/Postgres.png';
import quickbase from '../images/block-icons/integration-block-icons/quickbase.svg';
import quickbooks from '../images/quickbooks.svg';
import redshift from '../images/block-icons/integration-block-icons/redshift.svg';
import remove_a_value_of_series_of_characters from '../images/block-icons/remove-a-value-of-series-of-characters.svg';
import remove_comma from '../images/block-icons/remove-comma.svg';
import remove_dollar_signs from '../images/block-icons/remove-dollar-signs.svg';
import remove_mostly_empty_columns from '../images/block-icons/remove-mostly-empty-columns.svg';
import remove_percent from '../images/block-icons/remove-percent.svg';
import remove_quote from '../images/block-icons/remove-quote.svg';
import remove_rows_with_all_values_missing from '../images/block-icons/remove-rows-with-all-values-missing.svg';
import remove_rows_with_missing_in_selected_column from '../images/block-icons/remove-rows-with-missing-in-selected-column.svg';
import rename_columns from '../images/block-icons/rename-columns.svg';
import replace_text_with_another_column from '../images/block-icons/replace-text-with-another-column.svg';
import REST_API from '../images/block-icons/integration-block-icons/REST-API.svg';
import round_numbers from '../images/block-icons/round-numbers.svg';
import running_total from '../images/block-icons/running-total.svg';
import salesforce from '../images/block-icons/integration-block-icons/salesforce.svg';
import scatter_plot from '../images/block-icons/scatter-plot.svg';
import select_subset_of_columns from '../images/block-icons/select-subset-of-columns.svg';
import shopify from '../images/block-icons/integration-block-icons/shopify.svg';
import snowflake from '../images/block-icons/integration-block-icons/snowflake.svg';
import sort_data from '../images/block-icons/sort-data.svg';
import split_with_separator from '../images/block-icons/split-with-separator.svg';
import sql_database from '../images/block-icons/integration-block-icons/sql-database.svg';
import stacked_bar_chart from '../images/block-icons/stacked-bar-chart.svg';
import sum_columns from '../images/block-icons/sum-columns.svg';
import truncate_columns_with_n_characters from '../images/block-icons/truncate-columns-with-n-characters.svg';
import typeform from '../images/block-icons/integration-block-icons/typeform.svg';
import unify_date_time_format from '../images/block-icons/unify-date-time-format.svg';
import unpivot_table from '../images/block-icons/unpivot-table.svg';
import upload_file from '../images/block-icons/integration-block-icons/upload-file.svg';
import woocommerce from '../images/block-icons/integration-block-icons/woocommerce.png';

export const iconNameToFileName = (iconName) =>
  (iconName || '').replaceAll('_', '-');

export default {
  add_characters_to_each_element_in_a_column: add_characters_to_each_element_in_a_column,
  add_time_to_date: add_time_to_date,
  aggregate_data: aggregate_data,
  airtable: airtable,
  bar_chart: bar_chart,
  boolean_is_cell_populated: boolean_is_cell_populated,
  break_up_comma_separated_list: break_up_comma_separated_list,
  closeio: closeio,
  compare_datetime_column_to_date: compare_datetime_column_to_date,
  compare_to_number: compare_to_number,
  convert_column_percentage_dist: convert_column_percentage_dist,
  convert_text_case: convert_text_case,
  copy_column: copy_column,
  create_dummy_columns_if_missing: create_dummy_columns_if_missing,
  create_dummy_columns: create_dummy_columns,
  dashboard: dashboard,
  delete_columns: delete_columns,
  difference_datetime_columns: difference_datetime_columns,
  do_math: do_math,
  domo: domo,
  dynamodb: dynamodb,
  email: email,
  enrich_by_zipcode: enrich_by_zipcode,
  extract_date_time: extract_date_time,
  facebook_ads: facebook_ads,
  fill_missing_values: fill_missing_values,
  filter_data: filter_data,
  filter_for_true: filter_for_true,
  find_and_replace: find_and_replace,
  find_missing_rows_in_two_tables: find_missing_rows_in_two_tables,
  find_words_from_a_list_and_add_them_to_new_column: find_words_from_a_list_and_add_them_to_new_column,
  google_big_query: google_big_query,
  google_sheets: google_sheets,
  harvest: harvest,
  heatmap: heatmap,
  horizontal_bar_chart: horizontal_bar_chart,
  hubspot: hubspot,
  join_tables_horizontally: join_tables_horizontally,
  join_tables_vertically: join_tables_vertically,
  line_chart: line_chart,
  logical_and: logical_and,
  merge_multiple_columns_with_separator: merge_multiple_columns_with_separator,
  mongodb: mongodb,
  note: note,
  notion: notion,
  pie_chart: pie_chart,
  pivot_table: pivot_table,
  Postgres: Postgres,
  quickbase: quickbase,
  quickbooks: quickbooks,
  redshift: redshift,
  remove_a_value_of_series_of_characters: remove_a_value_of_series_of_characters,
  remove_comma: remove_comma,
  remove_dollar_signs: remove_dollar_signs,
  remove_mostly_empty_columns: remove_mostly_empty_columns,
  remove_percent: remove_percent,
  remove_quote: remove_quote,
  remove_rows_with_all_values_missing: remove_rows_with_all_values_missing,
  remove_rows_with_missing_in_selected_column: remove_rows_with_missing_in_selected_column,
  rename_columns: rename_columns,
  replace_text_with_another_column: replace_text_with_another_column,
  REST_API: REST_API,
  round_numbers: round_numbers,
  running_total: running_total,
  salesforce: salesforce,
  scatter_plot: scatter_plot,
  select_subset_of_columns: select_subset_of_columns,
  shopify: shopify,
  snowflake: snowflake,
  sort_data: sort_data,
  split_with_separator: split_with_separator,
  sql_database: sql_database,
  stacked_bar_chart: stacked_bar_chart,
  sum_columns: sum_columns,
  truncate_columns_with_n_characters: truncate_columns_with_n_characters,
  typeform: typeform,
  unify_date_time_format: unify_date_time_format,
  unpivot_table: unpivot_table,
  upload_file: upload_file,
  woocommerce: woocommerce,
};
