/* eslint-disable import/no-anonymous-default-export */
import { Button, Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { PurpleButton, DarkButton } from './CustomColorButton';
import { makeAPICall } from '../api/useAPI';

const TitleElements = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

const EditingTitleElements = styled(TitleElements)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => p.theme.color.grayscale.white};
  border: 1px solid ${(p) => p.theme.color.secondary.lighter};
  border-radius: 4px;
  padding: 4px 5px 4px 0px;
  cursor: default;
`;

const Title = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.normal};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  color: ${(p) =>
    p.isDraft ? p.theme.color.tertiary.bright : p.theme.color.grayscale.dark};
  margin-right: 10px;
`;

const ActionButtonWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const EditButton = styled(Button)`
  border-color: transparent;
  color: ${(p) => p.theme.color.secondary.bright};
`;

const CancelButton = styled(DarkButton)`
  margin-left: 5px;
`;

const EditIcon = styled(EditOutlined)``;

export default ({
  title,
  titleStyle,
  isEditable = true,
  isDraft,
  saveTitleChange,
}) => {
  const [newTitle, setNewTitle] = useState();
  const [editingInProgress, setEditingInProgress] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setNewTitle(undefined);
    setEditingInProgress(false);
  }, [title, isEditable]);

  const onStartEditingButtonClick = (e) => {
    e.stopPropagation();
    setEditingInProgress(true);
  };

  const cancelEditing = (e) => {
    e.stopPropagation();
    setEditingInProgress(false);
    setNewTitle(undefined);
  };

  const onConfirmEdit = async (e) => {
    e.stopPropagation();
    setEditingInProgress(false);
    setSaving(true);
    const success = await saveTitleChange(newTitle);
    setSaving(false);
  };

  const titleTextComponent = (
    <Title isDraft={isDraft} style={titleStyle}>
      {title}
    </Title>
  );
  if (!isEditable) {
    return titleTextComponent;
  }

  if (editingInProgress) {
    return (
      <EditingTitleElements onClick={(e) => e.stopPropagation()}>
        <Input
          defaultValue={title}
          onClickCapture={(e) => e.stopPropagation()}
          onPressEnter={onConfirmEdit}
          bordered={false}
          onChange={(e) => setNewTitle(e.target.value)}
        />
        <ActionButtonWrapper>
          {!saving && (
            <PurpleButton
              shape="circle"
              type="primary"
              size="small"
              disabled={newTitle === undefined}
              icon={<CheckOutlined />}
              onClickCapture={onConfirmEdit}
            />
          )}
          <CancelButton
            danger
            shape="circle"
            size="small"
            icon={<CloseOutlined />}
            onClickCapture={cancelEditing}
            loading={saving}
          />
        </ActionButtonWrapper>
      </EditingTitleElements>
    );
  }

  return (
    <TitleElements>
      {titleTextComponent}
      {saveTitleChange && (
        <EditButton
          icon={<EditIcon />}
          shape="circle"
          title="Rename"
          onClickCapture={onStartEditingButtonClick}
        />
      )}
    </TitleElements>
  );
};
