import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SectionTitle } from '../standard-components/typography';
import { CardContainer } from '../standard-components/containers';
import INTEGRATION_TYPES from '../integration-components/integrationTypes';
import { useImmer } from 'use-immer';
import IntegrationFormFooter from '../integration-components/IntegrationFormFooter';
import FormInputRow from '../integration-components/FormInputRow';
import { makeAPICall } from '../api/useAPI';

const PREVIEW_CARD_WIDTH = '531px';
const PREVIEW_CARD_HEIGHT = '577px';
const IntegrationCardContainer = styled(CardContainer)`
  padding: 0;
  padding-top: 22px;
  min-width: ${PREVIEW_CARD_WIDTH};
  max-width: ${PREVIEW_CARD_WIDTH};
  min-height: ${PREVIEW_CARD_HEIGHT};
  // max-height: ${PREVIEW_CARD_HEIGHT};
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const AddIntegrationFormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const IntegrationCardHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid
    ${({ theme: { color } }) => color.grayscale.extraLightGray};
`;

const IntegrationLogoImage = styled.img`
  width: 38px;
  height: 38px;
  margin-right: 38px;
`;

const IntegrationFormBodyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 56px;
`;

const RendererStyledContainer = styled.div`
  display: flex;
`;

const fetchExistingIntegration = (integrationId, updateFormRowsData) => {
  makeAPICall({
    endpoint: `/integration/?integration_id=${integrationId}`,
  }).then(([{ name, credentials = {} }]) => {
    // works if credentials undefined (eg integration has only protected fields)
    updateFormRowsData((draft) =>
      draft.map((formRow) => ({
        ...formRow,
        // Just use '' for protected credentials for now
        value: formRow.id === 'name' ? name : credentials[formRow.id] || '',
      }))
    );
  });
};

const hackyFilterJustForRESTAPI = (formRowsData, integrationTypeId) => {
  if (integrationTypeId === INTEGRATION_TYPES.REST_API.integration_type) {
    const authType = (formRowsData.find(({ id }) => id === 'auth_type') || {})
      .value;
    return formRowsData.filter(
      ({ useWith, id }) =>
        useWith === authType || id === 'auth_type' || id === 'name'
    );
  }
  return formRowsData;
};

export const useIntegrationFormRowsData = (
  integrationTypeId,
  integrationId
) => {
  const { credentials } = INTEGRATION_TYPES[integrationTypeId];
  const nameRow = {
    id: 'name',
    inputTitle: 'Integration name',
    placeholder: 'eg Sales Database',
  };
  const allFormRowsNeeded = [nameRow, ...credentials];
  const [formRowsData, updateFormRowsData] = useImmer([]);
  useEffect(() => {
    const updatedFormRowsData = allFormRowsNeeded.map((formRowInfo, i) => ({
      ...formRowInfo,
      value: '',
      onChange: (value) =>
        updateFormRowsData((draft) => {
          draft[i].value = value;
        }),
    }));
    updateFormRowsData(() => updatedFormRowsData);
    integrationId &&
      fetchExistingIntegration(integrationId, updateFormRowsData);
  }, []);

  return hackyFilterJustForRESTAPI(formRowsData, integrationTypeId);
};

const IntegrationFormBody = ({
  acceptsSSHCredentials,
  integrationType,
  integrationId,
  onIntegrationAddComplete,
  redirectToIntegrations,
  integrationName,
}) => {
  const formRowsData = useIntegrationFormRowsData(
    integrationType,
    integrationId
  );

  const processedFormRowsData = formRowsData.reduce(
    (accum, row) => ({ ...accum, [row.id]: row.value }),
    {}
  );

  const formIncomplete = formRowsData.some(
    ({ value, isProtected, optional }) => {
      const shouldBeFilled = !optional && (!isProtected || !integrationId);
      return !value && shouldBeFilled;
    }
  );

  return (
    <IntegrationFormBodyContainer>
      <div>
        {formRowsData.map((formRowData) => (
          <FormInputRow key={formRowData.inputTitle} {...formRowData} />
        ))}
      </div>
      <IntegrationFormFooter
        values={processedFormRowsData}
        formIncomplete={formIncomplete}
        integrationType={integrationType}
        integrationId={integrationId}
        redirectToIntegrations={redirectToIntegrations}
        onIntegrationAddComplete={onIntegrationAddComplete}
        integrationName={integrationName}
      />
    </IntegrationFormBodyContainer>
  );
};

const AddIntegrationForm = ({
  name,
  image,
  integrationType,
  integrationId,
  onIntegrationAddComplete,
  redirectToIntegrations,
}) => (
  <AddIntegrationFormContainer>
    <IntegrationCardContainer>
      <IntegrationCardHeader>
        <IntegrationLogoImage src={image} />
        <SectionTitle bold grayscale darker>
          {name}
        </SectionTitle>
      </IntegrationCardHeader>

      <IntegrationFormBody
        integrationType={integrationType}
        integrationId={integrationId}
        onIntegrationAddComplete={onIntegrationAddComplete}
        redirectToIntegrations={redirectToIntegrations}
        integrationName={name}
      />
    </IntegrationCardContainer>
  </AddIntegrationFormContainer>
);

export default ({
  integrationType,
  integrationId = '',
  onIntegrationAddComplete = () => {},
  redirectToIntegrations,
}) => {
  let integrationTypeInfo = INTEGRATION_TYPES[integrationType];
  if (!integrationTypeInfo) {
    return (
      <>
        <p>Sorry, this integration type is not available right now</p>
      </>
    );
  }
  const Renderer = integrationTypeInfo.renderer;
  return (
    <>
      {Renderer ? (
        <RendererStyledContainer>
          <Renderer
            {...integrationTypeInfo}
            integrationType={integrationType}
            integrationId={integrationId}
            onIntegrationAddComplete={onIntegrationAddComplete}
            redirectToIntegrations={redirectToIntegrations}
          />
        </RendererStyledContainer>
      ) : (
        <AddIntegrationForm
          {...integrationTypeInfo}
          integrationType={integrationType}
          integrationId={integrationId}
          onIntegrationAddComplete={onIntegrationAddComplete}
          redirectToIntegrations={redirectToIntegrations}
        />
      )}
    </>
  );
};
