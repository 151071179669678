import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { makeAPICall } from '../api/useAPI';
import { Button, message, Form } from 'antd';
import React from 'react';
import { useTeamId } from '../model/Team';

const IntegrationFormFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const handleSubmitFormData = async ({
  values,
  integrationId,
  team_id,
  updatingExisting,
  integrationType,
  integrationName,
  redirectToIntegrations,
  onIntegrationAddComplete,
  history,
}) => {
  const { name, ...credentials } = values;

  const body = {
    integration_type: integrationType,
    name,
    credentials,
    team_id,
  };

  if (updatingExisting) {
    window.analytics.track('Edit Integration', {
      type: integrationType,
    });
  } else {
    window.analytics.track('Add Integration', {
      type: integrationType,
    });
  }

  const [result, error] = await makeAPICall({
    endpoint: updatingExisting
      ? `/integration/edit?integration_id=${integrationId}`
      : '/integration/',
    method: 'POST',
    body: updatingExisting ? { name, credentials } : body,
  });

  if (error) {
    message.error(
      `There was an issue adding your ${integrationName} integration. Please try again or contact us at hello@intersectlabs.io`
    );
    onIntegrationAddComplete(false);
    return;
  }

  onIntegrationAddComplete(true);
  if (redirectToIntegrations) {
    history.push('/integrations');
  }
  message.success(
    `Successfully ${
      updatingExisting ? 'Updated' : 'Added'
    } ${integrationName} Integration`
  );
};

export default ({
  values = {},
  formIncomplete = false,
  integrationType,
  integrationId = '',
  redirectToIntegrations = true,
  onIntegrationAddComplete = () => {},
  integrationName,
}) => {
  const team_id = useTeamId();
  const history = useHistory();
  const updatingExisting = !!integrationId;

  return (
    <IntegrationFormFooterContainer>
      <Button
        type="primary"
        disabled={formIncomplete}
        onClick={() => {
          handleSubmitFormData({
            values,
            integrationId,
            team_id,
            updatingExisting,
            integrationType,
            integrationName,
            redirectToIntegrations,
            onIntegrationAddComplete,
            history,
          });
        }}
      >
        {updatingExisting ? 'Update' : 'Add'}
      </Button>
    </IntegrationFormFooterContainer>
  );
};
