import React, { useState } from 'react';
import PricingView from '../checkout-components/PricingViewController';
import styled from 'styled-components';
import { Space, Button, Modal, Spin } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import CurrentSubscriptionSummary from '../checkout-components/CurrentSubscriptionSummary';
import { PRICING_FLOWS } from '../checkout-components/SubscriptionUtils';
import { systemColors } from '../colors/systemColors';
import {
  useBillingInfo,
  useBillingInfoIsLoading,
  useCancelSubscription,
  useSubscriptionIsOrWillBeCanceled,
} from '../model/BillingContext';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import InvoiceListView from './InvoiceListView';
import { get } from 'lodash';

const BillingView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const BillingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 30px;
`;

const SectionTitle = styled.div`
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const WarningIcon = styled(ExclamationCircleFilled)`
  color: ${systemColors.gold.cheezIt};
`;

const BILLING_MODAL_OPTIONS = {
  changePlans: 'change-plans',
};

export default ({ subTabId = null }) => {
  const [selectedPricingFlow, setSelectedPricingFlow] = useState(
    subTabId === BILLING_MODAL_OPTIONS.changePlans
  );
  const { subscription_details, past_invoices } = useBillingInfo();
  const isLoading = useBillingInfoIsLoading();
  const subscriptionIsOrWillBeCanceled = useSubscriptionIsOrWillBeCanceled();
  const cancelSubscription = useCancelSubscription();

  const confirmedCancelSubscription = async () => {
    const success = await cancelSubscription();

    if (success) {
      Modal.success({
        title:
          'Your subscription has been successfully set to cancel at the end of your current billing period. ',
      });
    }
  };

  const showCancellationWarning = () => {
    Modal.confirm({
      icon: <WarningIcon />,
      title: 'Are you sure you want to cancel?',
      content:
        "We really don't want to see you go. Please contact us at hello@intersectlabs.io if we can help change your mind.",
      cancelText: 'Cancel Subscription',
      okText: "Don't Cancel",
      onCancel: confirmedCancelSubscription,
      onOk: () => {},
    });
  };

  return (
    <BillingView>
      <Spin spinning={isLoading}>
        <BillingSection>
          <SectionTitle>Current Plan</SectionTitle>
          <Space direction="horizontal">
            <CurrentSubscriptionSummary />
          </Space>
        </BillingSection>
        <BillingSection>
          <SectionTitle>Update Subscription</SectionTitle>
          <Space direction="horizontal" size="middle">
            <Button
              onClick={() =>
                setSelectedPricingFlow(PRICING_FLOWS.updateCreditCard)
              }
              type="primary"
            >
              Update Credit Card
            </Button>
            <Button
              onClick={() => setSelectedPricingFlow(PRICING_FLOWS.changePlans)}
            >
              {subscriptionIsOrWillBeCanceled
                ? 'Start New Subscription'
                : 'Change Plan'}
            </Button>
            {!subscriptionIsOrWillBeCanceled && (
              <Button onClick={showCancellationWarning}>
                Cancel Subscription
              </Button>
            )}
          </Space>
        </BillingSection>
        {selectedPricingFlow && (
          <PricingView
            pricingFlow={selectedPricingFlow}
            showModal={true}
            onClose={() => setSelectedPricingFlow(null)}
          />
        )}
        {past_invoices && (
          <BillingSection>
            <SectionTitle>Invoices</SectionTitle>
            <InvoiceListView invoices={past_invoices} />
          </BillingSection>
        )}
      </Spin>
    </BillingView>
  );
};
