import { Select } from 'antd';
import { get, isArray, isUndefined } from 'lodash';
import React, { useContext } from 'react';
import SchemaRendererContext from '../../../../model/SchemaRendererContext';
import { useVariables } from '../../../../model/DataAppVariableContextHelpers';
import { PublishedValueText, withCoreInputBehavior } from './CoreInputHOC';
import SelectInput from './SelectInput';
import { renderChildren } from './SentenceRendererCore';
import CustomValueOrOptionSelectInput from './CustomValueOrOptionSelectInput';
import { ColumnInputContext } from '../../../../model/ColumnInputContext';
import styled from 'styled-components';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';

const PlaceholderSelect = styled(Select)`
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const DA_VINCI_COLUMN_TYPES = {
  UNDEFINED: 'Undefined',
  NUMERICAL_COLUMNS: 'Numerical',
  STRING_COLUMNS: 'Characters',
  DATETIME_COLUMNS: 'Datetime',
  AGGREGATIONS: 'Aggregations',
};

const ALL_COLUMNS_OPTION_VALUE = '__INTERSECT_OPTION_VALUE_ALL_COLUMNS__';
const ALL_COLUMNS_OPTION_DISPLAY = 'All Numerical Columns';

const getColumnsFromDataset = (dataset, schema) => {
  const categorizedColumns = get(dataset, 'properties.column_data_types') || {};

  if (schema.restrict_column_type) {
    return schema.restrict_column_type.reduce(
      (accum, type) => [
        ...accum,
        ...(categorizedColumns[type.toLowerCase()] || []),
      ],
      []
    );
  } else {
    return categorizedColumns['all'] || [];
  }
};

const getColumns = (dataset, schema) => {
  const columnsFromDataset = getColumnsFromDataset(dataset, schema);
  return schema.insert_all_columns_option
    ? [
        { [ALL_COLUMNS_OPTION_DISPLAY]: ALL_COLUMNS_OPTION_VALUE },
        ...columnsFromDataset,
      ]
    : columnsFromDataset;
};

const getPathForChild = (currentPath, childId) => {
  const pathArray = currentPath.split('.');
  pathArray.pop();
  pathArray.push(childId);
  return pathArray.join('.');
};

const filterChildrenByColumnTypeConditions = ({
  schema,
  dataset,
  currentValue,
  reportInputValue,
  getCurrentValue,
  currentPath,
  disabled,
}) => {
  if (schema.children && dataset && dataset.properties.column_data_types) {
    const { numerical, datetime, all } = dataset.properties.column_data_types;
    const string = all.filter(
      (columnName) =>
        !(numerical.includes(columnName) || datetime.includes(columnName))
    );
    const colIsNumerical = numerical.includes(currentValue);
    const colIsDatetime = datetime.includes(currentValue);
    const colIsString = string.includes(currentValue);
    const columnTypeFilteredChildren = schema.children.filter(
      ({ show_if_col_type = null, id }) => {
        if (!show_if_col_type) return true;
        const showChild = show_if_col_type.some((columnType) => {
          switch (columnType) {
            case DA_VINCI_COLUMN_TYPES.NUMERICAL_COLUMNS:
              return colIsNumerical;
            case DA_VINCI_COLUMN_TYPES.DATETIME_COLUMNS:
              return colIsDatetime;
            case DA_VINCI_COLUMN_TYPES.STRING_COLUMNS:
              return colIsString;
            default:
              console.error('Encountered novel columnType', columnType);
              return false;
          }
        });
        return showChild;
      }
    );

    const renderedChildren = renderChildren({
      children: columnTypeFilteredChildren,
      reportInputValue,
      getCurrentValue,
      currentPath,
      disabled,
      parentId: schema.id,
      renderAsSibling: true,
      config: {
        dataset,
        column: currentValue,
        colIsNumerical,
        colIsDatetime,
        colIsString,
      },
    });
    return (
      <ColumnInputContext.Provider
        value={{ colIsNumerical, colIsDatetime, colIsString }}
      >
        {renderedChildren}
      </ColumnInputContext.Provider>
    );
  }

  return null;
};

const displayCol = (col) =>
  col === ALL_COLUMNS_OPTION_VALUE ? ALL_COLUMNS_OPTION_DISPLAY : col;

const ColumnInput = ({
  schema,
  reportInputValue,
  currentValue,
  getCurrentValue,
  currentPath,
  disabled,
  shouldShowPublishedVersion,
  size,
  suffix,
  stepInteractivity,
  config,
}) => {
  const variables = useVariables();
  const { firstDataSetInputValue, getValueForId } = useContext(
    SchemaRendererContext
  );

  const selectedDataset = !isUndefined(schema.use_columns_from_this_input)
    ? get(getValueForId(schema.use_columns_from_this_input), 'value')
    : get(firstDataSetInputValue, 'value');

  const dataset = variables[selectedDataset];
  const columns = getColumns(dataset, schema);

  const columnTypeFilteredChildren = filterChildrenByColumnTypeConditions({
    schema,
    dataset,
    currentValue,
    reportInputValue,
    getCurrentValue,
    currentPath,
    disabled,
    stepInteractivity,
  });

  if (shouldShowPublishedVersion) {
    let publishedVersion;
    if (currentValue) {
      if (schema.type === 'value_or_column') {
        publishedVersion = (
          <PublishedValueText>
            {displayCol(Object.values(currentValue)[0])}
          </PublishedValueText>
        );
      } else if (isArray(currentValue)) {
        publishedVersion = (
          <PublishedValueText>
            {currentValue.map(displayCol).join(', ')}
          </PublishedValueText>
        );
      } else {
        publishedVersion = (
          <PublishedValueText>{displayCol(currentValue)}</PublishedValueText>
        );
      }
    } else {
      publishedVersion = <PublishedValueText>no column(s)</PublishedValueText>;
    }

    return (
      <>
        {publishedVersion}
        {columnTypeFilteredChildren}
      </>
    );
  }

  if (
    isUndefined(schema.use_columns_from_this_input) &&
    isUndefined(firstDataSetInputValue)
  ) {
    const helperMessage = 'Select dataset to select columns';
    return (
      <PlaceholderSelect defaultValue={helperMessage} disabled>
        <Select.Option>{helperMessage}</Select.Option>
      </PlaceholderSelect>
    );
  }

  const onColumnSelect = (_, selectedValue) => {
    // doesn't need type: 'static' specification because
    // SelectInput handles that
    reportInputValue(schema.id, selectedValue);
  };

  const baseProps = {
    options: columns,
    reportInputValue: onColumnSelect,
    value: currentValue,
    disabled: disabled,
  };

  return (
    <>
      {schema.type === 'value_or_column' ? (
        <CustomValueOrOptionSelectInput
          {...baseProps}
          placeholder={
            schema.placeholder_value || 'Enter value or select column...'
          }
          providedOptionsKey="column_name"
          providedOptionsLabel="Column"
          typedOptionKey="primitive_value"
          typedOptionLabel="Value"
          size={size}
          config={config}
        />
      ) : (
        <SelectInput
          {...baseProps}
          placeholder={schema.placeholder_value || 'Select column...'}
          allowMultiple={schema.allow_multiple}
          showSearch
          size={size}
        />
      )}
      {suffix}
      {columnTypeFilteredChildren}
    </>
  );
};

export default withCoreInputBehavior(ColumnInput);
