import React, { createContext, useContext, useEffect } from 'react';
import { useTeamId } from './Team';
import { makeAPICall } from '../api/useAPI';
import { useImmer } from 'use-immer';
import { get } from 'lodash';

export const BillingContext = createContext({});

export const useBillingInfo = () => {
  const { billingInfo } = useContext(BillingContext);
  return billingInfo || {};
};

export const useBillingInfoIsLoading = () => {
  const { isLoading } = useContext(BillingContext);
  return isLoading;
};

export const useTeammates = () => {
  const { user_info = [] } = useBillingInfo();
  const sortedAlphabetically = [...user_info];
  sortedAlphabetically.sort((a, b) => a.name.localeCompare(b.name));
  return sortedAlphabetically;
};

const EXAMPLE_FEATURE_FLAGS = {
  'integration.shopify': {
    flag: 'invisible',
    to_unblock: ['prod_J4LzZTmrY0XU1A', 'prod_J4M0YYr6BTuOhl'],
  },
  'integration.snowflake': {
    flag: 'visible_not_usable',
    to_unblock: ['prod_J4LzZTmrY0XU1A', 'prod_J4M0YYr6BTuOhl'],
  },
};

export const useFeatureFlagInfo = (featureFlagId) => {
  const { feature_flags = {} } = useBillingInfo();
  return get(feature_flags, featureFlagId, {});
};

export const useSubscriptionWillBeCanceled = () => {
  const { subscription_details } = useBillingInfo();
  return get(subscription_details, 'cancel_at_period_end');
};

export const useSubscriptionIsCanceled = () => {
  const { subscription_details } = useBillingInfo();
  return get(subscription_details, 'subscription_status') === 'canceled';
};

export const useSubscriptionIsOrWillBeCanceled = () => {
  const willCancelAtPeriodEnd = useSubscriptionWillBeCanceled();
  const subscriptionIsCanceled = useSubscriptionIsCanceled();
  return subscriptionIsCanceled || willCancelAtPeriodEnd;
};

export const useUpdateCreditCard = () => {
  const { updateBillingContextValue } = useContext(BillingContext);
  const teamId = useTeamId();

  return async (paymentMethodId) => {
    updateBillingContextValue((draftState) => {
      draftState.isLoading = true;
    });

    const [updatedBillingInfo, ccUpdateError] = await makeAPICall({
      endpoint: `/billing/creditcard?team_id=${teamId}&payment_method=${paymentMethodId}`,
      method: 'POST',
    });

    if (!ccUpdateError) {
      updateBillingContextValue((draftState) => {
        draftState.billingInfo = updatedBillingInfo;
        draftState.isLoading = false;
      });
      return false;
    }

    updateBillingContextValue((draftState) => {
      draftState.isLoading = false;
    });

    return ccUpdateError;
  };
};

export const useUpdateSubscription = () => {
  const { updateBillingContextValue, billingInfo } = useContext(BillingContext);
  const teamId = useTeamId();
  const subscriptionIsOrWillBeCanceled = useSubscriptionIsOrWillBeCanceled();

  return async (price_id) => {
    updateBillingContextValue((draftState) => {
      draftState.isLoading = true;
    });

    let action = 'edit';

    const id = get(billingInfo, 'subscription_details.id', undefined);

    if (!id || subscriptionIsOrWillBeCanceled) {
      action = 'start';
    }

    const [updatedBillingInfo, error] = await makeAPICall({
      endpoint: `/billing/subscription/${action}?team_id=${teamId}`,
      method: 'POST',
      body: { price_id },
    });

    if (!error) {
      updateBillingContextValue((draftState) => {
        draftState.billingInfo = updatedBillingInfo;
        draftState.isLoading = false;
      });
      return true;
    }

    updateBillingContextValue((draftState) => {
      draftState.isLoading = false;
    });

    return false;
  };
};

export const useCancelSubscription = () => {
  const { updateBillingContextValue } = useContext(BillingContext);
  const teamId = useTeamId();

  return async () => {
    updateBillingContextValue((draftState) => {
      draftState.isLoading = true;
    });

    const [updatedBillingInfo, error] = await makeAPICall({
      endpoint: `/billing/subscription/delete?team_id=${teamId}`,
      method: 'POST',
    });

    if (!error) {
      updateBillingContextValue((draftState) => {
        draftState.billingInfo = updatedBillingInfo;
        draftState.isLoading = false;
      });
      return true;
    }

    updateBillingContextValue((draftState) => {
      draftState.isLoading = false;
    });

    return false;
  };
};

export const BillingContextProvider = ({ children }) => {
  const [billingContextValue, updateBillingContextValue] = useImmer({});
  const teamId = useTeamId();

  useEffect(() => {
    const fetchBillingInfo = () => {
      updateBillingContextValue((draftState) => {
        draftState.isLoading = true;
      });
      makeAPICall({
        endpoint: `/billing/?team_id=${teamId}`,
      }).then(([fetchedBillingInfo, fetchError]) =>
        updateBillingContextValue((draftState) => {
          draftState.billingInfo = fetchedBillingInfo;
          draftState.isLoading = false;
        })
      );
    };
    if (teamId) {
      fetchBillingInfo();
    }
  }, [teamId]);

  return (
    <BillingContext.Provider
      value={{ ...billingContextValue, updateBillingContextValue }}
    >
      {children}
    </BillingContext.Provider>
  );
};
