import React, { createContext, useMemo, useContext } from 'react';

const StepOutputContext = createContext({});

export const useStepOutputVariableId = () => {
  const { variable } = useContext(StepOutputContext);
  return variable;
};

export const StepOutputContextProvider = ({ variable, children, ...props }) => {
  const value = useMemo(() => ({ variable }), [variable]);
  return (
    <StepOutputContext.Provider value={value} {...props}>
      {children}
    </StepOutputContext.Provider>
  );
};
