import React from 'react';
import styled from 'styled-components';
import { DataAppContextProvider } from '../../model/DataAppContext';
import { StudioPageContextProvider } from '../../model/StudioPageContext';
import RunInstancePage from './RunInstancePage';

const StepsContainer = styled.div`
  flex-grow: 1;

  @media (max-width: 1224px) {
    width: 100vw;
  }
`;

export default ({
  appId,
  instanceId,
  isEmbedded = true,
  stepsOnly = true,
  noShadow = false,
  doNotShowInteractiveTag = false,
}) => {
  setTimeout(() => (window.prerenderReady = true), 5000);
  return (
    <DataAppContextProvider appId={appId} instanceId={instanceId}>
      <StudioPageContextProvider>
        <StepsContainer>
          <RunInstancePage
            currentInstanceId={instanceId}
            isEmbedded={isEmbedded}
            noShadow={noShadow}
            showAsPublicApp
            stepsOnly={stepsOnly}
            hideShowAllSteps
            doNotShowInteractiveTag={doNotShowInteractiveTag}
          />
        </StepsContainer>
      </StudioPageContextProvider>
    </DataAppContextProvider>
  );
};
