import { isPlainObject, isString } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import blockTypes, {
  singleStepTypeBlockInfo,
} from '../../../../model/blockTypes';
import SchemaRendererContext, {
  findValueForId,
  getFirstDataSetInputValue,
} from '../../../../model/SchemaRendererContext';
import StepContext, { useAllStepInputValues } from '../../StepContext';
import ArrayInput from './ArrayInput';
import BooleanInput from './BooleanInput';
import ColumnInput from './ColumnInput';
import MLModelInput from './MLModelInput';
import DatasetInput from './DataSetInput';
import NumberInput from './NumberInput';
import ObjectInput from './ObjectInput';
import SentenceContainerInput from './SentenceContainerInput';
import StringInput from './StringInput';
import CodeInput from './CodeInput';
import IntegrationInput from './IntegrationInput';
import GoogleSpreadsheetInput from './GoogleSpreadsheetInput';
import TextSegment from './TextSegment';

export const SentenceWrapperMaxWidth = '700px';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

/* Inserting this collapsed row between two flex items will make
 * the flex item that comes after it break to a new row */
const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

const InputRenderers = {
  array: ArrayInput,
  bool: BooleanInput,
  code_editor: CodeInput,
  column: ColumnInput,
  value_or_column: ColumnInput,
  ml_model: MLModelInput,
  integration: IntegrationInput,
  dataset: DatasetInput,
  any_variable: DatasetInput,
  number: NumberInput,
  object: ObjectInput,
  sentence: SentenceContainerInput,
  string: StringInput,
  new_line: () => <Break />,
  google_sheet: GoogleSpreadsheetInput,
};

export const renderChildren = ({
  children,
  reportInputValue,
  getCurrentValue,
  currentPath,
  disabled,
  parentId,
  renderAsSibling = false,
  shouldRenderAsInteractive,
  config = {},
}) => {
  if (children === undefined) return <></>;

  return children.map((child, index) => {
    if (isString(child)) {
      return <TextSegment key={child + index}>{child}</TextSegment>;
    } else if (child.type !== undefined) {
      const ChildInputRenderer = InputRenderers[child.type];
      let childCurrentPath = child.id;
      if (currentPath) {
        const currentPathAsArray = currentPath.split('.');
        let currentPathForChildren = currentPath;
        if (parentId && renderAsSibling) {
          currentPathAsArray.pop();
          currentPathForChildren = currentPathAsArray.join('.');
        }

        if (child.id !== undefined) {
          childCurrentPath = `${currentPathForChildren}.${child.id}`;
        } else {
          childCurrentPath = currentPathForChildren;
        }
      }

      return (
        <ChildInputRenderer
          key={child.type + index}
          schema={child}
          reportInputValue={reportInputValue}
          getCurrentValue={getCurrentValue}
          currentPath={childCurrentPath}
          disabled={disabled}
          parentId={parentId}
          shouldRenderAsInteractive={shouldRenderAsInteractive}
          config={config}
        />
      );
    } else {
      return <></>;
    }
  });
};

export const SentenceInput = ({ reportInputValue }) => {
  const sentenceInputValue = useAllStepInputValues();

  let {
    type,
    properties: { schema },
    setStepStateIsSaveable,
    stepIsEditable,
    updateSentenceInputValues,
  } = useContext(StepContext);

  if (!schema && type === 'APP') {
    // @SINCHAN TODO: this seems unused... ?
    schema = singleStepTypeBlockInfo[blockTypes.ML_TRAIN.id].schema;
  }

  if (reportInputValue) {
    updateSentenceInputValues = reportInputValue;
  }

  useEffect(() => {
    //TODO: enable required input checking
    setStepStateIsSaveable(true);
  }, []);

  const registerInputValueWithSentence = (id, value) => {
    if (id === 'root') {
      updateSentenceInputValues(value.value);
    } else {
      updateSentenceInputValues({ [id]: value });
    }
  };

  const firstDataSetInputValue = getFirstDataSetInputValue(
    schema,
    sentenceInputValue
  );

  const contextValue = {
    stepIsEditable,
    registerInputValueWithSentence,
    firstDataSetInputValue,
    getValueForId: (id) => findValueForId(id, sentenceInputValue),
  };

  return (
    <SchemaRendererContext.Provider value={contextValue}>
      <Wrapper>
        <ObjectInput
          schema={{ ...schema, id: 'root' }}
          reportInputValue={registerInputValueWithSentence}
          getCurrentValue={() => ({
            type: 'static',
            value: sentenceInputValue,
          })}
        />
      </Wrapper>
    </SchemaRendererContext.Provider>
  );
};
