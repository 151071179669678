import React, { useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useFullDatasetCSVString } from '../../../model/DataAppVariableContextHelpers';

const DownloadButton = styled(Button)`
  margin-right: 4px;
  border-color: transparent;
  color: ${(p) => p.theme.color.secondary.bright};
`;

export default ({ variable, buttonText = '' }) => {
  const [downloadClicked, setDownloadClicked] = useState(0);

  const [fullDatasetCSVString, { isLoading }] = useFullDatasetCSVString({
    variableId: variable,
    download: downloadClicked,
    downloadAfterFetching: true,
  });

  return (
    <DownloadButton
      loading={isLoading}
      icon={<DownloadOutlined />}
      shape="circle"
      onClick={() => setDownloadClicked(downloadClicked + 1)} // yolo
    >
      {buttonText}
    </DownloadButton>
  );
};
