import bar_chart from '../images/block-icons/bar-chart.svg';
import extract_date_time from '../images/block-icons/extract-date-time.svg';
import horizontal_bar_chart from '../images/block-icons/horizontal-bar-chart.svg';
import line_chart from '../images/block-icons/line-chart.svg';
import pie_chart from '../images/block-icons/pie-chart.svg';
import scatter_plot from '../images/block-icons/scatter-plot.svg';
import sum_columns from '../images/block-icons/sum-columns.svg';
import delete_columns from '../images/block-icons/delete-columns.svg';
import unify_date_time_format from '../images/block-icons/unify-date-time-format.svg';
import truncate_columns_with_n_characters from '../images/block-icons/truncate-columns-with-n-characters.svg';
import add_characters_to_each_element_in_a_column from '../images/block-icons/add-characters-to-each-element-in-a-column.svg';
import convert_text_case from '../images/block-icons/convert-text-case.svg';
import remove_a_value_of_series_of_characters from '../images/block-icons/remove-a-value-of-series-of-characters.svg';
import remove_comma from '../images/block-icons/remove-comma.svg';
import remove_dollar_signs from '../images/block-icons/remove-dollar-signs.svg';
import remove_percent from '../images/block-icons/remove-percent.svg';
import remove_quote from '../images/block-icons/remove-quote.svg';
import find_and_replace from '../images/block-icons/find-and-replace.svg';
import find_missing_rows_in_two_tables from '../images/block-icons/find-missing-rows-in-two-tables.svg';
import find_words_from_a_list_and_add_them_to_new_column from '../images/block-icons/find-words-from-a-list-and-add-them-to-new-column.svg';
import replace_text_with_another_column from '../images/block-icons/replace-text-with-another-column.svg';
import create_dummy_columns from '../images/block-icons/create-dummy-columns.svg';
import merge_multiple_columns_with_separator from '../images/block-icons/merge-multiple-columns-with-separator.svg';
import copy_column from '../images/block-icons/copy-column.svg';
import join_tables_vertically from '../images/block-icons/join-tables-vertically.svg';
import join_tables_horizontally from '../images/block-icons/join-tables-horizontally.svg';
import difference_datetime_columns from '../images/block-icons/difference-datetime-columns.svg';
import create_dummy_columns_if_missing from '../images/block-icons/create-dummy-columns-if-missing.svg';
import remove_rows_with_missing_in_selected_column from '../images/block-icons/remove-rows-with-missing-in-selected-column.svg';
import remove_rows_with_all_values_missing from '../images/block-icons/remove-rows-with-all-values-missing.svg';
import remove_mostly_empty_columns from '../images/block-icons/remove-mostly-empty-columns.svg';
import fill_missing_values from '../images/block-icons/fill-missing-values.svg';
import boolean_is_cell_populated from '../images/block-icons/boolean-is-cell-populated.svg';
import select_subset_of_columns from '../images/block-icons/select-subset-of-columns.svg';
import split_with_separator from '../images/block-icons/split-with-separator.svg';
import rename_columns from '../images/block-icons/rename-columns.svg';
import add_time_to_date from '../images/block-icons/add-time-to-date.svg';
import compare_datetime_column_to_date from '../images/block-icons/compare-datetime-column-to-date.svg';
import round_numbers from '../images/block-icons/round-numbers.svg';
import running_total from '../images/block-icons/running-total.svg';
import unpivot_table from '../images/block-icons/unpivot-table.svg';
import sort_data from '../images/block-icons/sort-data.svg';
import pivot_table from '../images/block-icons/pivot-table.svg';
import logical_and from '../images/block-icons/logical-and.svg';
import filter_for_true from '../images/block-icons/filter-for-true.svg';
import filter_data from '../images/block-icons/filter-data.svg';
import enrich_by_zipcode from '../images/block-icons/enrich-by-zipcode.svg';
import compare_to_number from '../images/block-icons/compare-to-number.svg';
import break_up_comma_separated_list from '../images/block-icons/break-up-comma-separated-list.svg';
import aggregate_data from '../images/block-icons/aggregate-data.svg';
import convert_column_percentage_dist from '../images/block-icons/convert-column-percentage-dist.svg';
import dashboard from '../images/block-icons/dashboard.svg';
import airtable from '../images/block-icons/integration-block-icons/airtable.svg';
import closeio from '../images/block-icons/integration-block-icons/closeio.svg';
import domo from '../images/block-icons/integration-block-icons/domo.svg';
import dynamodb from '../images/block-icons/integration-block-icons/dynamodb.png';
import facebook_ads from '../images/block-icons/integration-block-icons/facebook-ads.svg';
import google_sheets from '../images/block-icons/integration-block-icons/google-sheets.svg';
import mongodb from '../images/block-icons/integration-block-icons/mongodb.svg';
import Postgres from '../images/block-icons/integration-block-icons/Postgres.png';
import quickbase from '../images/block-icons/integration-block-icons/quickbase.svg';
import redshift from '../images/block-icons/integration-block-icons/redshift.svg';
import REST_API from '../images/block-icons/integration-block-icons/REST-API.svg';
import salesforce from '../images/block-icons/integration-block-icons/salesforce.svg';
import shopify from '../images/block-icons/integration-block-icons/shopify.svg';
import snowflake from '../images/block-icons/integration-block-icons/snowflake.svg';
import sql_database from '../images/block-icons/integration-block-icons/sql-database.svg';
import typeform from '../images/block-icons/integration-block-icons/typeform.svg';
import upload_file from '../images/block-icons/integration-block-icons/upload-file.svg';
import email from '../images/block-icons/integration-block-icons/email.svg';
import heatmap from '../images/block-icons/heatmap.svg';
import import_data from '../images/category-icons/import-data.svg';
import export_data from '../images/category-icons/export-data.svg';
import transform_data from '../images/category-icons/transform-data.svg';
import data_viz from '../images/category-icons/data-viz.svg';
import note from '../images/category-icons/note.svg';
import machine_learning from '../images/category-icons/machine-learning.svg';
import hubspot from '../images/hubspot.svg';
import fallbackIcon from '../images/fallback-icon.svg';
import accountingIcon from '../images/accounting-category.svg';
import QuickbooksLogo from '../images/quickbooks.svg';
import HarvestLogo from '../images/harvest-logo.png';

export const importDataCategoryDetails = {
  id: 'Import Data',
  description:
    'Import data from Excel/CSV files, databases, Google Sheets, etc.',
  icon: import_data,
};

export const mainCategoryPaths = [
  {
    ...importDataCategoryDetails,
    children: [
      {
        id: 'Upload File',
        display: 'Upload CSV or Excel File',
        description: 'Import tabular data from a CSV file or Excel sheet',
        icon: upload_file,
      },
      {
        id: 'Import from Google',
        display: 'Google Sheets',
        description: 'Import data from a sheet in Google Sheets',
        icon: google_sheets,
      },
      {
        id: 'Import from Database',
        display: 'Database',
        description:
          'Import from Postgres, SQL, MongoDB, Airtable, Quickbase, DynamoDB, etc.',
        icon: sql_database,
      },
      {
        id: 'Import E-Commerce Data',
        display: 'E-Commerce Data',
        description: 'Import data from Shopify, WooCommerce, etc.',
        icon: shopify,
      },
      {
        id: 'Import from CRM',
        display: 'CRM',
        description: 'Import from Salesforce, Close.io, etc.',
        children: [
          {
            id: 'Salesforce',
            description: 'Import Salesforce data, reports, etc.',
            icon: salesforce,
          },
          {
            id: 'Close.io',
            description: 'Import leads, etc.',
            icon: closeio,
          },
          {
            id: 'Hubspot',
            description: 'Import deals, etc.',
            icon: hubspot,
          },
        ],
        icon: salesforce,
      },
      {
        id: 'Import from Accounting',
        display: 'Accounting Tool',
        description: 'Import from Quickbooks, Harvest, etc.',
        icon: accountingIcon,
        children: [
          {
            id: 'Quickbooks',
            description: 'Import Quickbooks data, reports, etc.',
            icon: QuickbooksLogo,
          },
          {
            id: 'Import from Harvest',
            description: 'Import Harvest data, etc.',
            icon: HarvestLogo,
          },
        ],
      },
      {
        id: 'Import Marketing Data',
        display: 'Marketing data',
        description: 'Import from Facebook Ads, etc.',
        children: [
          {
            id: 'Facebook',
            description: 'Import from Facebook Ads',
            icon: facebook_ads,
          },
        ],
        icon: facebook_ads,
      },
      {
        id: 'Import from BI Tool',
        display: 'BI Tool',
        description: 'Import from Domo, etc.',
        icon: domo,
      },
      {
        id: 'Import from Data Warehouse',
        display: 'Data Warehouse',
        description: 'Import from RedShift, Snowflake, etc.',
        icon: snowflake,
      },
      {
        id: 'Import from REST API',
        display: 'REST API',
        description: 'Read data from a REST API, custom sources etc.',
        icon: REST_API,
      },
      {
        id: 'Import Sample Data',
        display: 'Sample Data',
        description: 'Import sample data to try out blocks',
        icon: create_dummy_columns,
      },
    ],
  },
  {
    id: 'Work With Data',
    description: 'Analyze and transform data with powerful blocks',
    icon: transform_data,
    children: [
      {
        id: 'Modify & Format Text',
        description: '',
        icon: convert_text_case,
      },
      {
        id: 'Find and Replace',
        description: '',
        icon: find_and_replace,
      },
      {
        id: 'Create, Combine & Duplicate',
        description: '',
        icon: join_tables_horizontally,
      },
      {
        id: 'Handle Missing Values',
        description: '',
        icon: fill_missing_values,
      },
      {
        id: 'Rename, Split or Remove Columns',
        description: '',
        icon: delete_columns,
      },
      {
        id: 'Do Math',
        description: '',
        icon: sum_columns,
      },
      {
        id: 'Date Time Operations',
        description: '',
        icon: difference_datetime_columns,
      },
      {
        id: 'Summarize, Group & Enrich Data',
        description: '',
        icon: pivot_table,
      },
      {
        id: 'Compare Columns',
        description: '',
        icon: compare_to_number,
      },
      {
        id: 'Filter or Sort',
        description: '',
        icon: filter_data,
      },
    ],
  },
  {
    id: 'Export Data',
    description: 'Export data to databases, web services or via email',
    icon: export_data,
    children: [
      {
        id: 'Export to Database',
        description: '',
        icon: sql_database,
      },
      {
        id: 'Export to Google',
        description: '',
        icon: google_sheets,
      },
      {
        id: 'Export to CRM',
        description: '',
        icon: hubspot,
        children: [
          {
            id: 'Hubspot',
            description: '',
            icon: hubspot,
          },
        ],
      },
      {
        id: 'Export to REST API',
        description: '',
        icon: REST_API,
      },
      {
        id: 'Export via Email',
        description: '',
        icon: email,
      },
    ],
  },
  {
    id: 'Data Visualization',
    description:
      'Visualize your data with powerful charts, graphs and dashboards',
    icon: data_viz,
  },
  {
    id: 'Note',
    display: 'Note',
    description:
      'A block where you can write notes and comments in your data app',
    icon: note,
  },
  {
    id: 'ML',
    display: 'Machine Learning',
    description:
      'Train machine learning models and use them to make predictions',
    icon: machine_learning,
  },
  {
    id: 'Custom',
    description: 'Use custom blocks designed for your team',
    icon: fallbackIcon,
  },
];

export const getChildCategoriesInPath = (
  path,
  startingAtCategory = mainCategoryPaths
) => {
  if (path.length > 0) {
    const matchingCategory = startingAtCategory.find(
      (cat) => cat.id === path[0]
    );
    if (matchingCategory) {
      if (matchingCategory.children) {
        const updatedPath = [...path].splice(1);
        return getChildCategoriesInPath(updatedPath, matchingCategory.children);
      }

      return [];
    }
  }
  return startingAtCategory;
};

export const getCategoriesInPath = (
  path,
  startingAtCategory = mainCategoryPaths
) => {
  if (path.length > 0) {
    const matchingCategory = startingAtCategory.find(
      (cat) => cat.id === path[0]
    );
    if (matchingCategory) {
      if (matchingCategory.children) {
        const updatedPath = [...path].splice(1);
        return [
          matchingCategory,
          ...getCategoriesInPath(updatedPath, matchingCategory.children),
        ];
      }

      return [matchingCategory];
    }
  }

  return [];
};

const getAllRootToLeafPaths = (
  categoryList = mainCategoryPaths,
  currentPath = []
) => {
  let paths = [];
  categoryList.forEach((cat) => {
    const updatedPath = [...currentPath, cat.id];
    if (cat.children) {
      const childPaths = getAllPaths(cat.children, updatedPath);
      paths = [...paths, ...childPaths];
    } else {
      paths = [...paths, updatedPath];
    }
  });
  return paths;
};

const getAllPaths = (categoryList = mainCategoryPaths, currentPath = []) => {
  let paths = [];
  categoryList.forEach((cat) => {
    const updatedPath = [...currentPath, cat.id];
    if (cat.children) {
      const childPaths = getAllPaths(cat.children, updatedPath);
      paths = [...paths, ...childPaths, updatedPath];
    } else {
      paths = [...paths, updatedPath];
    }
  });
  return paths;
};

export const allCategoryPaths = getAllPaths();
export const allCategoryRootToLeafPaths = getAllRootToLeafPaths();

export const idToIcon = {
  '3063086d-cac3-476b-badc-2e31d3f18057': bar_chart,
  '604d38c9-efd8-4e6e-98d7-48507718142c': extract_date_time,
  '95448b2f-2e8a-49a4-8342-4f4eb4f86ae2': horizontal_bar_chart,
  '5d401c2e-f2bd-48e8-b04e-b3a3c0d7704f': heatmap,
  '7a209b5f-2c55-49aa-988a-fce7f1a1e4e7': line_chart,
  '9b0a0e4e-1643-472a-a2fb-6461ae13002d': pie_chart,
  '6cef8596-8cc3-4813-9750-cfc0113a2fdb': scatter_plot,
  '88e53567-5cb6-44ca-8294-4ecb6b1ec3c3': delete_columns,
  '2bd99403-7961-4d9e-8c13-cde25028b47e': unify_date_time_format,
  '7bf2b3ed-7d2e-4450-b4e3-f6d91b3d36aa': truncate_columns_with_n_characters,
  '0ac3d49d-72c1-4e16-b025-4810eabfb9eb': add_characters_to_each_element_in_a_column,
  '7ee06c70-3a1c-45dd-bfc2-ab20f1dd91cc': convert_text_case,
  '7e2822d1-2e6b-47d1-bc55-a8fb6733d794': remove_a_value_of_series_of_characters,
  '7742ed67-df83-4c07-9454-9d4850859f98': remove_comma,
  '7b8e0d29-7fdd-48a9-8cd3-4cff287cc41f': remove_dollar_signs,
  'b1c487fc-9dbf-4812-84e1-0aa39fd86007': remove_percent,
  '0bf652bd-ca07-4b3c-b092-a945c0827bf9': remove_quote,
  'eef5c0b5-3895-49d3-a6f2-151afe8159af': find_and_replace,
  'bf9a50f2-b024-41ed-baad-4342ebed23d4': find_missing_rows_in_two_tables,
  '9c2f831f-6ab2-45fe-bfaf-4351f1e5a378': find_words_from_a_list_and_add_them_to_new_column,
  '08424013-adb4-4477-afdc-fbb3e6d20d3e': replace_text_with_another_column,
  '699c2cac-c5c6-4040-87b4-5422a49d4fcd': create_dummy_columns,
  '7cf9a57d-d6d9-4f89-89fe-93b20ba4ab4f': merge_multiple_columns_with_separator,
  '63c40858-4ffa-4d89-82a9-22ca6dd4476c': copy_column,
  'f74485a1-9884-4f87-baa6-370c200b1a23': join_tables_vertically,
  'e2834dbf-4ca0-460b-9d28-399c603638d0': join_tables_horizontally,
  'a76441b3-76ac-4bfd-8310-4b0c9f6ce5f3': difference_datetime_columns,
  'f352d2bf-82f6-434b-829e-fdbc0b2f5a8b': create_dummy_columns_if_missing,
  '568a3942-7686-4436-a445-b8d61a2b4515': remove_rows_with_missing_in_selected_column,
  '8270020d-a6c5-4397-a7f3-e6194a1ca2c5': remove_rows_with_all_values_missing,
  'a6c548f2-31b6-4f1c-b6f2-b069965f3b6a': remove_mostly_empty_columns,
  'be299537-9cf9-43c1-aa94-a25a5ff6f087': fill_missing_values,
  '27457338-30ab-4f8b-bfe9-c745be7e0d49': boolean_is_cell_populated,
  '5150b7c4-498d-4576-88db-dece024d9601': select_subset_of_columns,
  'dc5109de-87a9-46a3-a1da-e79cfa3d42eb': split_with_separator,
  '947079c5-330c-46e7-bcf3-1e9b104d4073': rename_columns,
  'b22cfffb-bee0-4dc1-952b-32807fe03149': add_time_to_date,
  '3aba0321-d487-4bed-aa93-240ce704f184': compare_datetime_column_to_date,
  '0904b435-79bb-4149-8700-19d164b1ed5b': round_numbers,
  'd06773d6-2ff3-49b1-a187-3fd73595ad0c': running_total,
  '60e54237-4307-4a57-9837-8d5632b0f8a6': unpivot_table,
  '3d51ba39-a0ca-45aa-a078-49b7a4b1fa0a': sort_data,
  'e3373d54-fa4e-4dfb-9c4c-16593dde7740': pivot_table,
  '9f756ead-0416-40c2-a84f-7cfb980f0005': logical_and,
  'dfe68780-b985-4a45-85af-e5aed9cbe494': filter_for_true,
  'f46017a5-2a94-48ab-9485-0be8a8cb5abd': enrich_by_zipcode,
  'bafbfd41-6625-44c1-8795-749d4ed83a62': compare_to_number,
  'c8e722e8-5ca2-4c2f-b55b-15f950984d49': break_up_comma_separated_list,
  '693e9f8e-ab8e-42e3-bc48-f5ea87396bcc': aggregate_data,
  '0b578cea-a03c-4595-869a-82404a7ea4a5': convert_column_percentage_dist,
  '5200f5d7-6c64-43f3-b39a-36a93bfe6a4d': dashboard,
  '1ba71c43-9c50-4456-adf0-7c291c40ec67': airtable,
  '359d00e7-a387-462f-90b8-28a940f4ef6f': closeio,
  '898de12f-46b9-43e0-84d4-8c7c40351098': domo,
  '3287b44b-ef23-4a22-9d50-5b3aa8f814b1': dynamodb,
  'f49ed91a-cfe6-49aa-b215-cf193e9aa962': facebook_ads,
  'b986ddf8-c4b9-473f-a68f-b253ca4146d2': google_sheets,
  '09bd314f-b8f3-4ad0-b989-aff0f4172bd7': google_sheets,
  '99180221-65cf-4542-8ac9-21a1cf644f99': mongodb,
  'b5a39180-55bc-40d7-97b3-f025c9e15707': Postgres,
  '43f06e60-7617-4301-b98e-def3bbee7fd7': Postgres,
  '17eab873-9d08-485b-9651-2ce657ad3fe4': quickbase,
  '24e8edaa-25ae-4d6e-b7be-df3a073e1974': redshift,
  '130c3379-e966-4130-83f1-86b6d32548ef': REST_API,
  '2dfb84d4-2bf6-4b7c-b32c-2bf4f240a00d': salesforce,
  '3f4342b7-986f-47fd-aa88-971678bb4585': salesforce,
  '53550faf-7edf-42da-a086-d3f1e5defa42': shopify,
  'f0b73cce-63c8-467e-be69-ca7af009bcdd': snowflake,
  'a547af20-7503-4303-9a14-140ce90039a2': sql_database,
  '7d8939a9-8f99-40a6-978d-45f0b2b8592b': typeform,
  '53b0c203-4ae8-4192-b161-20c5739f9f9b': upload_file,
  '7b67b8dc-6620-430a-9a21-825ee3bf35cd': email,
  '92f06650-4b84-431e-82b6-6ee3300835a6': email,
  'd2ad5118-ab8a-42aa-9d9f-1028e8f721a3': filter_data,
  '7cb9fca5-c37f-4f8e-a81c-4f67ea1a7e1e': sum_columns,
};
