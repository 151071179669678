import React, { useContext } from 'react';
import StepContext from '../../StepContext';
import { SentenceInput } from '../SentenceRenderers/SentenceRendererCore';

export default ({}) => {
  const { updateSentenceInputValues } = useContext(StepContext);

  const onDataChange = (updatedVariable) => {
    updateSentenceInputValues({
      ...updatedVariable,
      _user_id: null,
      _email_address: null,
      _user_first_name: null,
    });
  };

  return <SentenceInput reportInputValue={onDataChange} />;
};
