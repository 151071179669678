import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Responsive, WidthProvider } from 'react-grid-layout';
import styled from 'styled-components';
import { StepOutputBody } from '../../StepOutput/StepOutput';
import StepContext, { useStepInputValue } from '../../StepContext';
import { useSteps } from '../../../../model/DataAppStepsContextHelpers';
import {
  DataSetInputUnattachedToSchema,
  isCommentOutput,
} from '../SentenceRenderers/DataSetInput';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import './dashboard.css';
import { useIsPublishedOrRunVersionOpen } from '../../../../model/DataAppContext';
import { SubSectionTitle } from '../../../../standard-components/typography';
import {
  useIsDataviz,
  useVariableName,
} from '../../../../model/DataAppVariableContextHelpers';
import PublishedStepHeader from '../../../published-components/PublishedStepHeader';
import { StepActionButtons } from '../../StepBody/StepActionButtons';

const DashboardItemContainer = styled.div`
  width: inherit;
  height: inherit;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
`;

const EditViewContainer = styled.div`
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px 6px 0px 0px;
  background-color: ${(p) => p.theme.color.tertiary.dull};
`;

export const DashboardItemTitleFontSize = 18;

const DashboardItemEditAndTitleBar = ({
  variableId,
  onVariableSelect,
  onDelete,
}) => {
  const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();
  const variableName = useVariableName(variableId);

  const isDataviz = useIsDataviz(variableId);
  const isComment = isCommentOutput(variableId);
  const doesNotNeedTitleWhenPublished =
    isPublishedOrRunVersionOpen && isComment;
  if (doesNotNeedTitleWhenPublished) return null;

  if (isPublishedOrRunVersionOpen) {
    return <PublishedStepHeader title={variableName} />;
  }

  return (
    <EditViewContainer>
      <DataSetInputUnattachedToSchema
        onVariableSelect={onVariableSelect}
        currentValue={variableId}
        includeDataviz
        includeComment
      />
      <Button onClick={onDelete} icon={<DeleteOutlined />} />
    </EditViewContainer>
  );
};

const DashboardItemContent = ({ variableId, style }) => {
  const steps = useSteps();
  const stepThatOutputsThisVariable = steps.find(({ outputs }) =>
    outputs.includes(variableId)
  );

  if (!variableId) {
    return null;
  }

  if (!stepThatOutputsThisVariable) {
    return (
      <SubSectionTitle style={{ padding: 48, whiteSpace: 'normal' }}>
        Looks like the step result that used to live here got deleted. Select a
        new one from the dropdown above, or just delete this dashboard item!
      </SubSectionTitle>
    );
  }

  return (
    <StepContext.Provider
      value={{ ...stepThatOutputsThisVariable, isInsideDashboard: true }}
    >
      <div style={style}>
        <StepOutputBody variable={variableId} />
      </div>
    </StepContext.Provider>
  );
};

const DashboardItem = ({ variableId, onVariableSelect, onDelete }) => {
  const isComment = isCommentOutput(variableId);
  const itemHeight = isComment ? '100%' : 'calc(100% - 55px)';

  return (
    <DashboardItemContainer>
      <DashboardItemEditAndTitleBar
        variableId={variableId}
        onVariableSelect={onVariableSelect}
        onDelete={onDelete}
      />
      <DashboardItemContent
        variableId={variableId}
        style={{ height: itemHeight }}
      />
    </DashboardItemContainer>
  );
};

const generateItemId = () => `${new Date().getTime()}`;

// const DIMENSIONS_MIN_AND_MAX = { minW: 4, minH: 5, maxW: 12, maxH: 14 };
const DEFAULT_NEW_ITEM_LAYOUT_PROPS = {
  x: 0,
  y: 0,
  w: 3,
  h: 1,
  // ...DIMENSIONS_MIN_AND_MAX,
};

const generateNewItemObject = () => ({
  i: generateItemId(),
  variableId: null,
  ...DEFAULT_NEW_ITEM_LAYOUT_PROPS,
});
const DEFAULT_LAYOUT = [generateNewItemObject()];

const useDeserializedLayout = () => {
  const serializedLayoutJSONInputObject =
    useStepInputValue('serialized_layout_json') || {};
  const [deserializedLayoutJSON, setDeserializedLayoutJSON] = useState(
    serializedLayoutJSONInputObject.value
      ? JSON.parse(serializedLayoutJSONInputObject.value)
      : DEFAULT_LAYOUT
  );
  const { updateSentenceInputValues, stepIsEditable, stepIsDirty } = useContext(
    StepContext
  );

  const setLayout = (newLayoutFn) => {
    const arrayOfVariableObjects = deserializedLayoutJSON
      .filter(({ variableId }) => !!variableId)
      .map(({ variableId }) => ({
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: {
          this_can_be_any_variable: {
            type: SCHEMA_INPUT_TYPE.VARIABLE,
            value: variableId,
          },
        },
      }));
    // One way sync, local copy is source of truth
    const newLayout = newLayoutFn(deserializedLayoutJSON);
    setDeserializedLayoutJSON(newLayout);

    updateSentenceInputValues({
      variables_used_in_dashboard: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: arrayOfVariableObjects,
      },
      serialized_layout_json: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: JSON.stringify(newLayout),
      },
    });
  };

  return [deserializedLayoutJSON, setLayout];
};

const GridLayoutWrapper = styled.div`
  width: 55vw;
  max-width: 100%;
  background: ${(p) => p.theme.color.grayscale.white};
  border-radius: 4px;
`;

const ResponsiveGridLayout = WidthProvider(Responsive);
const StyledGridLayout = styled(ResponsiveGridLayout)``;

const AddNewDashboardItemButton = styled(Button)`
  margin: 10px 10px 20px 20px;
`;

export default () => {
  const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();
  const [layout, setLayout] = useDeserializedLayout();
  const handleAddNewDashboardItem = () =>
    setLayout((oldLayout) => [...oldLayout, generateNewItemObject()]);

  const handleDelete = (itemIdToDelete) => () =>
    setLayout((oldLayout) => oldLayout.filter(({ i }) => i !== itemIdToDelete));

  const handleLayoutChange = (newLayout) => {
    window.dispatchEvent(new Event('resize')); // triggers Plotly resize :P
    // assumption: never called when items are deleted/added
    setLayout((oldLayout) =>
      oldLayout.map(({ variableId, ...rest }, i) => ({
        ...rest,
        ...newLayout[i],
        variableId,
      }))
    );
  };

  const handleVariableSelect = (itemId) => (selectedVariableId) => {
    setLayout((oldLayout) =>
      oldLayout.map(({ i, variableId, ...rest }) => {
        return i === itemId
          ? { i, ...rest, variableId: selectedVariableId }
          : { i, variableId, ...rest };
      })
    );
  };

  return (
    <GridLayoutWrapper>
      <StyledGridLayout
        className="layout"
        layouts={{ lg: layout }}
        cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
        margin={[15, 15]}
        rowHeight={400}
        verticalCompact
        onLayoutChange={handleLayoutChange}
        resizeHandles={isPublishedOrRunVersionOpen ? [] : ['se']}
        isDraggable={!isPublishedOrRunVersionOpen}
        isResizable={!isPublishedOrRunVersionOpen}
        isBounded
      >
        {layout.map(({ i, variableId }) => (
          <div key={i}>
            <DashboardItem
              variableId={variableId}
              onVariableSelect={handleVariableSelect(i)}
              onDelete={handleDelete(i)}
            />
          </div>
        ))}
      </StyledGridLayout>
      {!isPublishedOrRunVersionOpen && (
        <>
          <AddNewDashboardItemButton
            type="primary"
            onClick={handleAddNewDashboardItem}
          >
            <PlusOutlined />
            Add new item to the dashboard
          </AddNewDashboardItemButton>
          <StepActionButtons noPadding />
        </>
      )}
    </GridLayoutWrapper>
  );
};
