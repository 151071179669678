import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Login, { getContinueToURLParam } from './pages/LoginPage';
import PasswordResetForm from './pages/PasswordResetForm';
import SignUp from './pages/SignUp';
import HopperHome from './pages/HopperHome';
import BlockGallery from './pages/BlockGallery';

import './App.less';
import './App.css';
import 'draft-js/dist/Draft.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import UnauthedPaymentUpdate from './pages/UnauthedPaymentUpdate';
import DemoCallPage from './pages/DemoCallPage';
import { withIsSignedInHOC } from './model/User';
import SchemaSandbox from './studio-page-components/Step/StepRenderers/SchemaSandbox';
import LoadingSplash from './standard-components/LoadingSplash';
import Theme from './theming/Theme';
import PaperCupChatWidget from './settings-components/PaperCupChatWidget';
import ILAnalytics from './settings-components/ILAnalytics';
import AuthTasks from './auth-action-components/AuthTasks';
import WelcomeTour from './pages/WelcomeTour';
import PricingPage from './pages/PricingPage';

const checkForAnyRequiredContinueTo = (location) => {
  const pathExists = location.pathname && location.pathname !== '/';
  return pathExists && encodeURIComponent(location.pathname);
};

const App = ({ isLoadingSignedInState, isSignedIn }) => {
  if (isLoadingSignedInState) {
    return <LoadingSplash />;
  }

  const UnauthedRoute = ({ children, ...rest }) => {
    const urlContinueToParam = getContinueToURLParam(rest.location);

    const render = () => {
      if (!isSignedIn) {
        return children;
      } else {
        if (rest.path.startsWith('/join-us')) {
          return (
            <Redirect
              to={
                !urlContinueToParam
                  ? `/welcome`
                  : `/welcome?continueTo=${encodeURIComponent(
                      urlContinueToParam
                    )}`
              }
            />
          );
        } else {
          return <Redirect to={urlContinueToParam || '/'} />;
        }
      }
    };

    return <Route {...rest} render={render} />;
  };

  const AuthedRoute = (props) => {
    const { children, ...rest } = props;

    const continueToPostSignIn =
      !isSignedIn && checkForAnyRequiredContinueTo(props.location);
    const pathname = continueToPostSignIn
      ? `/login?continueTo=${continueToPostSignIn}`
      : '/login';

    return (
      <Route
        {...rest}
        render={() => (isSignedIn ? children : <Redirect to={pathname} />)}
      />
    );
  };

  const themeName = process.env.REACT_APP_THEME_NAME || null;

  return (
    <Router>
      <Theme themeName={themeName}>
        <div className="App">
          {window.location.pathname === '/_auth' ? (
            <AuthTasks />
          ) : (
            <Switch>
              <UnauthedRoute exact path="/credit-card-form/:encryptedUserId">
                <UnauthedPaymentUpdate />
              </UnauthedRoute>
              <UnauthedRoute path="/login">
                <Login />
              </UnauthedRoute>
              <UnauthedRoute exact path="/join-us/:signupLink">
                <SignUp />
              </UnauthedRoute>
              <UnauthedRoute path="/join-us/">
                <SignUp />
              </UnauthedRoute>
              <UnauthedRoute path="/reset-password">
                <PasswordResetForm />
              </UnauthedRoute>
              <Route path="/gallery/blocks/:blockId?">
                <BlockGallery />
              </Route>
              <Route path="/pricing">
                <PricingPage />
              </Route>

              <Route path="/demo-call/:encryptedString">
                <DemoCallPage />
              </Route>

              <Route path="/public/apps">
                <HopperHome />
              </Route>

              <AuthedRoute path="/schema-sandbox">
                <SchemaSandbox />
              </AuthedRoute>

              <Route path="/welcome">
                <WelcomeTour />
              </Route>

              <AuthedRoute path="/" exact>
                <Redirect
                  to={{
                    pathname: '/apps',
                  }}
                />
              </AuthedRoute>

              <AuthedRoute path="/">
                <HopperHome />
              </AuthedRoute>
            </Switch>
          )}
          <ILAnalytics />
          <PaperCupChatWidget />
        </div>
      </Theme>
    </Router>
  );
};

export default withIsSignedInHOC(App);
