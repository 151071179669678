import React, { useEffect, useState } from 'react';
import { Modal, Spin, Radio } from 'antd';
import styled from 'styled-components';
import CheckoutForm from './CheckoutForm';
import {
  getPricingRate,
  getPriceId,
  productsContainPricingForPeriod,
  PRICING_FLOWS,
  CHECKOUT_VISIBILITY,
  SUBSCRIPTIONS_PERIODS,
} from './SubscriptionUtils';
import PricingView from './PricingView';
import {
  useBillingInfo,
  useBillingInfoIsLoading,
  useUpdateSubscription,
} from '../model/BillingContext';

const SelectPlanPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanColumnRow = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 40px;
`;

const Title = styled.div`
  font-size: 18px;
  color: #000;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
`;

const Subtitle = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
`;

export default ({
  showModal,
  onClose,
  pricingFlow = PRICING_FLOWS.changePlans,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [isCheckoutFormVisible, setIsCheckoutFormVisible] = useState(
    pricingFlow.showCheckoutForm === CHECKOUT_VISIBILITY.show
  );
  const updateSubscription = useUpdateSubscription();
  const {
    subscription_details = {},
    available_plans,
    credit_card_details,
  } = useBillingInfo();
  const billingInfoIsLoading = useBillingInfoIsLoading();

  useEffect(() => {
    setIsCheckoutFormVisible(
      pricingFlow.showCheckoutForm === CHECKOUT_VISIBILITY.show
    );
  }, [pricingFlow]);

  const updatePlanSelection = async (product) => {
    const price_id = getPriceId(product, product.subscriptionPeriod);

    const success = await updateSubscription(price_id);

    if (success) {
      cleanUpAndClose();
    } else {
      Modal.error({
        title:
          'Sorry, something went wrong when trying to update your subscription. Please try again or contact support at hello@intersectlabs.io',
      });
    }
  };

  const noCCFiled =
    !credit_card_details || credit_card_details.last_four === null;

  // Should do start subscription or update with CC as a parameter in instead of calling update credit card
  const shouldGetCCWhileSelectingPlan = (product = {}) => {
    return product.no_trial && noCCFiled;
  };

  const onProductSelect = (product) => {
    setSelectedProduct(product);
    const { subscription_status } = subscription_details || {};

    let continueToCheckout = false;
    if (shouldGetCCWhileSelectingPlan(product)) {
      continueToCheckout = true;
    } else if (pricingFlow.showCheckoutForm === CHECKOUT_VISIBILITY.show) {
      continueToCheckout = true;
    } else if (
      pricingFlow.showCheckoutForm ===
      CHECKOUT_VISIBILITY.showIfNotTrialAndNoPaymentMethod
    ) {
      if (subscription_status !== 'trialing' && noCCFiled === true) {
        continueToCheckout = true;
      }
    }

    setIsCheckoutFormVisible(continueToCheckout);
    if (!continueToCheckout) {
      updatePlanSelection(product);
    }
  };

  const onSuccessfulPaymentMethodUpdate = (paymentMethod) => {
    if (selectedProduct) {
      updatePlanSelection(selectedProduct);
    } else {
      cleanUpAndClose();
    }

    return Promise.resolve();
  };

  const cleanUpAndClose = () => {
    if (!onClose) return;

    setSelectedProduct(undefined);
    onClose();
  };

  let checkoutFormCallbackProps = {
    onSuccessfulPaymentMethodUpdate: onSuccessfulPaymentMethodUpdate,
  };

  return (
    <Modal
      visible={showModal}
      header={null}
      footer={null}
      width="80vw"
      onCancel={cleanUpAndClose}
      closable={onClose !== null}
    >
      <Spin spinning={billingInfoIsLoading} tip="Updating subscription...">
        {isCheckoutFormVisible ? (
          <CheckoutForm
            showPlans={() => setIsCheckoutFormVisible(false)}
            product={selectedProduct}
            {...checkoutFormCallbackProps}
          />
        ) : (
          <PricingView
            title={pricingFlow.title}
            subtitle={pricingFlow.subtitle}
            available_plans={available_plans}
            onProductSelect={onProductSelect}
            currentSubscriptionDetails={subscription_details}
            actionButtonText={pricingFlow.actionButtonText}
          />
        )}
      </Spin>
    </Modal>
  );
};
