import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Input, Form, Switch, Spin, Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { CardContainer } from '../standard-components/containers';
import { handleSubmitFormData } from './IntegrationFormFooter';
import { PASSWORD_PRIVATE_KEY_INPUT_TYPE } from './integrationTypes';
import PasswordOrPrivateKeyConfigPanel from './PasswordOrPrivateKeyConfigPanel';

const Container = styled(CardContainer)`
  min-width: 600px;
  margin: auto;
  width: 40vw;
  padding: 50px;
`;

const LoadingContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SSHCredentialsContainer = styled(CardContainer)`
  margin: 15px 0px 30px;
  padding: 25px;
`;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

const moreInfoSSHLink =
  'https://intersectlabs.notion.site/Intersect-SSH-tunnel-instructions-6e9411ba3576427a9adca6fa106b85b0';

const setDefaultValuesForForm = (usingSSH) => {
  try {
    if (usingSSH) {
      document.getElementById('host').value = 'localhost';
      document.getElementById('port').value = 5432;
    }
  } catch (error) {}
};

const TextFormInput = ({
  id,
  placeholder,
  inputTitle,
  isProtected,
  type,
  optional = false,
}) => {
  if (type === PASSWORD_PRIVATE_KEY_INPUT_TYPE) {
    return <PasswordOrPrivateKeyConfigPanel namePath={id} />;
  }

  let InputType = Input;
  if (isProtected) InputType = Input.Password;
  return (
    <Form.Item
      label={inputTitle}
      name={id}
      required={!optional}
      rules={[{ required: !optional }]}
    >
      <InputType placeholder={placeholder} />
    </Form.Item>
  );
};

export default ({
  name,
  integrationId,
  integrationType,
  credentials,
  onIntegrationAddComplete,
  redirectToIntegrations,
}) => {
  const updatingExisting = !!integrationId;
  const [isLoading, setIsLoading] = useState(updatingExisting);
  const [usingSSH, setUsingSSH] = useState(false);
  const [databaseForm] = Form.useForm();
  const team_id = useTeamId();
  const history = useHistory();

  useEffect(() => {
    const fetchExistingIntegration = async () => {
      setIsLoading(true);
      const [{ name, credentials = {} }, error] = await makeAPICall({
        endpoint: `/integration/?integration_id=${integrationId}`,
      });

      if (!error && name) {
        if (credentials.ssh) {
          setUsingSSH(true);
        }
        databaseForm.setFieldsValue({ name, ...credentials });
      }

      setIsLoading(false);
    };

    if (integrationId) {
      fetchExistingIntegration();
    }
  }, [integrationId, databaseForm]);

  useEffect(() => {
    setDefaultValuesForForm(usingSSH);
  }, [usingSSH]);

  useEffect(() => {
    setDefaultValuesForForm(usingSSH);
  }, [isLoading]);

  const onChangeSSHCheckBox = () => {
    setUsingSSH((_usingSSH) => !_usingSSH);
  };

  const onFinish = (values) => {
    handleSubmitFormData({
      values,
      integrationId,
      team_id,
      updatingExisting,
      integrationType,
      integrationName: name,
      redirectToIntegrations,
      onIntegrationAddComplete,
      history,
    });
  };

  if (isLoading)
    return (
      <LoadingContainer>
        <Spin tip="Loading integration details..." />
      </LoadingContainer>
    );

  return (
    <Container>
      <Form
        layout="vertical"
        form={databaseForm}
        onFinish={onFinish}
        preserve={false}
        requiredMark="optional"
        validateMessages={validateMessages}
      >
        <TextFormInput
          inputTitle="Integration Name"
          id="name"
          placeholder="eg. Sales Database"
        />
        {credentials.map((cred) => (
          <TextFormInput {...cred} />
        ))}

        <Form.Item
          label="Use SSH Tunnel"
          tooltip={{
            title: 'Click to Learn More',
            icon: <QuestionCircleTwoTone />,
            onClick: () => {
              window.open(moreInfoSSHLink, '_blank');
            },
          }}
        >
          <Switch checked={usingSSH} onChange={onChangeSSHCheckBox} />
          {usingSSH && (
            <SSHCredentialsContainer>
              <TextFormInput
                id={['ssh', 'host']}
                inputTitle="Bastion/SSH Host"
                placeholder="10.x.x.x"
              />
              <TextFormInput
                id={['ssh', 'port']}
                inputTitle="Port"
                placeholder="5432"
              />
              <TextFormInput
                id={['ssh', 'username']}
                inputTitle="Username"
                placeholder="eg. username"
              />
              <PasswordOrPrivateKeyConfigPanel namePath={['ssh']} />
            </SSHCredentialsContainer>
          )}
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            {updatingExisting ? 'Update' : 'Add'}
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};
