import { Tag, Tree, TreeSelect } from 'antd';
import { get, isPlainObject } from 'lodash';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';
import { useEffect } from 'react';
import { makeAPICall } from '../../../../api/useAPI';
import {
  useAppId,
  useInstanceId,
} from '../../../../model/DataAppMetadataContextHelpers';
import useSchemaOptions from './helpers/useSchemaOptions';

const StyledTreeSelect = styled(TreeSelect)`
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .ant-tag {
    margin-left: 10px;
  }
`;

const TreeSectionTitle = styled.span`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  color: ${(p) => p.theme.color.grayscale.faintGray1};
`;

const CustomValueOrOptionSelectInput = ({
  id,
  reportInputValue,
  value: currentValue,
  disabled,
  placeholder,
  options,
  providedOptionsKey,
  providedOptionsLabel,
  providedOptionsCategoryLabel,
  typedOptionKey,
  typedOptionLabel,
  reportValueAsObjectWithTypeKey = true,
  size,
  config = {},
}) => {
  const [formattedCurrentValue, setFormattedCurrentValue] = useState();
  const [currentValueAsOption, setCurrentValueAsOption] = useState();
  const [currentlyEnteredValue, setCurrentlyEnteredValue] = useState();
  const configDatasetName = get(config, 'dataset.name', 'Dataset');
  const configDatasetVariable = get(config, 'dataset.variable');
  const configColumnName = get(config, 'column');
  const [datasetColumnValueOptions, setDatasetColumnValueOptions] = useState();
  const appId = useAppId();
  const instanceId = useInstanceId();
  const [evaluatedProvidedOptions = [], isEvaluatingOptions] = useSchemaOptions(
    options
  );

  useEffect(() => {
    const fetchOptions = async () => {
      const [options, error] = await makeAPICall({
        endpoint: `/app/variable/unique-values?app_id=${appId}&instance_id=${instanceId}&variable_name=${configDatasetVariable}&column_name=${configColumnName}`,
      });

      if (options && !error) {
        setDatasetColumnValueOptions(options);
      }
    };

    if (configDatasetVariable && configColumnName) {
      fetchOptions();
    }
  }, [configDatasetVariable, configColumnName, appId, instanceId]);

  useEffect(() => {
    if (currentValue) {
      let typeKey = Object.keys(currentValue)[0];
      let selection = currentValue[typeKey];
      if (!isPlainObject(currentValue)) {
        selection = currentValue;
        const isInProvidedOptions =
          (evaluatedProvidedOptions || []).indexOf(currentValue) !== -1;
        typeKey = isInProvidedOptions ? providedOptionsKey : typedOptionKey;
      }
      setFormattedCurrentValue(`${typeKey}:${selection}`);

      // Columns are options by default. Existing primitive_value's must be added.
      if (typeKey === typedOptionKey) {
        setCurrentValueAsOption(selection);
      } else {
        setCurrentValueAsOption(undefined);
      }
    }
  }, [currentValue, evaluatedProvidedOptions]);

  const getValueToSelect = (typeKey, value) => `${typeKey}:${value}`;

  const onColumnSelect = (selectedValue) => {
    const typeKey = selectedValue.split(':')[0];
    const selection = selectedValue.substring(typeKey.length + 1);
    let valueToReport = selection;
    if (reportValueAsObjectWithTypeKey) {
      valueToReport = { [typeKey]: selection };
    }

    reportInputValue(id, {
      type: 'static',
      value: valueToReport,
    });
  };

  const getTreeOptionData = (typeKey, value, tagLabel, tagColor) => {
    const valueToSelect = getValueToSelect(typeKey, value);
    const option = (
      <OptionRow>
        <span>{value}</span>
        <Tag color={tagColor}>{tagLabel}</Tag>
      </OptionRow>
    );

    return {
      title: option,
      value: valueToSelect,
    };
  };

  const providedOptionsData = (evaluatedProvidedOptions || []).map((col) =>
    getTreeOptionData(providedOptionsKey, col, providedOptionsLabel, 'orange')
  );

  const nonColumnOptions = [];
  const isFromColumnValueOptionsSet = (option) => {
    return (
      datasetColumnValueOptions && datasetColumnValueOptions.includes(option)
    );
  };
  if (currentValueAsOption) {
    if (!isFromColumnValueOptionsSet(currentValueAsOption)) {
      nonColumnOptions.push(currentValueAsOption);
    }
  }
  if (currentlyEnteredValue && currentlyEnteredValue !== currentValueAsOption) {
    if (!isFromColumnValueOptionsSet(currentlyEnteredValue)) {
      nonColumnOptions.push(currentlyEnteredValue);
    }
  }
  const nonColumnOptionsData = nonColumnOptions.map((nonColumnOption) =>
    getTreeOptionData(
      typedOptionKey,
      nonColumnOption,
      typedOptionLabel,
      'purple'
    )
  );

  let treeData = [];

  const treeExpandedKeys = [];
  const columnChoiceKey = 'columns_choice';
  const customValuesChoiceKey = 'custom_values_choice';
  const datasetColumnValuesChoiceKey = 'dataset_column_values_choice';

  if (providedOptionsData.length > 0) {
    treeData.push({
      title: (
        <TreeSectionTitle>
          {providedOptionsCategoryLabel || `${configDatasetName} Columns`}
        </TreeSectionTitle>
      ),
      value: columnChoiceKey,
      selectable: false,
      children: providedOptionsData,
    });
  }
  if (nonColumnOptionsData.length > 0) {
    treeExpandedKeys.push(customValuesChoiceKey);
    treeData.push({
      title: <TreeSectionTitle>Custom Values</TreeSectionTitle>,
      value: customValuesChoiceKey,
      selectable: false,
      children: nonColumnOptionsData,
    });
  }

  if (datasetColumnValueOptions) {
    treeExpandedKeys.push(datasetColumnValuesChoiceKey);
    treeData.push({
      title: <TreeSectionTitle>{configColumnName} Options</TreeSectionTitle>,
      value: datasetColumnValuesChoiceKey,
      selectable: false,
      children: datasetColumnValueOptions.map((nonColumnOption) =>
        getTreeOptionData(
          typedOptionKey,
          nonColumnOption,
          typedOptionLabel,
          'purple'
        )
      ),
    });
  }

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const valueToSelect = getValueToSelect(
        typedOptionKey,
        currentlyEnteredValue
      );
      onColumnSelect(valueToSelect);
    }
  };

  if (treeExpandedKeys.length === 0) {
    treeExpandedKeys.push(columnChoiceKey);
  }
  return (
    <StyledTreeSelect
      style={{ minWidth: 200 }}
      showSearch
      onSearch={setCurrentlyEnteredValue}
      value={formattedCurrentValue}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      // treeDefaultExpandAll
      treeDefaultExpandedKeys={treeExpandedKeys}
      onChange={onColumnSelect}
      onInputKeyDown={onInputKeyDown}
      placeholder={placeholder}
      size={size}
      disabled={disabled}
      loading={isEvaluatingOptions}
    />
  );
};

export default CustomValueOrOptionSelectInput;
