import React, { useEffect, useState } from 'react';
import { isEqual, isPlainObject } from 'lodash';
import { useAllStepInputValues } from '../studio-page-components/Step/StepContext';

export const SCHEMA_INPUT_TYPE = {
  VARIABLE: 'variable',
  STATIC: 'static',
};

export const SCHEMA_INPUT_VALUE_TYPE = {
  STRING: 'string',
  INT: 'int',
  FLOAT: 'float',
  ARRAY: 'array',
  OBJECT: 'object',
  COLUMN: 'column',
  BOOL: 'bool',
  DATASET: 'dataset',
};

const SchemaRendererContext = React.createContext({});
export default SchemaRendererContext;

export const useValuesForIds = (ids = []) => {
  const sentenceInputValue = useAllStepInputValues();
  const [currentValues, setCurrentValues] = useState({});

  const values = {};
  ids.forEach((id) => {
    const currentValueObject = findValueForId(id, sentenceInputValue) || {};
    values[id] = currentValueObject.value;
  });

  useEffect(() => {
    if (!isEqual(currentValues, values)) {
      setCurrentValues(values);
    }
  }, [values]);

  return currentValues;
};

export const findValueForId = (id, inputValueTree = {}) => {
  const keys = Object.keys(inputValueTree);
  if (keys.includes(id)) {
    return inputValueTree[id];
  }

  const values = Object.values(inputValueTree);
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (isPlainObject(value)) {
      const targetValue = findValueForId(id, value);
      if (targetValue !== undefined) return targetValue;
    }
  }

  return undefined;
};

export const traverseSchema = (schema, callback) => {
  callback(schema);
  if (schema.children) {
    schema.children.map((child) => {
      traverseSchema(child, callback);
    });
  }
};

export const getFirstDataSetInputValue = (schema, inputValueTree) => {
  let datasetInputValues = [];
  traverseSchema(schema, (node) => {
    if (node.type === 'dataset') {
      const currentValue = findValueForId(node.id, inputValueTree);
      if (currentValue !== undefined) {
        datasetInputValues.push(currentValue);
      }
    }
  });
  if (datasetInputValues.length > 0) return datasetInputValues[0];
};
