/*
  Code effectively identical to example (minus using styled-components):
  https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
 */

import React from 'react';
import Draft from 'draft-js';
import styled from 'styled-components';
import {
  BoldOutlined,
  CodeOutlined, 
  ItalicOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

const { Editor, RichUtils } = Draft;

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'Quote', style: 'blockquote' },
  { label: <UnorderedListOutlined />, style: 'unordered-list-item' },
  { label: <OrderedListOutlined />, style: 'ordered-list-item' },
  {
    label: (
      <code>
        <CodeOutlined />
      </code>
    ),
    style: 'code-block',
  },
];
const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type, index) => (
        <StyleButton
          key={type.style + index}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
var INLINE_STYLES = [
  { label: <BoldOutlined />, style: 'BOLD' },
  { label: <ItalicOutlined />, style: 'ITALIC' },
  { label: <UnderlineOutlined />, style: 'UNDERLINE' },
];
const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type, index) => (
        <StyleButton
          key={type.style + index}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const RichTextEditorContainer = styled.div`
  font-size: 14px;
  margin-right: 16px;
  padding: 15px 15px 15px 30px;
  padding: 15px 15px 15px 30px;

  & .RichEditor-controls {
    font-size: 16px;
    user-select: none;
    display: inline-block;
  }

  & .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  & .RichEditor-activeButton {
    color: ${(p) => p.theme.color.primary.bright};
  }
`;

const EditorWrapper = styled.div`
  ${(p) =>
    p.hasTopDecoration &&
    `border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px;`};
  cursor: text;
  font-size: 16px;

  & .public-DraftEditorPlaceholder-root,
  & .public-DraftEditor-content {
    margin: 0 -15px -15px 0px;
    padding: 0px 15px 15px 0px;
  }

  & .public-DraftEditor-content {
    min-height: 40px;
  }

  & .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }

  & .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  & .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const EditorControlsWrapper = styled(ControlsWrapper)``;

export class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editor = React.createRef();
    this.focus = () => this.editor.current && this.editor.current.focus();
  }

  handleKeyCommand = (command) => {
    const { editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.props.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  onTab = (e) => {
    const maxDepth = 4;
    this.props.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth));
  };

  toggleBlockType = (blockType) => {
    this.props.onChange(
      RichUtils.toggleBlockType(this.props.editorState, blockType)
    );
  };

  toggleInlineStyle = (inlineStyle) => {
    this.props.onChange(
      RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
    );
  };

  render() {
    const { editorState, onChange } = this.props;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    const contentState = editorState.getCurrentContent();
    const hidePlaceholder =
      !contentState.hasText() &&
      contentState.getBlockMap().first().getType() !== 'unstyled';

    return (
      <RichTextEditorContainer>
        <EditorWrapper
          hasTopDecoration={!this.props.readOnly}
          hidePlaceholder={hidePlaceholder}
          onClick={this.focus}
        >
          <Editor
            blockStyleFn={getBlockStyle}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            customStyleMap={styleMap}
            editorState={editorState}
            // handleKeyCommand={this.handleKeyCommand}
            onChange={onChange}
            onTab={this.onTab}
            placeholder={
              this.props.readOnly
                ? 'Not editable...'
                : 'Write some text here. Use the controls above to style it. Common shortcuts also work!'
            }
            ref={this.editor}
            spellCheck={true}
            readOnly={this.props.readOnly}
          />
        </EditorWrapper>
        {!this.props.readOnly && (
          <ControlsWrapper>
            <EditorControlsWrapper>
              <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
              />
              <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
              />
            </EditorControlsWrapper>
            {this.props.actionButtons}
          </ControlsWrapper>
        )}
      </RichTextEditorContainer>
    );
  }
}
