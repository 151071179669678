import React from 'react';

import styled from 'styled-components';
import { NAV_BAR_HEIGHT } from '../standard-components/containers';
import { StudioPageContextProvider } from '../model/StudioPageContext';
import { DataAppContextProvider } from '../model/DataAppContext';
import PublishedDashboardPanel from '../studio-page-components/PublishedDashboardPanel';
import { useParams } from 'react-router-dom';
import DraftDashboardPanel from '../studio-page-components/StudioEditor';
import { BlocksContextProvider } from '../model/BlocksContext';
import RunInstancePage from '../studio-page-components/published-components/RunInstancePage';
import AppNavBar from '../studio-page-components/published-components/AppNavBar';
import { useLocation } from 'react-router-dom';

const StudioPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AppPageContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  height: calc(100vh - ${NAV_BAR_HEIGHT});
`;

const MainContentContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.color.tertiary.dull};
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  let { appId, instanceId } = useParams();
  let query = useQuery();
  const isEmbedded = query.get('embed');

  return (
    <DataAppContextProvider appId={appId} instanceId={instanceId}>
      <BlocksContextProvider>
        <StudioPageContextProvider>
          <StudioPage>
            <AppPageContentContainer>
              <MainContentContainer>
                <AppNavBar isEmbedded={isEmbedded} />
                <PublishedDashboardPanel currentInstanceId={instanceId} />
                <RunInstancePage
                  currentInstanceId={instanceId}
                  isEmbedded={isEmbedded}
                />
                <DraftDashboardPanel />
              </MainContentContainer>
            </AppPageContentContainer>
          </StudioPage>
        </StudioPageContextProvider>
      </BlocksContextProvider>
    </DataAppContextProvider>
  );
};
