import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateNewApp } from '../app-list-page-components/CreateNewAppButtonAndModal';
import {
  GalleryPageTitle,
  SectionTitle,
  SubSectionTitle,
} from '../standard-components/typography';
import {
  CardContainer,
  GalleryContentHeader,
  GalleryPageContainer,
} from '../standard-components/containers';
import styled from 'styled-components';
import { CACHE_POLICY, useAPI } from '../api/useAPI';
import { CardCreationInfo } from '../standard-components/CardCreationInfo';
import { useTeamId } from '../model/Team';
import LoadingSplash from '../standard-components/LoadingSplash';
import { Input } from 'antd';
import useRefreshMessage from '../standard-components/useRefreshMessage';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Button, Modal } from 'antd';
import { useRef } from 'react';
import AppCardMenu from '../app-list-page-components/AppCardMenu';
import CardActionButton from '../standard-components/CardActionButton';

const { confirm } = Modal;

const PREVIEW_CARD_WIDTH = '300px';
const PREVIEW_CARD_HEIGHT = '200px';
const AppCardContainer = styled(CardContainer)`
  margin-right: 3%;
  margin-bottom: 40px;
  padding: 20px 25px;
  min-width: 275px;
  max-width: ${PREVIEW_CARD_WIDTH};
  min-height: ${PREVIEW_CARD_HEIGHT};
  max-height: ${PREVIEW_CARD_HEIGHT};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;
`;

const AppCardAccessories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const AppsGalleryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GallerySearchBar = styled(Input)`
  width: 200px;
  margin-right: 15px;
  border-radius: 20px;

  .anticon {
    color: ${(p) => p.theme.color.grayscale.faintGray1};
    margin-right: 5px;
  }
`;

const ZeroStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: 24px;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  background-color: ${(p) => p.theme.color.grayscale.white};
  padding: 20px 30px;
  border: 1px solid ${(p) => p.theme.color.grayscale.faintGray4};
  border-radius: 6px;

  > span {
    margin-bottom: 24px;
  }
`;

const AppsGallery = ({ searchFilter }) => {
  const teamId = useTeamId();
  const [appDetailsList, { isLoading, forceLocalResultUpdate }] = useAPI({
    endpoint: `/app/list?team_id=${teamId}`,
    cachePolicy: CACHE_POLICY.CACHE_THEN_NETWORK,
  });

  const onAppDeleteComplete = (appId) => {
    if (!appDetailsList) return;

    const appDetailsListUpdated = appDetailsList.filter(
      (app) => app.id !== appId
    );
    forceLocalResultUpdate(appDetailsListUpdated);
  };

  // Displays refresh message automatically if cache is being refreshed
  useRefreshMessage(appDetailsList && isLoading);

  if (!appDetailsList) {
    return <LoadingSplash tip="Loading Apps..." style={{ height: '30%' }} />;
  }

  if (appDetailsList.length === 0) {
    return (
      <ZeroStateContainer>
        <span>
          Looks like you don't have access to any data apps yet! Create one:
        </span>
        <CreateNewApp />
      </ZeroStateContainer>
    );
  }

  const getFilteredList = () => {
    if (searchFilter.length === 0) return appDetailsList;
    return appDetailsList.filter((app) =>
      app.name.toLowerCase().includes(searchFilter.toLowerCase())
    );
  };

  return (
    <AppsGalleryContainer>
      {getFilteredList().map((app) => (
        <AppCard
          key={app.id}
          {...app}
          teamId={teamId}
          onAppDeleteComplete={onAppDeleteComplete}
        />
      ))}
    </AppsGalleryContainer>
  );
};

const AppCard = ({
  name,
  id,
  created_by,
  last_updated_at,
  onAppDeleteComplete,
  teamId,
}) => {
  const history = useHistory();
  const appCardRef = useRef(null);

  return (
    <AppCardContainer
      ref={appCardRef}
      onClick={() => {
        history.push(`/apps/${id}`);
      }}
    >
      <SectionTitle primary dark>
        {name}
      </SectionTitle>
      <AppCardAccessories>
        <CardCreationInfo
          createdBy={created_by}
          lastUpdatedTime={last_updated_at}
          isMissingZ
        />
        <Dropdown
          overlay={
            <AppCardMenu
              appId={id}
              teamId={teamId}
              onAppDeleteComplete={onAppDeleteComplete}
            />
          }
          trigger={['click']}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CardActionButton />
        </Dropdown>
      </AppCardAccessories>
    </AppCardContainer>
  );
};

export default () => {
  const [appSearchFilter, setAppSearchFilter] = useState('');

  useEffect(() => {
    window.analytics.page('App List Page');
  }, []);

  return (
    <GalleryPageContainer>
      <GalleryContentHeader>
        <GalleryPageTitle>Data Apps</GalleryPageTitle>
        <ControlsContainer>
          <GallerySearchBar
            value={appSearchFilter}
            onChange={({ target }) => setAppSearchFilter(target.value)}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
          <CreateNewApp />
        </ControlsContainer>
      </GalleryContentHeader>
      <AppsGallery searchFilter={appSearchFilter} />
    </GalleryPageContainer>
  );
};
