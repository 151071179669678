import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';
import {
  getPriceId,
  getPricingRate,
  productsContainPricingForPeriod,
  SUBSCRIPTIONS_PERIODS,
} from './SubscriptionUtils';
import PlanColumn, { CUSTOM_ENTERPRISE_PLAN } from './PlanColumn';

const SelectPlanPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanColumnRow = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 40px;

  @media (max-width: 950px) {
    flex-wrap: wrap;
    margin: 20px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: #000;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
`;

const Subtitle = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
`;

export default ({
  title,
  subtitle,
  available_plans,
  onProductSelect,
  currentSubscriptionDetails,
  actionButtonText,
}) => {
  const [subscriptionPeriod, setSubscriptionPeriod] = useState('month');
  const [sortedPlanIds, setSortedPlanIds] = useState([]);

  useEffect(() => {
    if (!available_plans) return;
    const sortedPlanIds = Object.keys(available_plans);
    sortedPlanIds.sort(
      (a, b) =>
        getPricingRate(available_plans[a], subscriptionPeriod) -
        getPricingRate(available_plans[b], subscriptionPeriod)
    );
    setSortedPlanIds(sortedPlanIds);
  }, [available_plans, subscriptionPeriod]);

  const _onProductSelect = (product) => {
    const priceId = getPriceId(product, subscriptionPeriod);
    onProductSelect({ ...product, subscriptionPeriod }, priceId);
  };

  const indexOfMiddleElement = Math.floor(sortedPlanIds.length / 2);
  const plansRendered = sortedPlanIds.map((planId, index) => (
    <PlanColumn
      key={planId}
      product={{ price_id: planId, ...available_plans[planId] }}
      isEmphasized={indexOfMiddleElement === index}
      onSelect={_onProductSelect}
      currentSubscription={currentSubscriptionDetails}
      subscriptionPeriod={subscriptionPeriod}
      actionButtonText={actionButtonText}
    />
  ));

  plansRendered.push(
    <PlanColumn
      key={CUSTOM_ENTERPRISE_PLAN}
      product={{
        price_id: CUSTOM_ENTERPRISE_PLAN,
        custom_price: 'Custom',
        name: 'Enterprise',
      }}
      isEmphasized={false}
      onSelect={_onProductSelect}
      currentSubscription={currentSubscriptionDetails}
      subscriptionPeriod={subscriptionPeriod}
      actionButtonText="Contact Us"
    />
  );

  const showMonthlyPricing = productsContainPricingForPeriod(
    available_plans,
    SUBSCRIPTIONS_PERIODS.month
  );
  const showYearlyPricing = productsContainPricingForPeriod(
    available_plans,
    SUBSCRIPTIONS_PERIODS.year
  );

  return (
    <SelectPlanPanel>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Radio.Group
        onChange={(e) => setSubscriptionPeriod(e.target.value)}
        defaultValue={subscriptionPeriod}
        style={{ margin: 'auto' }}
      >
        {showMonthlyPricing && (
          <Radio.Button value={SUBSCRIPTIONS_PERIODS.month}>
            Monthly
          </Radio.Button>
        )}
        {showYearlyPricing && (
          <Radio.Button value={SUBSCRIPTIONS_PERIODS.year}>Annual</Radio.Button>
        )}
      </Radio.Group>
      <PlanColumnRow>{plansRendered}</PlanColumnRow>
    </SelectPlanPanel>
  );
};
