import styled from 'styled-components';
import {
  DAYS_OF_WEEK,
  useDayOfWeek,
  useSetDayOfWeek,
} from '../../../model/RunSchedulingContext';
import React from 'react';
import { BottomPaddedSubSection } from './RunScheduling';

const DayCircle = styled.div`
  background-color: ${({ isSelected, theme: { color } }) =>
    isSelected ? color.tertiary.bright : color.tertiary.dull};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(p) => p.theme.color.grayscale.dark};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  font-size: 12px;
  line-height: 11px;
  transition: 0.3s background-color;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover {
    background-color: ${(p) =>
      !p.isSelected && p.theme.color.tertiary.dullBorder};
  }
`;
export const DayOfWeekSelector = () => {
  const selectedDays = useDayOfWeek();
  const setSelectedDays = useSetDayOfWeek();
  const handleDayClicked = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((oldDay) => oldDay !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  return (
    <BottomPaddedSubSection size="middle" style={{ display: 'flex' }}>
      {DAYS_OF_WEEK.map((day) => (
        <DayCircle
          key={day}
          isSelected={selectedDays.includes(day)}
          onClick={() => handleDayClicked(day)}
        >
          {day[0].toUpperCase()}
        </DayCircle>
      ))}
    </BottomPaddedSubSection>
  );
};
