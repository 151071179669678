import React from 'react';
import { BottomPaddedSubSection } from './RunScheduling';
import { useStartDateState } from '../../../model/RunSchedulingContext';
import DatePicker from '../../../standard-components/DatePicker';

export default () => {
  const [startDate, setStartDate] = useStartDateState();
  return (
    <div>
      <BottomPaddedSubSection>
        and start on
        <DatePicker
          value={startDate}
          defaultPickerValue={startDate}
          onChange={setStartDate}
          use12Hours
          format="YYYY-MM-DD HH:mm"
          showTime
        />
      </BottomPaddedSubSection>
    </div>
  );
};
