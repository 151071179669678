import { Button } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { API_PREFIX, makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { CardContainer } from '../standard-components/containers';
import LoadingSplash from '../standard-components/LoadingSplash';

const Container = styled(CardContainer)`
  min-width: 530px;
  margin: auto;
  width: 50vw;
  height: 200px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;

const SectionTitleStyled = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  margin-bottom: 20px;
`;

export default ({
  integrationId,
  onIntegrationAddComplete,
  redirectToIntegrations,
}) => {
  const teamId = useTeamId();
  const [integrationData, setIntegrationData] = useState();
  const [loading, setLoading] = useState(false);
  const [isAuthFlowInProgress, setIsAuthFlowInProgress] = useState(false);
  const history = useHistory();

  const onAuthorizeClick = async () => {
    const [endpoint, error] = await makeAPICall({
      endpoint: `/oauth2/url?integration_name=GOOGLESHEETS&team_id=${teamId}`,
    });

    if (endpoint && !error) {
      window.open(endpoint, '_blank');
      setIsAuthFlowInProgress(true);
    }
  };

  useEffect(() => {
    const fetchIntegrationData = async () => {
      const [result, error] = await makeAPICall({
        endpoint: `/integration/?integration_id=${integrationId}`,
      });

      setIntegrationData(result);
      setLoading(false);
    };

    if (integrationId) {
      setLoading(true);
      fetchIntegrationData();
    }
  }, [integrationId]);

  if (loading) {
    return <LoadingSplash />;
  }

  if (integrationData) {
    return (
      <Container>
        <SectionTitleStyled>
          {integrationData.name} is connected.
        </SectionTitleStyled>
      </Container>
    );
  }

  if (isAuthFlowInProgress) {
    const onContinueClick = () => {
      setIsAuthFlowInProgress(false);
      onIntegrationAddComplete && onIntegrationAddComplete(true);
      if (redirectToIntegrations) {
        history.push('/integrations');
      }
    };
    return (
      <Container>
        <SectionTitleStyled>
          Please click continue once you're done authorizing Intersect.
        </SectionTitleStyled>
        <Button onClick={onContinueClick} size="large" type="primary">
          Continue
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      <SectionTitleStyled>
        Let Intersect Labs get data from and write to the Google Sheets you add
        to your apps:
      </SectionTitleStyled>
      <Button onClick={onAuthorizeClick} size="large" type="primary">
        Authorize Intersect Labs
      </Button>
    </Container>
  );
};
