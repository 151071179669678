export const getColorFromProps = ({ theme, ...props }, defaultColor = '') => {
  const propNames = Object.keys(props);
  const colorCategoryPropName = propNames.find(
    (propName) => !!theme.color[propName]
  );
  const colorPropName =
    colorCategoryPropName &&
    propNames.find(
      (propName) => !!theme.color[colorCategoryPropName][propName]
    );
  if (props[colorCategoryPropName] && props[colorPropName]) {
    return theme.color[colorCategoryPropName][colorPropName];
  }
  return defaultColor || theme.color.grayscale.dark;
};

export const getFontWeightFromProps = (
  { theme, ...props },
  defaultFontWeight = 300
) => {
  const propNames = Object.keys(props);
  const fontWeightPropName = propNames.find(
    (propName) => !!theme.typography.fontWeight[propName]
  );
  if (fontWeightPropName) {
    return theme.typography.fontWeight[fontWeightPropName];
  }
  return defaultFontWeight || 300;
};

export const ELLIPSES_ON_OVERFLOW = `
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;
