import { PlayCircleFilled } from '@ant-design/icons';
import { Button, Row, Col, Spin, Result, Pagination } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { makeAPICall } from '../api/useAPI';
import { useIsPublishedVersionOpen, useRunApp } from '../model/DataAppContext';
import {
  APP_STATUS,
  getViewingAppAsPublic,
  useAppId,
  useAppStatus,
  useMetadata,
} from '../model/DataAppMetadataContextHelpers';
import { WhitePrimaryShadowButton } from '../standard-components/CustomColorButton';
import AppHeader from './published-components/AppHeader';
import AppRunEntry from './published-components/AppRunEntry';
import RunScheduling from './published-components/RunScheduling/RunScheduling';

const PublishedDashboard = styled(Row)`
  background-color: ${(p) => p.theme.color.primary.lighter};
  width: 100vw;
  padding-top: 90px;
  flex-grow: 1;
  height: 100%;

  > * {
    max-height: 100%;
  }
`;

export const PublishedComponentPanel = styled.div`
  background-color: ${(p) => p.theme.color.grayscale.white};
  border-radius: 6px;
  color: ${(p) => p.theme.color.primary.blackout};
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  box-shadow: 0px 3px 7px -3px rgba(0, 0, 0, 0.3),
    0px 3px 12px -2px rgba(50, 50, 93, 0.15);
  max-height: 100%;

  > .AppRunListLoadingContainer {
    overflow: auto;
  }

  > .ant-pagination {
    padding: 20px;
    align-self: center;
  }
`;

const ActionComponentPanel = styled(PublishedComponentPanel)`
  background-color: ${(p) => p.theme.color.primary.dark};
`;

const NotebookRunsPanel = styled(PublishedComponentPanel)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const AppRunEntriesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
`;

export const PublishedComponentPanelTitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  padding: 15px 20px;
  text-align: center;
`;

const RunAppButton = styled(WhitePrimaryShadowButton)`
  margin: 0px 20px 20px;
  height: 45px;
`;

const NoRunsYetMessage = styled.div`
  padding: 0px 30px 30px;
  color: ${(p) => p.theme.color.grayscale.faintGray1};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  text-align: center;
`;

const NUM_RUNS_ON_EACH_PAGE = 20;

export default ({ currentInstanceId }) => {
  const { last_published_at } = useMetadata();
  const history = useHistory();
  const isPublishedVersionOpen = useIsPublishedVersionOpen();
  const currentAppStatus = useAppStatus();
  const runApp = useRunApp();
  const appId = useAppId();
  const [isLoading, setIsLoading] = useState(true);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [totalRuns, setTotalRuns] = useState(0);
  const [runs, setRuns] = useState();
  const viewingAppAsPublic = getViewingAppAsPublic();
  let prefix = '';
  if (viewingAppAsPublic) {
    prefix = '/public';
  }

  const fetchRuns = async () => {
    setIsLoading(true);
    const [appRunsFetchResult, error] = await makeAPICall({
      endpoint: `/app/runs/v2?app_id=${appId}&start_index=${paginationStartIndex}`,
    });
    if (!error) {
      const result = appRunsFetchResult || {};
      setRuns(result.runs);
      setTotalRuns(result.total_runs);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (appId && isPublishedVersionOpen) {
      fetchRuns();
    }
  }, [appId, isPublishedVersionOpen, paginationStartIndex]);

  if (!last_published_at || !isPublishedVersionOpen) return <></>;

  let runEntries = (
    <NoRunsYetMessage>
      You haven't run your app yet. Click on the Run App button to add a new
      run.
    </NoRunsYetMessage>
  );
  if (runs && runs.length > 0) {
    const navigateToRunInstance = (navToInstanceId) => {
      history.push(`${prefix}/apps/${appId}/${navToInstanceId}`);
    };
    runEntries = runs.map((run, index) => (
      <AppRunEntry
        key={'run' + index}
        navigateToRunInstance={navigateToRunInstance}
        {...run}
      />
    ));
  }

  const onPageUpdate = (page) => {
    setPaginationStartIndex((Math.max(page, 1) - 1) * NUM_RUNS_ON_EACH_PAGE);
  };

  const onRunButtonClick = async () => {
    const newRun = await runApp(totalRuns);

    if (newRun) {
      runs.unshift(newRun);
    }
  };

  return (
    <PublishedDashboard gutter={[32, 16]} justify="center">
      <Col xs={12} sm={11} md={10} lg={10} xl={8} xxl={6}>
        <ActionComponentPanel>
          <AppHeader isDraftDashboard={false} />
          {!viewingAppAsPublic && (
            <>
              <RunScheduling />
              <RunAppButton
                size="large"
                icon={<PlayCircleFilled />}
                onClick={onRunButtonClick}
                id="run-app-button"
              >
                Run App
              </RunAppButton>
            </>
          )}
        </ActionComponentPanel>
      </Col>

      <Col xs={12} sm={11} md={10} lg={10} xl={8} xxl={6}>
        <NotebookRunsPanel>
          <PublishedComponentPanelTitle>App Runs</PublishedComponentPanelTitle>
          <Spin
            spinning={isLoading}
            wrapperClassName="AppRunListLoadingContainer"
          >
            <AppRunEntriesList>{runEntries}</AppRunEntriesList>
          </Spin>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={NUM_RUNS_ON_EACH_PAGE}
            total={totalRuns}
            onChange={onPageUpdate}
          />
        </NotebookRunsPanel>
      </Col>
    </PublishedDashboard>
  );
};
