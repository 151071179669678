import React, { useState } from 'react';
import { makeAPICall } from '../api/useAPI';
import { Menu, Modal, Input, message } from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { useEffect } from 'react';

const RenameModalStyled = styled(Modal)``;
const RenameInput = styled(Input)`
  padding-right: 10px;
`;
const RenameModalWrap = styled.div``;

export default (props) => {
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const { integrationName } = props;
  const { integrationId } = props;
  const { setNewIntegrationNameForIntegrationCard } = props;
  const { onUpdateComplete } = props;
  const inputRef = React.useRef(null);
  const [newIntegrationName, setNewIntegrationName] = useState(integrationName);

  useEffect(() => {
    if (inputRef.current && isRenameModalVisible) {
      inputRef.current.focus({
        cursor: 'end',
      });
    }
  }, [isRenameModalVisible]);

  const DeleteIntegration = async () => {
    const hideLoadingMessage = message.loading('Deleting integration...', 0);

    const [deletedAppConfirmation, error] = await makeAPICall({
      method: 'POST',
      endpoint: `/integration/delete?integration_id=${integrationId}`,
    });

    hideLoadingMessage();
    if (!error) {
      onUpdateComplete();
      message.success(`Successfully Deleted ${integrationName} Integration`);
    } else {
      message.error(
        'Sorry there was an issue deleting your integration. Please try again'
      );
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this integration?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        DeleteIntegration();
      },
    });
  };

  const handleRenameModalCancel = () => {
    setIsRenameModalVisible(false);
  };

  const renameIntegration = async () => {
    const hideLoadingMessage = message.loading('Renaming integration...', 0);

    const body = {
      name: newIntegrationName,
    };
    const [renameIntegration, error] = await makeAPICall({
      method: 'POST',
      endpoint: `/integration/edit?integration_id=${integrationId}`,
      body: body,
    });

    hideLoadingMessage();
    if (!error) {
      setNewIntegrationNameForIntegrationCard(newIntegrationName);
      message.success(
        `Successfully renamed ${integrationName} to ${newIntegrationName}`
      );
    } else {
      message.error(
        'Sorry there was an issue renaming your integration. Please try again'
      );
    }
    setIsRenameModalVisible(false);
  };

  const showRenameModal = () => {
    setIsRenameModalVisible(true);
  };

  const MenuFunctions = {
    DeleteIntegration: showDeleteConfirm,
    RenameIntegration: showRenameModal,
  };

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
    const functionName = e.key;
    MenuFunctions[functionName]();
  };

  return (
    <>
      <Menu {...props} onClick={handleMenuClick}>
        <Menu.Item key="RenameIntegration" icon={<EditOutlined />}>
          Rename Integration
        </Menu.Item>
        <Menu.Item danger key="DeleteIntegration" icon={<DeleteOutlined />}>
          Delete Integration
        </Menu.Item>
      </Menu>
      <RenameModalWrap
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <RenameModalStyled
          title="Rename Integration"
          visible={isRenameModalVisible}
          onCancel={handleRenameModalCancel}
          header={null}
          onOk={renameIntegration}
          destroyOnClose
        >
          <RenameInput
            size="large"
            defaultValue={integrationName}
            prefix={<EditOutlined />}
            allowClear
            enterButton
            ref={inputRef}
            onChange={(e) => {
              setNewIntegrationName(e.target.value);
            }}
          />
        </RenameModalStyled>
      </RenameModalWrap>
    </>
  );
};
