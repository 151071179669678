import React, { useState } from 'react';
import { CollapseExpandHeader } from '../CollapseExpandHeader';
import {
  useUpdateVariableName,
  useVariableValue,
} from '../../../model/DataAppVariableContextHelpers';
import { StepBodyContainer } from '../../../standard-components/containers';
import styled, { useTheme } from 'styled-components';
import DataTable from '../../../standard-components/DataTable';
import DataVizRenderer from '../StepRenderers/DataVizRenderers/DataVizRenderer';
import { useIsInsideDashboard } from '../StepContext';
import LoadingSplash from '../../../standard-components/LoadingSplash';
import { StepOutputContextProvider } from '../../../model/StepOutputContext';
import { isCommentOutput } from '../StepRenderers/SentenceRenderers/DataSetInput';
import { CommentStepInner } from '../StepRenderers/CommentRenderers/CommentStep';
import { useIsPublishedOrRunVersionOpen } from '../../../model/DataAppContext';
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { TagLabel } from '../../../standard-components/typography';
import { Tooltip } from 'antd';
import { get } from 'lodash';

const StepOutputBodyContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const PlainOutput = styled.div`
  padding: 10px 20px;
  background: ${(p) => p.theme.color.grayscale.white};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  border-radius: 4px;
`;

const EmptyOutputView = styled.div`
  height: 100%;
  width: 100%;
  min-height: 200px;
  background: ${(p) => p.theme.color.primary.lighter};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${(p) => p.theme.color.grayscale.faintGray1};
`;

const EmptyViewIconStyled = styled(ClockCircleOutlined)`
  font-size: 36px;
  margin-bottom: 14px;
`;

const NotUsedLabel = styled(TagLabel)`
  background-color: #fff4ee;
  color: #cc5e18;
`;

const getDataset = (variableInfo) => {
  const { types = [], value, columns = null } = variableInfo || {};

  if (types.includes('DATA')) {
    // new format
    return value;
  } else if (columns) {
    // old format
    return variableInfo;
  }
};

export const isDataviz = (variableInfo = {}) => {
  let typesToCheck = [];
  if (variableInfo.types) {
    typesToCheck = variableInfo.types;
  } else if (get(variableInfo, 'outputs_types')) {
    typesToCheck = get(variableInfo, 'outputs_types');
  }

  return typesToCheck.includes('DATAVIZ');
};

const OutputView = ({ variable, variableInfo }) => {
  if (!variableInfo)
    return (
      <EmptyOutputView>
        <EmptyViewIconStyled />
        <div>Waiting for previous steps to finish running.</div>
      </EmptyOutputView>
    );

  const dataset = getDataset(variableInfo);
  if (dataset) {
    return <DataTable data={dataset} variable={variable} />;
  } else if (isDataviz(variableInfo)) {
    return <DataVizRenderer />;
  } else if (isCommentOutput(variable)) {
    return <CommentStepInner rawContentState={variableInfo.value} readOnly />;
  }

  return <PlainOutput>{JSON.stringify(variableInfo, null, 2)}</PlainOutput>;
};

export const StepOutputBody = ({ variable }) => {
  const [variableInfo, { isLoading }] = useVariableValue(variable);
  const isInsideDashboard = useIsInsideDashboard();

  if (isLoading) return <LoadingSplash style={{ height: 250 }} />;

  return (
    <StepOutputContextProvider variable={variable}>
      <StepOutputBodyContainer isInsideDashboard={isInsideDashboard}>
        <OutputView variable={variable} variableInfo={variableInfo} />
      </StepOutputBodyContainer>
    </StepOutputContextProvider>
  );
};

export const StepOutput = ({
  name,
  variable,
  isOfLeafStep,
  canEditName,
  initiallyExpanded = false,
  noExtraMargin = false,
}) => {
  const { typography, color } = useTheme();
  const [outputIsExpanded, setOutputIsExpanded] = useState(initiallyExpanded);
  const updateVariableName = useUpdateVariableName();
  const isPublishedOrRunVersionOpen = useIsPublishedOrRunVersionOpen();

  const onSaveNewVariableName = (newName) => {
    updateVariableName(variable, newName);
  };

  const titleStyle = {
    fontWeight: typography.fontWeight.light,
    color: color.grayscale.dark,
  };

  const customBodyContainerStyle = {
    marginLeft: !noExtraMargin && 24,
    borderRadius: outputIsExpanded ? '20px 20px 6px 6px' : '20px',
  };
  return (
    <StepBodyContainer
      style={customBodyContainerStyle}
      expanded={outputIsExpanded}
      shouldShowPublishedVersion={isPublishedOrRunVersionOpen}
    >
      <CollapseExpandHeader
        title={name}
        onlyShowShadowOnHover
        rounded
        leftLabel={
          <>
            <TagLabel>Result</TagLabel>
            {isOfLeafStep && (
              <Tooltip title="No steps are using this result">
                <NotUsedLabel className="unused-result-label">
                  Unused
                </NotUsedLabel>
              </Tooltip>
            )}
          </>
        }
        titleStyle={titleStyle}
        onClick={() => setOutputIsExpanded(!outputIsExpanded)}
        isExpanded={outputIsExpanded}
        saveTitleChange={canEditName ? onSaveNewVariableName : undefined}
        showIcon={false}
      />
      {outputIsExpanded && <StepOutputBody variable={variable} />}
    </StepBodyContainer>
  );
};
