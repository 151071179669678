export const CHECKOUT_VISIBILITY = {
  show: 'alwaysShow',
  doNotShow: 'neverShow',
  showIfNotTrialAndNoPaymentMethod: 'showIfNotTrialAndNoPaymentMethod',
};

export const SUBSCRIPTIONS_PERIODS = {
  month: 'month',
  year: 'year',
};

export const freeTrialMessaging = {
  title: "Let's Get Started",
  subtitle:
    "Which plan do you want to try out in your free trial? You don't need to put down a credit card to get started.",
  actionButtonText: 'Start Free Trial',
};
const standardMessaging = {
  title: 'Choose a Plan',
  subtitle: 'All plans come with our world class support.',
};

export const PRICING_FLOWS = {
  startFreeTrial: {
    showPlansFirst: true,
    showCheckoutForm: CHECKOUT_VISIBILITY.doNotShow,
    actionButtonText: freeTrialMessaging.actionButtonText,
    ...freeTrialMessaging,
  },
  updateCreditCard: {
    showPlansFirst: false,
    showCheckoutForm: CHECKOUT_VISIBILITY.show,
    actionButtonText: 'Subscribe Now',
    ...standardMessaging,
  },
  changePlans: {
    showPlansFirst: true,
    showCheckoutForm: CHECKOUT_VISIBILITY.showIfNotTrialAndNoPaymentMethod,
    actionButtonText: 'Subscribe Now',
    ...standardMessaging,
  },
};

export const getPricing = (product = {}, period = 'month') => {
  if (period === 'month') {
    return product.monthly_price;
  } else {
    return product.annual_price;
  }
};

export const getPricingRate = (product = { pricing: [] }, period = 'month') => {
  const { amount } = getPricing(product, period) || {};
  if (!amount) return -1;
  return amount;
};

export const getPriceId = (product = { pricing: [] }, period = 'month') => {
  const relevantPricing = getPricing(product, period);
  return relevantPricing.id;
};

export const productsContainPricingForPeriod = (
  products = {},
  period = 'month'
) => {
  let contains = false;
  Object.values(products).forEach((product) => {
    if (getPricingRate(product, period) !== -1) {
      contains = true;
    }
  });
  return contains;
};
