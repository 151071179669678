import styled from 'styled-components';
import React from 'react';
import { Button } from 'antd';
import { useAddingBlockCategoryPath } from '../../model/StudioPageContext';
import AddBlockDrawer from './AddBlockMenu';
import Modal from 'antd/lib/modal/Modal';

export const MiniAddStepButton = styled(Button)`
  transition: opacity 50ms;
  color: ${({ theme: { color } }) => color.grayscale.faintGray1};
  border-color: ${({ theme: { color } }) => color.grayscale.faintGray4};
  font-size: 12px;
  cursor: default !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-right: ${(p) => p.alwaysvisible && '10px'};

  :hover {
    cursor: pointer !important;
  }

  &:hover > span.anticon-plus {
    padding-top: 2px;
  }
`;

const AddStepMenu = ({ afterStepId, onSelectComplete }) => {
  const { addingBlockCategoryPath } = useAddingBlockCategoryPath();

  return (
    <AddBlockDrawer
      addingBlockAfterStepId={afterStepId}
      addingBlockCategoryPath={addingBlockCategoryPath}
      onSelectComplete={onSelectComplete}
    />
  );
};

const modalMaskStyle = {
  background: 'transparent',
};

const modalBodyStyle = {
  maxHeight: '85vh',
  overflow: 'auto',
  padding: 0,
};

export const AddStepMenuModal = ({
  afterStepId = null,
  isLoading = false,
  onSelectComplete = () => {},
  visible,
}) => {
  if (isLoading) return <></>;

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      onCancel={onSelectComplete}
      header={null}
      footer={null}
      width="80vw"
      maskStyle={modalMaskStyle}
      bodyStyle={modalBodyStyle}
    >
      <AddStepMenu
        afterStepId={afterStepId}
        onSelectComplete={onSelectComplete}
      />
    </Modal>
  );
};
