import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';

export const ColumnInputContext = createContext({});

export const useClearPreviousSchemaInputOnColumnTypeChange = (
  callbackFn,
  id
) => {
  const { colIsNumerical, colIsDatetime, colIsString } = useContext(
    ColumnInputContext
  );
  const [isMounted, setIsMounted] = useState(false);
  const ref = useRef(id); // refs still have previous value in useEffect update

  useEffect(() => {
    // Other than on mounting, clear old values when column type changes
    if (colIsNumerical || colIsDatetime || colIsString) {
      if (isMounted) {
        callbackFn(ref.current, undefined);
        ref.current = id;
      } else {
        setIsMounted(true);
      }
    }
  }, [colIsNumerical, colIsDatetime, colIsString]);
};
