import { message } from 'antd';
import { useEffect } from 'react';

const loadingMessageKey = 'loading-key-refresh-message';

export default (
  shouldShowLoading = false,
  refreshMessage = 'Refreshing...'
) => {
  useEffect(() => {
    return () => message.destroy(loadingMessageKey);
  }, []);

  useEffect(() => {
    if (shouldShowLoading) {
      message.loading({
        content: refreshMessage,
        key: loadingMessageKey,
        duration: 0,
        maxCount: 1,
        className: 'refresh-message',
      });
    }

    if (!shouldShowLoading) {
      message.destroy(loadingMessageKey);
    }
  }, [shouldShowLoading, refreshMessage]);

  return [];
};
