/* eslint-disable import/no-anonymous-default-export */
import {
  CloseCircleOutlined,
  CopyOutlined,
  GlobalOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Modal, Input, message, Result, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  useAppId,
  useInstanceId,
  useMetadata,
} from '../../model/DataAppMetadataContextHelpers';
import { WhitePrimaryShadowButton } from '../../standard-components/CustomColorButton';
import { makeAPICall } from '../../api/useAPI';
import LoadingSplash from '../../standard-components/LoadingSplash';

const ShareAppRunModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  border-radius: 12px;
  min-width: 600px;

  .ant-modal-content {
    background-color: ${(p) => p.theme.color.primary.darker};
    border-radius: 12px;
    color: ${(p) => p.theme.color.grayscale.white};
  }

  .ant-input-group-addon {
    border: none;
    background-color: ${(p) => p.theme.color.primary.light};
  }
`;

const ShareAppRunContainerStyled = styled.div`
  min-width: 600px;
`;

const ShareAppRunTitle = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(p) => p.theme.typography.fontWeight.normal};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
`;

const ShareAppRunStatusContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
`;

const ShareAppRunDescriptionTitle = styled.div`
  display: flex;
  justify-content: center;
`;

const ShareAppRunDescriptionSubTitle = styled.div`
  text-align: center;
  flex-flow: wrap;
  margin: 0px auto 10px;
  max-width: 80%;
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
`;

const ShareAppRunButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  padding-left: 10px;

  > button {
    margin-left: 20px;
  }
`;

const CopyLinkButton = styled(WhitePrimaryShadowButton)`
  color: black;
`;

const StopSharingAppRunButton = styled(WhitePrimaryShadowButton)`
  color: ${(p) => p.theme.color.info.error};
`;

const ShareAppRunLinkContainer = styled.div`
  .ant-input-disabled {
    background-color: ${(p) => p.theme.color.grayscale.white};
    color: ${(p) => p.theme.color.primary.dark};
  }
`;

const ShareAppRunPrefixContainer = styled.div`
  color: ${(p) => p.theme.color.primary.dark};
`;
const GlobalOutlinedStyled = styled(GlobalOutlined)`
  margin-left: 5px;
  margin-right: 5px;
`;

const ShareAppRunPrefix = (
  <>
    <ShareAppRunPrefixContainer>
      {' Public'}
      <GlobalOutlinedStyled />
    </ShareAppRunPrefixContainer>
  </>
);

const ErrorResult = styled(Result)`
  > div {
    color: ${(p) => p.theme.color.grayscale.white};
  }
`;

export default ({ isShareAppRunModalVisible, closeShareAppRunModal }) => {
  const { instance_name } = useMetadata();
  const appId = useAppId();
  const instanceId = useInstanceId();
  const [isAppPubliclyShared, setIsAppPubliclyShared] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchCurrentShareStatus = async () => {
      setIsLoading(true);
      const [result, error] = await makeAPICall({
        endpoint: `/app/public?instance_id=${instanceId}&app_id=${appId}`,
      });

      if (result) {
        setIsAppPubliclyShared(result);
      } else {
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchCurrentShareStatus();
  }, []);

  const makeAppPublic = async () => {
    setIsLoading(true);
    const [result, error] = await makeAPICall({
      endpoint: `/app/public/give-access?app_id=${appId}&instance_id=${instanceId}`,
      method: 'POST',
    });

    if (!error) {
      message.success(`App ${instance_name} is now public`);
      setIsAppPubliclyShared(result);
    } else {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const makeAppPrivate = async () => {
    setIsLoading(true);
    const [result, error] = await makeAPICall({
      endpoint: `/app/public/revoke-access?app_id=${appId}&instance_id=${instanceId}`,
      method: 'POST',
    });

    if (!error) {
      message.success(`App ${instance_name} is now private`);
      setIsAppPubliclyShared({ access: 'NO_ACCESS' });
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const copySharingAppLink = () => {
    let dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = isAppPubliclyShared.url;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    message.success('Link Copied');
  };

  const StartSharingAppRunModal = () => (
    <ShareAppRunContainerStyled>
      <ShareAppRunTitle> Share App {instance_name}</ShareAppRunTitle>
      <ShareAppRunStatusContainer>
        <ShareAppRunDescriptionTitle>
          This app isn't publicly shared.
        </ShareAppRunDescriptionTitle>
        <ShareAppRunDescriptionSubTitle>
          Only people who have been given access in app mission control can
          interact with the app run.
        </ShareAppRunDescriptionSubTitle>
      </ShareAppRunStatusContainer>
      <ShareAppRunButtonContainer>
        <WhitePrimaryShadowButton
          icon={<LinkOutlined />}
          onClick={() => {
            makeAppPublic();
          }}
        >
          Create Public Share Link
        </WhitePrimaryShadowButton>
      </ShareAppRunButtonContainer>
    </ShareAppRunContainerStyled>
  );

  const LiveSharingAppRunModal = () => (
    <ShareAppRunContainerStyled>
      <ShareAppRunTitle> Share App {instance_name}</ShareAppRunTitle>
      <ShareAppRunStatusContainer>
        <ShareAppRunDescriptionSubTitle>
          Anyone on the internet with this link can interact with the app run.
        </ShareAppRunDescriptionSubTitle>
      </ShareAppRunStatusContainer>
      <ShareAppRunLinkContainer>
        <Input
          size={'large'}
          addonBefore={ShareAppRunPrefix}
          defaultValue={isAppPubliclyShared.url}
          disabled={true}
        />
      </ShareAppRunLinkContainer>
      <ShareAppRunButtonContainer>
        <CopyLinkButton
          icon={<CopyOutlined />}
          onClick={() => {
            copySharingAppLink();
          }}
        >
          Copy Link
        </CopyLinkButton>
        <StopSharingAppRunButton
          icon={<CloseCircleOutlined />}
          onClick={() => {
            makeAppPrivate();
          }}
        >
          Stop Sharing
        </StopSharingAppRunButton>
      </ShareAppRunButtonContainer>
    </ShareAppRunContainerStyled>
  );

  const setModalForSharingApp = () => {
    if (isLoading) {
      return (
        <ShareAppRunContainerStyled>
          <LoadingSplash style={{ maxHeight: '180px' }} />
        </ShareAppRunContainerStyled>
      );
    }

    if (isError) {
      return (
        <ShareAppRunContainerStyled>
          <ErrorResult
            status="500"
            title="Sorry, something went wrong."
            subTitle="Please try again or contact hello@intersectlabs.io"
          />
        </ShareAppRunContainerStyled>
      );
    }

    if (
      isAppPubliclyShared.access === 'VIEWER' ||
      isAppPubliclyShared.access === 'USER'
    ) {
      return LiveSharingAppRunModal();
    }

    if (isAppPubliclyShared.access === 'NO_ACCESS') {
      return StartSharingAppRunModal();
    }
  };

  return (
    <>
      <ShareAppRunModalStyled
        visible={isShareAppRunModalVisible}
        onCancel={closeShareAppRunModal}
        header={null}
        footer={null}
        className="run-share-modal"
      >
        {setModalForSharingApp()}
      </ShareAppRunModalStyled>
    </>
  );
};
