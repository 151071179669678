import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useAddingBlockCategoryPath } from '../model/StudioPageContext';
import { useSteps } from '../model/DataAppStepsContextHelpers';
import BlockCard from './block-addition-components/BlockCard';
import { importDataCategoryDetails } from '../model/blockCategories';
import BLOCK_TYPES from '../model/blockTypes';
import { AddStepMenuModal } from './block-addition-components/AddStepMenuModal';
import { useState } from 'react';

const AppTitleStep = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-bottom: 20px;
  margin-top: 40px;
  align-items: space-between;
`;

const Title = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.large};
  margin-bottom: 5px;
`;

const Subtitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  margin-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftColumn = styled(Column)`
  width: 55px;
  align-items: flex-end;
`;

const RightColumn = styled(Column)`
  align-items: flex-start;
  margin-left: 15px;
`;

const GetStarted = () => {
  const { updateAddingBlockCategoryPath } = useAddingBlockCategoryPath();
  const steps = useSteps();
  const inputSteps = steps.filter((step) => BLOCK_TYPES.INPUT.id === step.type);
  const [modalVisible, setModalVisible] = useState(false);

  if (inputSteps.length === 0) {
    return (
      <>
        <BlockCard
          icon={<img alt="Import Data" src={importDataCategoryDetails.icon} />}
          title="Add An Import Data Block To Get Started"
          subtitle="Once you import data into your data app, you can work with the data, visualize it and take actions with it."
          onSelect={() => {
            updateAddingBlockCategoryPath(importDataCategoryDetails.id, null);
            setModalVisible(true);
          }}
          style={{ maxWidth: 400, margin: '30px 0px' }}
          bordered
        />
        <AddStepMenuModal
          visible={modalVisible}
          onSelectComplete={() => setModalVisible(false)}
        />
      </>
    );
  }
};

export default ({ showIntroMessage }) => {
  const { logos } = useTheme();

  if (!showIntroMessage) {
    return <></>;
  }

  return (
    <AppTitleStep>
      <Row>
        <LeftColumn>
          <img src={logos.tinySquare} alt="Intersect Labs Logo" />
        </LeftColumn>
        <RightColumn>
          <Title>Welcome to your new data app!</Title>
          <Subtitle>
            Data Apps can import, transform, analyze, visualize and take actions
            with data using simple building blocks.
          </Subtitle>
          <GetStarted />
        </RightColumn>
      </Row>
    </AppTitleStep>
  );
};
