import React from 'react';
import { withAuthHOC } from '../model/User';

export const identifyUser = (userInfo, companyName) => {
  if (!userInfo || !userInfo.id) return;

  const properties = {
    name: userInfo.name,
    email: userInfo.email,
    joined_at: userInfo.created_time,
  };

  if (companyName) properties.company_name = companyName;
  window.analytics.identify(userInfo.id, properties);
};

export default withAuthHOC(({ userInfo }) => {
  if (userInfo) {
    identifyUser(userInfo);
  }

  return <></>;
});
