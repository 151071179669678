import React from 'react';
import styled from 'styled-components';
import { fromUnixTime, format } from 'date-fns';
import FreeTrialBanner from './FreeTrialBanner';
import { Button } from 'antd';
import { systemColors } from '../colors/systemColors';
import {
  useBillingInfo,
  useSubscriptionIsCanceled,
  useSubscriptionWillBeCanceled,
} from '../model/BillingContext';
import { get } from 'lodash';

const Title = styled.div`
  color: #000;
  font-weight: 600;
`;

const SubscriptionCostSummary = styled.div`
  font-weight: 300;
  font-size: 18px;
  color: #000;
`;

const SubscriptionSummary = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 20px;
  margin-right: 50px;
  background: #ffffff;

  > ${Title}, > ${SubscriptionCostSummary} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ChangePlanButton = styled(Button)`
  margin-left: 20px;
  margin-top: 10px;
`;

export default ({ hideFreeTrialBanner = false, onChangePlanClick }) => {
  const {
    subscription_details: currentSubscription,
    upcoming_invoice,
  } = useBillingInfo();
  const subscriptionIsCanceled = useSubscriptionIsCanceled();
  const subscriptionWillBeCanceled = useSubscriptionWillBeCanceled();

  if (!currentSubscription || subscriptionIsCanceled) {
    return (
      <SubscriptionSummary>
        <Title>You're not subscribed to Intersect Labs</Title>
        {onChangePlanClick && (
          <ChangePlanButton onClick={onChangePlanClick}>
            See Subscription Options
          </ChangePlanButton>
        )}
      </SubscriptionSummary>
    );
  }

  // Showing current subscription
  const endDate = new Date(currentSubscription.current_period_end);
  const endDateFormatted = format(endDate, 'PPP');
  const upcomingInvoiceAmount = get(upcoming_invoice, 'amount');

  if (subscriptionWillBeCanceled || upcomingInvoiceAmount === null) {
    return (
      <SubscriptionSummary>
        <Title>Your subscription is set to end on {endDateFormatted}.</Title>
        {onChangePlanClick && (
          <ChangePlanButton onClick={onChangePlanClick}>
            See Subscription Options
          </ChangePlanButton>
        )}
      </SubscriptionSummary>
    );
  }

  const title = `${currentSubscription.product_public_name}`;
  const costText = `$${upcomingInvoiceAmount / 100} on ${format(
    new Date(upcoming_invoice.timestamp),
    'PPP'
  )}`;

  return (
    <SubscriptionSummary>
      <Title>{title}</Title>
      <SubscriptionCostSummary>{costText}</SubscriptionCostSummary>
      {onChangePlanClick && (
        <ChangePlanButton onClick={onChangePlanClick}>
          Change Plan
        </ChangePlanButton>
      )}
    </SubscriptionSummary>
  );
};
