import styled from 'styled-components';
import Sidebar from './Sidebar';
import React from 'react';

export const SENTENCE_INPUT_MARGIN = '6px 8px 6px 0px';

export const BOX_SHADOW_CSS_PROP =
  '0px 0px 8px 0px rgba(23, 24, 24, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.15);';
export const BOX_SHADOW_CSS_PROP_HOVER =
  '0px 0px 10px 0px rgba(23, 24, 24, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.20);';
export const CARD_CONTAINER_PADDING = '14px 14px 12px 0';
export const BORDER_RADIUS = '6px';

export const NAV_BAR_HEIGHT = '60px';

export const CardContainer = styled.div`
  box-shadow: ${(p) => (p.expanded ? 'none' : BOX_SHADOW_CSS_PROP)};
  transition: all 0.3s;
  background-color: ${(p) =>
    p.expanded ? p.theme.color.grayscale.extraLightGray : '#ffffff'};
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) =>
    p.expanded ? p.theme.color.grayscale.faintGray4 : '#ffffff'};
  border-radius: ${BORDER_RADIUS};

  &:hover {
    box-shadow: ${(p) => (p.expanded ? 'none' : BOX_SHADOW_CSS_PROP_HOVER)};
  }
`;

export const StepBodyContainer = styled.div`
  transition: all 0.3s;
  background-color: ${(p) => p.theme.color.grayscale.extraLightGray};
  border-width: 2px;
  border-style: solid;
  border-color: ${(p) => {
    if (p.expanded && p.shouldShowPublishedVersion) {
      return p.theme.color.grayscale.white;
    }

    return p.theme.color.grayscale.faintGray4;
  }};
  border-radius: ${BORDER_RADIUS};
  font-size: ${(p) =>
    p.shouldShowPublishedVersion && p.theme.typography.fontSize.mediumSmall};
`;

export const RightDrawerContainer = styled.div`
  min-height: 100%;
  padding: 28px 24px;
`;

export const PageWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
`;
export const PageWrapperContent = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
`;

const Page = styled.div`
  display: flex;
  flex-grow: 1;
`;
const PageContent = styled.div`
  padding: 64px 5vw;
  background: ${({ theme: { color } }) => color.grayscale.extraLightGray};
  flex-grow: 1;
  overflow: auto;

  > :last-child {
    margin-bottom: 64px;
  }
`;
export const GalleryContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin-bottom: 24px;
`;

export const GalleryPageContainer = ({ children }) => (
  <Page>
    <Sidebar />
    <PageContent>{children}</PageContent>
  </Page>
);
