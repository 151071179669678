import React from 'react';
import { ThemeProvider } from 'styled-components';
import IntersectTinySquareLogo from '../images/LogoCircles.svg';
import IntersectLogo from '../images/Logo.svg';
import IntersectLogoWhite from '../images/Logo_white.svg';
import UpstartLogo from '../images/WhiteLabelLogos/UpstartLogo.png';
import UpstartLogoWhite from '../images/WhiteLabelLogos/UpstartLogoWhite.png';

// NOTE: use the singular for property names
export const defaultTheme = {
  logos: {
    tinySquare: IntersectTinySquareLogo,
    fullWordmark: IntersectLogo,
    fullWordmarkForDarkBG: IntersectLogoWhite,
  },
  color: {
    info: {
      error: '#EB5757',
      success: '#52c41a',
    },
    grayscale: {
      dark: '#24313B',
      darkish: '#595959',
      faintGray1: '#959595',
      faintGray2: '#D9D9D9',
      faintGray3: '#D5D5D5',
      faintGray4: '#ECECEC',
      lightGray: '#F4F3F3',
      extraLightGray: '#f9f9f9',
      white: '#FFF',
    },
    primary: {
      blackout: '#002660',
      darkest: '#0F499F',
      darker: '#1659BD',
      dark: '#2770DC',
      darkDull: '#496B9D',
      brightCool: '#5dd2fa',
      bright: '#47A0FF',
      light: '#DDE6FD',
      lighter: '#F1F5FA',
      lightest: '#F6F9FC',

      gentleGrayishText: '#3a3f44',
      gentleText: '#7F95D0',
    },
    secondary: {
      darkest: '#29133D',
      dark: '#340061',
      light: '#928AA9',
      lighter: '#EAEAFA',
      lightest: '#FCFCFF',
      bright: '#6D26AB',

      logoBright: '#BF78FE',
      lightBorder: '#F3F3FE',

      gentleText: '#9691C6',
    },
    tertiary: {
      darkish: '#DDB140',
      bright: '#FFD772',
      light: '#F5DD95',
      dull: '#F7F6F3',
      dullLight: '#FCFBFA',
      dullBorder: '#E2E2E2',
    },
  },
  typography: {
    fontFamily: {
      main: '"Lato", sans-serif',
    },
    fontSize: {
      xxl: '144px',
      xl: '36px',
      large: '24px',
      mediumLarge: '21px',
      medium: '18px',
      mediumSmall: '16px',
      small: '14px',
      ittybitty: '11px',
    },
    fontWeight: {
      black: 900,
      bold: 700,
      normal: 400,
      light: 300,
    },
  },
};

export const UpstartWorksTheme = {
  logos: {
    tinySquare: IntersectTinySquareLogo,
    fullWordmark: UpstartLogo,
    fullWordmarkForDarkBG: UpstartLogoWhite,
  },
  color: {
    info: {
      error: '#EB5757',
      success: '#52c41a',
    },
    grayscale: {
      dark: '#24313B',
      darkish: '#595959',
      faintGray1: '#959595',
      faintGray2: '#D9D9D9',
      faintGray3: '#D5D5D5',
      faintGray4: '#ECECEC',
      lightGray: '#F4F3F3',
      extraLightGray: '#f9f9f9',
      white: '#FFF',
    },
    primary: {
      blackout: '#1F1352',
      darkest: '#2C1A7A',
      darker: '#352092',
      dark: '#4127B4',
      darkDull: '#4D4475',
      brightCool: '#5dd2fa',
      bright: '#4C2FC9',
      light: '#EFECFF',
      lighter: '#F7F5FF',
      lightest: '#FAF9FF',

      gentleGrayishText: '#3a3f44',
      gentleText: '#9483DC',
    },
    secondary: {
      darkest: '#003640',
      dark: '#09505C',
      light: '#759FA7',
      lighter: '#DCEDF0',
      lightest: '#F8FCFD',
      bright: '#20B2CB',

      logoBright: '#A9EAF5',
      lightBorder: '#E6F3F5',

      gentleText: '#A6C6CC',
    },
    tertiary: {
      darkish: '#DDB140',
      bright: '#FFD772',
      light: '#F5DD95',
      dull: '#F7F6F3',
      dullLight: '#FCFBFA',
      dullBorder: '#E2E2E2',
    },
  },
  typography: {
    fontFamily: {
      main: '"Lato", sans-serif',
    },
    fontSize: {
      xxl: '144px',
      xl: '36px',
      large: '24px',
      mediumLarge: '21px',
      medium: '18px',
      mediumSmall: '16px',
      small: '14px',
      ittybitty: '11px',
    },
    fontWeight: {
      black: 900,
      bold: 700,
      normal: 400,
      light: 300,
    },
  },
};

const getTheme = (themeName = '') => {
  switch (themeName) {
    case 'UpstartWorksTheme':
      return UpstartWorksTheme;
    default:
      return defaultTheme;
  }
};

const Theme = ({ themeName, children }) => {
  const theme = getTheme(themeName);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
