import React, { useContext } from 'react';
import { Select } from 'antd';
import StepContext, { useStepInputValue } from '../../StepContext';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import styled from 'styled-components';
import CodeEditor from '../../../../standard-components/CodeEditor';
import IntegrationInput from '../SentenceRenderers/IntegrationInput';

const QueryTypeSelect = styled(Select)`
  min-width: 120px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 10px;
`;

const DB_TYPES = {
  MONGODB: 'MONGODB',
};

const QueryTypeDropdown = () => {
  const query_type = (useStepInputValue('query_type') || {}).value || undefined;
  const { updateSentenceInputValues } = useContext(StepContext);

  const query_type_options = ['find', 'aggregate'];

  const onChange = (query_type) => {
    updateSentenceInputValues({
      query_type: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: query_type,
      },
    });
  };
  const placeholder =
    query_type_options.length === 0
      ? 'No Query Types yet!'
      : 'Select Query Type';

  return (
    <QueryTypeSelect
      value={query_type}
      placeholder={placeholder}
      onChange={onChange}
    >
      {query_type_options.map((query_type) => (
        <Select.Option key={query_type} value={query_type}>
          {query_type}
        </Select.Option>
      ))}
    </QueryTypeSelect>
  );
};

export default ({ dbType = DB_TYPES.MONGODB }) => {
  const { updateSentenceInputValues } = useContext(StepContext);
  const mongodbContent = (useStepInputValue('query') || {}).value || '';
  const onEditorContentChange = (updatedMongodbContent) => {
    updateSentenceInputValues({
      query: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: updatedMongodbContent,
      },
    });
  };
  return (
    <div>
      <div>Select integration: </div>
      <IntegrationInput integrationType={dbType} />
      <div>
        Select a type of query:
        <QueryTypeDropdown />
      </div>
      <CodeEditor
        editorContent={mongodbContent}
        onEditorContentChange={onEditorContentChange}
        language={'mongodb'}
        placeholder={'Write your MongoDB Query here...'}
      />
    </div>
  );
};
