import React, { createContext, useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useAppId } from './DataAppMetadataContextHelpers';
import { makeAPICall } from '../api/useAPI';

export const TIME_UNITS = {
  hour: 'hour',
  day: 'day',
  weekday: 'day_of_week',
  month: 'month',
};

export const DAYS_OF_WEEK = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const RUN_SCHEDULING_BLANK_VALUE = {
  month: undefined,
  day_of_week: undefined,
  day: undefined,
  hour: undefined,
};

export const RunSchedulingContext = createContext({
  timeUnitValues: RUN_SCHEDULING_BLANK_VALUE,
});

export const useRunScheduling = () => {
  const { runScheduling = RUN_SCHEDULING_BLANK_VALUE } = useContext(
    RunSchedulingContext
  );
  return runScheduling;
};

export const useUpdate = () => {
  const { update } = useContext(RunSchedulingContext);
  return update;
};

export const useTimeUnit = () => {
  const runScheduling = useRunScheduling();
  return Object.keys(RUN_SCHEDULING_BLANK_VALUE).find((unit) => {
    const intervalVal = runScheduling[unit];
    const exists = intervalVal !== undefined && intervalVal !== null;
    return (
      exists && (intervalVal.includes('*/') || unit === TIME_UNITS.weekday)
    );
  });
};

export const useTimeInterval = () => {
  const { runScheduling } = useContext(RunSchedulingContext);
  const timeUnit = useTimeUnit();
  const timeInterval = runScheduling[timeUnit];
  if (timeUnit === TIME_UNITS.weekday) {
    return timeInterval;
  }
  return timeInterval && timeInterval.replace('*/', '');
};

export const useDayOfWeek = () => {
  const day_of_week = useTimeInterval();
  return day_of_week ? day_of_week.split(',') : [];
};

export const useSetDayOfWeek = () => {
  const update = useUpdate();
  return (selectedDaysOfWeek) =>
    update((draftState) => {
      const { start_date } = draftState.runScheduling;
      draftState.runScheduling = {
        ...RUN_SCHEDULING_BLANK_VALUE,
        start_date,
        day_of_week: selectedDaysOfWeek.join(','),
      };
    });
};

const getNewTimeInterval = (timeInterval, timeUnit, previousTimeUnit) => {
  if (timeUnit === TIME_UNITS.weekday) {
    return timeInterval;
  } else {
    const isSwitchingOutOfWeekday =
      previousTimeUnit === TIME_UNITS.weekday &&
      timeUnit !== TIME_UNITS.weekday;
    if (isSwitchingOutOfWeekday) {
      return '*/1'; // Give an arbitrary starting number.
    }
    return !!timeInterval ? `*/${timeInterval}` : timeInterval;
  }
};

export const useSetTimeUnitAndTimeInterval = () => {
  const update = useUpdate();
  const previousTimeUnit = useTimeUnit();
  return (timeUnit, timeInterval) =>
    update((draftState) => {
      const { start_date } = draftState.runScheduling;
      const newTimeInterval = getNewTimeInterval(
        timeInterval,
        timeUnit,
        previousTimeUnit
      );
      draftState.runScheduling = {
        ...RUN_SCHEDULING_BLANK_VALUE,
        start_date,
        [timeUnit]: newTimeInterval,
      };
    });
};

export const useStartDateState = () => {
  const update = useUpdate();
  const { start_date } = useRunScheduling();
  const setStartDate = (dateObject) => {
    update((draftState) => {
      draftState.runScheduling.start_date = dateObject.valueOf(); // Full unix timestamp (including milli)
    });
  };
  return [start_date, setStartDate];
};

const useJobReadyToSubmit = () => {
  const runScheduling = useRunScheduling();
  const numTimeUnitsFilledIn = Object.keys(RUN_SCHEDULING_BLANK_VALUE).reduce(
    (numFilledIn, timeUnit) => {
      const unitInterval = runScheduling[timeUnit];
      return !!unitInterval ? ++numFilledIn : numFilledIn;
    },
    0
  );
  const startDateExists = !!runScheduling.start_date;
  return numTimeUnitsFilledIn === 1 && startDateExists;
};

const useRunSchedulingWithHourAndMinute = () => {
  const runScheduling = useRunScheduling();
  const startDateObject = new Date(runScheduling.start_date);

  return {
    ...runScheduling,
    hour: runScheduling.hour || `${startDateObject.getUTCHours()}`,
    minute: `${startDateObject.getUTCMinutes()}`,
  };
};

export const useAddScheduledJob = () => {
  const appId = useAppId();
  const update = useUpdate();
  const [_, toggleModal] = useIsEditModalOpen();
  const jobReadyToSubmit = useJobReadyToSubmit();
  const runSchedulingWithHourAndMinute = useRunSchedulingWithHourAndMinute();

  return {
    jobReadyToSubmit,
    addScheduledJob: () => {
      if (!jobReadyToSubmit) return;
      toggleModal();
      makeAPICall({
        endpoint: `/schedule/job/add?app_id=${appId}`,
        method: 'POST',
        body: runSchedulingWithHourAndMinute,
      }).then(([{ jobs }, fetchError]) => {
        window.analytics.track('Scheduled Job');
        update((draftState) => {
          draftState.scheduledRuns = jobs;
        });
      });
    },
  };
};

export const useEditScheduledJob = () => {
  const { job_id } = useExistingJob();
  const jobExists = !!job_id;
  const update = useUpdate();
  const [_, toggleModal] = useIsEditModalOpen();
  const runSchedulingWithHourAndMinute = useRunSchedulingWithHourAndMinute();

  return {
    jobExists,
    editScheduledJob: () => {
      if (!jobExists) return;
      toggleModal();
      makeAPICall({
        endpoint: `/schedule/job/edit?job_id=${job_id}`,
        method: 'POST',
        body: runSchedulingWithHourAndMinute,
      }).then(([{ jobs }, fetchError]) => {
        update((draftState) => {
          draftState.scheduledRuns = jobs;
        });
      });
    },
  };
};

export const useDeleteScheduledJob = () => {
  const app_id = useAppId();
  const update = useUpdate();
  const [_, toggleModal] = useIsEditModalOpen();
  const { job_id } = useExistingJob();
  const noJobExists = !job_id;

  return {
    noJobExists,
    deleteScheduledJob: () => {
      if (noJobExists) return;

      toggleModal();
      makeAPICall({
        endpoint: `/schedule/job/cancel?app_id=${app_id}&job_id=${job_id}`,
        method: 'POST',
        body: {},
      }).then(([{ jobs }, fetchError]) => {
        update((draftState) => {
          draftState.scheduledRuns = jobs;
        });
      });
    },
  };
};

export const useIsEditModalOpen = () => {
  const { isEditModalOpen } = useContext(RunSchedulingContext);
  const update = useUpdate();
  return [
    isEditModalOpen,
    () =>
      update((draftState) => {
        draftState.isEditModalOpen = !draftState.isEditModalOpen;
      }),
  ];
};

export const useExistingJob = () => {
  const { scheduledRuns } = useContext(RunSchedulingContext);
  return (scheduledRuns && scheduledRuns[0]) || {}; // should only be one
};

export const RunSchedulingContextProvider = ({ children }) => {
  const [runSchedulingContextValue, updateRunSchedulingContextValue] = useImmer(
    {
      runScheduling: RUN_SCHEDULING_BLANK_VALUE,
      isEditModalOpen: false,
    }
  );
  const appId = useAppId();
  useEffect(() => {
    const fetchScheduledRuns = () => {
      makeAPICall({
        endpoint: `/schedule/app?app_id=${appId}`,
      }).then(([res, fetchError]) => {
        const { jobs } = res || {};
        if (!jobs || fetchError) return;
        updateRunSchedulingContextValue((draftState) => {
          draftState.scheduledRuns = jobs;
          const existingJob = jobs[0];
          if (existingJob) {
            draftState.runScheduling = existingJob.schedule;
          }
        });
      });
    };

    fetchScheduledRuns();
  }, [appId]);

  return (
    <RunSchedulingContext.Provider
      value={{
        ...runSchedulingContextValue,
        update: updateRunSchedulingContextValue,
      }}
    >
      {children}
    </RunSchedulingContext.Provider>
  );
};
