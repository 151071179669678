import { CheckCircleFilled, CopyFilled } from '@ant-design/icons';
import { Button, Input, Typography, message } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { CardContainer } from '../standard-components/containers';

const SignupLinkContainer = styled(Typography.Paragraph)`
  padding: 20px 15px 10px;
  margin-top: 20px;
  background: ${(p) => p.theme.color.grayscale.extraLightGray};
  border: 1px solid ${(p) => p.theme.color.grayscale.faintGray4};
  border-radius: 4px;
  position: relative;
`;

const CopyButton = styled(Button)`
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
`;

export default () => {
  const [email, setEmail] = useState('');
  const [signupLink, setSignupLink] = useState('');
  const [loading, setLoading] = useState(false);
  const teamId = useTeamId();
  const handleSubmit = async () => {
    setLoading(true);

    const [res = {}, fetchError] = await makeAPICall({
      method: 'POST',
      endpoint: `/billing/invite?team_id=${teamId}`,
      body: { email_address: email },
    });

    if (res.signup_link) {
      message.success('Email Sent');
      setSignupLink(res.signup_link);
    } else if (res.error) {
      message.warning('Invalid Email');
      setSignupLink('');
    } else if (res.user_exist) {
      message.warning('User is already apart of the team');
      setSignupLink('');
    } else {
      message.error('Error Sending Email');
      setSignupLink(res.signup_link);
    }

    setLoading(false);
  };

  return (
    <div>
      <Input.Search
        placeholder="email of the person you want to invite"
        onChange={({ target }) => setEmail(target.value)}
        enterButton="Send Invite Link"
        size="large"
        loading={loading}
        onPressEnter={handleSubmit}
        onSearch={handleSubmit}
      />
      {signupLink && (
        <SignupLinkContainer
          href={signupLink}
          copyable={{
            icon: [
              <CopyButton size="small" type="primary" icon={<CopyFilled />}>
                Copy
              </CopyButton>,
              <CopyButton
                size="small"
                type="default"
                icon={<CheckCircleFilled />}
              >
                Copied
              </CopyButton>,
            ],
          }}
        >
          {signupLink}
        </SignupLinkContainer>
      )}
    </div>
  );
};
