import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  DatabaseOutlined,
  ProfileOutlined,
  SettingOutlined,
  LogoutOutlined,
  BookOutlined,
  PlaySquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { logout } from '../model/User';
import SidebarTeamPicker from '../settings-components/SidebarTeamPicker';
import { Popover, Space } from 'antd';
import FreeTrialBanner from '../checkout-components/FreeTrialBanner';

const SidebarWrapper = styled.div`
  text-align: left;
  background-color: ${({ theme: { color } }) => color.grayscale.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 256px;
`;

const LogoImg = styled.img`
  margin: 30px 45px 40px 18px;
  max-width: 195px;
`;

const Logo = () => {
  const theme = useTheme();
  return (
    <LogoImg
      src={theme.logos.fullWordmark}
      alt="Intersect Labs"
      title="Intersect Labs"
    />
  );
};

const Items = styled.div`
  padding-left: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Item = styled.a.attrs(({ theme: { color } }) => ({
  activeStyle: {
    background: color.grayscale.extraLightGray,
  },
}))`
  font-size: 16px;
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
  color: ${({ theme: { color } }) => color.grayscale.dark};
  cursor: pointer;
  padding: 10px;
  border-radius: 5px 0px 0px 5px;

  &:hover {
    text-decoration: underline;
    background: ${(p) => p.theme.color.grayscale.extraLightGray};
  }
`;

const LinkItem = Item.withComponent(NavLink);

const FooterItems = styled.div`
  border-top: 1px solid
    ${({ theme: { color } }) => color.grayscale.extraLightGray};
  padding-top: 24px;
  margin-left: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const iconStylingCSS = css`
  color: ${({ theme: { color } }) => color.grayscale.faintGray3};
  margin-right: 8px;
  font-size: 18px;
`;

const AppIcon = styled(ProfileOutlined)`
  ${iconStylingCSS}
`;
const IntegrationsIcon = styled(DatabaseOutlined)`
  ${iconStylingCSS}
`;
const ResourcesIcon = styled(BookOutlined)`
  ${iconStylingCSS}
`;
const SettingsIcon = styled(SettingOutlined)`
  ${iconStylingCSS}
`;
const LogoutIcon = styled(LogoutOutlined)`
  ${iconStylingCSS}
`;

const TutorialIcon = AppIcon.withComponent(PlaySquareOutlined);
const DocsIcon = AppIcon.withComponent(BookOutlined);

const ResourcesMenuContainer = styled(Space)`
  align-items: stretch;

  a {
    width: 100%;
    display: block;
  }
`;

export const ResourcesMenu = () => {
  return (
    <ResourcesMenuContainer direction="vertical">
      <Item
        href="https://intersectlabs.io/getting-started-with-intersect"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TutorialIcon />
        Tutorials
      </Item>
      <Item
        href="https://showcase.intersectlabs.io/gallery/blocks/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <DocsIcon />
        Block Gallery
      </Item>
    </ResourcesMenuContainer>
  );
};

const Sidebar = () => (
  <SidebarWrapper>
    <Logo />
    <Items>
      <LinkItem exact to="/apps">
        <AppIcon />
        Data Apps
      </LinkItem>
      <LinkItem to="/integrations">
        <IntegrationsIcon />
        Integrations
      </LinkItem>
    </Items>
    <FooterItems>
      <FreeTrialBanner />
      <SidebarTeamPicker />
      <Popover placement="right" content={ResourcesMenu}>
        <Item>
          <ResourcesIcon />
          Resources
        </Item>
      </Popover>
      <LinkItem to="/settings">
        <SettingsIcon />
        Settings
      </LinkItem>
      <Item onClick={logout}>
        <LogoutIcon />
        Logout
      </Item>
    </FooterItems>
  </SidebarWrapper>
);

export default Sidebar;
