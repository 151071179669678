import { get, head } from 'lodash';
import React, { createContext, useEffect, useState, useContext } from 'react';
import { makeAPICall } from '../api/useAPI';
import LoadingSplash from '../standard-components/LoadingSplash';
import { store } from './LocalForageStore';
import { withIsSignedInHOC } from './User';

export const TeamContext = createContext({});

export const STORE_KEY_TEAM = 'intersect.currentTeam';

export const useTeamId = () => {
  const { currentTeam } = useContext(TeamContext);
  return get(currentTeam, 'id', null);
};

export const useTeamName = () => {
  const { currentTeam } = useContext(TeamContext);
  return get(currentTeam, 'name', 'Your Team');
};

export const TeamContextProvider = withIsSignedInHOC(
  ({ children, isSignedIn, isLoadingSignedInState }) => {
    const [teams, setTeams] = useState();
    const [currentTeam, setCurrentTeam] = useState(null);
    const [currentTeamIsLoading, setCurrentTeamIsLoading] = useState(true);

    useEffect(() => {
      const loadTeam = async () => {
        if (!isSignedIn && !isLoadingSignedInState) {
          setCurrentTeamIsLoading(false);
          return;
        } else {
          setCurrentTeamIsLoading(true);
        }

        const cachedTeam = await store.getItem(STORE_KEY_TEAM);
        if (cachedTeam !== null) {
          setCurrentTeam(cachedTeam);
          setCurrentTeamIsLoading(false);
        }

        const [teams, error] = await makeAPICall({
          endpoint: '/user/teams',
        });
        setTeams(teams);
        if (teams && cachedTeam === null) {
          const firstTeam = head(teams);
          store.setItem(STORE_KEY_TEAM, firstTeam);
          setCurrentTeam(firstTeam);
        }
        setCurrentTeamIsLoading(false);
      };

      loadTeam();
    }, [isSignedIn, isLoadingSignedInState]);

    const updateCurrentTeam = (team) => {
      store.setItem(STORE_KEY_TEAM, team);
      setCurrentTeam(team);
    };

    if (currentTeamIsLoading) {
      return <LoadingSplash tip="Loading studio..." />;
    }

    return (
      <TeamContext.Provider
        value={{ teams, currentTeam, updateCurrentTeam, currentTeamIsLoading }}
      >
        {children}
      </TeamContext.Provider>
    );
  }
);
