import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { format, subDays, parseISO } from 'date-fns';
import { Steps } from 'antd';
import DemoCallFileUpload from '../data-set-components/upload-panels/DemoCallFileUpload';
import Loading from '../standard-components/Loading';
import { get } from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import { makeAPICall } from '../api/useAPI';

const { Step } = Steps;

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  background: #f0f6ff;
`;

const ContentPiece = styled.div`
  border-radius: 6px;
  background: #fff;
  margin-bottom: 50px;
  color: #444;
  text-align: center;
  font-size: 18px;
  padding: 30px 15%;
`;

const BlueContentPiece = styled(ContentPiece)`
  background: #003c7c;
  color: #fff;
  padding: 25px 15px;
`;

const SpaceBetweenRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DueDateIndicator = styled.div`
  background: #00346b;
  border-radius: 20px;
  padding: 6px 20px;
  font-size: 14px;
`;

const Title = styled.div`
  font-weight: 600;
  margin-bottom: 25px;
`;

const UploadedFileList = styled.ul`
  background: #00346b;
  border-radius: 6px;
  font-size: 14px;
  text-align: left;
  padding: 10px 20px;
  margin-top: 20px;

  li {
    list-style: none;
    border-left: 2px solid #1890ff;
    padding-left: 10px;
    margin: 10px auto;
  }
`;

const UploadedFileListTitle = styled.div`
  color: #1890ff;
`;

export default withRouter(({ match }) => {
  const [leadData, setLeadData] = useState();
  const encryptedString = match.params.encryptedString;

  const lead_status = [
    'Exploratory Call Scheduled',
    'Demo Call Scheduled',
    'Model/pipeline set up',
    'Free trial',
    'Paying Customer',
  ];

  useEffect(() => {
    if (!['loading', 'none'].includes(encryptedString)) {
      makeAPICall({ endpoint: `/close/demo/${encryptedString}` })
        .then((data) => {
          setLeadData(data);
        })
        .catch((error) => {
          console.error(`Error fetching lead data`, error);
        });
    }
  }, [encryptedString]);

  const addFilesToUploadedList = (files) => {
    setLeadData((leadData) => ({
      ...leadData,
      files: [...(leadData.files || []), ...files],
    }));
  };

  if (encryptedString === 'none') {
    return (
      <PageWrapper>
        <ContentPiece>
          Sorry! This link doesn't map to a demo call. Please contact us at
          hello@intersectlabs.io
        </ContentPiece>
      </PageWrapper>
    );
  }
  if (!leadData || encryptedString === 'loading') return <Loading />;

  const uploadedFiles = get(leadData, 'files', null);
  let demoCallDateString = 'is coming up.';
  let dueOnDateString = 'soon.';
  const demoCallStartTime = get(leadData, 'upcoming_meeting.start_time');
  if (demoCallStartTime) {
    const demoCallDate = parseISO(get(leadData, 'upcoming_meeting.start_time'));
    demoCallDateString = `is scheduled for ${format(demoCallDate, 'PPp')}`;
    dueOnDateString = `by ${format(subDays(demoCallDate, 2), 'MMMM do')}`;
  }
  return (
    <PageWrapper>
      {/* Because this page is embedded in an iframe, we tell the parent of any height changes */}
      <div style={{ position: 'absolute', top: 0, bottom: 0 }}>
        <ReactResizeDetector>
          {({ height }) => {
            // eslint-disable-next-line no-restricted-globals
            if (window.parent !== undefined)
              window.parent.postMessage(
                { type: 'il-height-changed', height },
                '*'
              );
            return <div style={{ position: 'absolute', top: 0, bottom: 0 }} />;
          }}
        </ReactResizeDetector>
      </div>
      <ContentPiece>
        <Title>Hey {leadData.lead_name}!</Title>
        <div>
          <p>
            The demo that’ll show you the future <b>{demoCallDateString}</b>.
          </p>
          <p>
            Please upload your data <b>{dueOnDateString}</b>
          </p>
        </div>
      </ContentPiece>
      <BlueContentPiece>
        <SpaceBetweenRow>
          <b>Data for the Demo</b>
          <DueDateIndicator>Due {dueOnDateString}</DueDateIndicator>
        </SpaceBetweenRow>
        {uploadedFiles !== null && (
          <UploadedFileList>
            <UploadedFileListTitle>
              Data Uploaded to ML Training Vault
            </UploadedFileListTitle>
            {uploadedFiles.map((file) => (
              <li key={file}>{file}</li>
            ))}
          </UploadedFileList>
        )}
        <DemoCallFileUpload
          encryptedString={encryptedString}
          addFilesToUploadedList={addFilesToUploadedList}
        />
      </BlueContentPiece>
      <ContentPiece>
        <Title>The Path to Predictions</Title>
        <Steps
          direction="vertical"
          current={lead_status.indexOf(leadData.current_lead_status)}
        >
          <Step
            title="The Introductory Call"
            description="Let's get started."
          />
          <Step
            title="The Demo With Your Data"
            description="See how ML can work for your business."
          />
          <Step
            title="Model Built"
            description="Your first machine learning model has come to life!"
          />
          <Step
            title="Free Trial Started"
            description="A two week free trial to help you get familiar with Intersect Labs."
          />
          <Step
            title="Operationalized Predictions"
            description="Predictions are integrated and giving your business foresight."
          />
        </Steps>
      </ContentPiece>
    </PageWrapper>
  );
});
