import React from 'react';
import { useStepType } from '../StepContext';
import { SentenceInput } from './SentenceRenderers/SentenceRendererCore';
import CommentStep from './CommentRenderers/CommentStep';
import InputRenderer from './InputRenderers/InputRenderer';
import OutputRenderer from './OutputRenderers/OutputRenderer';
import EmailRenderer from './EmailRenderers/EmailRenderer';
import DashboardRenderer from './DashboardRenderers/DashboardRenderer';
import SchemaSandbox from './SchemaSandbox';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TYPE_TO_RENDERER_MAPPING = {
  // COMMENT: null, // both get assigned custom *step* renderers
  // COMMENT_V2: null,
  INPUT: InputRenderer,
  TRANSFORM: SentenceInput,
  EMAIL: EmailRenderer,
  DATAVIZ: SentenceInput,
  OUTPUT: OutputRenderer,
  DASHBOARD: DashboardRenderer,
};

export default () => {
  const type = useStepType();
  let query = useQuery();
  const Renderer = TYPE_TO_RENDERER_MAPPING[type] || SentenceInput; // catch all

  const schemaSandboxMode = query.get('sandbox');
  if (schemaSandboxMode) {
    return <SchemaSandbox Renderer={Renderer} />;
  }

  return <Renderer />;
};
