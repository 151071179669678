import React from 'react';
import { Alert } from 'antd';
import { SubText } from './typography';
import styled from 'styled-components';
import DataDownloadButton from '../studio-page-components/Step/StepOutput/DataDownloadButton';
import { useIsInsideDashboard } from '../studio-page-components/Step/StepContext';
import AGDataTable from '../data-set-components/AGDataTable';

const DataDimensions = styled(SubText)``;

const DataTableContainer = styled.div`
  height: inherit;
  font-weight: ${(p) => p.theme.typography.fontWeight.normal};
  display: flex;
  flex-direction: column;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: ${(p) => p.theme.color.grayscale.white};
`;

const DataTableHolder = styled.div`
  display: flex;

  > div {
    width: 100%;
  }
`;

const Metadata = ({ metadata }) => {
  if (!metadata.rows || !metadata.columns) {
    return null;
  }
  return (
    <DataDimensions>{`${metadata.rows} rows x ${metadata.columns} columns`}</DataDimensions>
  );
};

const MemoizedGrid = React.memo(({ columns, values, suggestedHeight }) => {
  return (
    <AGDataTable
      rowData={values}
      columns={columns}
      suggestedHeight={suggestedHeight}
    />
  );
});

export default React.memo(({ data = {}, variable }) => {
  const isInsideDashboard = useIsInsideDashboard();
  const { columns, values, metadata = {} } = data;

  if (!columns || !values) {
    return (
      <Alert
        showIcon
        type="warning"
        message="Couldn't find any data for this data piece."
      />
    );
  }
  const tableHeight = isInsideDashboard ? '100%' : '300px';
  const tableWrapperHeight = isInsideDashboard ? 'inherit' : 'auto';

  return (
    <DataTableContainer>
      {!isInsideDashboard && (
        <InfoRow>
          <Metadata metadata={metadata} />
          <DataDownloadButton variable={variable} />
        </InfoRow>
      )}
      <DataTableHolder style={{ height: tableWrapperHeight }}>
        <MemoizedGrid
          values={values}
          columns={columns}
          suggestedHeight={tableHeight}
        />
      </DataTableHolder>
    </DataTableContainer>
  );
});
