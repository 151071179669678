import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-mongodb';
import 'prismjs/components/prism-markup';
import './prism.css';
import { useTheme } from 'styled-components';
import { blockSubTypes } from '../model/blockTypes';

// if the subtype (eg snowflake) isn't the language name
const SUBTYPE_TO_LANGUAGE_MAPPING = {
  [blockSubTypes.MYSQL]: 'sql',
  [blockSubTypes.REDSHIFT]: 'sql',
  [blockSubTypes.SNOWFLAKE]: 'sql',
  [blockSubTypes.SENDGRID]: 'html',
};

const SUBTYPE_TO_PLACEHOLDER_MAPPING = {
  [blockSubTypes.SENDGRID]: 'Write your email body in HTML',
};

export default ({
  editorContent,
  onEditorContentChange,
  language = 'sql',
  placeholder = 'Write your code here...',
  disabled = false,
  style = {},
}) => {
  const { color } = useTheme();
  const baseStyle = {
    fontFamily: 'monospace',
    fontSize: 13,
    backgroundColor: color.grayscale.white,
    borderColor: color.secondary.lighter,
    borderWidth: 1,
    borderStyle: 'solid',
    width: '100%',
    minWidth: 600,
    minHeight: 200,
    ...style,
  };

  const _language = SUBTYPE_TO_LANGUAGE_MAPPING[language] || language;
  const _placeholder = SUBTYPE_TO_PLACEHOLDER_MAPPING[language] || placeholder;

  return (
    <Editor
      placeholder={_placeholder}
      value={editorContent || ''}
      onValueChange={(content) => onEditorContentChange(content || '')}
      highlight={(content) =>
        highlight(content, languages[_language], _language)
      }
      padding={15}
      style={baseStyle}
      autoFocus={true}
      disabled={disabled}
    />
  );
};
