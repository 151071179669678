import React from 'react';
import styled from 'styled-components';
import { getPricingRate } from './SubscriptionUtils';
import { get } from 'lodash';
import PlanProsAndCons from './PlanProsAndCons';
import { useSubscriptionIsOrWillBeCanceled } from '../model/BillingContext';
import { CoolBlueButton } from '../standard-components/CustomColorButton';

const PlanColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 25px 30px;
  width: 250px;
  background: #11253e;
  color: ${(p) => p.theme.color.grayscale.white};
  border-radius: 10px;
  margin-right: 30px;

  :last-of-type {
    margin-right: 0px;
  }

  @media (max-width: 950px) {
    margin: 20px 20px;
    width: auto;
    flex-basis: 100%;

    :last-of-type {
      margin: 20px 20px;
    }
  }
`;

const Title = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: 28px;
  margin-bottom: 10px;
`;

const EmphasizedPlanColumn = styled(PlanColumn)`
  background: #a0e7ff;
  color: ${(p) => p.theme.color.primary.blackout};
`;

const PriceDisplay = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.large};
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  text-transform: lowercase;

  .actual-price {
    font-weight: ${(p) => p.theme.typography.fontWeight.bold};
    text-transform: capitalize;
  }
`;

const ActionButton = styled(CoolBlueButton)`
  margin-top: 40px;
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  width: 100%;
`;

export const CUSTOM_ENTERPRISE_PLAN = 'CUSTOM_ENTERPRISE_PLAN';

const LitePros = ['2 users', '2 data apps', 'Basic integrations'];
const ProPlanPros = [
  '5 users',
  '5 data apps',
  'Advanced integrations',
  'Data export',
  'Scheduling',
  'Granular permissions',
];
const TeamPros = [
  '10 users',
  '10 data apps',
  'Premium integrations',
  'Data export',
  'Scheduling',
  'Granular permissions',
  'Versioning',
];
const EnterprisePros = [
  'Unlimited users',
  'Unlimited data apps',
  'Custom integrations',
  'Data export',
  'Scheduling',
  'Granular permissions',
  'Versioning',
  'SSO',
  'On-prem',
];

const planIdToPros = {
  prod_JvSC3AowtArnq4: LitePros,

  prod_JvSDWTwcuTOIRA: ProPlanPros,

  prod_JvSEjhuUINaPP5: TeamPros,

  [CUSTOM_ENTERPRISE_PLAN]: EnterprisePros,
};

export default ({
  product,
  isEmphasized = false,
  onSelect,
  currentSubscription,
  actionButtonText = 'Subscribe Now',
  subscriptionPeriod,
}) => {
  const { price_id, name, custom_price } = product;
  const pros = planIdToPros[price_id];
  let Column = PlanColumn;
  const subscriptionIsOrWillBeCanceled = useSubscriptionIsOrWillBeCanceled();
  if (isEmphasized) Column = EmphasizedPlanColumn;
  const isCurrentSubscription =
    get(currentSubscription, 'product_public_name') === name &&
    !subscriptionIsOrWillBeCanceled;
  const pricing = getPricingRate(product, subscriptionPeriod);
  console.log(
    'isCurrentSubscription',
    currentSubscription,
    isCurrentSubscription,
    name
  );

  let buttonText = actionButtonText;
  let isButtonDisabled = false;
  if (isCurrentSubscription) {
    isButtonDisabled = true;
    buttonText = 'Current Subscription';
  } else if (product.no_trial || get(product, 'feature_flags.galileo')) {
    buttonText = 'Start Subscription';
  }
  let subscriptionPeriodCapitalized =
    subscriptionPeriod.charAt(0).toUpperCase() + subscriptionPeriod.slice(1);
  let priceText = (
    <div>
      <span className="actual-price">${pricing / 100}</span> /{' '}
      {subscriptionPeriodCapitalized}
    </div>
  );

  let actionView = (
    <ActionButton
      onClick={() => onSelect(product)}
      type="primary"
      size="large"
      shape="round"
      disabled={isButtonDisabled}
    >
      {buttonText}
    </ActionButton>
  );
  if (custom_price) {
    priceText = <span className="actual-price">Custom</span>;
    actionView = (
      <ActionButton
        href={
          'https://calendly.com/intersect-labs/hello?utm_source=hopper&utm_medium=pricing_view'
        }
        target="_blank"
        type="primary"
        size="large"
        shape="round"
        className="btn-bright-purple"
      >
        Talk to us
      </ActionButton>
    );
  }

  return (
    <Column key={price_id}>
      <Title>{name.replace('Intersect ', '')}</Title>
      <PriceDisplay>{priceText}</PriceDisplay>
      <PlanProsAndCons pros={pros} />
      {actionView}
    </Column>
  );
};
