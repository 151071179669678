import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { SectionTitle } from '../standard-components/typography';

export default () => {
  const { app_id_to_duplicate } = useParams();
  const team_id = useTeamId();
  const history = useHistory();

  useEffect(() => {
    makeAPICall({
      method: 'POST',
      body: {
        app_id_to_duplicate,
        team_id,
      },
      endpoint: `/app/duplicate`,
    }).then(([duplicateAppInfo, error]) => {
      if (error) {
        console.error(error);
        return history.replace(`/apps`);
      }
      history.replace(`/apps/${duplicateAppInfo.id}/setup`);
    });
  }, []);

  return (
    <SectionTitle style={{ margin: 48 }}>
      Duplicating the app... you'll be redirected in a moment.
    </SectionTitle>
  );
};
