import React, { useContext, useEffect } from 'react';
import { Select } from 'antd';
import StepContext, { useStepInputValue } from '../../../StepContext';
import { useAPI } from '../../../../../api/useAPI';
import { SCHEMA_INPUT_TYPE } from '../../../../../model/SchemaRendererContext';
import styled from 'styled-components';
import { SENTENCE_INPUT_MARGIN } from '../../../../../standard-components/containers';

const FetchedSelect = styled(Select)`
  min-width: 200px;
  margin: ${SENTENCE_INPUT_MARGIN};
`;

export default ({
  endpoint,
  shouldRefetch,
  stepInputId, // eg 'integration_id'
  disabled,
  placeholder,
  ifNoResultsComponent,
  afterResultsAddOn,
  errorNotification = true,
}) => {
  const selectedListItemId =
    (useStepInputValue(stepInputId) || {}).value || undefined;
  const { updateSentenceInputValues } = useContext(StepContext);
  let [fetchedList = [], { isLoading, refetch }] = useAPI({
    endpoint,
    errorNotification,
  });

  useEffect(() => {
    if (shouldRefetch) {
      refetch && refetch();
    }
  }, [shouldRefetch]);

  if (
    !isLoading &&
    fetchedList &&
    fetchedList.length === 0 &&
    ifNoResultsComponent
  ) {
    return ifNoResultsComponent;
  }

  const onChange = (selectedListItemId) => {
    updateSentenceInputValues({
      [stepInputId]: {
        type: SCHEMA_INPUT_TYPE.STATIC,
        value: selectedListItemId,
      },
    });
  };

  const currentPlaceholder =
    !isLoading && fetchedList.length === 0
      ? 'None exist yet!'
      : placeholder || 'Select an item';

  const options = fetchedList.map((listItem) => (
    <Select.Option key={listItem.id} value={listItem.id}>
      {listItem.name}
    </Select.Option>
  ));

  return (
    <FetchedSelect
      value={isLoading ? undefined : selectedListItemId}
      loading={isLoading}
      placeholder={isLoading ? 'Loading...' : currentPlaceholder}
      onChange={onChange}
      disabled={disabled}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      dropdownRender={(menu) => (
        <div>
          {menu}
          {afterResultsAddOn}
        </div>
      )}
    >
      {options}
    </FetchedSelect>
  );
};
