import styled from 'styled-components';
import { Button } from 'antd';

const hoverBoxShadow =
  '0px 1px 6px rgba(0, 0, 0, 0.20), 0px 1px 8px rgba(0, 0, 0, 0.1)';

const CustomColorButton = styled(Button)`
  background-color: ${(p) => p.colors.base};
  cursor: pointer;
  transition: ${(p) => (p.selected ? 'none' : '0.2s all')};
  color: ${(p) => p.colors.fontColor || '#ffffff'};
  font-weight: ${(p) => p.fontWeight};
  font-size: ${(p) => p.fontSize};
  border: 1px solid ${(p) => p.colors.borderColor || 'transparent'};
  box-shadow: ${(p) =>
    p.showshadow &&
    '0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 8px rgba(0, 0, 0, 0.1)'};
  border-radius: ${(p) => p.customborderradius};
  padding: ${(p) => p.padding};
  height: ${(p) => p.height};

  :hover {
    background-color: ${(p) => p.colors.action};
    color: ${(p) => p.colors.fontColor || '#ffffff'};
    border-color: ${(p) =>
      p.colors.actionBorderColor || p.colors.borderColor || p.colors.action};
    box-shadow: ${(p) => p.showshadow && hoverBoxShadow};
    padding: ${(p) => p.hoverpadding};
    height: ${(p) => p.hoverheight};
  }

  :active {
    background-color: ${(p) => p.colors.base};
    border-color: ${(p) =>
      !p.selected && (p.colors.actionBorderColor || p.colors.action)};
    color: ${(p) => p.colors.fontColor || '#ffffff'};
  }

  :focus {
    background-color: ${(p) => p.colors.action};
    border-color: ${(p) => p.colors.borderColor || 'transparent'};
    color: ${(p) => p.colors.fontColor || '#ffffff'};
  }

  :focus:hover {
    background-color: ${(p) => p.colors.action};
    border-color: ${(p) => p.colors.borderColor || 'transparent'};
    color: ${(p) => p.colors.fontColor || '#ffffff'};
  }
`;
export default CustomColorButton;

export const CoolBlueButton = styled(CustomColorButton).attrs((props) => ({
  colors: {
    base: props.theme.color.grayscale.white,
    action: props.theme.color.primary.brightCool,
    fontColor: props.theme.color.primary.blackout,
  },
}))``;

export const PurpleButton = styled(CustomColorButton).attrs((props) => ({
  colors: {
    base: props.theme.color.secondary.bright,
    action: props.theme.color.secondary.dark,
  },
}))``;

export const DarkButton = styled(CustomColorButton).attrs((props) => ({
  colors: {
    base: props.theme.color.grayscale.darkish,
    action: props.theme.color.grayscale.dark,
  },
}))``;

export const DarkBlueButton = styled(CustomColorButton).attrs((props) => ({
  colors: {
    base: props.theme.color.primary.darkest,
    action: props.theme.color.primary.blackout,
  },
}))``;

export const DarkDullBlueButton = styled(CustomColorButton).attrs((props) => ({
  colors: {
    base: props.theme.color.primary.darkDull,
    action: props.theme.color.primary.darker,
  },
}))``;

export const LightBlueButton = styled(CustomColorButton).attrs((props) => ({
  colors: {
    base: props.theme.color.primary.lighter,
    action: props.theme.color.primary.light,
    fontColor: props.theme.color.primary.darkDull,
  },
}))``;

export const YellowButton = styled(CustomColorButton).attrs((props) => ({
  colors: {
    base: props.theme.color.tertiary.darkish,
    action: props.theme.color.tertiary.bright,
    fontColor: props.theme.color.grayscale.white,
  },
}))``;

/********** Nav Bar Buttons ***********/

const attachedButtonBaseProps = (props) => ({
  fontWeight: props.theme.typography.fontWeight.bold,
  showshadow: (!props.selected).toString(),
  padding: props.selected ? '12px 20px 12px' : '8px 20px',
  hoverpadding: '12px 20px 12px',
  customborderradius: '0px 0px 26px 26px',
  height: props.selected ? '60px' : '45px',
  hoverheight: props.hoverheight || '60px',
  style: {
    borderWidth: 0,
    borderTopWidth: props.selected ? 5 : 0,
    boxShadow:
      props.selected &&
      'inset 0px 2px 5px rgba(0, 0, 0, 0.25), inset 0px -2px 8px rgba(0, 0, 0, 0.1)',
    ...props.style,
  },
});

const whiteSecondaryButtonProps = (props) => ({
  ...attachedButtonBaseProps(props),
  colors: {
    base: props.theme.color.grayscale.white,
    action: props.theme.color.grayscale.white,
    fontColor: props.theme.color.secondary.darkest,
    borderColor: props.selected && props.theme.color.secondary.darkest,
    actionBorderColor: props.theme.color.secondary.darkest,
  },
});

const primaryButtonProps = (props) => ({
  ...attachedButtonBaseProps(props),
  colors: {
    base: props.theme.color.primary.dark,
    action: props.theme.color.primary.bright,
    fontColor: props.theme.color.grayscale.white,
    borderColor: props.selected && props.theme.color.primary.dark,
    actionBorderColor: props.theme.color.primary.dark,
  },
});

/** Secondary Buttons **/

export const TopLeftAttachedWhiteSecondaryButton = styled(
  CustomColorButton
).attrs((props) => ({
  ...whiteSecondaryButtonProps(props),
  colors: {
    base: props.theme.color.grayscale.white,
    action: props.theme.color.grayscale.white,
    fontColor: props.theme.color.grayscale.faintGray1,
  },
  customborderradius: '0px 0px 26px 0px',
  fontSize: '18px',
}))``;

export const TopAttachedWhiteSecondaryButton = styled(CustomColorButton).attrs(
  whiteSecondaryButtonProps
)``;

export const TopAttachedSecondaryButton = styled(CustomColorButton).attrs(
  (props) => ({
    ...attachedButtonBaseProps(props),
    colors: {
      base: props.theme.color.secondary.darkest,
      action: props.theme.color.secondary.dark,
    },
  })
)``;

/** Primary Buttons **/
export const TopLeftAttachedPrimaryButton = styled(CustomColorButton).attrs(
  (props) => ({
    ...primaryButtonProps(props),
    customborderradius: '0px 0px 26px 0px',
    fontSize: '18px',
    padding: '5px 20px 12px 15px',
  })
)``;

export const TopAttachedPrimaryButton = styled(CustomColorButton).attrs(
  (props) => ({
    ...primaryButtonProps(props),
  })
)``;

export const TopAttachedWhitePrimaryButton = styled(CustomColorButton).attrs(
  (props) => ({
    ...primaryButtonProps(props),
    colors: {
      base: props.theme.color.grayscale.white,
      action: props.theme.color.grayscale.white,
      fontColor: props.theme.color.grayscale.dark,
      borderColor: props.selected && props.theme.color.primary.dark,
    },
  })
)``;

export const WhitePrimaryShadowButton = styled(CustomColorButton).attrs(
  (props) => ({
    colors: {
      base: props.theme.color.grayscale.white,
      action: props.theme.color.primary.lighter,
      fontColor: props.theme.color.primary.darker,
    },
    showshadow: 'true',
  })
)``;

export const PrimaryShadowButton = styled(CustomColorButton).attrs((props) => ({
  ...primaryButtonProps(props),
  hoverheight: undefined,
  hoverpadding: undefined,
  showshadow: 'true',
}))``;
