import React, { useContext, useEffect, useState } from 'react';
import SchemaRendererContext from '../../../../model/SchemaRendererContext';
import { withCoreInputBehavior } from './CoreInputHOC';
import { renderChildren } from './SentenceRendererCore';

const ObjectInput = ({
  schema,
  reportInputValue,
  currentValue = {},
  currentPath,
}) => {
  const reportObjectInputValue = (id, value) => {
    if (id === undefined) {
      reportInputValue(schema.id, {
        type: 'static',
        value: { ...currentValue, ...value },
      });
    } else {
      reportInputValue(schema.id, {
        type: 'static',
        value: { ...currentValue, [id]: value },
      });
    }
  };

  const getCurrentChildValue = (childId) => {
    return currentValue[childId];
  };

  return renderChildren({
    children: schema.children,
    reportInputValue: reportObjectInputValue,
    getCurrentValue: getCurrentChildValue,
    currentPath,
    parentId: schema.id,
  });
};

export default withCoreInputBehavior(ObjectInput);
