import localforage from 'localforage';

export const store = localforage.createInstance({
  name: 'intersect_store',
});

export const setValue = async (key, value) => {
  try {
    await store.setItem(key, value);
  } catch (err) {
    console.log('LocalForageStore: Set value error', err);
  }
};

export const getValue = (key) => {
  return store.getItem(key);
};
