import React from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import {
  AddStepMenuModal,
  MiniAddStepButton,
} from '../../block-addition-components/AddStepMenuModal';
import { useState } from 'react';
import { PurpleButton } from '../../../standard-components/CustomColorButton';
import { useDraftStepExists } from '../../../model/DataAppStepsContextHelpers';

const firstIndent = 0;
const secondIndent = 26;
const STEP_CONNECTOR_LINE_LEFT_MARGIN = `${firstIndent}px`;
const OUTPUT_CONNECTOR_LINE_LEFT_MARGIN = `${firstIndent + secondIndent}px`;
const ConnectorLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${(p) => (p.isBeforeFirstStep ? 'flex-start' : 'flex-end')};
  opacity: ${(p) => (p.invisible ? 0 : 1)};

  margin-left: ${({ isOutput }) =>
    isOutput
      ? OUTPUT_CONNECTOR_LINE_LEFT_MARGIN
      : STEP_CONNECTOR_LINE_LEFT_MARGIN};
  margin-top: ${(p) => p.isBeforeFirstStep && '-35px'};
  width: 1px;
  min-height: ${(p) => (p.isOutput || p.isBeforeFirstStep ? '25px' : '45px')};
  overflow: visible;
  background-color: ${({ theme: { color } }) => color.grayscale.faintGray4};
`;

const FullAddStepButton = styled(PurpleButton)`
  margin-bottom: -40px;
  margin-left: 70px;
`;

export default ({
  afterStepId,
  disabled: disabledProp = false,
  isOutput = false,
  invisible = false,
  showFullButton = false,
  isBeforeFirstStep = false,
}) => {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const draftStepExists = useDraftStepExists();
  const shouldBeDisabled = draftStepExists || disabledProp;

  let button = (
    <MiniAddStepButton
      disabled={shouldBeDisabled}
      shape="circle"
      size="small"
      data-btn-id="mid-pipeline-add-transform"
      onClick={() => setMenuIsVisible(!menuIsVisible)}
      className="intermediary-add-step-button"
    >
      <PlusOutlined />
    </MiniAddStepButton>
  );

  if (showFullButton) {
    button = (
      <FullAddStepButton
        shape="round"
        size="large"
        onClick={() => setMenuIsVisible(!menuIsVisible)}
        icon={<PlusOutlined />}
        disabled={shouldBeDisabled}
        id="main-add-step-button"
      >
        Add Step
      </FullAddStepButton>
    );
  }

  if (shouldBeDisabled) {
    button = (
      <Tooltip title="You haven't finished adding a step. Finish adding the step or delete it to add another step.">
        {button}
      </Tooltip>
    );
  }

  return (
    <ConnectorLine
      isOutput={isOutput}
      invisible={invisible}
      isBeforeFirstStep={isBeforeFirstStep}
    >
      {!invisible && !isOutput && (
        <>
          {button}

          <AddStepMenuModal
            afterStepId={afterStepId}
            alwaysvisible={false}
            onSelectComplete={() => setMenuIsVisible(false)}
            visible={menuIsVisible}
          />
        </>
      )}
    </ConnectorLine>
  );
};
