import React, { useState } from 'react';
import styled from 'styled-components';
import { Badge, Button, Card } from 'antd';
import { formatDistanceStrict, differenceInHours } from 'date-fns';
import PricingView from './PricingViewController';
import { PRICING_FLOWS } from './SubscriptionUtils';
import { useBillingInfo } from '../model/BillingContext';
import { isEmpty } from 'lodash';

const FreeTrialContainer = styled.div`
  margin-bottom: 20px;
`;

const FreeTrialMessage = styled.div`
  width: 226px;
  margin-bottom: 5px;
  font-size: 16px;
`;

const FreeTrialCard = styled(Card)`
  background-color: ${(p) => p.theme.color.grayscale.extraLightGray};
  color: ${(p) => (p.expired ? '#FF0000' : '#595959')};
`;

const SubscribeNowButton = styled(Button)``;

export default ({ style }) => {
  const [isPricingViewVisible, setIsPricingViewVisible] = useState(false);
  const {
    credit_card_details = {},
    subscription_details: currentSubscription = {},
  } = useBillingInfo();

  if (!isEmpty(credit_card_details)) return null;

  let content;

  if (
    currentSubscription &&
    currentSubscription.subscription_status === 'past_due'
  ) {
    content = 'Subscription Expired';
  } else if (
    currentSubscription &&
    currentSubscription.subscription_status === 'trialing'
  ) {
    const today = Date.now();
    const endDate = Date.parse(currentSubscription.current_period_end);
    const hoursRemaining = differenceInHours(endDate, today);

    let isExpired = false;
    let statusText = `${formatDistanceStrict(endDate, today, {
      unit: hoursRemaining < 24 ? 'hour' : 'day',
      roundingMethod: 'floor',
    })} left`;

    if (hoursRemaining < 0) {
      statusText = 'Expired';
      isExpired = true;
    }

    content = (
      <>
        <FreeTrialCard expired={isExpired} title={statusText} size="small">
          {isExpired && (
            <FreeTrialMessage> Your trial has expired</FreeTrialMessage>
          )}
          <SubscribeNowButton
            size="small"
            type="primary"
            shape="round"
            onClick={() => setIsPricingViewVisible(true)}
          >
            Subscribe Now
          </SubscribeNowButton>
        </FreeTrialCard>
      </>
    );
  } else {
    return null;
  }

  return (
    <FreeTrialContainer>
      <Badge.Ribbon text="Free Trial">{content}</Badge.Ribbon>
      <PricingView
        pricingFlow={PRICING_FLOWS.updateCreditCard}
        showModal={isPricingViewVisible}
        onClose={() => setIsPricingViewVisible(false)}
      />
    </FreeTrialContainer>
  );
};
