import { notification } from 'antd';
import { getAccessToken, refreshToken } from '../model/User';
import { get, isString } from 'lodash';
import { getValue, setValue } from '../model/LocalForageStore';
const { useEffect, useState } = require('react');

const API_PREFIX_BASE = process.env.REACT_APP_DAVINCI_ENDPOINT;
const API_PREFIX = `https://${API_PREFIX_BASE}`;
export const API_WEBSOCKET_PREFIX = `wss://${process.env.REACT_APP_DAVINCI_WEBSOCKETS_ENDPOINT}`;
export const DEFAULT_ERROR_MESSAGE =
  'Please check your internet connection or contact hello@intersectlabs.io';

const checkResponseSuccess = async (response) => {
  const body = (await response.json()) || {};

  if (response.status === 401) {
    const success = await refreshToken(body.detail);
    if (success) {
      return [undefined, undefined, true];
    }
  }

  if (response.status !== 200 && response.status !== 201) {
    const error = get(body, 'message.error') ? body.message : body.detail; // Since generic errors look like { message: 'Some error string' }
    return [undefined, error];
  }

  return [body];
};

const displayErrorNotification = ({
  message = '',
  errorNotification = true,
  duration = null,
}) => {
  let _message;
  if (isString(errorNotification)) {
    _message = errorNotification;
  } else if (isString(message)) {
    _message = message;
    // In case detail object was passed in with a message
  } else if (isString(get(message, 'message'))) {
    _message = get(message, 'message');
  }
  notification.error({
    duration, // By default, duration = null, meaning user must manually close it
    message: 'Something went wrong',
    description: _message || DEFAULT_ERROR_MESSAGE,
  });
};

const processError = (error, errorNotification) => {
  if (error) {
    errorNotification &&
      displayErrorNotification({
        message: error,
        errorNotification,
      });
    return error;
  }
  return undefined;
};

export const makeAPICall = async (config) => {
  const {
    endpoint,
    method = 'GET',
    body,
    headers,
    errorNotification,
    signal,
  } = config;

  try {
    const accessToken = await getAccessToken();
    let fullHeaders = {
      'Content-Type': 'application/json',
    };
    if (accessToken) fullHeaders.Authorization = `Bearer ${accessToken}`;
    fullHeaders = { ...fullHeaders, ...headers };

    const result = await fetch(`${API_PREFIX}${endpoint}`, {
      method: method.toUpperCase(),
      body: body && JSON.stringify(body),
      headers: fullHeaders,
      signal,
    });
    const [fetchedValue, error, shouldRetry] = await checkResponseSuccess(
      result
    );
    // Access Token was refreshed so API call should be retried.
    if (shouldRetry) return await makeAPICall(config);

    processError(error, errorNotification);
    return [fetchedValue, error];
  } catch (err) {
    const error = {
      message: DEFAULT_ERROR_MESSAGE,
      fullError: err,
    };
    if (err.name !== 'AbortError') {
      processError(error, errorNotification);
    }
    return [undefined, error];
  }
};

export const CACHE_POLICY = {
  NETWORK: 'NETWORK',
  CACHE_THEN_NETWORK: 'CACHE_THEN_NETWORK',
};

export const STORE_KEY_API = 'intersect.api';

export const useAPI = ({
  suppress = false,
  cachePolicy = CACHE_POLICY.NETWORK,
  ...config
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [shouldRefetch, setShouldRefetch] = useState();

  const refetch = () => {
    setShouldRefetch(Date.now());
  };

  const forceLocalResultUpdate = (updatedResult) => {
    setResult(updatedResult);
    if (cachePolicy === CACHE_POLICY.CACHE_THEN_NETWORK) {
      setValue(`${STORE_KEY_API}.${config.endpoint}`, updatedResult);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const doFetch = async () => {
      try {
        if (cachePolicy === CACHE_POLICY.CACHE_THEN_NETWORK) {
          const cachedResult = await getValue(
            `${STORE_KEY_API}.${config.endpoint}`
          );
          setResult(cachedResult);
        }
        setIsLoading(true);

        const [fetchedValue, fetchError] = await makeAPICall({
          ...config,
          signal,
        });
        setResult(fetchedValue);

        const operationWasAborted =
          get(fetchError, 'fullError.name') === 'AbortError';
        if (!operationWasAborted) {
          setError(fetchError);

          if (!fetchError && cachePolicy === CACHE_POLICY.CACHE_THEN_NETWORK) {
            setValue(`${STORE_KEY_API}.${config.endpoint}`, fetchedValue);
          }
        }
      } catch (err) {
        console.error('useAPI error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    if (!suppress) {
      doFetch();
    }

    return () => {
      // Abort the old fetch call if we're going to do a new one
      controller.abort();
    };
  }, [config.endpoint, shouldRefetch]);

  return [result, { isLoading, error, refetch, forceLocalResultUpdate }];
};
