import styled from 'styled-components';
import { withCoreInputBehavior } from './CoreInputHOC';

const TextSegment = styled.span`
  margin-right: 8px;
  font-weight: ${(p) =>
    p.shouldShowPublishedVersion && p.theme.typography.fontWeight.light};
`;

export default withCoreInputBehavior(
  ({ shouldShowPublishedVersion, children }) => {
    return (
      <TextSegment shouldShowPublishedVersion={shouldShowPublishedVersion}>
        {children}
      </TextSegment>
    );
  }
);
