import React from 'react';
import { withCoreInputBehavior } from './CoreInputHOC';
import { renderChildren } from './SentenceRendererCore';

const SentenceContainerInput = ({
  schema,
  reportInputValue,
  getCurrentValue,
  disabled,
  currentPath,
}) => {
  if (schema.children) {
    return renderChildren({
      children: schema.children,
      reportInputValue,
      getCurrentValue,
      currentPath,
      disabled,
      parentId: schema.id,
      renderAsSibling: true,
    });
  }

  return <></>;
};

export default withCoreInputBehavior(SentenceContainerInput);
