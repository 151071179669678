import PricingView from '../checkout-components/PricingView';

const basePlans = {
  prod_JvSC3AowtArnq4: {
    name: 'Lite',
    description: null,
    unit_label: null,
    monthly_price: {
      amount: '7500',
      id: 'price_1JHbI6Kk4xi90w7RSSX8XCm0',
    },
    annual_price: {
      amount: '75000',
      id: 'price_1JHbI6Kk4xi90w7RasqEb3Md',
    },
  },
  prod_JvSDWTwcuTOIRA: {
    name: 'Team',
    description: null,
    unit_label: null,
    monthly_price: {
      amount: '15000',
      id: 'price_1JHbIvKk4xi90w7RKoupE2fj',
    },
    annual_price: {
      amount: '150000',
      id: 'price_1JHbIvKk4xi90w7RESmK9MLJ',
    },
  },
  prod_JvSEjhuUINaPP5: {
    name: 'Professional',
    description: null,
    unit_label: null,
    monthly_price: {
      amount: '50000',
      id: 'price_1JHbJdKk4xi90w7RuMUiRWDj',
    },
    annual_price: {
      amount: '500000',
      id: 'price_1JHbJdKk4xi90w7RKDA6oZBv',
    },
  },
};

export default () => {
  const onProductSelect = (_, priceId) => {
    window.open(
      `https://studio.intersectlabs.io/join-us?utm_content=/pricing&plan=${priceId}`,
      '_blank'
    );
  };

  return (
    <PricingView
      available_plans={basePlans}
      onProductSelect={onProductSelect}
      currentSubscriptionDetails={undefined}
      actionButtonText="Start Free Trial"
    />
  );
};
