/* eslint-disable import/no-anonymous-default-export */
import React, { useContext } from 'react';
import StepContext from '../../StepContext';
import { SentenceInput } from '../SentenceRenderers/SentenceRendererCore';

const SUBTYPE_TO_RENDERER_MAPPING = {};

export default () => {
  const { subtype } = useContext(StepContext);
  const Renderer = SUBTYPE_TO_RENDERER_MAPPING[subtype];

  let renderedInput = <SentenceInput />;
  if (Renderer) renderedInput = <Renderer />;
  return <>{renderedInput}</>;
};
