import React, { useState } from 'react';
import { Input, Form, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

const PasswordInputSSH = styled(Input)`
  width: 410px;
  margin: 10px 0px 0px 0px;
`;

const PrivateKeyTextArea = styled(TextArea)`
  height: 150px;
  margin-top: 15px;
`;

export default ({ namePath = [] }) => {
  const password_field_key = `password`;
  const private_key_field_key = `private_key`;
  const [currentCredentialType, setCurrentCredentialType] = useState(
    password_field_key
  );

  return (
    <>
      <Radio.Group
        onChange={(e) => setCurrentCredentialType(e.target.value)}
        value={currentCredentialType}
        defaultValue={password_field_key}
      >
        <Radio.Button value={password_field_key}>Password</Radio.Button>
        <Radio.Button value={private_key_field_key}>Private Key</Radio.Button>
      </Radio.Group>
      {currentCredentialType === password_field_key ? (
        <Form.Item name={[...namePath, password_field_key]}>
          <PasswordInputSSH
            name={password_field_key}
            placeholder="Shhhhhh..."
          />
        </Form.Item>
      ) : (
        <Form.Item name={[...namePath, private_key_field_key]}>
          <PrivateKeyTextArea placeholder="Private key..." rows={4} />
        </Form.Item>
      )}
    </>
  );
};
