import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { useCurrentInstanceRunStatus } from '../model/DataAppMetadataContextHelpers';
import { message } from 'antd';

const LoadingContainer = styled.div`
  justify-content: center;
  width: fit-content;
  background-color: white;
  flex-direction: column;
  border-radius: 0px 0px 20px 20px;
  border-top: 0px;
  color: ${(p) => p.theme.color.primary.dark};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 1px 8px rgba(0, 0, 0, 0.05);
`;

const StatusContentContainer = styled.div`
  display: flex;
  padding: 10px 20px;
`;

const StatusText = styled.div`
  margin-left: 1em;
`;

const ErrorStatusText = styled(StatusText)`
  color: ${(p) => p.theme.color.primary.blackout};
`;

const ErrorIcon = styled(CloseCircleFilled)`
  color: ${(p) => p.theme.color.info.error};
  font-size: 22px;
`;

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 22px;
  color: ${(p) => p.theme.color.info.success};
`;

const NotDoneIcon = styled(LoadingOutlined)`
  font-size: 22px;
`;

const Loading = ({ title = 'Running App' }) => {
  return (
    <>
      <LoadingIcon />
      <StatusText>{title}</StatusText>
    </>
  );
};

const LoadingFailure = () => {
  return (
    <>
      <ErrorIcon />
      <ErrorStatusText>
        An error popped up while running the app!
      </ErrorStatusText>
    </>
  );
};

const LoadingNotDone = () => {
  return (
    <>
      <NotDoneIcon />
      <StatusText>Waiting for user input</StatusText>
    </>
  );
};

const LoadingOptions = {
  FAILURE: <LoadingFailure />,
  NOT_DONE: <LoadingNotDone />,
  RUNNING: <Loading />,
};

export const RunStatusDisplay = () => {
  const status = useCurrentInstanceRunStatus();
  const statusIsSuccess = status === 'SUCCESS';

  useEffect(() => {
    if (status === 'SUCCESS') {
      message.success('Done Running!', 1);
    }
  }, [status]);

  if (statusIsSuccess || !status) return <></>;

  return (
    <LoadingContainer>
      <StatusContentContainer>{LoadingOptions[status]}</StatusContentContainer>
    </LoadingContainer>
  );
};
