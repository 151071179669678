import React, { useState } from 'react';
import styled from 'styled-components';
import { useAddingBlockCategoryPath } from '../../model/StudioPageContext';
import { get } from 'lodash';
import { useAddDraftStep } from '../../model/DataAppStepsContextHelpers';
import { useContext } from 'react';
import LoadingSplash from '../../standard-components/LoadingSplash';
import BlockCard from './BlockCard';
import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import BlockCategoryExplorer from './BlockCategoryExplorer';
import {
  BlocksContext,
  featuredBlocks,
  shouldShowBlockChecker,
  useBlockGalleryURL,
} from '../../model/BlocksContext';
import fallbackIcon from '../../images/fallback-icon.svg';
import blockIcons from '../../images/blockIcons';
import { LightBlueButton } from '../../standard-components/CustomColorButton';
import { QuestionCircleOutlined } from '@ant-design/icons';

const AddBlockDrawerWrapper = styled.div`
  min-width: 100%;
  min-height: 530px;
  border-radius: 8px;
  overflow: hidden;
  background: ${(p) => p.theme.color.grayscale.extraLightGray};
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  color: ${(p) => p.theme.color.grayscale.dark};
`;

const MoreInfoForBlockButton = ({ operation }) => {
  const blockGalleryURL = useBlockGalleryURL(operation);

  if (!blockGalleryURL) return <></>;

  return (
    <LightBlueButton
      href={blockGalleryURL}
      target="_blank"
      icon={<QuestionCircleOutlined />}
    >
      Learn More
    </LightBlueButton>
  );
};

const AddBlockMenu = ({
  addingBlockAfterStepId,
  onSelectComplete = () => {},
  alwaysvisible = false,
}) => {
  const {
    addingBlockCategoryPath,
    updateAddingBlockCategoryPath,
  } = useAddingBlockCategoryPath();
  const [filterPath, updateFilterPath] = useImmer([]);

  const {
    blocks,
    blocksAreLoading,
    pathsToBlocks,
    pathsToFeaturedBlocks,
  } = useContext(BlocksContext);
  const isOpen = !!addingBlockCategoryPath;

  useEffect(() => {
    if (isOpen) {
      updateFilterPath(() => [addingBlockCategoryPath]);
    }
  }, [addingBlockCategoryPath]);

  const addDraftStep = useAddDraftStep();
  const close = () => {
    setSearchFilter();
    updateAddingBlockCategoryPath(null);
  };
  const [searchFilter, setSearchFilter] = useState();

  let transformsToShow = pathsToBlocks[filterPath] || [];
  let featuredTransformsToShow = pathsToFeaturedBlocks[filterPath] || [];
  if (filterPath.length === 0) {
    transformsToShow = blocks;
    featuredTransformsToShow = Object.keys(featuredBlocks);
  }
  transformsToShow = transformsToShow.filter(
    shouldShowBlockChecker(searchFilter)
  );
  if (searchFilter && searchFilter.length > 0) {
    featuredTransformsToShow = [];
  }

  const closeModalAndAddTransform = (transform) => {
    updateFilterPath(() => []);
    addDraftStep(transform, addingBlockAfterStepId);
    close();
    onSelectComplete();
  };

  const getTransformCard = (
    transform,
    limitSubtitleToTwoLines,
    keySuffix = ''
  ) => {
    const title = get(transform, 'schema.title', 'Transform');
    const subtitle = get(transform, 'schema.description');
    const icon = (
      <img alt={title} src={blockIcons[transform.block_icon] || fallbackIcon} />
    );
    return (
      <BlockCard
        key={transform.id + keySuffix}
        title={title}
        subtitle={subtitle}
        icon={icon}
        onAdd={() => closeModalAndAddTransform(transform)}
        moreInfoButton={
          <MoreInfoForBlockButton operation={transform.operation} />
        }
        boldTitle={false}
        limitSubtitleToTwoLines={limitSubtitleToTwoLines}
      />
    );
  };

  let transformCards = transformsToShow.map(getTransformCard);
  let featuredTransformCards = featuredTransformsToShow.map(
    (featuredTransformId, index) => {
      const transform = transformsToShow.find(
        (transform) => featuredTransformId === transform.id
      );
      if (transform) {
        return getTransformCard(transform, true, 'featured');
      }

      return (
        <React.Fragment key={`noMatchingTransform${index}`}></React.Fragment>
      );
    }
  );

  if (transformCards.length % 2 === 1)
    transformCards.push(
      <div key="spacer" style={{ margin: '0px 30px 30px' }} />
    );

  return (
    <AddBlockDrawerWrapper alwaysvisible={alwaysvisible}>
      {blocksAreLoading && false ? (
        <LoadingSplash />
      ) : (
        <Body>
          <BlockCategoryExplorer
            allPossiblePaths={Object.keys(pathsToBlocks)}
            filterPath={filterPath}
            updateFilterPath={updateFilterPath}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            transformCardsForPath={transformCards}
            featuredTransformCardsForPath={featuredTransformCards}
          />
        </Body>
      )}
    </AddBlockDrawerWrapper>
  );
};

export default AddBlockMenu;
