import React from 'react';
import { Input, Select } from 'antd';
import styled from 'styled-components';
import { withCoreInputBehavior } from './CoreInputHOC';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';

const StyledStringInput = styled(Input)`
  width: 200px;
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const TagSelect = styled(Select)`
  width: 250px;
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const StringInput = ({
  schema,
  reportInputValue,
  currentValue,
  disabled,
  size,
}) => {
  const onNewValue = (value) => {
    reportInputValue(schema.id, {
      type: 'static',
      value,
    });
  };

  const onNewStringValue = (e) => onNewValue(e.currentTarget.value);

  if (schema.allow_multiple) {
    return (
      <TagSelect
        disabled={disabled}
        placeholder={schema.placeholder_value}
        value={currentValue}
        onChange={onNewValue}
        mode="tags"
        size={size}
        tokenSeparators={[',']}
        notFoundContent={
          <div>Add additional items by pressing enter or adding a comma</div>
        }
      />
    );
  }

  return (
    <StyledStringInput
      disabled={disabled}
      placeholder={schema.placeholder_value}
      value={currentValue || schema.default_value}
      onChange={onNewStringValue}
      size={size}
    />
  );
};

export default withCoreInputBehavior(StringInput);
