import React from 'react';
import { makeAPICall } from '../api/useAPI';
import { Menu, Modal, message } from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router';

export default (props) => {
  const { appId, onAppDeleteComplete, teamId } = props;
  const history = useHistory();
  const DeleteApp = async () => {
    const hideLoadingMessage = message.loading('Deleting app...', 0);
    const [deletedApp, error] = await makeAPICall({
      method: 'POST',
      endpoint: `/app/delete?app_id=${appId}`,
      errorNotification: true,
    });
    hideLoadingMessage();
    if (error) {
      message.warn('Error deleting app, please try again');
    } else {
      message.success('App deleted');
      onAppDeleteComplete(appId);
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this app?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        DeleteApp();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const DuplicateApp = async () => {
    const hideLoadingMessage = message.loading('Duplicating app...', 0);
    const data = {
      app_id_to_duplicate: appId,
      team_id: teamId,
    };
    const [duplicatedApp, error] = await makeAPICall({
      method: 'POST',
      endpoint: `/app/duplicate?app_id=${appId}`,
      body: data,
    });
    hideLoadingMessage();
    if (error) {
      if (error.status_code === 500) {
        message.warn(
          'You must first publish the app before you can duplicate it'
        );
      } else {
        message.warn('Error duplicating app, please try again');
      }
    } else {
      message.success('Duplicated');
      const duplicatedAppId = duplicatedApp.id;
      history.push(`/apps/${duplicatedAppId}`);
    }
  };

  const showAppDuplicateConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to duplicate this app?',
      icon: <CopyOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        DuplicateApp();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const MenuFunctions = {
    DeleteApp: showDeleteConfirm,
    DuplicateApp: showAppDuplicateConfirm,
  };
  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
    const functionName = e.key;
    MenuFunctions[functionName]();
  };

  return (
    <Menu {...props} onClick={handleMenuClick}>
      <Menu.Item danger key="DeleteApp" icon={<DeleteOutlined />}>
        Delete App
      </Menu.Item>
      <Menu.Item key="DuplicateApp" icon={<CopyOutlined />}>
        Duplicate App
      </Menu.Item>
    </Menu>
  );
};
