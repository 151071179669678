import React from 'react';
import { Input, Modal, Select, Space } from 'antd';
import {
  PublishedComponentPanel,
  PublishedComponentPanelTitle,
} from '../../PublishedDashboardPanel';
import styled from 'styled-components';
import {
  DarkBlueButton,
  DarkDullBlueButton,
  LightBlueButton,
} from '../../../standard-components/CustomColorButton';
import {
  RunSchedulingContextProvider,
  TIME_UNITS,
  useAddScheduledJob,
  useDeleteScheduledJob,
  useEditScheduledJob,
  useExistingJob,
  useIsEditModalOpen,
  useRunScheduling,
  useSetTimeUnitAndTimeInterval,
  useTimeInterval,
  useTimeUnit,
} from '../../../model/RunSchedulingContext';
import { format } from 'date-fns';
import { DayOfWeekSelector } from './DayOfWeekSelector';
import StartDateSelector from './StartDateSelector';
import { ClockCircleFilled } from '@ant-design/icons';

export const BottomPaddedSubSection = styled(Space)`
  margin-bottom: 12px;
`;

const SchedulingInfoContainer = styled.div`
  margin: 20px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
`;

const NextScheduledRunLabel = styled.div`
  color: ${(p) => p.theme.color.grayscale.white};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  text-align: center;
  width: 100%;
`;

const pluralize = (nonPluralWord, count) =>
  +count === 1 ? nonPluralWord : `${nonPluralWord}s`;

export const Z_INDEX_BIGGER_THAN_MODAL = 21474837499;
const INPUT_HEIGHT = 32;

const SchedulingOptions = () => {
  const r = useRunScheduling();
  console.log(r);
  const timeUnit = useTimeUnit();
  const timeInterval = useTimeInterval();
  const isWeekdaysUnit = timeUnit === TIME_UNITS.weekday;

  const setTimeUnitAndTimeInterval = useSetTimeUnitAndTimeInterval();

  const handleTimeUnitIntervalChange = ({ target: { value } }) => {
    if (value === '' || (!isNaN(value) && value > 0)) {
      setTimeUnitAndTimeInterval(timeUnit, value);
    }
  };

  const handleChangeTimeUnit = (selectedTimeUnit) => {
    setTimeUnitAndTimeInterval(
      selectedTimeUnit,
      selectedTimeUnit === TIME_UNITS.weekday ? '' : timeInterval
    );
  };

  return (
    <div style={{ padding: 20 }}>
      <BottomPaddedSubSection direction="horizontal">
        Repeat every
        {!isWeekdaysUnit && (
          <Input
            style={{ width: 60, height: INPUT_HEIGHT }}
            value={timeInterval}
            defaultValue={timeInterval}
            onChange={handleTimeUnitIntervalChange}
            placeholder="#"
          />
        )}{' '}
        <Select
          showSearch
          defaultValue={timeUnit}
          dropdownStyle={{ zIndex: Z_INDEX_BIGGER_THAN_MODAL }}
          value={timeUnit}
          style={{ width: 140, height: INPUT_HEIGHT }}
          optionFilterProp="children"
          onChange={handleChangeTimeUnit}
          placeholder="hours, days, etc."
        >
          {Object.keys(TIME_UNITS).map((humanReadableUnit) => (
            <Select.Option
              key={humanReadableUnit}
              value={TIME_UNITS[humanReadableUnit]}
            >
              {pluralize(humanReadableUnit, timeInterval)}
            </Select.Option>
          ))}
        </Select>
      </BottomPaddedSubSection>
      {isWeekdaysUnit && (
        <BottomPaddedSubSection>
          <BottomPaddedSubSection>on the weekday(s)</BottomPaddedSubSection>
          <DayOfWeekSelector />
        </BottomPaddedSubSection>
      )}
      <StartDateSelector />
    </div>
  );
};

const SaveRunScheduling = styled(DarkBlueButton)`
  margin: 0px 20px 20px;
  height: 45px;
`;

const DeleteRunScheduling = styled(LightBlueButton)`
  margin: 0 20px 20px 20px;
  height: 45px;
`;

const EditScheduling = styled(DarkBlueButton)`
  height: 45px;
  width: 100%;
`;

const EditSchedulingTitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  text-align: center;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const EditSchedulingButton = ({ edit }) => {
  const [isEditModalOpen, toggleIsEditModalOpen] = useIsEditModalOpen();
  return (
    <div>
      <EditScheduling
        size="large"
        icon={<ClockCircleFilled />}
        onClick={toggleIsEditModalOpen}
        id="schedule-run-button"
      >
        {edit ? 'Edit' : 'Schedule Runs'}
      </EditScheduling>
    </div>
  );
};

const AddDeleteButtons = () => {
  const { addScheduledJob, jobReadyToSubmit } = useAddScheduledJob();
  const { editScheduledJob } = useEditScheduledJob();
  const { deleteScheduledJob, noJobExists } = useDeleteScheduledJob();
  return (
    <ActionButtonsWrapper>
      <SaveRunScheduling
        disabled={!jobReadyToSubmit}
        onClick={noJobExists ? addScheduledJob : editScheduledJob}
      >
        {noJobExists ? 'Save' : 'Update'}
      </SaveRunScheduling>
      <DeleteRunScheduling disabled={noJobExists} onClick={deleteScheduledJob}>
        Delete Scheduled Run
      </DeleteRunScheduling>
    </ActionButtonsWrapper>
  );
};

const EditModal = () => {
  const [isEditModalOpen, toggleIsEditModalOpen] = useIsEditModalOpen();
  return (
    <Modal
      title={<EditSchedulingTitle>Edit Scheduling</EditSchedulingTitle>}
      visible={isEditModalOpen}
      onCancel={toggleIsEditModalOpen}
      destroyOnClose
      footer={null}
    >
      <SchedulingOptions />
      <AddDeleteButtons />
    </Modal>
  );
};

const SchedulingInfo = () => {
  const { next_run_time } = useExistingJob() || {};
  const jobExists = !!next_run_time;
  return (
    <SchedulingInfoContainer>
      {jobExists && (
        <BottomPaddedSubSection align="center" direction="vertical">
          <NextScheduledRunLabel>
            Next Scheduled Run: {format(new Date(next_run_time), 'PPp')}
          </NextScheduledRunLabel>
        </BottomPaddedSubSection>
      )}
      <EditSchedulingButton edit={jobExists} />
    </SchedulingInfoContainer>
  );
};

export default () => {
  return (
    <RunSchedulingContextProvider>
      <SchedulingInfo />
      <EditModal />
    </RunSchedulingContextProvider>
  );
};
