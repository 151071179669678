import React, { createContext, useContext } from 'react';
import { useImmer } from 'use-immer';
import { singleStepTypeBlockInfo } from './blockTypes';
import { useAddDraftStep } from './DataAppStepsContextHelpers';

const StudioPageContext = createContext({});

export const StudioPageContextProvider = ({ children }) => {
  const [studioPageContextValue, updateStudioPageContextValue] = useImmer({
    uiState: {
      addingBlockCategoryPath: null,
    },
  });

  return (
    <StudioPageContext.Provider
      value={{ ...studioPageContextValue, updateStudioPageContextValue }}
    >
      {children}
    </StudioPageContext.Provider>
  );
};

export const useUiState = () => {
  const { uiState } = useContext(StudioPageContext);
  return uiState || {};
};

const shouldAddStepDirectly = (type) =>
  Object.keys(singleStepTypeBlockInfo).includes(type);

export const useAddingBlockCategoryPath = () => {
  const { addingBlockCategoryPath, addingBlockAfterStepId } = useUiState();
  const { updateStudioPageContextValue } = useContext(StudioPageContext);
  const addDraftStep = useAddDraftStep();
  return {
    addingBlockCategoryPath,
    addingBlockAfterStepId,
    updateAddingBlockCategoryPath: (newAddBlockDrawerType, afterStepId) => {
      if (shouldAddStepDirectly(newAddBlockDrawerType)) {
        addDraftStep(
          singleStepTypeBlockInfo[newAddBlockDrawerType],
          afterStepId
        );
      } else {
        updateStudioPageContextValue((draftState) => {
          draftState.uiState.addingBlockCategoryPath = newAddBlockDrawerType;
          draftState.uiState.addingBlockAfterStepId = afterStepId;
        });
      }
    },
  };
};
