import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE, makeAPICall } from '../api/useAPI';
import 'localforage-observable';
import Observable from 'zen-observable';
import { get } from 'lodash';
import { store } from './LocalForageStore';

window.Observable = Observable;

export const STORE_KEY_USER = 'intersect.user';

const storeUserInfo = async (user) => {
  try {
    await store.setItem(STORE_KEY_USER, user);
  } catch (err) {
    console.log('Set user info error', err);
  }
};

export const getUserInfo = () => {
  return store.getItem(STORE_KEY_USER);
};

export const getAccessToken = async () => {
  const userInfo = await getUserInfo();
  return get(userInfo, 'tokens.access_token');
};

export const getUserEmail = async () => {
  const userInfo = await getUserInfo();
  return get(userInfo, 'email');
};

export const withAuthHOC = (ChildComponent) => {
  return class AuthAwareComponent extends React.Component {
    state = {
      loading: true,
      isSignedIn: undefined,
      userInfo: undefined,
    };

    setUserInfoSubscription = (subscription) => {
      this.subscription = subscription;
    };

    componentDidMount() {
      const that = this;
      const subscribeToUserInfo = async () => {
        try {
          await store.ready();
          var userInfoObservable = store.getItemObservable(STORE_KEY_USER);
          const subscription = userInfoObservable.subscribe({
            next: function (value) {
              that.setState({
                loading: false,
                userInfo: value,
                isSignedIn: get(value, 'tokens.access_token') !== undefined,
              });
            },
          });

          that.setUserInfoSubscription(subscription);
        } catch (err) {
          console.log('userinfo subscription error', err);
        }
      };

      subscribeToUserInfo();
    }
    componentWillUnmount() {
      this.subscription.unsubscribe();
    }

    render() {
      const { loading, isSignedIn, userInfo } = this.state;

      return (
        <ChildComponent
          {...this.props}
          isAuthLoading={loading}
          isSignedIn={isSignedIn}
          userInfo={userInfo}
        />
      );
    }
  };
};

export const withIsSignedInHOC = (ChildComponent) =>
  withAuthHOC(
    class extends React.Component {
      state = {
        loading: true,
        isSignedIn: false,
        userInfo: undefined,
      };

      shouldComponentUpdate(nextProps) {
        return nextProps.isSignedIn !== this.props.isSignedIn;
      }

      render() {
        return (
          <ChildComponent
            {...this.props}
            isLoadingSignedInState={this.props.isAuthLoading}
            isSignedIn={this.props.isSignedIn}
          />
        );
      }
    }
  );

export const withAccessTokenHOC = (ChildComponent) =>
  withAuthHOC(
    class extends React.Component {
      shouldComponentUpdate(nextProps) {
        const currentAccessToken = get(
          this.props,
          'userInfo.tokens.access_token'
        );
        const nextAccessToken = get(nextProps, 'userInfo.tokens.access_token');
        return currentAccessToken !== nextAccessToken;
      }

      render() {
        return (
          <ChildComponent
            {...this.props}
            isAccessTokenLoading={this.props.isAuthLoading}
            accessToken={get(this.props, 'userInfo.tokens.access_token')}
          />
        );
      }
    }
  );

export const fetchUserAndToken = async ({
  setLoading,
  forceRefresh = false,
  name,
  team_name,
  team_id = null,
  userInfoCallback,
  pricing_plan,
}) => {
  setLoading(true);
  let user = firebase.auth().currentUser;
  const idToken = await user.getIdToken(forceRefresh);
  let tokenEndpointWithPricePlanIncluded = `/user/tokens/`; // Trailing slash MUST be there
  if (pricing_plan) {
    tokenEndpointWithPricePlanIncluded += `?pricing_plan=${pricing_plan}`;
  }
  const [result, error] = await makeAPICall({
    endpoint: tokenEndpointWithPricePlanIncluded,
    method: 'POST',
    body: {
      firebase_token: idToken,
      name,
      email: user.email,
      team_name,
      team_id,
    },
  });

  if (!error && result) {
    const { user, tokens } = result;
    const userInfo = {
      ...user,
      tokens,
    };

    if (userInfoCallback) {
      userInfoCallback(userInfo);
    }
    storeUserInfo(userInfo);
  } else {
    console.error('Fetch user token error', error);
    notification.error({
      message: "Couldn't log you in (token001)",
      description: DEFAULT_ERROR_MESSAGE,
    });
  }

  setLoading(false);
};

export const refreshToken = async (detail = {}) => {
  if (detail.code === 'REFRESH_TOKEN_INVALID') {
    logout();
    return false;
  }

  const userInfo = await getUserInfo();
  const refreshToken = get(userInfo, 'tokens.refresh_token');
  if (!refreshToken) {
    logout();
    return false;
  }

  const [newAccessToken, error] = await makeAPICall({
    endpoint: '/user/tokens/refresh',
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  if (!error) {
    await storeUserInfo({
      ...userInfo,
      tokens: { ...userInfo.tokens, access_token: newAccessToken },
    });
    return true;
  } else {
    logout();
    return false;
  }
};

export const logout = () => {
  console.log('Logging out...');
  firebase.auth().signOut();
  store.clear();
};
