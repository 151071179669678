import { Button } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { API_PREFIX, makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { CardContainer } from '../standard-components/containers';
import LoadingSplash from '../standard-components/LoadingSplash';
import { SectionTitle } from '../standard-components/typography';

const Container = styled(CardContainer)`
  min-width: 530px;
  margin: auto;
  width: 50vw;
  height: 200px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;

export default ({ integrationId }) => {
  const teamId = useTeamId();
  const [integrationData, setIntegrationData] = useState();
  const [loading, setLoading] = useState(false);
  const onAuthorizeClick = async () => {
    const [endpoint, error] = await makeAPICall({
      endpoint: `/oauth2/url?integration_name=NOTION&team_id=${teamId}`,
      redirect: 'follow',
    });

    if (endpoint && !error) {
      window.location.href = endpoint;
    }
  };

  useEffect(() => {
    const fetchIntegrationData = async () => {
      const [result, error] = await makeAPICall({
        endpoint: `/integration/?integration_id=${integrationId}`,
      });

      setIntegrationData(result);
      setLoading(false);
    };

    if (integrationId) {
      setLoading(true);
      fetchIntegrationData();
    }
  }, [integrationId]);

  if (loading) {
    return <LoadingSplash />;
  }

  if (integrationData) {
    return (
      <Container>
        <SectionTitle>{integrationData.name} is connected.</SectionTitle>
      </Container>
    );
  }

  return (
    <Container>
      <SectionTitle>
        Let Intersect Labs get data from Notion when you add a Notion
        block to your apps:
      </SectionTitle>
      <Button onClick={onAuthorizeClick} size="large" type="primary">
        Authorize Intersect Labs
      </Button>
    </Container>
  );
};
