import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const HomeButton = styled(Button).attrs(() => ({
  shape: 'round',
}))`
  margin-left: -22px;
  padding-left: 25px;
`;

const LightHomeButton = styled(HomeButton)`
  color: ${(p) => p.theme.color.tertiary.dullBorder};
  border-color: ${(p) => p.theme.color.tertiary.dullBorder};
`;

const DarkHomeIcon = styled(HomeOutlined)`
  color: ${(p) => p.theme.color.grayscale.dark};
`;

const DarkHomeButton = styled(HomeButton)`
  background-color: ${(p) => p.theme.color.primary.darkest};
  border-color: ${(p) => p.theme.color.primary.darkest};
`;

export default ({ inDraftDashboard }) => {
  const history = useHistory();

  const goHome = () => history.push('/apps/');

  if (inDraftDashboard) {
    return (
      <LightHomeButton onClick={goHome}>
        <DarkHomeIcon />
      </LightHomeButton>
    );
  }

  return (
    <DarkHomeButton type="primary" onClick={goHome}>
      <HomeOutlined />
    </DarkHomeButton>
  );
};
