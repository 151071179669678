import React, { useEffect } from 'react';
import { makeAPICall } from '../../../../api/useAPI';
import { Button } from 'antd';
import { useStepInputValue } from '../../StepContext';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import {
  PublishedValueText,
  withCoreInputBehavior,
} from '../SentenceRenderers/CoreInputHOC';
import styled from 'styled-components';
import { get } from 'lodash';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';

const GoogleSpreadsheetInputWrapper = styled.div`
  display: inline-block;
`;

const ChooseButton = styled(Button)`
  margin: ${SENTENCE_INPUT_MARGIN};
  display: inline-block;
`;

const SelectedSpreadsheet = styled.div`
  padding: 5px 15px;
  border: 1px solid ${(p) => p.theme.color.grayscale.faintGray4};
  border-radius: 24px 0 0 24px;
  background-color: ${(p) => p.theme.color.grayscale.white};
  display: inline-block;
`;

const GoogleSpreadsheetInput = ({
  schema,
  reportInputValue,
  disabled,
  shouldShowPublishedVersion = false,
}) => {
  const googleAppId = '861821103764';
  const developerKey = 'AIzaSyB4I4ftzftEpbPmMEf5NGZcJE_SurIVBPM';
  const [accessToken, setAccessToken] = useState();
  const integrationInputId = schema.integration_input_id || 'integration_id';
  const spreadsheetNameInputId =
    schema.spreadsheet_name_input_id || 'spreadsheet_name';
  const selectedIntegrationId = get(
    useStepInputValue(integrationInputId),
    'value'
  );
  let spreadsheet_name = get(
    useStepInputValue(spreadsheetNameInputId),
    'value',
    ''
  );

  useEffect(() => {
    const fetchToken = async () => {
      const [token, error] = await makeAPICall({
        endpoint: `/oauth2/google/refresh?integration_id=${selectedIntegrationId}`,
        errorNotification:
          'Something went wrong while trying to load this Google Integration.',
      });

      if (token) {
        setAccessToken(token.access_token);
      }
    };

    if (selectedIntegrationId && !shouldShowPublishedVersion) {
      fetchToken();
    }
  }, [selectedIntegrationId]);

  useEffect(() => {
    if (shouldShowPublishedVersion) return;

    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/client:api.js?';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  if (shouldShowPublishedVersion) {
    return <PublishedValueText>{spreadsheet_name}</PublishedValueText>;
  }

  const loadPicker = () => {
    window.gapi.load('picker', { callback: createPicker });
  };

  const createPicker = () => {
    var picker = new window.google.picker.PickerBuilder()
      .addView(window.google.picker.ViewId.SPREADSHEETS)
      .setAppId(googleAppId)
      .setOAuthToken(accessToken)
      .setDeveloperKey(developerKey)
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      var { id, name } = data.docs[0];
      reportInputValue(undefined, {
        [schema.id]: {
          type: SCHEMA_INPUT_TYPE.STATIC,
          value: id,
        },
        [spreadsheetNameInputId]: {
          type: SCHEMA_INPUT_TYPE.STATIC,
          value: name,
        },
      });
    }
  };

  return (
    <GoogleSpreadsheetInputWrapper>
      {spreadsheet_name && (
        <SelectedSpreadsheet>{spreadsheet_name}</SelectedSpreadsheet>
      )}
      <ChooseButton
        type={'primary'}
        onClick={loadPicker}
        disabled={!accessToken || disabled}
      >
        {spreadsheet_name ? <EditOutlined /> : 'Choose Google Spreadsheet'}
      </ChooseButton>
    </GoogleSpreadsheetInputWrapper>
  );
};

export default withCoreInputBehavior(GoogleSpreadsheetInput);
