import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Modal, Input, Select, List, Empty, Divider } from 'antd';
import { SubSectionTitle } from '../../standard-components/typography';
import {
  useTeammates,
  useBillingInfoIsLoading,
} from '../../model/BillingContext';
import { Z_INDEX_BIGGER_THAN_MODAL } from '../published-components/RunScheduling/RunScheduling';
import { useTeamId, useTeamName } from '../../model/Team';
import { useAppId } from '../../model/DataAppMetadataContextHelpers';
import { useImmer } from 'use-immer';
import { makeAPICall } from '../../api/useAPI';
import { getUserEmail } from '../../model/User';
import {
  LoadingOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const SectionContainer = styled.div`
  border-radius: 8px;
  /* background-color: ${(p) => p.theme.color.secondary.lightest}; */
  /* border: 1px solid ${(p) => p.theme.color.secondary.lighter}; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TeamDefaultSectionContainer = styled.div`
  background-color: ${(p) => p.theme.color.secondary.darkest};
  padding: 25px;
  color: ${(p) => p.theme.color.grayscale.white};
  border: none;
  display: block;
  border-radius: 8px;
  margin: 20px 20px 0px;
`;

const Dropdown = styled(Select)`
  margin: 0px 8px;
`;

const TeamList = styled(List)`
  max-height: 360px;
  overflow: auto;
`;

const TeamListHeader = styled.div`
  padding: 10px 15px 0px;
`;

const Title = styled(SubSectionTitle)`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const ACCESS_LEVEL_OPTIONS = {
  OWNER: 0,
  EDITOR: 1,
  USER: 2,
  VIEWER: 3,
  NO_ACCESS: 4,
};

const displayAccessLevel = (accessLevel) =>
  accessLevel && accessLevel.toLowerCase().replace('_', ' ');

const AccessLevelDropdown = ({
  selectedAccess,
  onSelect,
  highestAccessEditAllowed,
  disableEntireDropdown = false,
  placeholder = undefined,
}) => {
  const { confirm } = Modal;

  const showConfirmForNoAccess = (selectedAccess) => {
    confirm({
      title: 'Are you sure you want to give no access to the data app?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onSelect(selectedAccess);
      },
      onCancel() {},
    });
  };

  return (
    <Dropdown
      value={selectedAccess}
      placeholder={placeholder || 'no access (default)'}
      disabled={disableEntireDropdown}
      onSelect={(selectedAccess) => {
        if (selectedAccess === 'NO_ACCESS') {
          showConfirmForNoAccess(selectedAccess);
        } else {
          onSelect(selectedAccess);
        }
      }}
      dropdownStyle={{ zIndex: Z_INDEX_BIGGER_THAN_MODAL }}
      style={{ width: 200 }}
    >
      {Object.keys(ACCESS_LEVEL_OPTIONS).map((accessLevel) => (
        <Select.Option
          key={accessLevel}
          value={accessLevel}
          disabled={
            ACCESS_LEVEL_OPTIONS[accessLevel] < highestAccessEditAllowed
          }
        >
          {displayAccessLevel(accessLevel)}
        </Select.Option>
      ))}
    </Dropdown>
  );
};

const TeammateShare = ({
  userAccessLevels,
  updateEntityAppAccess,
  teamAccessLevel,
  currentUserAccessLevel,
}) => {
  const [searchFilter, setSearchFilter] = useState('');

  const billingInfoIsLoading = useBillingInfoIsLoading();
  const teammates = useTeammates();
  const filteredTeamates = teammates.filter(
    ({ name }) =>
      !searchFilter ||
      name.toLowerCase().includes(searchFilter.toLowerCase().trim())
  );

  return (
    <SectionContainer>
      <TeamListHeader>
        <Divider>
          <Title>Share with specific teammates</Title>
        </Divider>
        <Input.Search
          placeholder={`Search for a teammate...`}
          onChange={(e) => setSearchFilter(e.target.value)}
          allowClear
          value={searchFilter}
        />
      </TeamListHeader>
      <TeamList
        size="large"
        loading={billingInfoIsLoading}
        locale={{ emptyText: <Empty description="No Team Members found." /> }}
        dataSource={filteredTeamates}
      >
        {filteredTeamates.map(({ name, email, id }) => (
          <TeamListItem
            key={id}
            id={id}
            name={name}
            email={email}
            updateEntityAppAccess={updateEntityAppAccess}
            userAccessLevels={userAccessLevels}
            currentUserAccessLevel={currentUserAccessLevel}
            teamAccessLevel={teamAccessLevel}
          />
        ))}
      </TeamList>
    </SectionContainer>
  );
};

const TeamListItem = ({
  name,
  id,
  email,
  updateEntityAppAccess,
  userAccessLevels,
  currentUserAccessLevel,
  teamAccessLevel,
}) => {
  const currentUserIsLessPrivileged =
    userAccessLevels[email] &&
    ACCESS_LEVEL_OPTIONS[currentUserAccessLevel] >
      ACCESS_LEVEL_OPTIONS[userAccessLevels[email]];

  const highestAccessEditAllowed = ACCESS_LEVEL_OPTIONS[currentUserAccessLevel];
  const placeholder =
    teamAccessLevel && `${displayAccessLevel(teamAccessLevel)} (team default)`;
  return (
    <List.Item>
      <List.Item.Meta title={name} description={email} />
      <AccessLevelDropdown
        onSelect={(access) =>
          updateEntityAppAccess({
            access,
            userId: id,
            userEmail: email,
          })
        }
        selectedAccess={userAccessLevels[email]}
        disableEntireDropdown={currentUserIsLessPrivileged}
        highestAccessEditAllowed={highestAccessEditAllowed}
        placeholder={placeholder}
      />
    </List.Item>
  );
};

const TeamText = styled.span`
  font-size: 16px;
`;

const TeamWideShare = ({
  teamAccessLevel,
  updateEntityAppAccess,
  currentUserAccessLevel,
}) => {
  const teamName = useTeamName();
  const teamId = useTeamId();
  const highestAccessEditAllowed = ACCESS_LEVEL_OPTIONS[currentUserAccessLevel];
  return (
    <TeamDefaultSectionContainer>
      <TeamText>By default everyone on team {teamName} should have </TeamText>
      <AccessLevelDropdown
        onSelect={(access) => updateEntityAppAccess({ access, teamId })}
        selectedAccess={teamAccessLevel}
        highestAccessEditAllowed={highestAccessEditAllowed}
      />
      <TeamText>access unless specified otherwise below.</TeamText>
    </TeamDefaultSectionContainer>
  );
};

const formatAccessInfoForFrontend = (fetchedAccessInfo, currentUserEmail) => {
  const userAccessLevels = fetchedAccessInfo.reduce(
    (accum, { access, email_address, team_id }) =>
      email_address ? { ...accum, [email_address]: access } : accum,
    {}
  );
  const teamAccessLevel = (
    fetchedAccessInfo.find(({ team_id }) => !!team_id) || {}
  ).access;

  const currentUserAccessLevel =
    (
      fetchedAccessInfo.find(
        ({ email_address }) => email_address === currentUserEmail
      ) || {}
    ).access || teamAccessLevel;

  return {
    userAccessLevels,
    teamAccessLevel,
    currentUserAccessLevel,
  };
};

const useAppAccessAPI = () => {
  // IMPLEMENTATION: on initial render load access info, then format it and save to local sate
  // Do optimistic updates by directly updating local state BEFORE making backend calls.
  const [appAccessInfo, updateAppAccessInfo] = useImmer({
    userAccessLevels: {},
    currentUserAccessLevel: null,
    teamAccessLevel: null,
  });
  const [isSaving, updateIsSaving] = useImmer(false);
  const app_id = useAppId();

  const loadAppAccess = async () => {
    const [fetchedAccessInfo, fetchError] = await makeAPICall({
      endpoint: `/app/access?app_id=${app_id}`,
    });
    const currentUserEmail = await getUserEmail();

    updateAppAccessInfo(() =>
      formatAccessInfoForFrontend(fetchedAccessInfo, currentUserEmail)
    );
  };

  useEffect(() => {
    loadAppAccess();
  }, []);

  const updateEntityAppAccess = async ({
    access,
    userId = undefined,
    userEmail = undefined,
    teamId = undefined,
  }) => {
    updateIsSaving(() => true);
    // Optimistic update:
    if (userId && userEmail) {
      updateAppAccessInfo((draftState) => {
        draftState.userAccessLevels[userEmail] = access;
      });
    }

    // API update:
    const [fetchedAccessInfo, error] = await makeAPICall({
      endpoint: `/app/access/edit?app_id=${app_id}`,
      method: 'POST',
      body: {
        access,
        user_ids: userId ? [userId] : undefined,
        team_id: teamId,
      },
    });
    const currentUserEmail = await getUserEmail();
    if (!error) {
      window.analytics.track('Shared App', { access });
      updateAppAccessInfo(() =>
        formatAccessInfoForFrontend(fetchedAccessInfo, currentUserEmail)
      );
    }
    updateIsSaving(() => false);
  };

  return {
    appAccessInfo,
    updateEntityAppAccess,
    isSaving,
  };
};

const SavingIndicator = styled.div`
  color: ${(p) => p.theme.color.grayscale.faintGray1};

  > span {
    margin-right: 8px;
  }
`;

export default ({ toggleAppSharingModal }) => {
  const { appAccessInfo, updateEntityAppAccess, isSaving } = useAppAccessAPI();
  const modalBodyStyle = {
    padding: 0,
  };

  let footerIcon = <LoadingOutlined />;
  let footerText = 'Saving...';
  if (!isSaving) {
    footerIcon = <CheckOutlined />;
    footerText = 'Saved';
  }
  const footer = (
    <Footer>
      <SavingIndicator>
        {footerIcon}
        {footerText}
      </SavingIndicator>
    </Footer>
  );
  return (
    <Modal
      title={<Title>Share App</Title>}
      visible={true}
      onCancel={toggleAppSharingModal}
      footer={footer}
      width={700}
      bodyStyle={modalBodyStyle}
    >
      <TeamWideShare
        teamAccessLevel={appAccessInfo.teamAccessLevel}
        updateEntityAppAccess={updateEntityAppAccess}
        currentUserAccessLevel={appAccessInfo.currentUserAccessLevel}
      />
      <TeammateShare
        userAccessLevels={appAccessInfo.userAccessLevels}
        updateEntityAppAccess={updateEntityAppAccess}
        teamAccessLevel={appAccessInfo.teamAccessLevel}
        currentUserAccessLevel={appAccessInfo.currentUserAccessLevel}
      />
    </Modal>
  );
};
