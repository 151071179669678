import React from 'react';
import { Button } from 'antd';
import { get, isString, set } from 'lodash';
import { useState } from 'react';
import { renderChildren } from './SentenceRendererCore';
import { DoubleLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SENTENCE_INPUT_MARGIN } from '../../../../standard-components/containers';
import { withCoreInputBehavior } from './CoreInputHOC';

const AddRemoveButton = styled(Button)`
  margin: ${SENTENCE_INPUT_MARGIN};
`;

const ArrayInput = ({
  schema,
  reportInputValue,
  currentValue = [],
  disabled,
  shouldShowPublishedVersion,
}) => {
  const [templateCount, setTemplateCount] = useState(currentValue.length || 1);

  const reportArrayInputValue = (id, value) => {
    const newArray = [...currentValue];
    set(newArray, [id], value);
    reportInputValue(schema.id, { type: 'static', value: newArray });
  };

  const removeArrayElement = (atIndex) => {
    const newArray = [...currentValue];
    newArray.splice(atIndex, 1);
    setTemplateCount(newArray.length);
    reportInputValue(schema.id, { type: 'static', value: newArray });
  };

  const getCurrentChildValue = (id) => get(currentValue, `[${id}]`);

  const addButton = (
    <AddRemoveButton
      key="add-button"
      onClick={() => setTemplateCount((count) => count + 1)}
      disabled={disabled}
    >
      ...and
    </AddRemoveButton>
  );

  if (schema.children) {
    const renderedTemplates = [];
    for (let index = 0; index < templateCount; index++) {
      const allChildren = [...schema.children];
      const processedChildren = allChildren.map((child) => {
        if (isString(child)) return child;

        return {
          ...child,
          schema: {
            ...child.schema,
            id: index,
          },
          id: index,
        };
      });

      renderedTemplates.push(
        renderChildren({
          children: processedChildren,
          reportInputValue: reportArrayInputValue,
          getCurrentValue: getCurrentChildValue,
          currentPath: schema.id,
          parentId: schema.id,
        })
      );

      if (index !== 0 && !shouldShowPublishedVersion) {
        const removeButton = (
          <AddRemoveButton
            key={`remove-button-${index}`}
            onClick={() => removeArrayElement(index)}
            disabled={disabled}
            icon={<DoubleLeftOutlined />}
            danger
            size="small"
            shape="circle"
          />
        );
        renderedTemplates.push(removeButton);
      }

      if (index !== templateCount - 1) {
        renderedTemplates.push(' and ');
      }
    }
    if (shouldShowPublishedVersion) {
      return [...renderedTemplates];
    }

    return [...renderedTemplates, addButton];
  } else {
    return <></>;
  }
};

export default withCoreInputBehavior(ArrayInput, true);
