import { Result, Spin, Button } from 'antd';
import { compareAsc } from 'date-fns';
import { get, isNull, isUndefined, set } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { DEFAULT_ERROR_MESSAGE, makeAPICall } from '../api/useAPI';
import { BLOCK_ICON_S3_PREFIX } from '../pages/BlockGallery';
import { browserNotificationPermissionNotReceieved } from '../settings-components/GeneralAppSettings';
import {
  useMetadata,
  getAppStatus,
  getVersionId,
  useAppId,
  APP_STATUS,
  getViewingAppAsPublic,
} from './DataAppMetadataContextHelpers';
import { fetchLatestStepVariableMetadata } from './DataAppVariableContextHelpers';
import { getValue, setValue } from './LocalForageStore';
import NotificationConnection from './NotificationContext';
import { useTeamId } from './Team';

export const DataAppContext = createContext({});

export const notificationConnection = new NotificationConnection();

const isTabHidden = () => {
  let hidden;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
  }

  return document[hidden];
};

const updateStepStatusIfNeeded = (id, draftState, newStatus, newStatusAt) => {
  const lastUpdatedAt = get(draftState, `step_status.${id}.status_at`);
  let shouldUpdate = 1;
  if (lastUpdatedAt) {
    shouldUpdate = compareAsc(new Date(newStatusAt), new Date(lastUpdatedAt));
  }

  if (shouldUpdate === 1) {
    set(draftState, `step_status.${id}`, {
      status: newStatus,
      status_at: newStatusAt,
    });
  }
};

const updateRunStatusIfNeeded = (
  instanceId,
  draftState,
  newStatus,
  newStatusAt
) => {
  const runList = draftState.runList || [];
  for (let i = 0; i < runList.length; i++) {
    if (runList[i].instance_id === instanceId) {
      const lastUpdatedAt = runList[i].status_at;
      let shouldUpdate = 1;
      if (lastUpdatedAt) {
        shouldUpdate = compareAsc(
          new Date(newStatusAt),
          new Date(lastUpdatedAt)
        );
      }

      if (shouldUpdate === 1) {
        runList[i].status = newStatus;
        runList[i].status_at = newStatusAt;
      }
    }
  }
};

export const DASHBOARD_URL_POSTFIX = 'dashboard';

const getInstanceIdToFetch = (instanceId, currentAppStatus) => {
  if (!instanceId && currentAppStatus === APP_STATUS.DRAFT) {
    return 'setup';
  }

  return instanceId;
};

export const DataAppContextProvider = ({ children, appId, instanceId }) => {
  const teamId = useTeamId();
  const [isLoading, setIsLoading] = useState(true);
  const [dataAppContextValue, updateDataAppContextValue] = useImmer({});
  const history = useHistory();
  const versionId = getVersionId(dataAppContextValue.data);
  const currentAppStatus = getAppStatus(dataAppContextValue.data);
  const LastViewedStatusKey = 'DataAppLastViewedStatus_' + appId;

  const fetchAppInfo = async (
    providedInstanceId,
    updateLoadingState = true,
    resetAuxiliaryState = false,
    abortSignal
  ) => {
    updateLoadingState && setIsLoading(true);

    let endpoint = `/app/?app_id=${appId}`;
    /** Which app instance should we fetch */
    if (providedInstanceId && providedInstanceId !== DASHBOARD_URL_POSTFIX) {
      endpoint += `&instance_id=${providedInstanceId}`;
    }
    /** If user is logged into to a team then send it */
    if (teamId) endpoint += `&team_id=${teamId}`;

    /** Which app status should we fetch */
    let appStatusToFetch;
    const lastViewedStatus = await getValue(LastViewedStatusKey);
    if (instanceId) {
      appStatusToFetch = APP_STATUS.PUBLISHED;
      if (instanceId === 'setup') {
        appStatusToFetch = APP_STATUS.DRAFT;
      } else if (instanceId === DASHBOARD_URL_POSTFIX) {
        setValue(LastViewedStatusKey, APP_STATUS.PUBLISHED);
        appStatusToFetch = APP_STATUS.PUBLISHED;
      } else {
        appStatusToFetch = APP_STATUS.RUN;
      }
    } else if (
      lastViewedStatus === APP_STATUS.PUBLISHED ||
      getViewingAppAsPublic()
    ) {
      appStatusToFetch = APP_STATUS.PUBLISHED;
    }

    if (appStatusToFetch) endpoint += `&status=${appStatusToFetch}`;

    const [appInfo, error] = await makeAPICall({
      endpoint,
      errorNotification: true,
      signal: abortSignal,
    });

    if (get(error, 'fullError.name') === 'AbortError') {
      return;
    }

    updateDataAppContextValue((draftState) => {
      if (resetAuxiliaryState) {
        draftState.variableValues = {};
        draftState.step_status = {};
      }
      draftState.data = appInfo;
      draftState.error = error;
    });
    updateLoadingState && setIsLoading(false);
  };

  const getStepForId = (draftState, id) => {
    const steps = get(draftState, `data.workflow.steps`);
    const step = steps.find((step) => step.id === id);
    return step;
  };

  const getNotificationProcessor = (abortSignal) => async (
    notification = {}
  ) => {
    const { node, done, message, status_at } = notification;
    if (message === 'Welcome') {
      fetchAppInfo(instanceId, false, false, abortSignal);
      return;
    }

    if (node === null && done) {
      updateDataAppContextValue((draftState) => {
        updateRunStatusIfNeeded(instanceId, draftState, done, status_at);
      });
    }

    if (!isUndefined(node) && !isUndefined(done)) {
      const isSuccess = done === 'SUCCESS';
      let stepDoesNotExist = !getStepForId(dataAppContextValue, node);
      if (stepDoesNotExist && isSuccess) {
        await fetchAppInfo(instanceId, false, false, abortSignal);
      }

      updateDataAppContextValue((draftState) => {
        const step = getStepForId(draftState, node);

        updateStepStatusIfNeeded(node, draftState, done, status_at);
        if (
          isSuccess &&
          isTabHidden() &&
          !browserNotificationPermissionNotReceieved()
        ) {
          // SHOW DESKTOP NOTIFICATION
          new Notification(`"${get(step, 'name', 'Step')}" Complete`, {
            body: `Step is done running.`,
            icon: `${BLOCK_ICON_S3_PREFIX}fallback-icon.png`,
            tag: 'stepRunningNotification',
            silent: true,
          });
        }
        const { outputs } = step || {};
        if (outputs) {
          if (isSuccess) {
            outputs.forEach((variableId) =>
              set(draftState, ['variableValues', variableId], undefined)
            );
            fetchLatestStepVariableMetadata(
              appId,
              getInstanceIdToFetch(instanceId, currentAppStatus),
              node,
              updateDataAppContextValue
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchAppInfo(instanceId, true, true, signal);

    return () => {
      notificationConnection.close();
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceId]);

  useEffect(() => {
    updateDataAppContextValue((draftState) => {
      const steps = get(draftState, `data.workflow.steps`);
      if (!steps) return;
      steps.forEach(({ id, status, status_at }) => {
        updateStepStatusIfNeeded(id, draftState, status, status_at);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(dataAppContextValue, `data.workflow.steps`)]);

  useEffect(() => {
    const validInstanceId = instanceId !== DASHBOARD_URL_POSTFIX;
    if (!versionId || !validInstanceId || isNull(teamId)) return;

    console.log('connecting...', appId, versionId, instanceId);

    const controller = new AbortController();
    const signal = controller.signal;
    const notificationProcessor = getNotificationProcessor(signal);

    notificationConnection.connect(
      appId,
      versionId,
      getInstanceIdToFetch(instanceId, currentAppStatus),
      teamId,
      notificationProcessor
    );

    return () => {
      controller.abort();
    };
  }, [appId, versionId, instanceId]);

  let wrapperClass = 'data-app-context-loading-wrapper';
  if (currentAppStatus === APP_STATUS.DRAFT) {
    wrapperClass += ' data-app-draft-wrapper';
  }

  if (!isLoading && dataAppContextValue.error) {
    if (get(dataAppContextValue, 'error.code') === 'UNAUTHORIZED') {
      return (
        <Result
          status="403"
          title="Can't access this app!"
          style={{ margin: 'auto' }}
          subTitle="Please contact the owner of the app and make sure you have edit, use, or view access to this app."
          extra={
            <Button onClick={() => history.push('/apps/')} type="primary">
              Back Home
            </Button>
          }
        />
      );
    } else {
      return (
        <Result
          status="500"
          title="Something went wrong while trying to open this app!"
          style={{ margin: 'auto' }}
          subTitle={get(
            dataAppContextValue,
            'error.message',
            DEFAULT_ERROR_MESSAGE
          )}
          extra={
            <Button onClick={() => history.push('/apps/')} type="primary">
              Back Home
            </Button>
          }
        />
      );
    }
  }

  return (
    <DataAppContext.Provider
      value={{
        ...dataAppContextValue,
        updateDataAppContextValue,
        fetchAppInfo,
        LastViewedStatusKey,
        isLoading,
        setIsLoading,
      }}
    >
      <Spin wrapperClassName={wrapperClass} spinning={isLoading}>
        {children}
      </Spin>
    </DataAppContext.Provider>
  );
};

export const useAppInfo = () => {
  const { data } = useContext(DataAppContext);
  return data || {};
};

export const useStepStatuses = () => {
  const { step_status } = useContext(DataAppContext);
  return step_status || {};
};

export const useIsPublishedOrRunVersionOpen = () => {
  const { status } = useMetadata();
  return status === APP_STATUS.PUBLISHED || status === APP_STATUS.RUN;
};

export const useIsDataAppLoading = () => {
  const { isLoading } = useContext(DataAppContext);
  return isLoading;
};

export const useIsPublishedVersionOpen = () => {
  const { status } = useMetadata();
  return status === APP_STATUS.PUBLISHED;
};

export const useIsRunVersionOpen = () => {
  const { status } = useMetadata();
  return status === APP_STATUS.RUN;
};

export const useGoToEditableApp = () => {
  const appId = useAppId();
  const history = useHistory();
  const {
    updateDataAppContextValue,
    LastViewedStatusKey,
    setIsLoading,
  } = useContext(DataAppContext);
  return async () => {
    setIsLoading(true);
    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/edit?app_id=${appId}`,
      method: 'POST',
      errorNotification:
        'You might not have edit access to this app. Please contact the app owner or support at hello@intersectlabs.io',
    });
    updateDataAppContextValue((draftState) => {
      if (!error) {
        setValue(LastViewedStatusKey, APP_STATUS.DRAFT);
        draftState.variableValues = {};
        draftState.step_status = {};
        draftState.data = appInfo;
        history.push(`/apps/${appId}/setup`);
      }
      setIsLoading(false);
    });
  };
};

export const usePublishApp = () => {
  const appId = useAppId();
  const history = useHistory();
  const { updateDataAppContextValue, LastViewedStatusKey } = useContext(
    DataAppContext
  );
  return async () => {
    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/publish?app_id=${appId}`,
      method: 'POST',
      body: {},
    });

    setValue(LastViewedStatusKey, APP_STATUS.PUBLISHED);
    history.push(`/apps/${appId}/${DASHBOARD_URL_POSTFIX}`);

    updateDataAppContextValue((draftState) => {
      if (!error) {
        draftState.variableValues = {};
        draftState.step_status = {};
        draftState.data = appInfo;

        window.analytics.track('Published App', {
          app_id: appId,
        });
      }
    });
  };
};

export const useRunApp = () => {
  const appId = useAppId();
  const history = useHistory();
  const { updateDataAppContextValue, setIsLoading } = useContext(
    DataAppContext
  );
  return async (totalRunCountBeforeRun) => {
    setIsLoading(true);
    const [appInfo, error] = await makeAPICall({
      endpoint: `/app/run?app_id=${appId}`,
      method: 'POST',
    });
    const { metadata = {} } = appInfo || {};
    window.analytics.track('Run App', {
      app_id: appId,
      run_count: totalRunCountBeforeRun,
      run_name: metadata.instance_name,
    });

    updateDataAppContextValue((draftState) => {
      if (!error) {
        const instanceId = get(appInfo, 'metadata.instance_id');
        if (!instanceId) return;
        draftState.variableValues = {};
        draftState.step_status = {};
        draftState.data = appInfo;
        history.push(`/apps/${appId}/${instanceId}`);
      }

      setIsLoading(false);
    });
  };
};
