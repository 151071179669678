import React from 'react';
import styled from 'styled-components';
import { SubText } from './typography';
import { format } from 'date-fns';

const AppCardDetailsText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

export const CardCreationInfo = ({
  createdBy,
  lastUpdatedTime,
  isMissingZ = false,
}) => {
  let dateFormattedString = '';

  try {
    dateFormattedString = format(
      new Date(`${lastUpdatedTime}${isMissingZ ? 'Z' : ''}`),
      'PPp'
    );
  } catch (e) {
    console.log('Could not format date string');
  }

  return (
    <AppCardDetailsText>
      <SubText title={createdBy}>{createdBy}</SubText>
      <SubText>{dateFormattedString}</SubText>
    </AppCardDetailsText>
  );
};
