import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { GalleryPageTitle } from '../standard-components/typography';
import {
  GalleryContentHeader,
  GalleryPageContainer,
} from '../standard-components/containers';
import INTEGRATION_TYPES from '../integration-components/integrationTypes';
import IntegrationForm from '../integration-components/IntegrationForm';

const ButtonContainer = styled.div`
  width: 150px;
`;
const BackButton = styled(Button)`
  color: ${({ theme: { color } }) => color.grayscale.darkish};
`;

const BackButtonAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export default () => {
  const history = useHistory();
  const { integrationTypeId, integrationId = null } = useParams();
  const updatingExisting = !!integrationId;
  const integrationTypeInfo = INTEGRATION_TYPES[integrationTypeId];
  const integrationName = integrationTypeInfo.name;
  return (
    <GalleryPageContainer>
      <GalleryContentHeader>
        <BackButtonAndTitleContainer>
          <ButtonContainer>
            <BackButton onClick={() => history.push('/integrations')}>
              Back
            </BackButton>
          </ButtonContainer>
          <GalleryPageTitle>
            {updatingExisting
              ? `Edit ${integrationName} Integration`
              : `Add ${integrationName}`}
          </GalleryPageTitle>
          <ButtonContainer />
        </BackButtonAndTitleContainer>
      </GalleryContentHeader>
      <IntegrationForm
        integrationType={integrationTypeId}
        integrationId={integrationId}
        redirectToIntegrations={true}
      />
    </GalleryPageContainer>
  );
};
