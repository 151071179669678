import React, { useContext, useState } from 'react';
import Draft from 'draft-js';
import StepContext, {
  useIsInsideDashboard,
  useStepInputValue,
} from '../../StepContext';
import { SCHEMA_INPUT_TYPE } from '../../../../model/SchemaRendererContext';
import { RichTextEditor } from './RichTextEditor';
import styled from 'styled-components';
import { CollapseExpandTriangle } from '../../CollapseExpandHeader';
import { useEffect } from 'react';
import { StepBodyContainer } from '../../../../standard-components/containers';
import { StepActionButtons } from '../../StepBody/StepActionButtons';
import { isUndefined } from 'lodash';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

const CommentStepContainer = styled(StepBodyContainer)`
  position: relative;
  overflow-y: ${(p) => (!p.expanded ? 'hidden' : 'auto')};
  height: ${(p) => !p.expanded && '120px'};
  background-color: ${(p) => p.theme.color.grayscale.white};
  border-color: ${(p) => p.focused && p.theme.color.grayscale.faintGray1};

  .rdw-editor-wrapper {
    display: flex;
    flex-direction: column;
  }

  .rdw-editor-main {
    order: 1;
    height: auto;
    // Sets the default font size for the editor
    font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};

    .rdw-right-aligned-block .public-DraftStyleDefault-ltr {
      text-align: right;
    }

    .rdw-center-aligned-block .public-DraftStyleDefault-ltr {
      text-align: center;
    }

    .rdw-left-aligned-block .public-DraftStyleDefault-ltr {
      text-align: left;
    }

    .public-DraftStyleDefault-ltr {
      text-align: inherit !important;
    }
  }

  .rdw-editor-toolbar {
    order: 2;
    background: ${(p) => p.theme.color.tertiary.dull};
    margin: 5px -25px 5px;

    .rdw-option-wrapper {
      border: 0.5px solid transparent;
      background: transparent;
      padding: 15px;
      border-radius: 4px;
    }

    .rdw-option-wrapper:hover {
      box-shadow: 1px 1px 4px #bfbdbd;
      background: ${(p) => p.theme.color.grayscale.white};
    }

    .rdw-option-wrapper.rdw-option-active {
      border: 0.5px solid ${(p) => p.theme.color.grayscale.dark};
      background: ${(p) => p.theme.color.grayscale.white};
      box-shadow: none;
    }

    .rdw-dropdown-selectedtext {
      color: ${(p) => p.theme.color.grayscale.dark};
    }

    .rdw-emoji-modal,
    .rdw-image-modal {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
        0px 1px 8px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    }
  }
`;

const EditorContainer = styled.div`
  padding: 2px 30px 0px !important;
`;

const ActionButtonContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: flex-end;
`;

const { EditorState, convertToRaw, convertFromRaw } = Draft;

const serializeContentState = (editorState) =>
  JSON.stringify(convertToRaw(editorState.getCurrentContent()));

const deserializeContentState = (rawContentState) =>
  convertFromRaw(JSON.parse(rawContentState));

export const CommentStepInner = ({
  rawContentState,
  readOnly = false,
  onFocus = () => {},
  onBlur = () => {},
  actionButtons = null,
  inputId = 'note',
  isDraft,
}) => {
  const [currentRawContentState, setCurrentRawContentState] = useState();
  const { updateSentenceInputValues, stepIsEditable } = useContext(StepContext);
  // We need to store editor state (but we want to inject the content state)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const initializeEditorState = () => {
    let newEditorState = EditorState.createEmpty();
    try {
      const contentState = deserializeContentState(rawContentState);
      newEditorState = EditorState.createWithContent(contentState);
    } catch {}
    setEditorState(newEditorState);

    !readOnly &&
      updateSentenceInputValues({
        [inputId]: {
          type: SCHEMA_INPUT_TYPE.STATIC,
          value: serializeContentState(newEditorState),
        },
      });
  };

  useEffect(() => {
    if (
      isUndefined(currentRawContentState) ||
      (isUndefined(rawContentState) && isDraft)
    ) {
      // Only do it on component load, otherwise you'll overwrite text written during the API call
      initializeEditorState();
      setCurrentRawContentState(rawContentState);
    }
  }, [rawContentState]);

  const onChange = (newEditorState) => {
    if (readOnly) return;
    // 1. update the Step-level state with the serialized content
    const actualContentChanged =
      serializeContentState(newEditorState) !==
      serializeContentState(editorState); // NOT cursor change
    if (actualContentChanged) {
      updateSentenceInputValues({
        [inputId]: {
          type: SCHEMA_INPUT_TYPE.STATIC,
          value: serializeContentState(newEditorState),
        },
      });
    }

    // 2. Update editor-level state (eg, where the cursor is, what's selected) which contains the contentState
    setEditorState(newEditorState);
  };

  return (
    <>
      <EditorContainer>
        <Editor
          key={'comment' + inputId}
          readOnly={readOnly || !stepIsEditable}
          editorState={editorState}
          onEditorStateChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          toolbarHidden={readOnly}
          toolbarCustomButtons={[
            !readOnly && (
              <ActionButtonContainer>{actionButtons}</ActionButtonContainer>
            ),
          ]}
        />
      </EditorContainer>
    </>
  );
};

const CommentStep = ({
  isHiddenStep,
  stepIsExpanded,
  setStepIsExpanded,
  shouldShowPublishedVersion,
  inputId = 'note',
  isDraft,
}) => {
  const isInsideDashboard = useIsInsideDashboard();
  useEffect(() => {
    setStepIsExpanded(true);
  }, []);
  const [focused, setFocused] = useState(false);
  const rawContentState = (useStepInputValue(inputId) || {}).value;

  if (isHiddenStep) return <></>;

  const CollapseTriangleStyle = {
    position: 'absolute',
    top: 13,
    left: 10,
    padding: '8px 2px 8px 8px',
    marginLeft: -10,
    marginRight: 0,
    minWidth: 'auto',
    maxWidth: 'auto',
  };

  return (
    <CommentStepContainer
      isInsideDashboard={isInsideDashboard}
      expanded={stepIsExpanded}
      shouldShowPublishedVersion={shouldShowPublishedVersion}
      focused={focused}
    >
      {!shouldShowPublishedVersion && (
        <CollapseExpandTriangle
          isExpanded={stepIsExpanded}
          style={CollapseTriangleStyle}
          onClick={() => setStepIsExpanded(!stepIsExpanded)}
        />
      )}
      <CommentStepInner
        rawContentState={rawContentState}
        readOnly={!stepIsExpanded || shouldShowPublishedVersion}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        actionButtons={<StepActionButtons noPadding />}
        inputId={inputId}
        isDraft={isDraft}
      />
    </CommentStepContainer>
  );
};

export const CommentStepV1 = (props) =>
  CommentStep({ ...props, inputId: 'comment_content' });

export default CommentStep;
