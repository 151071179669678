/* eslint-disable import/no-anonymous-default-export */
import { get } from 'lodash';
import { useState, useEffect } from 'react';
import { makeAPICall } from '../../../../../api/useAPI';
import { useValuesForIds } from '../../../../../model/SchemaRendererContext';

export default (options = []) => {
  const optionsNeedsAPICall = get(options, 'type') === 'API_CALL';
  const [finalOptions, setFinalOptions] = useState(
    !optionsNeedsAPICall && options
  );
  const [isLoadingOptions, setIsLoadingOptions] = useState(optionsNeedsAPICall);
  const inputsToQueryParamsIds = Object.values(
    get(options, 'inputs_to_query_params', {})
  );
  const dependentInputValues = useValuesForIds(inputsToQueryParamsIds);
  useEffect(() => {
    const fetchOptionsFromEndpoint = async () => {
      setIsLoadingOptions(true);
      const { url, query_params, inputs_to_query_params } = options || {};
      let endpoint = url;
      if (query_params) {
        endpoint += '?';
        const paramsFormatted = Object.keys(query_params).map(
          (param) => `${param}=${query_params[param]}`
        );
        endpoint += paramsFormatted.join('&');
      }
      if (inputs_to_query_params) {
        endpoint += '&';
        const inputParamKeys = Object.keys(inputs_to_query_params);
        const inputBasedParamsFormatted = inputParamKeys.map((param) => {
          const dependentId = inputs_to_query_params[param];
          const currentInputValue = dependentInputValues[dependentId];
          return `${param}=${currentInputValue}`;
        });
        endpoint += inputBasedParamsFormatted.join('&');
      }
      const [newOptions, error] = await makeAPICall({ endpoint });

      setFinalOptions(newOptions);
      setIsLoadingOptions(false);
    };

    if (optionsNeedsAPICall) {
      let allValuesAreReady = true;
      inputsToQueryParamsIds.forEach((id) => {
        if (dependentInputValues[id] === undefined) {
          allValuesAreReady = false;
        }
      });

      if (allValuesAreReady) {
        fetchOptionsFromEndpoint();
      }
    } else {
      setFinalOptions(options);
      setIsLoadingOptions(false);
    }
  }, [options, dependentInputValues]);

  return [finalOptions, isLoadingOptions];
};
