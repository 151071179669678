import React from 'react';

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

const LinkCellRenderer = (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <a href={cellValue} target="_blank" rel="noopener noreferrer">
      {cellValue}
    </a>
  );
};

export default LinkCellRenderer;
