import { Button, Result } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .ant-result-subtitle {
    max-width: 730px;
    font-size: 16px;
    margin-top: 10px;
  }
`;

export default () => {
  const { status } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const integrationType = query.get('type');
  const isSuccess = status === 'success';
  const typeFormatted = capitalize(integrationType);

  return (
    <Container>
      {isSuccess ? (
        <Result
          status="success"
          title={`Successfully added the ${typeFormatted} Integration!`}
          subTitle="You can close this tab now and go back to the main tab."
        />
      ) : (
        <Result
          status="warning"
          title={`We couldn't add the ${typeFormatted} Integration`}
          subTitle={`Please make sure you went through the authorization flow correctly. You can close this tab and try again. 
            If you continue to have issues, please contact contact hello@intersectlabs.io`}
        />
      )}
    </Container>
  );
};
