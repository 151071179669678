import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';

const CardActionButton = styled.div`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 8px rgba(0, 0, 0, 0.08);
  height: 25px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  font-size: 24px;
  color: ${({ theme: { color } }) => color.grayscale.faintGray1};

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18), 1px 2px 15px rgba(0, 0, 0, 0.1);
  }

  :active {
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  }
`;

export default (props) => (
  <CardActionButton {...props}>
    <EllipsisOutlined />
  </CardActionButton>
);
