import { Breadcrumb, Empty, Input } from 'antd';
import { last } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { getChildCategoriesInPath } from '../../model/blockCategories';
import BlockCard from './BlockCard';

const BlockCategoryExplorerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${(p) => p.theme.color.grayscale.extraLightGray};
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px 0px 10px;
  margin: 2px;
`;

const CurrentCategoryOptions = styled.div`
  flex-grow: 1px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: auto;

  > .ant-empty {
    margin: auto;
  }

  > * {
    flex-basis: 43%;

    @media (max-width: 784px) {
      flex-basis: 100%;
    }

    @media (min-width: 1304px) {
      flex-basis: 46%;
    }
  }

  ::after {
    content: '';
    pointer-events: none;
    height: 60px;
    width: 100%;
  }
`;

const FeaturedBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 5px;
  padding-left: 0px;
  background: ${(p) => p.theme.color.grayscale.faintGray4};
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;

  > * {
    min-width: 33%;
  }
`;

const FeaturedBlocks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  > * {
    min-width: 33%;
  }
`;

const FeaturedBlocksTitle = styled.div`
  padding: 10px 0px 12px;
  text-align: center;
  background-color: ${(p) => p.theme.color.grayscale.faintGray1};
  color: transparent;
  font-size: 21px;
  font-weight: 900;
  text-shadow: -1px 1px 3px hsla(0, 0%, 100%, 0.5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CurrentFilterTitle = styled.div`
  color: ${(p) => p.theme.color.tertiary.darkish};
  padding: 0px 10px;

  > * {
    font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  }
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const getBreadcrumbs = (filterPath, onCategorySelect) => {
  const allBlocks = 'All Blocks';
  const adjustedPath = [allBlocks, ...filterPath];

  return (
    <Breadcrumb>
      {adjustedPath.map((item) => {
        const id = item === allBlocks ? null : item;
        return (
          <Breadcrumb.Item key={item}>
            <a onClick={() => onCategorySelect(id)}>{item}</a>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

const BlockCategoryExplorer = ({
  allPossiblePaths = [],
  filterPath = [],
  updateFilterPath,
  searchFilter,
  setSearchFilter,
  transformCardsForPath,
  featuredTransformCardsForPath = [],
}) => {
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const onCategorySelect = (id) => {
    setSearchFilter('');

    if (id === null) {
      updateFilterPath(() => []);
      return;
    }

    const index = filterPath.indexOf(id);
    if (index !== -1) {
      updateFilterPath((path) => {
        path.splice(index + 1);
      });
      return;
    }

    updateFilterPath((path) => {
      path.push(id);
    });
  };

  let childCategoriesInPath = getChildCategoriesInPath(filterPath);
  childCategoriesInPath = childCategoriesInPath.filter((category) => {
    const categoryPath = [...filterPath, category.id].toString();
    return allPossiblePaths.includes(categoryPath);
  });

  const currentRefineOptions = childCategoriesInPath.map(
    ({ id, display, description, icon }) => (
      <BlockCard
        title={display || id}
        key={id + 'option'}
        subtitle={description}
        icon={
          <img
            alt={`${display || id} icon`}
            src={icon || require('../../images/category-icons/blank-icon.svg')}
          />
        }
        onSelect={() => onCategorySelect(id)}
      />
    )
  );

  const noCurrentRefineOptions = currentRefineOptions.length === 0;

  let currentSearchTitle = [];
  if (searchFilter && searchFilter.length > 0)
    currentSearchTitle = [`Searching for "${searchFilter}"...`];

  let transformCardsForPathAdjusted = transformCardsForPath;
  if (transformCardsForPath.length === 0) {
    let description = `Couldn't find any blocks."`;
    if (searchFilter && searchFilter.length > 0) {
      description = `Couldn't find any blocks for "${searchFilter}"...`;
    }
    transformCardsForPathAdjusted = <Empty description={description} />;
  }

  return (
    <BlockCategoryExplorerWrapper>
      <Header>
        <TitleBar>
          <CurrentFilterTitle>
            {getBreadcrumbs(
              [...filterPath, ...currentSearchTitle],
              onCategorySelect
            )}
          </CurrentFilterTitle>
        </TitleBar>
        <Input.Search
          ref={searchInputRef}
          placeholder={`Find ${last(filterPath) || 'any'} block...`}
          onChange={(e) => setSearchFilter(e.target.value)}
          style={{ width: 250, borderRadius: 16 }}
          allowClear
          value={searchFilter}
        />
      </Header>
      {featuredTransformCardsForPath.length > 0 && (
        <FeaturedBlocksWrapper>
          <FeaturedBlocksTitle>Suggested Blocks</FeaturedBlocksTitle>
          <FeaturedBlocks>{featuredTransformCardsForPath}</FeaturedBlocks>
        </FeaturedBlocksWrapper>
      )}
      <CurrentCategoryOptions>
        {noCurrentRefineOptions || searchFilter
          ? transformCardsForPathAdjusted
          : currentRefineOptions}
      </CurrentCategoryOptions>
    </BlockCategoryExplorerWrapper>
  );
};

export default BlockCategoryExplorer;
