import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useStatusIndicatorElements } from '../Step/StepBody/StepStatusIndicator';

const StatusIconHolder = styled.div`
  padding-right: 10px;
`;

const AppRunEntryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 15px 20px 10px;
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  border-bottom: 1px solid ${(p) => p.theme.color.primary.light};
  cursor: pointer;
  background-color: ${(p) => p.selected && p.theme.color.primary.darkest};
  color: ${(p) => p.selected && p.theme.color.grayscale.white};

  :last-child {
    margin-bottom: 40px;
  }

  :hover {
    background: ${(p) => !p.selected && p.theme.color.primary.lightest};
  }

  :active {
    background: ${(p) => !p.selected && p.theme.color.primary.light};
  }
`;

const AppRunElementsRowHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const AppRunElementsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const AppRunEntryTitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
`;

const AppRunEntrySubtitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
`;

export default ({
  created_time,
  email,
  instance_name,
  instance_id,
  status,
  navigateToRunInstance,
}) => {
  const [statusIcon] = useStatusIndicatorElements(status);
  let { instanceId: currentlyOpenInstanceId } = useParams();
  const selected = currentlyOpenInstanceId === instance_id;

  return (
    <AppRunEntryWrapper
      selected={selected}
      onClick={() => navigateToRunInstance(instance_id)}
    >
      <StatusIconHolder>{statusIcon}</StatusIconHolder>
      <AppRunElementsRowHolder>
        <AppRunElementsRow>
          <AppRunEntryTitle>{instance_name || instance_id}</AppRunEntryTitle>
          <AppRunEntrySubtitle>
            {format(new Date(`${created_time}`), 'PP')}
          </AppRunEntrySubtitle>
        </AppRunElementsRow>
        <AppRunElementsRow>
          <AppRunEntrySubtitle>{email}</AppRunEntrySubtitle>
          <AppRunEntrySubtitle>
            {format(new Date(`${created_time}`), 'p')}
          </AppRunEntrySubtitle>
        </AppRunElementsRow>
      </AppRunElementsRowHolder>
    </AppRunEntryWrapper>
  );
};
