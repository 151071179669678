/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useRef } from 'react';
import { Row, Col, Space } from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';
import {
  useMetadata,
  useRunBy,
} from '../../model/DataAppMetadataContextHelpers';
import { useHistory } from 'react-router';
import { Steps } from '../Steps';
import { useIsRunVersionOpen } from '../../model/DataAppContext';
import { PrimaryShadowButton } from '../../standard-components/CustomColorButton';
import { formatUTCDateStr } from '../utils';
import { get } from 'lodash';

const RunInstancePage = styled.div`
  background-color: ${(p) => p.theme.color.primary.lighter};
  height: 100vh;
  width: 100vw;
  color: ${(p) => p.theme.color.grayscale.dark};
  overflow: auto;
`;

const RunInstancePageContent = styled(Col)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 80px;
  margin-bottom: 30px;

  ::after {
    content: ' ';
    min-height: 80px;
  }
`;

const StepsDisplay = styled.div`
  background: ${(p) => p.theme.color.grayscale.white};
  box-shadow: ${(p) =>
    p.shadow &&
    '0px 3px 7px -3px rgba(0, 0, 0, 0.2), 0px 3px 12px -2px rgba(50, 50, 93, 0.25)'};
  border-radius: 30px;
  padding: 15px 50px;

  @media (max-width: 1224px) {
    padding: 15px;
  }
`;

const AppButton = styled(PrimaryShadowButton)`
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
`;

const AppInfoRow = styled(Space)`
  align-self: center;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.color.primary.blackout};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  background: ${(p) => p.theme.color.grayscale.white};
  padding: 10px 20px 10px 10px;
  border-radius: 32px;
`;

const Title = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
`;

const Subtitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
`;

export default ({
  isEmbedded = false,
  stepsOnly = false,
  hideShowAllSteps = false,
  noShadow = false,
  doNotShowInteractiveTag = false,
}) => {
  const { name, instance_name, run_at, id: appId } = useMetadata();
  const isRunVersionOpen = useIsRunVersionOpen();
  const history = useHistory();
  const pageContentRef = useRef(null);
  const runBy = useRunBy();
  const pageContentHeight =
    pageContentRef.current && pageContentRef.current.scrollHeight;
  useEffect(() => {
    if (isEmbedded && pageContentRef.current) {
      window.parent.postMessage(
        {
          type: 'DATA_APP_LOADED',
          scrollHeight: pageContentHeight,
        },
        '*'
      );
    }
  }, [isEmbedded, pageContentHeight]);

  if (!isRunVersionOpen) return <></>;

  const navigateToAppDashboard = () => {
    history.push(`/apps/${appId}/dashboard`);
  };

  const stepsDisplay = (
    <StepsDisplay shadow={!noShadow}>
      <Steps
        hideShowAllSteps={hideShowAllSteps}
        doNotShowInteractiveTag={doNotShowInteractiveTag}
      />
    </StepsDisplay>
  );

  if (stepsOnly) {
    return stepsDisplay;
  }
  return (
    <RunInstancePage>
      <Row justify="center" ref={pageContentRef}>
        <RunInstancePageContent xs={2} sm={22} md={22} lg={20} xl={18} xxl={16}>
          {!isEmbedded && (
            <AppInfoRow size="large" align="baseline">
              <AppButton
                onClick={navigateToAppDashboard}
                shape="round"
                size="large"
              >
                {name}
              </AppButton>
              <Title id="run-instance-name">{instance_name}</Title>
              <Subtitle>{runBy}</Subtitle>
              <Subtitle>{`Run at ${format(
                formatUTCDateStr(run_at),
                'PPp'
              )}`}</Subtitle>
            </AppInfoRow>
          )}
          {stepsDisplay}
        </RunInstancePageContent>
      </Row>
    </RunInstancePage>
  );
};
