import React from 'react';
import { Route } from 'react-router-dom';
import AppsPage from './AppListPage';
import StudioPage from './StudioPage';
import {
  PageWrapper,
  PageWrapperContent,
} from '../standard-components/containers';
import { TeamContextProvider } from '../model/Team';
import IntegrationListPage from './IntegrationListPage';
import IntegrationTypeOptionsPage from './IntegrationTypeOptionsPage';
import IntegrationAddPage from './IntegrationAddPage';
import {
  BillingContextProvider,
  useBillingInfo,
} from '../model/BillingContext';
import SettingsPage from './SettingsPage';
import StyleGuide from '../theming/StyleGuide';
import DuplicatingAppLoadingScreen from './DuplicatingAppLoadingScreen';
import IntegrationAddCompleteMessage from '../integration-components/IntegrationAddCompleteMessage';
import WelcomeTour from './WelcomeTour';
import RequiresSubscriptionModal from '../auth-action-components/RequiresSubscriptionModal';
import { get } from 'lodash';

const pathConfig = {
  '/duplicate/:app_id_to_duplicate': {
    component: DuplicatingAppLoadingScreen,
    exact: true,
  },
  '/public/apps/:appId/': { component: StudioPage, exact: true },
  '/public/apps/:appId/:instanceId': { component: StudioPage, exact: true },
  '/apps': { component: AppsPage, exact: true },
  '/apps/:appId/': { component: StudioPage, exact: true },
  '/apps/:appId/:instanceId': { component: StudioPage, exact: true },
  '/apps/:appId/?sandbox=1': { component: StudioPage, exact: true },
  '/integrations': { component: IntegrationListPage, exact: true },
  '/integrations/add/': { component: IntegrationTypeOptionsPage, exact: true },
  '/integrations/add/:integrationTypeId/': {
    component: IntegrationAddPage,
    exact: true,
  },
  '/integrations/oauth/complete/:status': {
    component: IntegrationAddCompleteMessage,
  },
  '/integrations/add/:integrationTypeId/:integrationId': {
    component: IntegrationAddPage,
    exact: true,
  },
  '/settings': { component: SettingsPage, exact: true },
  '/settings/:tabId/:subTabId?': { component: SettingsPage },
  '/core/style': { component: StyleGuide, exact: true },
};

const paths = Object.keys(pathConfig);

const NoSubscriptionBlocker = ({ children }) => {
  const { subscription_details: currentSubscription = {} } = useBillingInfo();

  if (
    ['canceled', 'past_due'].includes(
      get(currentSubscription, 'subscription_status')
    )
  ) {
    return <RequiresSubscriptionModal visible message="NoSubscription" />;
  }

  return <>{children}</>;
};

export default () => {
  const routes = paths.map((path) => (
    <Route key={path} {...pathConfig[path]} path={path} />
  ));

  return (
    <TeamContextProvider>
      <BillingContextProvider>
        <NoSubscriptionBlocker>
          <PageWrapper>
            <PageWrapperContent>{routes}</PageWrapperContent>
          </PageWrapper>
        </NoSubscriptionBlocker>
      </BillingContextProvider>
    </TeamContextProvider>
  );
};
