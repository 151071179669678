import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, message, Dropdown } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { CACHE_POLICY, useAPI } from '../api/useAPI';
import {
  GalleryPageTitle,
  SectionTitle,
  Text,
} from '../standard-components/typography';
import {
  CardContainer,
  GalleryContentHeader,
  GalleryPageContainer,
} from '../standard-components/containers';
import INTEGRATION_TYPES from '../integration-components/integrationTypes';
import { CardCreationInfo } from '../standard-components/CardCreationInfo';
import CardActionButton from '../standard-components/CardActionButton';
import { useTeamId } from '../model/Team';
import LoadingSplash from '../standard-components/LoadingSplash';
import useRefreshMessage from '../standard-components/useRefreshMessage';
import IntegrationCardMenu from '../app-list-page-components/IntegrationCardMenu';
import { set } from 'lodash';
import { PrimaryShadowButton } from '../standard-components/CustomColorButton';

const PREVIEW_CARD_WIDTH = '373px';
const PREVIEW_CARD_HEIGHT = '165px';
const IntegrationCardContainer = styled(CardContainer)`
  margin-right: 40px;
  margin-bottom: 40px;
  padding: 20px 25px;
  min-width: ${PREVIEW_CARD_WIDTH};
  max-width: ${PREVIEW_CARD_WIDTH};
  min-height: ${PREVIEW_CARD_HEIGHT};
  max-height: ${PREVIEW_CARD_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;
`;

const IntegrationCardAccessories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const IntegrationGalleryContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
`;

const ZeroStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: 24px;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  background-color: ${(p) => p.theme.color.grayscale.white};
  padding: 20px 30px;
  border: 1px solid ${(p) => p.theme.color.grayscale.faintGray4};
  border-radius: 6px;

  > span {
    margin-bottom: 24px;
  }
`;

const IntegrationCardHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const IntegrationLogoImage = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 24px;
`;

const IntegrationDropdown = styled(Dropdown)``;

const AddIntegrationButton = styled(PrimaryShadowButton)`
  height: 40px;
  border-radius: 3px;
`;

const IntegrationCard = ({
  name,
  id,
  integration_type,
  created_by,
  updated_time,
  onUpdateComplete,
}) => {
  const history = useHistory();
  const integrationTypeInfo = INTEGRATION_TYPES[integration_type] || {};
  const [integrationName, setIntegrationName] = useState(name);

  useEffect(() => {
    setIntegrationName(name);
  }, [name]);

  return (
    <IntegrationCardContainer
      onClick={() =>
        history.push(`/integrations/add/${integration_type}/${id}`)
      }
    >
      <IntegrationCardHeader>
        <IntegrationLogoImage src={integrationTypeInfo.image} />
        <SectionTitle bold grayscale darker>
          {integrationName}
        </SectionTitle>
      </IntegrationCardHeader>
      <IntegrationCardAccessories>
        <CardCreationInfo
          createdBy={created_by}
          lastUpdatedTime={updated_time}
          isMissingZ
        />
        <IntegrationDropdown
          overlay={
            <IntegrationCardMenu
              integrationId={id}
              integrationName={name}
              setNewIntegrationNameForIntegrationCard={setIntegrationName}
              onUpdateComplete={onUpdateComplete}
            />
          }
          trigger={['click']}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CardActionButton />
        </IntegrationDropdown>
      </IntegrationCardAccessories>
    </IntegrationCardContainer>
  );
};

const IntegrationsGallery = ({ integrationFilterText }) => {
  const teamId = useTeamId();
  const [integrationsList, { isLoading, refetch }] = useAPI({
    endpoint: `/integration/list?team_id=${teamId}`,
    cachePolicy: CACHE_POLICY.CACHE_THEN_NETWORK,
    errorNotification:
      'Something went wrong while trying to fetch your integrations, please try again or contact hello@intersectlabs.io',
  });
  // Displays refresh message automatically if cache is being refreshed
  useRefreshMessage(integrationsList && isLoading);

  if (!integrationsList) {
    return (
      <LoadingSplash tip="Loading Integrations..." style={{ height: '30%' }} />
    );
  }

  if (integrationsList.length === 0) {
    return (
      <ZeroStateContainer>
        <span>Looks like you don't have access to any integrations yet!</span>
        <CreateIntegrationButton />
      </ZeroStateContainer>
    );
  }
  return (
    <IntegrationGalleryContainer>
      {integrationsList
        .filter(({ name }) =>
          name.toLowerCase().includes(integrationFilterText.toLowerCase())
        )
        .map((integration) => (
          <IntegrationCard
            key={integration.id}
            {...integration}
            onUpdateComplete={() => refetch()}
          />
        ))}
    </IntegrationGalleryContainer>
  );
};

const CreateIntegrationButton = () => {
  const history = useHistory();
  return (
    <AddIntegrationButton
      onClick={() => history.push('/integrations/add/')}
      icon={<PlusOutlined />}
      size="large"
    >
      Add Integration
    </AddIntegrationButton>
  );
};

const GallerySearchBar = styled(Input)`
  width: 163px;
  height: 40px;
  margin-right: 15px;
  border-radius: 20px;

  .anticon {
    color: ${(p) => p.theme.color.grayscale.faintGray1};
    margin-right: 5px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SearchAndAddContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default () => {
  const [integrationFilterText, setIntegrationFilterText] = useState('');

  useEffect(() => {
    window.analytics.page('Integration List Page');
  }, []);

  return (
    <GalleryPageContainer>
      <GalleryContentHeader>
        <HeaderContainer>
          <GalleryPageTitle>Integrations</GalleryPageTitle>
          <SearchAndAddContainer>
            <GallerySearchBar
              value={integrationFilterText}
              onChange={({ target }) => setIntegrationFilterText(target.value)}
              placeholder="Search"
              prefix={<SearchOutlined />}
              size="large"
            />
            <CreateIntegrationButton />
          </SearchAndAddContainer>
        </HeaderContainer>
      </GalleryContentHeader>
      <IntegrationsGallery integrationFilterText={integrationFilterText} />
    </GalleryPageContainer>
  );
};
