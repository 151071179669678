import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Input, Card, Button } from 'antd';
import firebase from 'firebase/app';
import { fetchUserAndToken } from '../model/User';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loading from '../standard-components/Loading';
import { themeColors } from '../colors/themeColors';
import { systemColors } from '../colors/systemColors';
import { parse as queryStringParse } from 'query-string';

export const getContinueToURLParam = (location) => {
  return queryStringParse(location.search).continueTo;
};

var firebaseConfig = {
  apiKey: 'AIzaSyCcG3VHVrZhW_x1R1aZ-VV-UzP-eAn5pZw',
  authDomain: 'intersect-labs.firebaseapp.com',
  databaseURL: 'https://intersect-labs.firebaseio.com',
  projectId: 'intersect-labs',
  storageBucket: 'intersect-labs.appspot.com',
  messagingSenderId: '815566872801',
};
firebase.initializeApp(firebaseConfig);

const SubmitButton = styled(Button)`
  margin: 15px;
`;

const Logo = styled.img`
  margin-top: 50px;
  width: 195px;
`;

const LoginForm = styled(Card)`
  max-width: 100%;
  width: 460px;
  margin: auto;
  padding: 10px 30px;
  background: ${systemColors.background.fill};
  margin-top: 3%;
  border-radius: 4px;
  border: 1px solid ${systemColors.background.border};
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 25px;
`;

const ForgotPassword = styled(Link)`
  margin: 10px;
  font-size: 14px;
  color: ${themeColors.primary.base};
  cursor: pointer;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

const TCPP = styled.p`
  margin: 20px 0px;
  font-size: 14px;
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: red;
  margin: 10px;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignUpButton = () => {
  const history = useHistory();
  const location = useLocation();
  const continueTo = getContinueToURLParam(location);
  if (continueTo) {
    return (
      <Button
        onClick={() =>
          history.replace(
            `/join-us?continueTo=${encodeURIComponent(continueTo)}`
          )
        } // todo
      >
        Sign Up
      </Button>
    );
  }

  return <Button onClick={() => history.replace(`/join-us`)}>Sign Up</Button>;
};

const LoginPage = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    window.analytics.page('Login');
  }, []);

  useEffect(() => {
    setLoginError('');
  }, [emailAddress, password]);

  const onSubmit = () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, password)
      .then((firebaseUser) => {
        fetchUserAndToken({
          setLoading,
          userInfoCallback: () => {
            window.analytics.track('Logged In');
          },
        });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        if (
          ['auth/wrong-password', 'auth/user-not-found'].includes(errorCode)
        ) {
          setLoginError('Either your password or email was incorrect');
        } else if (error.message) {
          setLoginError(error.message);
        }
        setLoading(false);
      });
  };

  return (
    <StyledDiv>
      <Logo src={theme.logos.fullWordmark} title="Intersect Labs" />
      <LoginForm>
        <Title>Sign in</Title>
        <StyledInput
          placeholder="Email address"
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
          size="large"
        />
        <StyledInput.Password
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          size="large"
        />
        <ErrorText>{loginError}</ErrorText>
        <TCPP>
          By signing in, you confirm that you agree with the{' '}
          <a
            href="https://www.intersectlabs.io/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://www.intersectlabs.io/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </TCPP>
        {loading ? (
          <Loading style={{ height: 'inherit' }} />
        ) : (
          <SubmitButton type="primary" onClick={onSubmit}>
            Log In
          </SubmitButton>
        )}
        <ForgotPassword to={`/reset-password`}>
          Forgot your password?
        </ForgotPassword>
      </LoginForm>
      <LoginForm>
        <Title>Haven't signed up yet?</Title>
        <Button
          href="https://calendly.com/intersect-labs/hello"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book an Onboarding Call
        </Button>
      </LoginForm>
    </StyledDiv>
  );
};

export default LoginPage;
