import React, { useState } from 'react';
import styled from 'styled-components';
import MLTrainingVaultGraphic from '../../images/MLTrainingVaultGraphic.svg';
import { last } from 'lodash';
import { Upload, Button } from 'antd';
import { makeAPICall } from '../../api/useAPI';
const Dragger = Upload.Dragger;

const DraggerContent = styled(Dragger)`
  color: #1890ff !important;
  font-size: 16px;
  font-weight: 300;
  background: #00346b !important;
  border-radius: 6px;
  box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.25);
  margin-top: 25px !important;
  padding: 40px 50px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: #1890ff !important;

  :hover,
  :active {
    border-color: #ffffff !important;
  }

  span {
    color: #1890ff !important;
  }
`;

const DraggerContentTitle = styled.div`
  font-weight: 600;
  margin-top: 45px;
`;

const DraggerContentSubtitle = styled.div`
  max-width: 360px;
  display: inline-block;
`;

const UploadButton = styled(Button)`
  margin-top: 25px;
`;

const Graphic = styled.img`
  max-width: 100%;
`;

export default ({ encryptedString, addFilesToUploadedList }) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState();

  const draggerProps = {
    name: 'file',
    action: '/data/upload',
    fileList,
    showUploadList: true,
    beforeUpload: () => false,
    onChange: (info) => {
      const latestFile = last(info.fileList);

      if (latestFile === undefined) {
        setFileList([]);
      } else {
        setFileList(info.fileList);
      }
    },
  };

  const uploadFiles = async () => {
    if (!fileList || fileList.length === 0) return;

    setIsUploading(true);

    const fileNames = [];
    const formData = new FormData();
    fileList.forEach((file) => {
      fileNames.push(file.name);
      formData.append('files', file.originFileObj, file.name);
    });
    formData.append('string', encryptedString);

    makeAPICall({
      endpoint: '/close/lead-file-upload',
      method: 'POST',
      body: formData,
    })
      .then(({ input }) => {
        setIsUploading(false);
        setFileList([]);
        addFilesToUploadedList(fileNames);
      })
      .catch((error) => {
        setIsUploading(false);
        setError(error);
        console.error(`Error uploading demo call data`, error);
      });
  };

  return (
    <div className="demo-call-uploader">
      <DraggerContent {...draggerProps}>
        <Graphic
          src={MLTrainingVaultGraphic}
          title="Upload your historical data to our ML Training Vault"
        />
        <DraggerContentTitle>
          Click or drag and drop files here
        </DraggerContentTitle>
        <DraggerContentSubtitle>
          Add the historical data that we’ll use to train your machine learning
          model. For extra security, we use 64-bit TLS security protocols to
          upload your data.
        </DraggerContentSubtitle>
      </DraggerContent>
      {/* </Dragger> */}
      <UploadButton
        loading={isUploading}
        type="primary"
        size="large"
        onClick={uploadFiles}
        disabled={fileList.length === 0}
      >
        {isUploading ? 'Uploading...' : 'Start Secure Data Upload'}
      </UploadButton>
    </div>
  );
};
