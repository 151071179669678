import { MoreOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import blockTypes from '../../../model/blockTypes';
import { StepBodyContainer } from '../../../standard-components/containers';
import { CollapseExpandHeader } from '../CollapseExpandHeader';
import { StepActionButtons } from './StepActionButtons';
import StepRenderer from '../StepRenderers/StepRenderer';
import { TagLabel } from '../../../standard-components/typography';

const StepBodyContentContainer = styled.div`
  padding: ${(p) => !p.hasNoPadding && '20px 30px 0px'};
`;

const HiddenStep = styled.div``;

const InputTagLabel = styled(TagLabel)`
  background-color: #edf9f6;
  color: #009973;
`;

export default ({
  name,
  type,
  isDraft,
  isHiddenStep,
  saveTitleChange,
  stepIsExpanded,
  setStepIsExpanded,
  shouldShowPublishedVersion,
}) => {
  const hasNoPadding = get(blockTypes, `[${type}].hasNoPadding`, false);
  const isInput = type === blockTypes.INPUT.id;

  return (
    <StepBodyContainer
      expanded={stepIsExpanded}
      shouldShowPublishedVersion={shouldShowPublishedVersion}
    >
      {isHiddenStep ? (
        <HiddenStep>
          <MoreOutlined />
        </HiddenStep>
      ) : (
        <>
          <CollapseExpandHeader
            title={name}
            isDraft={isDraft}
            onClick={() => setStepIsExpanded(!stepIsExpanded)}
            isExpanded={stepIsExpanded}
            saveTitleChange={saveTitleChange}
            leftLabel={isInput && <InputTagLabel>Import</InputTagLabel>}
          />
          {stepIsExpanded && (
            <StepBodyContentContainer hasNoPadding={hasNoPadding}>
              <StepRenderer />
              <StepActionButtons addLeftMargin={hasNoPadding} />
            </StepBodyContentContainer>
          )}
        </>
      )}
    </StepBodyContainer>
  );
};
