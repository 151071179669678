/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import styled from 'styled-components';
import { useVariablesForTheseOutputVariableIds } from '../../../model/DataAppVariableContextHelpers';
import { StepOutput } from './StepOutput';

const StepOutputsContainer = styled.div`
  margin-bottom: 25px;
  width: 100%;

  > * {
    margin-top: ${(p) => !p.noExtraMargin && '15px'};
  }
`;

export default ({
  isOfLeafStep,
  outputs,
  stepIsEditable,
  expandedByDefault,
  onlyShowingInputsAndOutputs = false,
}) => {
  const outputVariablesForStep = useVariablesForTheseOutputVariableIds(outputs);
  return (
    <StepOutputsContainer noExtraMargin={onlyShowingInputsAndOutputs}>
      {outputVariablesForStep.map((outputVariable) => (
        <React.Fragment key={outputVariable.name}>
          {/* <ConnectorLine isOutput /> */}
          <StepOutput
            isOfLeafStep={isOfLeafStep}
            onlyShowingInputsAndOutputs={onlyShowingInputsAndOutputs}
            canEditName={stepIsEditable}
            initiallyExpanded={expandedByDefault}
            key={outputVariable.name}
            {...outputVariable}
          />
        </React.Fragment>
      ))}
    </StepOutputsContainer>
  );
};
