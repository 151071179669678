import React, { Component } from 'react';
import { Button, Card, Input, Space } from 'antd';
import styled from 'styled-components';
import IntersectLogo from '../images/Logo.svg';
import firebase from 'firebase/app';
import { systemColors } from '../colors/systemColors';

const Logo = styled.img`
  margin: 50px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const FormContainer = styled(Card)`
  max-width: 100%;
  width: 400px;
  margin: auto;
  padding: 10px 30px;
  background: ${systemColors.background.fill};
  margin-top: 3%;
  border-radius: 4px;
  border: 1px solid ${systemColors.background.border};
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

const SubmitButton = styled(Button)`
  margin: 15px;
`;

const Title = styled.p`
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 300;
  margin-bottom: 25px;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin: 10px;
`;

const PasswordInput = styled(StyledInput.Password)`
  width: 300px;
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordOne: '',
      passwordTwo: '',
      error: '',
      success: false,
    };
  }

  handleChangeInput = (field, value) => {
    this.setState({
      [field]: value,
      error: '',
    });
  };

  handlePasswordChange() {
    if (this.state.passwordOne === this.state.passwordTwo) {
      firebase
        .auth()
        .verifyPasswordResetCode(this.props.actionCode)
        .then(
          function (email) {
            firebase
              .auth()
              .confirmPasswordReset(
                this.props.actionCode,
                this.state.passwordOne
              )
              .then(
                function (resp) {
                  this.setState({ success: true });
                }.bind(this)
              )
              .catch(
                function (error) {
                  this.setState({
                    error: 'There was an error. Please contact system admin.',
                  });
                }.bind(this)
              );
          }.bind(this)
        )
        .catch(
          function (error) {
            this.setState({
              error: 'There was an error. Please contact system admin.',
            });
          }.bind(this)
        );
    } else {
      this.setState({
        error: "Passwords don't match",
      });
    }
  }

  whatToShow() {
    if (this.state.success) {
      return (
        <StyledDiv>
          <Logo src={IntersectLogo} title="Intersect Labs" />
          <FormContainer>
            <p>Your password has been reset</p>
          </FormContainer>
        </StyledDiv>
      );
    } else {
      return (
        <StyledDiv>
          <Logo src={IntersectLogo} title="Intersect Labs" />
          <FormContainer>
            <Title>Reset Password</Title>
            <Space direction="vertical" size="large">
              <PasswordInput
                placeholder="Password"
                onChange={(e) => {
                  this.handleChangeInput('passwordOne', e.target.value);
                }}
              />
              <PasswordInput
                placeholder="Re-enter password"
                onChange={(e) => {
                  this.handleChangeInput('passwordTwo', e.target.value);
                }}
              />
            </Space>

            <ErrorText>{this.state.error}</ErrorText>
            <SubmitButton
              onClick={() => {
                this.handlePasswordChange();
              }}
            >
              Reset password
            </SubmitButton>
          </FormContainer>
        </StyledDiv>
      );
    }
  }

  render() {
    return this.whatToShow();
  }
}

export default ResetPassword;
