import { Checkbox } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import StepContext from '../../StepContext';
import { SentenceInput } from '../SentenceRenderers/SentenceRendererCore';
import EmailToSelf from './EmailToSelf';

const SUBTYPE_TO_RENDERER_MAPPING = {
  SELF: EmailToSelf,
};

export default ({ schema }) => {
  const { subtype, user_provided, onUpdateUserProvidedFlag } = useContext(
    StepContext
  );

  const Renderer = SUBTYPE_TO_RENDERER_MAPPING[subtype];

  //   const onUserProvidedCheckboxToggle = (e) => {
  //     onUpdateUserProvidedFlag(e.target.checked);
  //   };

  if (!Renderer) return <SentenceInput schema={schema} />;
  return (
    <>
      <Renderer schema={schema} />
    </>
  );
};
