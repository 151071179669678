import React, { useContext } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import styled from 'styled-components';
import { TeamContext } from '../model/Team';
import { Popover } from 'antd';

const SidebarTeamPicker = styled.div`
  background-color: ${(p) => p.theme.color.primary.light};
  color: ${(p) => p.theme.color.primary.gentleText};
  font-size: ${(p) => p.theme.typography.fontSize.mediumSmall};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  border-radius: 5px 0px 0px 5px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s box-shadow;

  > span:first-child {
    align-self: flex-start;
  }

  > span.anticon {
    transition: 0.2s transform;
  }

  :hover {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);

    > span.anticon {
      transform: rotate(360deg);
    }
  }
`;

const TeamName = styled.div`
  color: ${(p) => p.theme.color.primary.darkest};
  margin-left: 10px;
  flex-grow: 1;
  line-height: 21px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Option = styled.div`
  color: ${(p) => p.theme.color.primary.darker};
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  padding: 5px 15px;
  margin: 5px 0px;
  transition: 0.3s all;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    color: ${(p) => p.theme.color.grayscale.white};
    background-color: ${(p) => p.theme.color.primary.darker};
  }
`;

const ActiveOption = styled(Option)`
  color: ${(p) => p.theme.color.grayscale.white};
  background-color: ${(p) => p.theme.color.primary.darker};
  cursor: default;
`;

const DisabledOption = styled.div`
  color: ${(p) => p.theme.color.grayscale.faintGray2};
  padding: 10px 15px;
`;

export default () => {
  const { currentTeam, teams, updateCurrentTeam } = useContext(TeamContext);

  const getOption = (team) => {
    const { id, name } = team || {};
    let OptionElement = Option;
    if (id === get(currentTeam, 'id')) {
      OptionElement = ActiveOption;
    }

    return (
      <OptionElement key={id} onClick={() => updateCurrentTeam(team)}>
        {name}
      </OptionElement>
    );
  };

  const teamOptionsMenu = (
    <Options>
      {teams ? (
        teams.map((team) => getOption(team))
      ) : (
        <DisabledOption>You're not on anyteams right now</DisabledOption>
      )}
    </Options>
  );

  return (
    <Popover content={teamOptionsMenu} placement="right">
      <SidebarTeamPicker>
        <span>Team</span>
        <TeamName>{get(currentTeam, 'name', 'Loading...')}</TeamName>
        <CaretRightOutlined />
      </SidebarTeamPicker>
    </Popover>
  );
};
