import { SettingOutlined, SettingTwoTone } from '@ant-design/icons';
import { Alert, Popover, Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import StepContext from './StepContext';

const InputConfigButton = styled.div`
  display: inline-block;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0px 3px 3px 0px;
  color: ${(p) => p.theme.color.grayscale.faintGray3};
  z-index: 300;
  height: 32px;
  padding: 4px 6px;
  cursor: pointer;
  transition: 0.2s all;
  margin-left: -8px;
  margin-right: 6px;

  :hover {
    border-color: ${(p) => p.theme.color.primary.bright};
    color: ${(p) => p.theme.color.primary.bright};
  }
`;

const InputConfigPanelWrapper = styled.div`
  padding: ${(p) => p.standalone && '15px 5px'};
`;

const InputConfigPanel = ({ pathToInput, doNotHideBehindIcon = false }) => {
  const { user_provided, interactive, updateInputInteractivity } = useContext(
    StepContext
  );

  const isInteractive = interactive[pathToInput];
  const onInputConfigButtonClick = (e) => {
    e.stopPropagation();
  };

  const configPanel = (
    <InputConfigPanelWrapper standalone={doNotHideBehindIcon}>
      {user_provided ? (
        <Alert
          message="The whole step is set to be interactive. Remove this setting in the step settings to only make specific inputs interactive."
          type="info"
          showIcon
        />
      ) : (
        <Checkbox
          checked={isInteractive}
          onChange={(e) =>
            updateInputInteractivity(pathToInput, e.target.checked)
          }
        >
          Should be interactive in app
        </Checkbox>
      )}
    </InputConfigPanelWrapper>
  );

  if (doNotHideBehindIcon) return configPanel;

  let currentIcon = <SettingOutlined />;
  if (isInteractive) currentIcon = <SettingTwoTone />;

  return (
    <Popover content={configPanel} trigger="click" placement="right">
      <InputConfigButton onClick={onInputConfigButtonClick}>
        <Tooltip title="Configure this input to show up as interactive in the published app.">
          {currentIcon}
        </Tooltip>
      </InputConfigButton>
    </Popover>
  );
};

export default InputConfigPanel;
