import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Input, Space, Button, Spin, Typography } from 'antd';
import styled from 'styled-components';
import NewSubscriptionSummary from './NewSubscriptionSummary';
import CurrentSubscriptionSummary from './CurrentSubscriptionSummary';
import { systemColors } from '../colors/systemColors';
import { makeAPICall } from '../api/useAPI';
import { useTeamId } from '../model/Team';
import { useBillingInfo, useUpdateCreditCard } from '../model/BillingContext';
import { get } from 'lodash';

const CheckoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 40px;
  background: ${systemColors.background.fill};
  border: 1px solid #e9f4ff;
  overflow: auto;

  > * {
    width: 45%;
    min-width: 242px;
  }
`;

const SpacedContainer = styled(Space)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 20px;
  min-width: 330px;
  background: #ffffff;
  width: 45%;
`;

const SectionTitle = styled.div`
  color: #000;
  font-weight: 600;
`;

const Title = styled.div`
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: ${systemColors.error.hot};
`;

const CurrentCardView = styled(SpacedContainer)`
  justify-content: space-between;

  > :first-child {
    flex: 1;
  }
`;

const getFullCheckoutInputs = (
  fullName,
  setFullName,
  organization,
  setOrganization
) => [
  <Input
    key="Name"
    placeholder="Full name..."
    value={fullName}
    onChange={(e) => {
      setFullName(e.target.value);
    }}
  />,
  <Input
    key="Org"
    placeholder="Organization..."
    value={organization}
    onChange={(e) => {
      setOrganization(e.target.value);
    }}
  />,
];

const PresetEmail = ({ email }) => <Input value={email} disabled />;

const CheckoutPaymentForm = ({
  onSuccessfulPaymentMethodUpdate,
  setIsLoading,
  predefinedEmail,
  onlyCapturePaymentMethodWithCallback,
  creditCardOnFile = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [fullName, setFullName] = useState('');
  const [organization, setOrganization] = useState('');
  const [error, setError] = useState();
  const teamId = useTeamId();
  const updateCreditCard = useUpdateCreditCard();

  const invalidInput =
    !predefinedEmail && (fullName === '' || organization === '');

  const handleSubmit = async () => {
    setIsLoading(true);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setIsLoading(false);
      setError(error.message);
    } else if (onlyCapturePaymentMethodWithCallback) {
      onlyCapturePaymentMethodWithCallback(paymentMethod.id).finally(() =>
        setIsLoading(false)
      );
    } else {
      const ccUpdateError = await updateCreditCard(paymentMethod.id);

      if (ccUpdateError) {
        setError(ccUpdateError.detail);
        setIsLoading(false);
      } else {
        onSuccessfulPaymentMethodUpdate();
      }
    }
  };

  return (
    <SpacedContainer direction="vertical" size="middle">
      <SectionTitle>
        {creditCardOnFile ? 'Change' : 'Add'} Credit Card Details
      </SectionTitle>
      {predefinedEmail ? (
        <PresetEmail email={predefinedEmail} />
      ) : (
        getFullCheckoutInputs(
          fullName,
          setFullName,
          organization,
          setOrganization
        )
      )}
      <CardElement
        className="ant-input stripe-card-input"
        onChange={() => setError(undefined)}
        options={{
          style: {
            base: {
              fontFamily: 'Lato',
              '::placeholder': {
                color: '#bfbfbf',
              },
            },
          },
        }}
      />
      {error && <Error>{error}</Error>}
      <Button
        onClick={handleSubmit}
        type="primary"
        className="btn-bright-purple"
        disabled={!stripe || invalidInput}
      >
        Submit
      </Button>
    </SpacedContainer>
  );
};

let stripePromise;
if (
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_ENVIRONMENT === 'staging'
) {
  stripePromise = loadStripe('pk_test_aDLApTYedojjAi9zpiXYuIvm');
} else {
  stripePromise = loadStripe('pk_live_ZcwXJXqEODvvw8cwEpoZnE28');
}

const SubscriptionSummary = ({ product, showPlans }) => {
  return product && product.price_id ? (
    <NewSubscriptionSummary
      onChangePlanClick={showPlans}
      subscribingToProduct={product}
      borderTop
    />
  ) : (
    <CurrentSubscriptionSummary
      hideFreeTrialBanner
      onChangePlanClick={showPlans}
    />
  );
};

export default ({
  product,
  showPlans,
  onSuccessfulPaymentMethodUpdate,
  predefinedEmail = '',
  onlyCapturePaymentMethodWithCallback,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { credit_card_details = {} } = useBillingInfo();
  const creditCardOnFile = get(credit_card_details, 'last_four', null) !== null;
  const blank_credit_card_details = {
    brand: '',
    last_four: '',
    expiry_year: ' ',
    expiry_month: '',
  };
  const { brand, last_four, expiry_year, expiry_month } =
    credit_card_details || blank_credit_card_details;

  return (
    <Elements stripe={stripePromise}>
      {!predefinedEmail && <Title>Checkout</Title>}
      <Spin spinning={isLoading} tip="Updating payment method...">
        <CheckoutWrapper>
          <Space direction="vertical" size="middle">
            {!predefinedEmail && (
              <SubscriptionSummary product={product} showPlans={showPlans} />
            )}
            {creditCardOnFile && (
              <CurrentCardView>
                <span>Current Credit Card</span>
                <Typography.Text strong>{brand}</Typography.Text>
                <Typography.Text code>{last_four}</Typography.Text>
                <Typography.Text type="secondary">
                  {expiry_month}/{expiry_year}
                </Typography.Text>
              </CurrentCardView>
            )}
          </Space>
          <CheckoutPaymentForm
            onSuccessfulPaymentMethodUpdate={onSuccessfulPaymentMethodUpdate}
            setIsLoading={setIsLoading}
            predefinedEmail={predefinedEmail}
            onlyCapturePaymentMethodWithCallback={
              onlyCapturePaymentMethodWithCallback
            }
            creditCardOnFile={creditCardOnFile}
          />
        </CheckoutWrapper>
      </Spin>
    </Elements>
  );
};
