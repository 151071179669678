import { Alert, Button, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
`;

const SectionTitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;

  > span {
    margin-left: 10px;
    font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  }
`;

const SectionSubtitle = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.small};
  color: ${(p) => p.theme.color.grayscale.faintGray1};
  text-align: left;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const noSupportForBrowserNotifications = () => !('Notification' in window);
export const browserNotificationPermissionNotReceieved = () =>
  Notification.permission === 'denied' || Notification.permission === 'default';

export default () => {
  const [
    notificationPermissionGranted,
    setNotificationPermissionGranted,
  ] = useState();

  function handlePermission() {
    if (browserNotificationPermissionNotReceieved()) {
      setNotificationPermissionGranted(false);
    } else {
      setNotificationPermissionGranted(true);
    }
  }

  useEffect(() => {
    handlePermission();
  }, []);

  const requestPermissions = () => {
    function checkNotificationPromise() {
      try {
        Notification.requestPermission().then();
      } catch (e) {
        return false;
      }

      return true;
    }

    // Let's check if the browser supports notifications
    if (noSupportForBrowserNotifications()) {
      console.log('This browser does not support notifications.');
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  };

  let content = (
    <Alert
      message="Browser doesn't support notifications"
      description="Please check your browser settings to make sure that you are allowing browser notifications."
      type="error"
      showIcon
    />
  );

  if (!noSupportForBrowserNotifications()) {
    if (!notificationPermissionGranted) {
      content = (
        <Button type="primary" onClick={requestPermissions}>
          Enable Notifications
        </Button>
      );
    } else {
      content = (
        <Alert
          message="Browser Notifications Enabled"
          description="If you want to disable them, please disable them in your browser."
          type="success"
          showIcon
        />
      );
    }
  }

  return (
    <div>
      <SectionContainer>
        <SectionTitle>
          Browser Notifications <Tag color="gold">Beta</Tag>
        </SectionTitle>
        <SectionSubtitle>
          You'll receive browser notifications when a step in a data app
          finishes running if you have it open but have navigated to a different
          tab.
        </SectionSubtitle>
        {content}
      </SectionContainer>
    </div>
  );
};
