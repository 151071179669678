import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { WhitePrimaryShadowButton } from '../standard-components/CustomColorButton';
import welcomeTrioGIF from '../images/welcome-trio.gif';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { getContinueToURLParam } from './LoginPage';

const PageContainer = styled.div`
  background-color: ${(p) => p.color};
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.5s;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70vw;
  margin-bottom: 10px;
  margin-top: 6vh;
`;

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  max-width: 40vw;
`;

const Title = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.xl};
  color: ${(p) => p.theme.color.grayscale.white};
  text-align: center;
`;

const Subtitle = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.light};
  font-size: ${(p) => p.theme.typography.fontSize.mediumLarge};
  color: ${(p) => p.theme.color.grayscale.white};
  text-align: center;
  min-height: 66px;

  a {
    color: ${(p) => p.theme.color.grayscale.white} !important;
    text-decoration: underline;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.1s;

    :hover {
      border-bottom: 1px solid ${(p) => p.theme.color.grayscale.white};
    }
  }
`;

const ContentWrapper = styled.div`
  margin-top: 10px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  min-width: 940px;

  img {
    border-radius: 10px;
    max-width: 100%;
  }
`;

const steps = [
  {
    title: "Let's Get Started!",
    subtitle: 'Simplify you data workflows with data apps in three quick steps',
    component: (
      <img
        src={welcomeTrioGIF}
        alt="Add steps to build a data app and then publish and run it"
      />
    ),
    color: 'primary.darker',
  },
];

export default () => {
  const { color } = useTheme();
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const atLastStep = currentStep === steps.length - 1;

  const goToNextStep = () => {
    if (atLastStep) {
      const urlContinueToParam = getContinueToURLParam(location);
      history.push(urlContinueToParam || '/apps');
    } else {
      setCurrentStep((_step) => _step + 1);
    }
  };

  useEffect(() => {
    window.analytics.track('Viewed Welcome Tour', {
      currentStep,
    });
  }, [currentStep]);

  const { color: stepColor, title, subtitle, component } = steps[currentStep];

  return (
    <PageContainer color={get(color, stepColor)}>
      <Header>
        <HeaderColumn>
          {currentStep > 0 && (
            <WhitePrimaryShadowButton
              shape="round"
              icon={<ArrowLeftOutlined />}
              size="large"
              onClick={() => setCurrentStep((_step) => _step - 1)}
            >
              Back
            </WhitePrimaryShadowButton>
          )}
        </HeaderColumn>
        <HeaderColumn>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </HeaderColumn>
        <HeaderColumn>
          <WhitePrimaryShadowButton
            shape="round"
            icon={<ArrowRightOutlined />}
            size="large"
            onClick={goToNextStep}
          >
            {atLastStep ? "Let's Go!" : 'Next'}
          </WhitePrimaryShadowButton>
        </HeaderColumn>
      </Header>
      <ContentWrapper>{component}</ContentWrapper>
    </PageContainer>
  );
};
