import React, { useRef } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { createGlobalStyle } from 'styled-components';
import { get } from 'lodash';
import LinkCellRenderer, { isValidHttpUrl } from './LinkCellRenderer';

const GlobalStyle = createGlobalStyle`
  .ag-theme-alpine {
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 14px;
    text-align: center;
  }

  .ag-theme-alpine .ag-root-wrapper {
    border-color: #e5e7eb; 
    border-radius: 8px;
    background-color: #f9fafb;
  }

  .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-print .ag-center-cols-container {
    min-height: 50px;
  }

  .ag-theme-alpine .ag-ltr .ag-cell {
    border-right: 1px solid #dde2eb;
  }

  .ag-cell-wrapper {
    justify-content: center;
  }
   
  .ag-theme-alpine .ag-header {
    background-color: #f9fafb;
  }

  .ag-theme-alpine .ag-header-row {
    color: #6b7280;
  }

  .ag-header-group-cell-label, .ag-header-cell-label {
    justify-content: center;
  }

  .ag-theme-alpine .ag-header-cell-resize::after {
    top: 0;
    height: 100%;
  }
`;

const linkRendererSelection = {
  component: 'linkCellRenderer',
};

const AGDataTable = ({ columns, rowData, suggestedHeight }) => {
  const onGridReady = ({ api, columnApi }) => {
    if (columnApi) {
      columnApi.autoSizeAllColumns();
      const bodyWidth = get(columnApi, 'columnController.bodyWidth', 0);
      const viewportWidth = get(columnApi, 'columnController.viewportRight', 0);
      if (bodyWidth < viewportWidth) api.sizeColumnsToFit();
    }
  };

  const shouldHaveFixedHeight = rowData.length >= 5;
  const domLayout = shouldHaveFixedHeight ? 'normal' : 'autoHeight';
  const wrapperStyle = {
    height: shouldHaveFixedHeight && suggestedHeight,
    width: '100%',
  };

  const frameworkComponents = {
    linkCellRenderer: LinkCellRenderer,
  };

  const cellRendererSelector = (params) => {
    if (isValidHttpUrl(params.valueFormatted || params.value)) {
      return linkRendererSelection;
    }

    return null;
  };

  return (
    <div className="ag-theme-alpine" style={wrapperStyle}>
      <GlobalStyle />
      <AgGridReact
        rowData={rowData}
        onGridReady={onGridReady}
        domLayout={domLayout}
        frameworkComponents={frameworkComponents}
        enableCellTextSelection={true}
        ensureDomOrder={true}
      >
        {columns.map((col) => (
          <AgGridColumn
            headerName={col.name}
            field={`${col.key}`}
            key={col.key}
            resizable={true}
            cellRendererSelector={cellRendererSelector}
          />
        ))}
      </AgGridReact>
    </div>
  );
};

export default AGDataTable;
