import { get } from 'lodash';
import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import IntersectLogo from '../images/Logo.svg';
import { idToIcon } from '../model/blockCategories';
import { TopAttachedWhitePrimaryButton } from '../standard-components/CustomColorButton';
import BlockCard from '../studio-page-components/block-addition-components/BlockCard';
import fallbackIcon from '../images/fallback-icon.svg';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  BLOCK_GALLERY_URL_PREFIX,
  BLOCK_ICON_S3_PREFIX,
} from '../pages/BlockGallery';
import { Helmet } from 'react-helmet';

const BlockGalleryHome = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  padding: 0px 5vw 15vh;
  background: ${(p) => p.theme.color.primary.lightest};
  height: 100vh;
  overflow: auto;
`;

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20px;
`;

const Logo = styled.img`
  margin: 20px 0px 20px;
  max-width: 150px;
`;

const PageTitle = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.xl};
  font-weight: ${(p) => p.theme.typography.fontWeight.black};
  color: ${(p) => p.theme.color.primary.blackout};
  margin-top: 12vh;
`;

const PageDescription = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  color: ${(p) => p.theme.color.primary.gentleGrayishText};
  max-width: 700px;
  margin: 30px auto;
`;

const SectionTitle = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.large};
  font-weight: ${(p) => p.theme.typography.fontWeight.black};
  margin-top: 30px;
  margin-bottom: 10px;
  background-color: ${(p) => p.theme.color.grayscale.faintGray1};
  color: transparent;
  text-shadow: -1px 1px 3px hsla(0, 0%, 100%, 0.7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CategoryOptions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: left;

  > * {
    width: 350px;
  }
`;

export default ({ data = {} }) => {
  const { categoryNames = [], categories = {} } = data;
  const history = useHistory();
  const metaTitle = "Intersect's Block Gallery";
  const metaDescription =
    'Intersect makes building data workflows easy. Now, meet the building blocks that power these interactive data apps. Examples are live -- play around!';

  var isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender');

  return (
    <StyleSheetManager disableCSSOMInjection={isPrerender}>
      <BlockGalleryHome>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Intersect's Block Gallery</title>
          <meta name="description" content={metaDescription} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@intersectlabshq" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta
            name="twitter:image"
            content={`${BLOCK_ICON_S3_PREFIX}block-gallery-twitter-card.png`}
          />
          {/* <!-- Open Graph general (Facebook, Pinterest & Google+) --> */}
          <meta
            property="og:image"
            content={`${BLOCK_ICON_S3_PREFIX}open-graph-image-blog-gallery.png`}
          />
          <meta
            property="og:url"
            content="https://showcase.intersectlabs.io/gallery/blocks"
          />
          <meta property="og:title" content="Intersect's Block Gallery" />
          <meta property="og:description" content={metaDescription} />
          <meta name="og:type" content="website" />
          {/* <!-- Facebook Pixel Code --> */}
          <script>{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '232837258684241');
          fbq('track', 'PageView');
          `}</script>
          <noscript>{`<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=232837258684241&ev=PageView&noscript=1"
        />`}</noscript>
          {/* <!-- End Facebook Pixel Code --> */}
        </Helmet>
        <NavBar>
          <Logo src={IntersectLogo} title="Intersect Labs" />
          <TopAttachedWhitePrimaryButton>Sign Up</TopAttachedWhitePrimaryButton>
        </NavBar>
        <PageTitle>Block Gallery</PageTitle>
        <PageDescription>
          Check out the building blocks you can use to create interactive data
          apps in Intersect. Each of the examples is actually a data app, so try
          it out and see how each of the powerful building blocks work!
        </PageDescription>
        {categoryNames.map((category) => (
          <Row gutter={[32, 16]} justify="center">
            <Col xs={24} sm={22} md={22} lg={22} xl={18} xxl={18}>
              <SectionTitle>{category}</SectionTitle>
              <CategoryOptions>
                {categories[category].map(
                  ({ block_id, title, gallery_app = {} }) => {
                    const icon = (
                      <img
                        alt={title}
                        src={idToIcon[block_id] || fallbackIcon}
                      />
                    );
                    return (
                      <BlockCard
                        key={block_id}
                        title={title}
                        subtitle={gallery_app.meta_desc}
                        icon={icon}
                        onSelect={() =>
                          history.push(`${BLOCK_GALLERY_URL_PREFIX}${block_id}`)
                        }
                        boldTitle={true}
                        style={{ margin: 20 }}
                      />
                    );
                  }
                )}
              </CategoryOptions>
            </Col>
          </Row>
        ))}
      </BlockGalleryHome>
    </StyleSheetManager>
  );
};
