import styled, { useTheme } from 'styled-components';
import React, { useContext } from 'react';
import {
  CaretDownFilled,
  InfoCircleTwoTone,
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import EditableTitle from '../../standard-components/EditableTitle';
import { BORDER_RADIUS } from '../../standard-components/containers';
import StepStatusIndicator from './StepBody/StepStatusIndicator';
import StepContext, { useDefinedCtx } from './StepContext';
import PublishedStepHeader from '../published-components/PublishedStepHeader';
import {
  BLOCK_GALLERY_URL_DOMAIN,
  BLOCK_GALLERY_URL_PREFIX,
} from '../../pages/BlockGallery';
import { BlocksContext, useBlockGalleryURL } from '../../model/BlocksContext';
import { TagLabel } from '../../standard-components/typography';

export const CollapseExpandHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 46px;
  padding: 15px 10px;
  box-shadow: ${(p) =>
    !p.onlyShowShadowOnHover && '0px 1px 4px 0px rgba(0, 0, 0, 0.09)'};
  border-radius: ${(p) => (p.rounded ? '20px' : BORDER_RADIUS)};
  background-color: ${(p) => p.theme.color.grayscale.white};
  border-bottom-left-radius: ${(p) => p.isExpanded && '0px'};
  border-bottom-right-radius: ${(p) => p.isExpanded && '0px'};
  border-left: ${(p) => p.stepTypeColor && `1px solid ${p.stepTypeColor}`};
  margin: -1px -1px 0px -1px;
  transition: all 0.2s;
  z-index: 50;
  position: relative;

  :hover {
    box-shadow: 0px 0px 6px 0px rgba(23, 24, 24, 0.04),
      0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  }

  svg {
    transition: all 0.2s;
  }
`;

const ControlAndInfo = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const StatusIconWrapper = styled.div`
  margin-right: -35px;
  margin-left: 20px;
`;

const BlockInfo = styled(Tooltip)`
  margin-left: auto;
  border: none;
  border-radius: 90%;
  font-size: 18px;
`;

const OpenBlockGalleryAppButton = ({ blockGalleryURL }) => {
  return (
    <BlockInfo
      title="Learn more about how this block works."
      onClick={(e) => {
        window.open(blockGalleryURL, '_blank');
        e.stopPropagation();
      }}
    >
      <QuestionCircleTwoTone />
    </BlockInfo>
  );
};

export const CollapseExpandTriangle = ({
  isExpanded,
  style = {},
  onClick = () => {},
  color = '#C8C8C8',
}) => (
  <CaretDownFilled
    onClick={onClick}
    rotate={isExpanded ? 0 : -90}
    style={{
      // must sum to 34px (indentation of header / text)
      marginRight: 14,
      minWidth: 10,
      maxWidth: 10,
      // color: '#C8C8C8',
      color,
      ...style,
    }}
  />
);

export const CollapseExpandHeader = ({
  title,
  leftLabel,
  rightLabel,
  titleStyle = {},
  isDraft = false,
  onClick,
  isExpanded,
  saveTitleChange,
  stepTypeColor,
  showIcon = true,
  onlyShowShadowOnHover = false,
  rounded = false,
}) => {
  const { color } = useTheme();
  const { onlyShowInputsAndOutputs } = useContext(StepContext);
  const stepInfo = useDefinedCtx();
  const blockOperation = stepInfo.properties['operation'];
  const blockGalleryURL = useBlockGalleryURL(blockOperation);

  let statusIcon = <StepStatusIndicator iconOnly={true} />;

  if (onlyShowInputsAndOutputs) {
    return <PublishedStepHeader title={title} />;
  }

  return (
    <CollapseExpandHeaderContainer
      isExpanded={isExpanded}
      onClick={onClick}
      stepTypeColor={stepTypeColor}
      onlyShowShadowOnHover={onlyShowShadowOnHover}
      rounded={rounded}
    >
      <ControlAndInfo>
        <CollapseExpandTriangle isExpanded={isExpanded} color={stepTypeColor} />
        {leftLabel}
        <EditableTitle
          title={title}
          titleStyle={titleStyle}
          isEditable={isExpanded}
          saveTitleChange={saveTitleChange}
        />
        {isExpanded && blockGalleryURL !== undefined && (
          <OpenBlockGalleryAppButton blockGalleryURL={blockGalleryURL} />
        )}

        {isDraft && <Tag color={color.tertiary.bright}>Draft</Tag>}
      </ControlAndInfo>
      {rightLabel}
      {showIcon && <StatusIconWrapper>{statusIcon}</StatusIconWrapper>}
    </CollapseExpandHeaderContainer>
  );
};
